import { BaseRow } from '../tableHelpers';
import { SelectOperation } from './type';

export function applySelectionChange<RowT extends BaseRow>(
  previouslySelectedItems: RowT[],
  changeItems: RowT[],
  operation: SelectOperation,
): RowT[] {
  if (operation === SelectOperation.SELECT) {
    const itemsToSelect = changeItems.filter(item => !previouslySelectedItems.includes(item));
    return [...previouslySelectedItems, ...itemsToSelect];
  }

  return previouslySelectedItems.filter(item => !changeItems.includes(item));
}
