import React, { memo, FC } from 'react';
import { Typography, makeStyles, Divider } from '@material-ui/core';
import { Loader } from '@openx/components/core';

import {
  getOpenSubgroupDemographicAge,
  getOpenSubgroupDemographicGender,
  getSelectedAge,
  useAudience,
} from 'modules/audiences/AudienceProvider';

import { Age } from './Age';
import { Gender } from './Gender';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px',
  },
  applyButton: {
    alignSelf: 'flex-start',
    marginTop: '20px',
    height: '28px',
  },
  title: {
    marginBottom: '20px',
  },
  divider: {
    marginTop: '30px',
  },
});

export const Demographics: FC = memo((): JSX.Element | null => {
  const classes = useStyles();
  const { state } = useAudience();

  const selectedGender = getOpenSubgroupDemographicGender(state);
  const selectedAges = getOpenSubgroupDemographicAge(state);
  const selectedAgeEdit = getSelectedAge(state);

  return (
    <div className={classes.root}>
      <Typography variant="h3" gutterBottom data-test="demographics-title" className={classes.title}>
        Demographics
      </Typography>
      <Age value={selectedAges} editAge={selectedAgeEdit} />
      <Gender value={selectedGender} />
      <Divider className={classes.divider} />
    </div>
  );
});
