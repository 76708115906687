import React, { useCallback } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { AutoComplete, Button } from '@openx/components/core';

import { AudienceRow } from './AudiencesList';
import { useAudience, BulkOption, getBulkAction, ActionType, defaultBulkOption } from '../AudienceProvider';

const useStyles = makeStyles(theme => ({
  select: {
    minWidth: '180px',
    '& .MuiAutocomplete-endAdornment': {
      top: 'auto',
    },
  },
  textField: {
    '& input::placeholder': {
      opacity: 1,
    },
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  counter: {
    marginLeft: theme.spacing(2),
  },
}));

enum Actions {
  ARCHIVE = 'archive',
  SHARE = 'share',
}

interface Props {
  openArchiveConfirmationDialog: (audiences: AudienceRow[]) => void;
  openBulkShareConfirmationDialog: (audiences: AudienceRow[]) => void;
  audiences: AudienceRow[];
}

export const TableBulkActions = ({
  openArchiveConfirmationDialog,
  openBulkShareConfirmationDialog,
  audiences,
}: Props) => {
  const { select, button, counter, textField } = useStyles();

  const { state, dispatch } = useAudience();

  const bulkAction = getBulkAction(state);

  const options: BulkOption[] = [
    { label: 'Bulk Archive', value: Actions.ARCHIVE, function: openArchiveConfirmationDialog },
    { label: 'Bulk Share', value: Actions.SHARE, function: openBulkShareConfirmationDialog },
    defaultBulkOption,
  ];

  const handleChangeAction = (_, option, reason) => {
    if (reason === 'clear') {
      dispatch({ type: ActionType.RESET_BULK_ACTION_FIELD });
      return;
    }
    dispatch({ type: ActionType.SET_BULK_ACTION_FIELD, payload: { bulkAction: option } });
  };

  const handleApply = useCallback(() => {
    if (bulkAction) {
      bulkAction.function(audiences);
    }
  }, [bulkAction, audiences]);

  const filterOptions = options => {
    return options.filter(({ value }) => value !== '');
  };

  const applyDisabled = audiences.length < 1 || bulkAction === defaultBulkOption;

  return (
    <>
      <AutoComplete
        options={options}
        getOptionLabel={option => option?.label || ''}
        getOptionSelected={(option, value) => option?.value === value?.value}
        onChange={handleChangeAction}
        inputValue={bulkAction?.label || ''}
        value={bulkAction}
        textFieldProps={{ placeholder: 'Actions', className: textField }}
        filterOptions={filterOptions}
        className={select}
        disableClearable
        textFieldReadOnly
        data-test="bulk-actions-dropdown"
      />
      <Button
        color="primary"
        onClick={handleApply}
        disabled={applyDisabled}
        className={button}
        data-test="bulk-operation-button"
      >
        Apply
      </Button>
      <Typography variant="body1" className={counter} data-test="selected-items-label">
        Selected {audiences.length} item{audiences.length !== 1 && 's'}
      </Typography>
    </>
  );
};
