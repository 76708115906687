import React, { ReactNode, useMemo } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { Warning, Error } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { AlertTooltip } from 'components/AlertTooltip';
import { HoverableItem } from 'components/OptionsList';
import { segmentArchivedText, segmentExpiredText, segmentSoonWillBeExpired } from 'modules/segments/constants';
import { isExpired, isSoonToBeExpired, daysToExpiration } from 'utils/expiryDates';
import { EnhancedTreeData } from 'utils';

interface Props<K> {
  item: K;
  icon: ReactNode;
  indentationLevel?: number;
  onClick?: (item: K) => void;
  clickLabel: string;
  onDelete: (item: K) => void;
  title?: ReactNode;
  readonly?: boolean;
  isNavOnly?: boolean;
}

const useStyles = makeStyles({
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  textContainer: {
    alignItems: 'center',
    display: 'flex',
    wordBreak: 'break-word',
    maxWidth: 'max-content',
  },
  marginRight: {
    marginRight: '5px',
  },
});

export function Item<K extends EnhancedTreeData>(props: Props<K>): JSX.Element {
  const {
    icon,
    item,
    onClick,
    clickLabel,
    onDelete,
    title,
    indentationLevel = 0,
    isNavOnly = false,
    readonly = false,
  } = props;

  const { alertContainer, marginRight, textContainer } = useStyles({});

  const { status, expiration_date: expirationDate } = item;

  const showArchivedAlers = item.is_archived;
  const showExpiredAlert = isExpired(status) && !isNavOnly;
  const showExpireSoonAlert = isSoonToBeExpired(expirationDate, status);

  const soonWillBeExpiredText = useMemo(() => {
    const daysRemaining = daysToExpiration(expirationDate);
    return segmentSoonWillBeExpired(false, daysRemaining);
  }, [expirationDate]);

  const { archivedText, expiredText } = useMemo(
    () => ({
      expiredText: segmentExpiredText(false),
      archivedText: segmentArchivedText(false),
    }),
    [],
  );

  return (
    <HoverableItem
      icon={icon}
      indentationLevel={indentationLevel}
      onClick={onClick ? () => onClick(item) : undefined}
      onDelete={() => onDelete(item)}
      clickLabel={clickLabel}
      readonly={readonly}
      isNavOnly={isNavOnly}
    >
      <Grid container>
        <Grid className={textContainer} xs item>
          <Typography variant="body1" data-test="included-excluded-item" className={marginRight}>
            {title || item.title || item.name}
          </Typography>
        </Grid>
        <Grid className={alertContainer} xs={1} item>
          {showExpiredAlert && (
            <AlertTooltip
              color="#D83158"
              title={expiredText}
              icon={<Error fontSize="small" color="inherit" data-test="expired-alert" />}
            />
          )}
          {showExpireSoonAlert && (
            <AlertTooltip
              color="#E8B046"
              title={soonWillBeExpiredText}
              icon={<Warning fontSize="small" color="inherit" data-test="expire-soon-alert" />}
            />
          )}
          {showArchivedAlers && (
            <AlertTooltip
              color="#D83158"
              title={archivedText}
              icon={<Error fontSize="small" color="inherit" data-test="archived-alert" />}
            />
          )}
        </Grid>
      </Grid>
    </HoverableItem>
  );
}
