import React from 'react';
import { Columns } from '@openx/components/table';
import { ProviderRow } from './ProvidersList';
import { ProviderNameCell } from './components';

export const tableColumns: Columns<ProviderRow> = [
  {
    title: 'Name',
    key: 'name',
    width: '85%',
    render: ({ name, id }) => <ProviderNameCell name={name} id={id} />,
  },
  {
    title: 'Linked Organization',
    key: 'allowed_accounts',
    width: '15%',
    render: ({ allowed_accounts }) => <span>{allowed_accounts ? allowed_accounts.length : 0}</span>,
  },
];
