export const NAME_FIELD = 'name';
export const TYPE_FIELD = 'pmp_deal_type';
export const DEAL_PARTICIPANTS_FIELD = 'deal_participants';
export const DEAL_PARTICIPANTS_DEMAND_PARTNER_FIELD = 'demand_partner';
export const BUYER_IDS_FIELD = 'buyer_ids';
export const PRICE_FIELD = 'deal_price';
export const CURRENCY_FIELD = 'currency';
export const START_DATE_FIELD = 'start_date';
export const END_DATE_FIELD = 'end_date';
export const IS_PUB_VISIBLE_FIELD = 'is_pub_visible';
export const EXCHANGE_DEAL_ID_FIELD = 'exchange_deal_id';
export const DEAL_ID_PREFIX_FIELD = 'deal_id_prefix';
export const THIRD_PARTY_FEE_FIELD = 'third_party_revenue';
export const THIRD_PARTY_FEE_SHARE_FIELD = 'share';
export const THIRD_PARTY_FEE_PARTNER_FIELD = 'partner';
export const REVENUE_METHOD_FIELD = 'revenue_method';
export const CPM_CAP_FIELD = 'cpm_cap';
export const INCLUDE_DV360_PACKAGE_FIELD = 'enable_dv360_inventory_package';

export const DEAL_TYPE_OPEN_AUCTION = { name: 'Open Auction', value: '3' };
export const DEAL_TYPES = [DEAL_TYPE_OPEN_AUCTION];

export enum DealStatus {
  PAUSED = 'Paused',
  PUBLISHING = 'Publishing',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  ERROR = 'Error',
  READY_FOR_ACTIVATION = 'ReadyForActivation',
}

export enum RevenueMethod {
  POM = 'PoM',
  CPM = 'CPM',
}

export const START_DATE_DV360_ERROR =
  'Deal start date is required for all DV360 deals and must be within a year from today';
