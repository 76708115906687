import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Paper, TextField } from '@openx/components/core';

import { useAudience, ActionType, getName, getDescription, getTemplateName } from 'modules/audiences/AudienceProvider';

export const Details = (): JSX.Element => {
  const { state, dispatch } = useAudience();

  const name = getName(state);
  const description = getDescription(state);
  const templateName = getTemplateName(state);

  useEffect(() => {
    if (templateName === name) {
      dispatch({
        type: ActionType.SET_NAME,
        payload: { name: `Copy ${new Date().toLocaleString()} of ${state.name}` },
      });
    }
  }, [name]);

  return (
    <Paper>
      <Typography variant="h2" gutterBottom data-test="audience-details-title">
        Audience Details
      </Typography>

      <TextField
        value={name}
        label="Audience Name"
        placeholder="Type your audience name."
        required
        fullWidth
        margin="dense"
        data-test="audience-name"
        onChange={e => dispatch({ type: ActionType.SET_NAME, payload: { name: e.target.value } })}
      />

      <TextField
        value={description}
        label="Description"
        placeholder="Enter audience description here."
        fullWidth
        margin="dense"
        data-test="description-name"
        multiline
        minRows={4}
        maxRows={4}
        onChange={e => dispatch({ type: ActionType.SET_DESCRIPTION, payload: { description: e.target.value } })}
      />
    </Paper>
  );
};
