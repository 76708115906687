import { useApolloClient } from '@apollo/client';
import React, { useMemo } from 'react';
import GraphiQL from 'graphiql';
import { gql, execute, makePromise } from 'apollo-boost';
import { makeStyles } from '@material-ui/styles';
import 'graphiql/graphiql.min.css';

import { TOP_BAR_HEIGHT } from 'config';

const useStyles = makeStyles({
  container: {
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  },
  '@global': {
    '.doc-explorer-title-bar, .history-title': {
      height: '40px!important',
    },
    '.search-box-icon': {
      marginTop: '6px!important',
    },
  },
});
const makeGraphQLFetcher = apolloClient => async operation => {
  operation.query = gql(operation.query);

  return makePromise(execute(apolloClient.link as any, operation));
};

export function GraphiQLPage() {
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const graphQLFetch = useMemo(() => makeGraphQLFetcher(apolloClient), [apolloClient]);

  return (
    <div className={classes.container}>
      <GraphiQL fetcher={graphQLFetch as any} />
    </div>
  );
}

// Note:
//
// This page is a hack and should be removed sooner than later
// Make sure that script from index.html has been removed (fix for: GraphiQL fix Uncaught ReferenceError: global is not defined)
//   <script>
//     if (global === undefined) {
//       var global = window;
//     }
//   </script>
