import React from 'react';

import { AudienceProvider, State } from './AudienceProvider';

export function withAudience(WrappedComponent: React.ComponentType, initialState?: State) {
  return () => (
    <AudienceProvider initialState={initialState}>
      <WrappedComponent />
    </AudienceProvider>
  );
}
