import React, { createRef, useState, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Tooltip } from '@openx/components/core';

interface EllipsisProps {
  message?: string;
  children?: string | React.ReactNode;
  width: string | number;
  tooltip?: boolean;
}

const useStyles = makeStyles({
  ellipsisWrapper: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: ({ width }: EllipsisProps) => width,
  },
});

export const Ellipsis = (props: EllipsisProps): JSX.Element => {
  const { message, children, tooltip = false } = props;
  const ref = createRef<HTMLDivElement>();
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  const classes = useStyles(props);
  const text = message || children;
  const ellipsisText = (
    <div ref={ref} className={classes.ellipsisWrapper}>
      {text}
    </div>
  );

  useLayoutEffect(() => {
    if (ref.current) {
      if (ref.current.clientWidth < ref.current.scrollWidth) {
        setIsTextTruncated(true);
      }
    }
  }, [ref]);

  if (tooltip && isTextTruncated) {
    return (
      <Tooltip title={text as string} data-test={props['data-test']}>
        {ellipsisText}
      </Tooltip>
    );
  }

  return ellipsisText;
};
