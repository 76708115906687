import React, { FC, useState, useEffect, useCallback } from 'react';
import { Typography, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';

import {
  FeeType,
  AudienceWithFee,
  FeeHandler,
  BulkFeeHandler,
  useValidationInterface,
  RevenueMethod,
  RevenueMethodHandler,
  BulkRevenueMethodHandler,
} from './types';
import { SAME_FEE_INPUT } from './constants';
import { FeeInput } from './FeeInput';

interface StyleProps {
  feeType: FeeType;
  fromEditPage: boolean;
}

const useStyles = makeStyles(theme => ({
  feeToggle: {
    marginTop: '11px',
    marginBottom: theme.spacing(1.5),
  },
  audienceRow: ({ feeType, fromEditPage }: StyleProps) => ({
    display: fromEditPage ? 'block' : 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '32.5px',
    marginBottom: feeType === FeeType.SAME ? theme.spacing(0.5) : theme.spacing(1),
  }),
  audienceLabel: {
    color: theme.palette.text.secondary,
    lineHeight: '16px',
    fontWeight: 400,
    marginBottom: '6px',
  },
  audienceNameClass: {
    lineHeight: '28px',
  },
  audienceNameBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(3),
  },
  radioButton: {}, // TODO:
  title: {
    marginBottom: theme.spacing(1.5),
  },
}));

interface AudienceFeeProps {
  audiences: AudienceWithFee[];
  onFeeChange: FeeHandler | BulkFeeHandler;
  onRevenueMethodChange: RevenueMethodHandler | BulkRevenueMethodHandler;
  rowIndex: number;
  useValidation: useValidationInterface;
  fromEditPage?: boolean;
}

export const AudienceFee: FC<AudienceFeeProps> = ({
  audiences,
  onFeeChange,
  onRevenueMethodChange,
  useValidation,
  rowIndex,
  fromEditPage = false,
}) => {
  const [feeType, setFeeType] = useState(FeeType.SAME);
  const [revenueMethod, setRevenueMethod] = useState(RevenueMethod.PoM);
  const { setValidationError, setIsFieldTouched } = useValidation;
  const isBulk = audiences.length > 1;

  const {
    feeToggle,
    audienceRow,
    audienceLabel,
    audienceNameClass,
    audienceNameBlock,
    radioButton,
    radioGroup,
    title,
  } = useStyles({
    feeType,
    fromEditPage,
  });

  const handleFeeTypeChange = useCallback(
    (_, value) => {
      if (value !== null) {
        setValidationError(rowIndex, SAME_FEE_INPUT, false);
        setIsFieldTouched(rowIndex, SAME_FEE_INPUT, false);

        audiences.forEach(({ audience: { id } }) => {
          setValidationError(rowIndex, id, false);
          setIsFieldTouched(rowIndex, id, false);
          (onFeeChange(id) as BulkFeeHandler)(audiences[0].fee as string);
        });

        setFeeType(value);
      }
    },
    [onFeeChange, rowIndex, setIsFieldTouched, setValidationError],
  );

  useEffect(() => {
    if (audiences.length) {
      const isSameFee = audiences.map(({ fee }) => fee).every(fee => fee === audiences[0].fee);
      setFeeType(isSameFee ? FeeType.SAME : FeeType.DIFFERENT);
      setRevenueMethod(audiences[0].revenueMethod as RevenueMethod);
    }
  }, []);

  const canSetFeeForAudienceRow = useCallback(
    index => feeType === FeeType.DIFFERENT || (feeType === FeeType.SAME && index === 0),
    [feeType],
  );

  const handleFeeChange = useCallback(
    (value, audienceId) => {
      const regex = /^[0-9]?[0-9]?$/;

      if (!regex.test(value) && revenueMethod === RevenueMethod.PoM) return;

      if (isBulk) {
        switch (feeType) {
          case FeeType.SAME:
            audiences.forEach(({ audience: { id } }) => {
              (onFeeChange(id) as BulkFeeHandler)(value);
            });

            break;
          case FeeType.DIFFERENT:
            (onFeeChange(audienceId) as BulkFeeHandler)(value);
            break;
        }
      } else {
        onFeeChange(value);
      }
    },
    [audiences, feeType, onFeeChange],
  );

  const handleRevenueMethodChange = useCallback(
    (_, value) => {
      if (isBulk) {
        audiences.forEach(({ audience: { id } }) => {
          (onRevenueMethodChange(id) as BulkRevenueMethodHandler)(value);
        });
      } else {
        onRevenueMethodChange(value);
      }
      setRevenueMethod(value);
    },
    [audiences, onRevenueMethodChange],
  );

  const sectionTitle = {
    [RevenueMethod.PoM]: 'Percentage of Media',
    [RevenueMethod.CPM]: 'Fixed CPM',
  };

  const revenueMethodChangeDisabled = !!audiences[0].shareId;

  return (
    <div>
      <RadioGroup classes={{ root: radioGroup }} value={revenueMethod} onChange={handleRevenueMethodChange}>
        <FormControlLabel
          disabled={revenueMethodChangeDisabled}
          value={RevenueMethod.PoM}
          className={radioButton}
          control={<Radio color="primary" />}
          label="Percentage of Media"
          data-test="pom-button"
        />
        <FormControlLabel
          disabled={revenueMethodChangeDisabled}
          value={RevenueMethod.CPM}
          className={radioButton}
          control={<Radio color="primary" />}
          label="Fixed CPM"
          data-test="cpm-button"
        />
      </RadioGroup>

      <Typography variant="h3" className={title}>
        {sectionTitle[revenueMethod]}
      </Typography>

      {isBulk && (
        <ToggleButtonGroup exclusive onChange={handleFeeTypeChange} value={feeType} classes={{ root: feeToggle }}>
          <ToggleButton id={FeeType.SAME} value={FeeType.SAME} data-test="same-fee-btn">
            SAME FEE
          </ToggleButton>
          <ToggleButton id={FeeType.DIFFERENT} value={FeeType.DIFFERENT} data-test="different-fee-btn">
            DIFFERENT FEE
          </ToggleButton>
        </ToggleButtonGroup>
      )}

      <div>
        {audiences.map(({ audience: { id, name }, fee }, index) => (
          <div className={audienceRow} key={id}>
            {!fromEditPage && (
              <div className={audienceNameBlock}>
                {canSetFeeForAudienceRow(index) && (
                  <Typography variant="caption" className={audienceLabel}>
                    Audience
                  </Typography>
                )}

                <Typography className={audienceNameClass}>{name}</Typography>
              </div>
            )}

            {canSetFeeForAudienceRow(index) && (
              <FeeInput
                fee={fee}
                fromEditPage={fromEditPage}
                revenueMethod={revenueMethod}
                isBulk={isBulk}
                isSameFeeInput={isBulk && feeType === FeeType.SAME && index === 0}
                audienceId={id}
                handleFeeChange={handleFeeChange}
                useValidation={useValidation}
                rowIndex={rowIndex}
                inputDisabled={!!audiences[0].shareId && revenueMethod === RevenueMethod.CPM}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
