import React from 'react';
import classNames from 'classnames';
import { FormControl, Grid, makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Button } from '../Button';

export interface FormActionsProps {
  isSubmitting?: boolean;
  disabled?: boolean;
  onCancel?: (() => void) | false;
  cancelButtonLabel?: string;
  onSubmit?: () => void;
  submitButtonLabel?: string;
  position?: 'fixed' | 'absolute' | 'sticky';
  alignButton?: 'left' | 'right';
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    left: 0,
    bottom: 0,
    margin: 0,
  },
  positionAbsolute: {
    position: 'absolute',
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.boxLight,
    padding: theme.spacing(1.3, 2.5),
  },
  positionFixed: {
    position: 'fixed',
    backgroundColor: 'white',
    padding: theme.spacing(1.2, 3.8),
  },
  positionSticky: {
    position: 'sticky',
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.black03, // TODO: update to coresponding color when DS 1.1 colors done
    padding: theme.spacing(1.2, 3.8),
  },
}));

export const FormActions = (props: FormActionsProps): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const {
    disabled,
    isSubmitting,
    onCancel,
    cancelButtonLabel = 'Cancel',
    onSubmit,
    submitButtonLabel = 'Save',
    position = 'fixed',
    alignButton = 'right',
  } = props;
  const goBackMethod = (): void => history.goBack();
  const cancelMethod = onCancel || goBackMethod;
  const direction = alignButton === 'right' ? 'row' : 'row-reverse';
  return (
    <FormControl
      margin="normal"
      fullWidth
      className={classNames(classes.container, {
        [classes.positionAbsolute]: position === 'absolute',
        [classes.positionFixed]: position === 'fixed',
        [classes.positionSticky]: position === 'sticky',
      })}
    >
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2} direction={direction}>
        {onCancel !== false && (
          <Grid item>
            <Button variant="text" onClick={cancelMethod} data-test="cancel-button">
              {cancelButtonLabel}
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            type="submit"
            color="primary"
            loading={isSubmitting}
            disabled={disabled}
            onClick={onSubmit}
            data-test="submit-button"
          >
            {submitButtonLabel}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
};
