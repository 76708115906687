import React, { useMemo, useState } from 'react';
import { Popover, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { AutoCompleteVirtualize, Button, Ellipsis } from '@openx/components/core';

import { ChipFilterValue, FilterOption, FilterConfig } from './types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  popover: {
    marginTop: '36px',
  },
  topContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderBottom: '1px solid #E6E6E6',
  },
  name: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '28px',
    color: theme.palette.text.primary,
  },
  autocompleteContainer: {
    minWidth: '300px',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    flexFlow: 'column',
    whiteSpace: 'nowrap',
  },
  closeIcon: {
    width: '28px',
    height: '28px',
    padding: '0px',
  },
  button: {
    marginLeft: 'auto',
    color: theme.palette.primary.main,
    fontSize: '13px',
    marginTop: theme.spacing(2),
  },
}));
interface FilterItemsMenuProps {
  category: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onApplyFilter: (category: string, value: ChipFilterValue) => void;
  onClose: () => void;
  currentValue: ChipFilterValue;
  filterConfig: FilterConfig;
}

export const filterOptions = (opts: FilterOption[], value: string): FilterOption[] => {
  const valueLower = value.toLowerCase();
  return opts.filter(opt => (opt.displayName || opt.value).toString().toLowerCase().includes(valueLower));
};

export const getCustomValue = (inputValue: string | number, title = 'Any includes') => ({
  value: inputValue,
  displayName: `${title}: ${inputValue}`,
});

export function FilterItemsMenu({
  category,
  anchorEl,
  open,
  onApplyFilter,
  onClose,
  currentValue,
  filterConfig,
}: FilterItemsMenuProps) {
  const classes = useStyles();
  const { name, options, loading, allowCustomValue, customValueTitle, onInputChange } = filterConfig;
  const [currentOption, setCurrentOption] = useState<string | FilterOption | null>(null);
  const filteredEmptyOptions = useMemo(() => options.filter(option => !!option.displayName || !!option.value), [
    options,
  ]);
  const { t } = useTranslation();

  return (
    <Popover
      id="filter-items"
      anchorEl={anchorEl}
      keepMounted
      open={open}
      onClose={onClose}
      className={classes.popover}
    >
      <div>
        <div className={classes.topContainer}>
          <span className={classes.name} data-test="filter-name">
            {name}
          </span>
          <IconButton className={classes.closeIcon} onClick={onClose} data-test="close-filter-dialog">
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.autocompleteContainer}>
          <AutoCompleteVirtualize
            freeSolo
            data-test="filter-list"
            options={filteredEmptyOptions}
            getOptionLabel={option => option.displayName || option.value.toString()}
            value={currentOption}
            loading={loading}
            disabled={!onInputChange && loading}
            onChange={(_, option) => setCurrentOption(option)}
            onInputChange={(event, value) => {
              if (event?.type !== 'change') {
                // call only on keyboard input
                return;
              }
              onInputChange && onInputChange(value);
            }}
            filterOptions={(options, params) => {
              const { inputValue } = params;
              const filtered = filterOptions(options, inputValue);

              if (allowCustomValue && inputValue) {
                filtered.unshift(getCustomValue(inputValue, customValueTitle));
              }

              return filtered;
            }}
            renderOption={option => (
              <Ellipsis width="100%" data-test="filter-item" tooltip>
                {option.displayName || option.value.toString()}
              </Ellipsis>
            )}
          />
          <Button
            disabled={currentOption === currentValue || loading}
            variant="text"
            className={classes.button}
            onClick={() => onApplyFilter(category, (currentOption as FilterOption)?.value || null)}
            data-test="apply-button"
          >
            {t('Apply')}
          </Button>
        </div>
      </div>
    </Popover>
  );
}
