import { isArray, flatMapDeep } from 'lodash';

interface Item {
  children?: Item[];
}

export function flatChildren<T extends Item>(item: T): T[] {
  if (!isArray(item.children)) return [];

  return flatMapDeep(item.children, child => {
    return [child as T, ...flatChildren(child as T)];
  });
}
