import React, { RefObject } from 'react';
import { DebounceInput, DebounceInputProps } from 'react-debounce-input';

import { TextField, TextFieldProps } from '../TextField';

export const DebounceField = (props: DebounceInputProps<HTMLInputElement, TextFieldProps>): JSX.Element => (
  <DebounceInput
    {...props}
    ref={props.ref as string & RefObject<HTMLInputElement>}
    element={TextField}
    minLength={0}
    debounceTimeout={props.debounceTimeout || 300}
  />
);
