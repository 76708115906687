// This module contains types common or shared by other types in other directories

export enum GlobalDimensionOperator {
  ALL = 'AND',
  ANY = 'OR',
}

export enum InterStrictDimensionOperator {
  AND = 'AND',
  OR = 'OR',
}

export enum InterDimensionOperator {
  AND = 'AND',
  OR = 'OR',
  ALL = 'AND',
  ANY = 'OR',
}

export enum Match {
  ALL = 'AND',
  ANY = 'OR',
  INTERSECTS = 'INTERSECTS',
  NOT_INTERSECTS = 'NOT INTERSECTS',
}

export enum ComparisonType {
  EQUALS = '==',
  DOES_NOT_EQUAL = '!=',
  ENDS_WITH = '=$',
  CONTAINS = '=?',
  BEGINS_WITH = '=^',
  DOES_NOT_END_WITH = 'NOT =$',
  DOES_NOT_CONTAIN = 'NOT =?',
  DOES_NOT_BEGIN_WITH = 'NOT =^',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  INVERSE_IN = 'INVERSE IN',
  INTERSECTS = 'INTERSECTS',
  DOES_NOT_INTERSECTS = 'NOT INTERSECTS',
  EQ = 'EQ',
  MORE = '>',
  MORE_OR_EQUAL = '>=',
  LESS = '<',
  LESS_OR_EQUAL = '<=',
}

export enum ContentOptionType {
  URL = 'page_url',
  APP_ID = 'app_bundle_id',
  ADUNIT_SIZE = 'adunit_size',
  INVENTORY = 'inventory',
}

export enum IntersectionOperation {
  INTERSECTS = 'INTERSECTS',
  NOT_INTERSECTS = 'NOT INTERSECTS',
}

export enum TechnographicType {
  DEVICE_TYPE = 'device_type',
  OS = 'os',
  API_FRAMEWORK = 'api_framework',
  LANGUAGE = 'language',
  BROWSER = 'browser',
}

export enum VideoType {
  ADUNIT_MAX_DURATION = 'adunit_max_duration',
  SCREEN_LOCATION = 'screen_location',
  VIDEO_FORMAT = 'video_format',
  VIDEO_PLCMT = 'video_plcmt',
  REWARDED_VIDEO = 'rewarded_video',
  VIDEO_SKIPPABILITY = 'skip',
}
export enum FormatType {
  AD_PLACEMENT = 'ad_placement',
  DISTRIBUTION_CHANNEL = 'distribution_channel',
}

export enum GeographicLocationType {
  CONTINENT = 'continent',
  COUNTRY = 'country',
  STATE = 'state',
  CITY = 'city',
  POSTAL_CODE = 'postal_code',
  DMA = 'dma',
  MSA = 'msa',
  REGION = 'region',
}

export enum TargetingContentOptionType {
  PAGE = 'page',
  SITE = 'site',
  ADUNIT = 'adunit',
  ACCOUNT = 'account',
  NETWORK = 'network',
  SECTION = 'section',
  INSTANCE = 'instance',
  SITESECTION = 'sitesection',
}

export enum ViewabilityOperation {
  GREATER_THAN_OR_EQUAL_TO = '>=',
}

export enum VtrOperation {
  GREATER_THAN_OR_EQUAL_TO = '>=',
}
