import React, { ReactNode, SyntheticEvent } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Button } from '@openx/components/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 676,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '-5px -4px',
  },
  closeIcon: {
    margin: theme.spacing(-1, -1, -1, 0),
  },
  dialogActions: {
    background: '#fff',
    padding: '12px 16px',
  },
  horizontal: { display: 'block', height: '1px', border: 0, borderTop: `1px solid ${theme.palette.border}` },
}));

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  submitButtonText?: string;
}

export const ApiKeyCreatedModal = ({ title, isOpen, onClose, children }: Props) => {
  const classes = useStyles();

  const handleCancelButton = event => {
    event.stopPropagation();
    onClose();
  };

  const handleClose = (e, reason) => {
    if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
      e.stopPropagation();
    }

    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      onClick={event => event.stopPropagation()}
      classes={{ paper: classes.paper }}
      data-test="key-generated-dialog"
    >
      <DialogTitle disableTypography data-test="dialog-heading">
        <div className={classes.titleContainer}>
          <Typography component="h2" variant="h3">
            {title}
          </Typography>
          {onClose && (
            <IconButton className={classes.closeIcon} aria-label="close" onClick={onClose}>
              <CloseIcon data-test="close-dialog" />
            </IconButton>
          )}
        </div>
      </DialogTitle>
      <Divider className={classes.horizontal} />
      <DialogContent>{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button data-test="dialog-cancel-button" onClick={handleCancelButton} variant="text">
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};
