import { DataType } from '../dataType';
import { MOBILE_AD_ID_DATA_TEMPLATE, EMAIL_DATA_TEMPLATE, PHONE_DATA_TEMPLATE } from './dataTemplates';

const mapDataTypeToTemplates: Record<DataType, string> = {
  maid: MOBILE_AD_ID_DATA_TEMPLATE,
  email: EMAIL_DATA_TEMPLATE,
  phone: PHONE_DATA_TEMPLATE,
};

export function downloadDataTemplateFile(dataType: DataType) {
  const fileName = `${dataType}-template.csv`;
  const data = mapDataTypeToTemplates[dataType];
  const blob = new Blob([data], {
    type: 'text/csv',
  });
  const fileURL = URL.createObjectURL(blob);
  const tmpAnchorElement = document.createElement('a');

  tmpAnchorElement.href = fileURL;
  tmpAnchorElement.setAttribute('download', fileName);
  tmpAnchorElement.setAttribute('target', '_blank');
  document.body.appendChild(tmpAnchorElement);
  tmpAnchorElement.click();
  document.body.removeChild(tmpAnchorElement);
}
