import { isEmpty } from 'lodash';
import { AllTableCriteria } from './tableCriteria';

export type QueryVar = {
  limit?: number | null;
  offset?: number | null;
  order_by?: {
    [column: string]: Record<string, unknown> | string | null | undefined;
  } | null;
  where?: {
    _and?:
      | [
          { _or?: { [column: string]: { _ilike: string } }[] } | null,
          { _and?: { [column: string]: Record<string, unknown> }[] } | null,
        ]
      | [{ _and?: { [column: string]: Record<string, unknown> }[] } | null]
      | [{ _or?: { [column: string]: { _ilike: string } }[] } | null]
      | null;
  } | null;
};

export type FiltersT = {
  byPhrase?: {
    fields: string[];
    phrase: string;
  };
  byValue?: [
    {
      field: string;
      values: string[];
    },
  ];
};

export const sortStage = sort => {
  if (sort && sort.column && sort.direction) {
    if (sort.column === 'account' && sort.valueDerivers?.account !== undefined)
      return {
        [sort.column]: {
          name: sort.direction,
        },
      };
    return { [sort.column]: sort.direction };
  }
  return {};
};

export const filterSubstageValue = filters => {
  const byValue = { _and: [] as { [column: string]: Record<string, unknown> }[] };
  if (filters?.byValue) {
    byValue._and = filters.byValue
      .filter(valFil => (valFil.values || []).length > 0)
      .map(({ field, values }) => {
        if (field === 'account') {
          return {
            [field]: {
              name: {
                _in: values,
              },
            },
          };
        }
        if (field === 'direct_audience_provider' || field === 'sub_type') {
          return {
            [field]: {
              _in: values.map(val => val?.toLowerCase() || 'openaudience'),
            },
          };
        }
        return {
          [field]: {
            _in: values,
          },
        };
      });
  }
  return byValue;
};

//from serverSideCriteriaProcessing.ts
export const filterSubstagePhrase = filters => {
  const byPhrase = { _or: [] as { [column: string]: { _ilike: string } }[] };
  if (filters?.byPhrase) {
    const { fields, phrase } = filters.byPhrase;

    if (phrase && fields) {
      byPhrase._or = fields.map(fieldKey => ({
        [fieldKey]: {
          _ilike: `%${phrase}%`,
        },
      }));
    }
  }
  return byPhrase;
};

//from serverSideCriteriaProcessing.ts
export const filterStage = filters => {
  const byPhrase = filterSubstagePhrase(filters);
  const byValue = filterSubstageValue(filters);

  const _and = [] as Record<string, unknown>[];
  if (byPhrase._or.length > 0) {
    _and.push(byPhrase);
  }
  if (byValue._and.length > 0) {
    _and.push(byValue);
  }

  const where: any = _and.length > 0 ? { _and } : undefined;
  return where;
};

export function serverSideCriteriaProcessing<T extends QueryVar = QueryVar, F extends FiltersT = FiltersT>(
  criteria: AllTableCriteria<F>,
): T {
  const { filters, sort, pagination } = criteria;
  const queryVar: QueryVar = {
    limit: pagination?.pageSize || null,
    offset: 0,
    order_by: null,
    where: null,
  };
  const pageIndex = (pagination?.pageNumber || 1) - 1;

  queryVar.where = filterStage(filters);

  queryVar.order_by = sortStage(sort);

  // paginationStage()
  if (pagination) {
    if (pageIndex * pagination.pageSize < pagination.totalCount) {
      queryVar.offset = pagination.pageSize * pageIndex;
    }
  }

  return queryVar as T;
}

export function serverSideTaxonomyProcessing<T extends QueryVar = QueryVar, F extends FiltersT = FiltersT>(
  criteria: AllTableCriteria<F>,
  initialQueryVar: QueryVar,
): T {
  const { filters, sort, pagination } = criteria;

  const queryVar: QueryVar = { ...initialQueryVar, limit: pagination?.pageSize || null, offset: 0 };

  const isPhraseAndValues = filters?.byPhrase?.phrase && filters?.byValue?.some(({ values }) => !isEmpty(values));
  const isOnlyPhrase = filters?.byPhrase?.phrase && !filters?.byValue?.some(({ values }) => !isEmpty(values));
  const isOnlyValues = !filters?.byPhrase?.phrase && filters?.byValue?.some(({ values }) => !isEmpty(values));

  if (isPhraseAndValues) {
    queryVar.where = filterStage(filters);
  }
  if (isOnlyPhrase || isOnlyValues) {
    queryVar.where = {
      ...filterStage(filters),
      ...initialQueryVar.where,
    };
  }

  queryVar.order_by = sortStage(sort);

  const pageIndex = (pagination?.pageNumber || 1) - 1;

  // paginationStage()
  if (pagination) {
    if (pageIndex * pagination.pageSize < pagination.totalCount) {
      queryVar.offset = pagination.pageSize * pageIndex;
    }
  }

  return queryVar as T;
}
