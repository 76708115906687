import React, { useState, useCallback, FC, useEffect, useMemo } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import { Paper } from '@openx/components/core';
import { TaxonomyHeader } from './TaxonomyHeader';
import {
  Table,
  SortDirection,
  applyCriteriaChange,
  AllTableCriteria,
  SearchFiltersValue,
  QueryVar,
  serverSideTaxonomyProcessing,
  clientSideCriteriaProcessing,
} from '@openx/components/tablev2';
import { tableColumns } from './tableColumns';
import { GetTaxonomyQuery, GetTaxonomyQueryVariables, Segment, Order_By } from 'types/schemaTypes';
import { SearchResultPlaceholder } from 'components/TablePlaceholder/SearchResultsPlaceholder';
import { EmptyTablePlaceholder } from 'components/TablePlaceholder/EmptyTablePlaceholder';
import { isDeepEqual } from 'utils';
import { mapTaxonomymetadataToTree, recursiveTreeSort } from '../utils';

import GetTaxonomy from 'graphql/query/taxonomy/GetTaxonomy.gql';
import GetTaxonomyMetadata from 'graphql/query/taxonomy/GetTaxonomyMetadata.gql';
import GetTaxonomySearchTotal from 'graphql/query/taxonomy/GetTaxonomySearchTotal.gql';

import { uniqBy, isEmpty } from 'lodash';

import { TABLE_PAGE_SIZE } from 'config';

const initialCriteria: AllTableCriteria = {
  sort: {
    column: 'name',
    direction: SortDirection.ASC,
    sortableColumns: ['name', 'segment_sub_category', 'segment_category', 'segment_source'],
  },
  filters: {
    byPhrase: {
      fields: ['name, description'],
      phrase: '',
    },
  },
};

type QueryParams = GetTaxonomyQueryVariables & QueryVar;

const initialQueryVariables: QueryParams = {
  //@ts-ignore
  order_by: [{ name: Order_By.Asc }],
  where: {
    is_archived: { _eq: false },
    is_leaf: { _eq: true },
    category: { _neq: 'direct' },
    name: { _neq: 'All' },
  },
};

export type TaxonomyRow = Pick<
  Segment,
  | 'id'
  | 'name'
  | 'segment_category'
  | 'segment_sub_category'
  | 'segment_type'
  | 'segment_source'
  | 'segment_source_description'
>;

export const TaxonomyList: FC = (): JSX.Element => {
  const [criteria, setCriteria] = useState<AllTableCriteria>(applyCriteriaChange(initialCriteria));
  const [tableData, setTableData] = useState<TaxonomyRow[]>([]);

  const searchApplied = useMemo(
    //@ts-ignore
    () => !!criteria.filters?.byPhrase?.phrase || criteria.filters?.byValue?.some(({ values }) => !isEmpty(values)),
    [criteria],
  );

  useEffect(() => {
    if (searchApplied) {
      setCriteria(prevCriteria => ({
        ...prevCriteria,
        pagination: {
          pageSize: TABLE_PAGE_SIZE,
          pageNumber: 1,
          totalCount: 50,
        },
      }));
    } else if (!searchApplied) {
      setCriteria(prevCriteria => ({ ...prevCriteria, pagination: undefined }));
    }
  }, [searchApplied]);

  const {
    loading: metadataLoading,
    error: metadataError,
    data: { segment_aggregate: { nodes: metadata = [] } = {} } = {},
    refetch: metadataRefetch,
  } = useQuery(GetTaxonomyMetadata);

  const sortedMetadata = useMemo(() => {
    if (searchApplied) return [];
    if (isDeepEqual(criteria.sort, initialCriteria.sort)) {
      return mapTaxonomymetadataToTree(metadata);
    } else {
      return recursiveTreeSort(
        mapTaxonomymetadataToTree(metadata),
        clientSideCriteriaProcessing,
        criteria,
        setCriteria,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata, criteria.sort, searchApplied]);

  const [getSearch, { error: searchError, loading: searchLoading, data: { segment: segmentSearch = [] } = {} }] =
    useLazyQuery<GetTaxonomyQuery, GetTaxonomyQueryVariables>(GetTaxonomy);
  const [
    getSegmentsSearchTotal,
    { error: searchTotalError, loading: searchTotalLoading, data: { segment_aggregate = {} } = {} },
  ] = useLazyQuery(GetTaxonomySearchTotal);

  if (
    !searchTotalLoading &&
    typeof criteria.pagination?.totalCount !== 'undefined' &&
    typeof segment_aggregate?.aggregate?.count !== 'undefined'
  )
    criteria.pagination.totalCount = segment_aggregate.aggregate.count;

  const [getSubOptions] = useLazyQuery(GetTaxonomy);

  useEffect(() => {
    if (!metadataLoading && sortedMetadata.length > 0 && !searchApplied) {
      setTableData(sortedMetadata);
    }
  }, [metadataLoading, sortedMetadata, searchApplied]);

  useEffect(() => {
    if (!searchLoading && segmentSearch.length > 0 && searchApplied) {
      setTableData(segmentSearch);
    }
  }, [searchLoading, segmentSearch, searchApplied]);

  const filterCategories = uniqBy(metadata, 'segment_category')
    //@ts-ignore
    .map(({ segment_category }) => segment_category)
    .filter(el => el !== null && el !== undefined);
  const filterSubCategories = uniqBy(metadata, 'segment_sub_category')
    //@ts-ignore
    .map(({ segment_sub_category }) => segment_sub_category)
    .filter(el => el !== null && el !== undefined);
  const filterTypes = uniqBy(metadata, 'segment_type')
    //@ts-ignore
    .map(({ segment_type }) => segment_type)
    .filter(el => el !== null && el !== undefined);
  const filterSources = uniqBy(metadata, 'segment_source')
    //@ts-ignore
    .map(({ segment_source }) => segment_source)
    .filter(el => el !== null && el !== undefined);

  const handleCriteriaChange = useCallback(
    newCriteria => {
      if (
        newCriteria?.dimension === 'sort' &&
        newCriteria.value?.column === criteria.sort?.column &&
        newCriteria.value?.direction === 'asc' &&
        newCriteria.value?.column !== initialCriteria.sort?.column
      ) {
        newCriteria.value.column = initialCriteria.sort?.column;
        newCriteria.value.direction = initialCriteria.sort?.direction;
      }

      const newCriteriaProcessed = applyCriteriaChange(criteria, newCriteria);

      if (!isDeepEqual({ ...newCriteriaProcessed }, { ...criteria })) {
        setCriteria(newCriteriaProcessed);
      }
    },
    [criteria],
  );

  useEffect(() => {
    if (searchApplied) {
      const queryCrit = serverSideTaxonomyProcessing<QueryParams>(criteria, initialQueryVariables);

      getSegmentsSearchTotal({ variables: { where: queryCrit.where } });

      getSearch({ variables: queryCrit });
    } else {
      metadataRefetch();
    }
  }, [criteria, searchApplied, getSearch, getSegmentsSearchTotal, metadataRefetch]);

  const fetchSubOptions = async data => {
    const fetchedChildren = await getSubOptions({
      variables: {
        order_by: [{ [criteria?.sort?.column as string]: criteria?.sort?.direction }],
        where: {
          is_archived: { _eq: false },
          is_leaf: { _eq: true },
          category: { _neq: 'direct' },
          segment_category: { _eq: data.segment_category },
          segment_sub_category: data.segment_sub_category ? { _eq: data.segment_sub_category } : { _is_null: true },
          segment_type: { _eq: data.segment_type },
          name: { _neq: 'All' },
        },
      },
    });

    const sortedChildSegments: TaxonomyRow[] = clientSideCriteriaProcessing(fetchedChildren?.data?.segment, criteria, {
      onCriteriaAdjust: setCriteria,
    });

    return sortedChildSegments;
  };

  const secondaryRowAction = {
    label: '',
    onClick: () => {},
  };
  const isTableEmpty = metadata.length === 0 && segmentSearch.length === 0;

  const isTableLoading = metadataLoading || searchLoading || searchTotalLoading;

  const noSearchResults = !searchLoading && segmentSearch.length === 0 && searchApplied;

  const isTableForcedExpanded = searchApplied && segmentSearch.length > 0;

  if (metadataError || searchError || searchTotalError) throw new Error('Taxonomy error');

  if (isTableEmpty) {
    return (
      <TaxonomyHeader
        handleCriteriaChange={handleCriteriaChange}
        categories={filterCategories as string[]}
        subCategories={filterSubCategories as string[]}
        types={filterTypes as string[]}
        sources={filterSources as string[]}
      >
        <Paper padding="none">
          {isTableLoading ? (
            <Table
              data={[]}
              columns={tableColumns}
              loading={true}
              isTree={!searchApplied}
              criteria={criteria}
              onCriteriaChange={handleCriteriaChange}
              highlightRules={(criteria.filters as SearchFiltersValue)?.byPhrase}
              fetchTreeChildren={fetchSubOptions}
              secondaryRowAction={secondaryRowAction}
            ></Table>
          ) : (
            <EmptyTablePlaceholder />
          )}
        </Paper>
      </TaxonomyHeader>
    );
  }

  return (
    <TaxonomyHeader
      handleCriteriaChange={handleCriteriaChange}
      categories={filterCategories as string[]}
      subCategories={filterSubCategories as string[]}
      types={filterTypes as string[]}
      sources={filterSources as string[]}
    >
      <Paper padding="none">
        <Table
          data={tableData}
          columns={tableColumns}
          loading={isTableLoading}
          isTree={!searchApplied}
          criteria={criteria}
          onCriteriaChange={handleCriteriaChange}
          highlightRules={(criteria.filters as SearchFiltersValue)?.byPhrase}
          fetchTreeChildren={fetchSubOptions}
          isForcedExpanded={isTableForcedExpanded}
        >
          {noSearchResults && <SearchResultPlaceholder columnsAmount={[].length + 2} />}
        </Table>
      </Paper>
    </TaxonomyHeader>
  );
};
