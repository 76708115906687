import React, { useMemo, memo } from 'react';
import { Link } from 'react-router-dom';
import { isEqual, omit } from 'lodash';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { Button as MuiButton, CircularProgress, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Tooltip } from '@openx/components/core';

type CircularProgressColor = 'primary' | 'secondary' | 'inherit';
export type MuiButtonColor = 'default' | 'primary' | 'secondary' | 'inherit';
export type ButtonVariant = 'contained' | 'text';
export type ButtonSize = 'medium' | 'small';
export type ButtonColor = MuiButtonColor | 'danger';

export interface ButtonProps extends Omit<MuiButtonProps, 'color'> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  color?: ButtonColor;
  loading?: boolean;
  allowed?: boolean;
  notAllowedMessage?: string;
  instantClick?: boolean;
  'data-test'?: string;
  onHover?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  labelHidden: {
    visibility: 'hidden',
  },
  progressLayer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    cursor: ({ loading }: ButtonProps) => (loading ? 'default' : 'pointer'),
    pointerEvents: ({ loading }: ButtonProps) => (loading ? 'none' : 'auto'),
    backgroundColor: ({ color, variant }: ButtonProps) =>
      color === 'danger' && variant !== 'text' ? theme.palette.error.main : 'auto',
    '$button&:hover': {
      backgroundColor: ({ color, variant }: ButtonProps) =>
        color === 'danger' && variant !== 'text' ? theme.palette.error.dark : 'auto',
    },
  },
}));

export const ButtonRaw = (props: ButtonProps): JSX.Element => {
  const {
    variant = 'contained',
    size = 'medium',
    color = 'default',
    loading = false,
    disabled = false,
    allowed = true,
    notAllowedMessage = 'You need permission to perform this action',
    children,
    onClick,
    instantClick,
    onHover = () => {},
    href,
    ...restProps
  } = props;
  const dataTest = props['data-test'] || 'button';
  const classes = useStyles({ loading, color, variant });

  const circularProgress = useMemo(() => {
    const CircularProgressColor: Record<ButtonVariant, string> = {
      contained: 'inherit',
      text: ['primary', 'secondary'].includes(color) ? color : 'inherit',
    };

    const CircularProgressColorSize: Record<ButtonSize, number> = {
      medium: 14,
      small: 10,
    };

    return (
      <div className={classes.progressLayer}>
        <CircularProgress
          color={CircularProgressColor[variant] as CircularProgressColor}
          size={CircularProgressColorSize[size]}
        />
      </div>
    );
  }, [variant, size, color, classes]);

  const label = useMemo(() => {
    if (loading) {
      return <span className={classes.labelHidden}>{children}</span>;
    }
    return children;
  }, [loading, children, classes]);

  const getMuiColor = useMemo((): MuiButtonColor => {
    if (color === 'danger') {
      return 'default';
    }

    return color;
  }, [color]);

  if (!allowed) {
    return (
      <Tooltip title={notAllowedMessage}>
        <span>
          <MuiButton variant={variant} size={size} data-test={dataTest} {...restProps} disabled>
            {children}
          </MuiButton>
        </span>
      </Tooltip>
    );
  }

  const onClickHandler = {
    [instantClick ? 'onMouseDown' : 'onClick']: onClick,
  };

  if (href) {
    return (
      <Link to={href}>
        <MuiButton
          onMouseEnter={onHover}
          variant={variant}
          color={getMuiColor}
          size={size}
          disabled={disabled}
          classes={{ root: classes.button }}
          disableRipple={variant === 'text'}
          data-test={dataTest}
          {...restProps}
        >
          {label}
          {loading && circularProgress}
        </MuiButton>
      </Link>
    );
  }

  return (
    <MuiButton
      onMouseEnter={onHover}
      variant={variant}
      color={getMuiColor}
      size={size}
      disabled={disabled}
      classes={{ root: classes.button }}
      disableRipple={variant === 'text'}
      data-test={dataTest}
      {...onClickHandler}
      {...restProps}
    >
      {label}
      {loading && circularProgress}
    </MuiButton>
  );
};

export const Button = memo(ButtonRaw, (prevProps, nextProps) =>
  isEqual(omit(prevProps, ['children']), omit(nextProps, ['children'])),
);
