import React, { useState } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IconButton } from '@material-ui/core';
import { COLORS } from '../theme/palette';

import { copyToClipboard } from '@openx/utils';
import { Tooltip } from '../Tooltip';

const useStyles = makeStyles(theme => ({
  tooltipContainer: {
    arrow: {
      backgroundColor: theme.palette.primary.main,
    },
    tooltip: {
      backgroundColor: theme.palette.primary.main,
      minWidth: '100px',
      textAlign: 'center',
    },
  },
  iconButtonSmall: {
    padding: theme.spacing(0.5),
    marginTop: -theme.spacing(0.25),
  },
}));

function CopyIcon(props): JSX.Element {
  return (
    <svg
      width={12}
      height={12}
      color={COLORS.TEXT_LIGHT}
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.357.518H1.286c-.648 0-1.179.53-1.179 1.178v8.25h1.179v-8.25h7.071V.518zm1.768 2.357H3.643c-.648 0-1.179.53-1.179 1.179v8.25c0 .648.53 1.178 1.179 1.178h6.482c.648 0 1.179-.53 1.179-1.178v-8.25c0-.649-.53-1.179-1.179-1.179zm0 9.429H3.643v-8.25h6.482v8.25z"
        fill="#666"
      />
    </svg>
  );
}

interface CopyToClipboardProps {
  text: string;
  iconSize?: 'small' | 'medium';
  containerClass?: string;
}

const sizesConfig = {
  small: { width: 12, height: 12 },
  medium: { width: 16, height: 16 },
};

export const CopyToClipboard = ({ text, iconSize = 'small', containerClass }: CopyToClipboardProps): JSX.Element => {
  const [copyText, setCopyText] = useState<string>('Copy to clipboard');
  const classes = useStyles();
  const size = sizesConfig[iconSize];

  const onClick = e => {
    e.stopPropagation();
    setCopyText('Copied!');
    copyToClipboard(text);
  };

  return (
    <Tooltip title={copyText} placement={'top'} className={classes.tooltipContainer}>
      <span className={containerClass}>
        <IconButton onClick={onClick} className={classNames({ [classes.iconButtonSmall]: iconSize === 'small' })}>
          <CopyIcon data-test="copy-to-clipboard" style={{ cursor: 'pointer' }} {...size}></CopyIcon>
        </IconButton>
      </span>
    </Tooltip>
  );
};
