export const isDeepEqual = (object1, object2, path = '', ignoreProto = true) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) {
    return false;
  }

  for (const key of objKeys1) {
    // Avoid prototype pollution
    if (key === '__proto__' && ignoreProto) {
      return true;
    }

    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2, `${path}.${key}`)) || (!isObjects && value1 !== value2)) {
      return false;
    }
  }
  return true;
};

export const isObject = object => {
  return object != null && typeof object === 'object';
};

export default isDeepEqual;
