import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import { ListType } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  include: {
    color: theme.palette.success.main,
  },
  exclude: {
    color: theme.palette.error.main,
  },
}));

interface Props {
  type: ListType;
}

const mapTypeToIcon = {
  [ListType.INCLUDE]: DoneIcon,
  [ListType.EXCLUDE]: BlockIcon,
};
export const Icon = (props: Props) => {
  const { type } = props;
  const classes = useStyles();

  const Icon = mapTypeToIcon[type];
  return <Icon fontSize="small" className={classes[type]} data-test="include-exclude-icon" />;
};
