import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { Paper } from '@openx/components/core';

import { AssociatedDealsList } from './AssociatedDealsList';

export function AssociatedDeals({ data, error, loading }) {
  const deals = useMemo(() => data.filter(deal => deal.is_archived !== true), [data]);

  if (error) {
    console.error('Cannot fetch deals associated with this audience');
    return null;
  }

  if (data.length === 0 && !loading) {
    return null;
  }

  return (
    <Paper>
      <Typography variant="h2" gutterBottom>
        Deal IDs
      </Typography>
      <Typography variant="body1">
        <b>The audience is used in the following deals:</b>
      </Typography>
      <AssociatedDealsList deals={deals} loading={loading} />
    </Paper>
  );
}
