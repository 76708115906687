import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseRow } from '../tableHelpers';
import { TableRow, TableRowProps } from './TableRow';
import { ShowMoreButton } from './ShowMoreButton';

export interface TableRowTreeProps<RowT extends BaseRow> extends TableRowProps<RowT> {
  rowData: RowT;
  treeChildrenKey?: string;
  isForcedExpanded?: (rowData: RowT) => boolean;
  depth?: number;
  treeLevelItems?: number;
}
const useStyles = makeStyles((depth: number) => ({
  rowColor: {
    backgroundColor: `rgba(0, 0, 0, ${depth * 0.02})`,
  },
  rowMouse: {
    cursor: 'default',
  },
}));

export function TableRowTree<RowT extends BaseRow>(props: TableRowTreeProps<RowT>): JSX.Element {
  const { rowData, isForcedExpanded, onRowClick, treeChildrenKey = 'children', depth = 0, treeLevelItems = 50 } = props;
  const [showItems, setShowItems] = useState(treeLevelItems);
  const classes = useStyles(depth);
  const isExpanded = isForcedExpanded ? isForcedExpanded(rowData) : false;

  const children = rowData[treeChildrenKey];

  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  const onClick = useCallback(
    (data: RowT, e: React.SyntheticEvent) => {
      // Check attribute expand row only when expand IconButton is clicked
      const attr = (e.target as HTMLElement).dataset.expandicon;
      const { parentNode } = e.target as HTMLElement;
      const parentAttr = parentNode && (parentNode as HTMLElement).dataset.expandicon;
      if ([attr, parentAttr].indexOf('expandRow') !== -1) {
        if (!isExpanded) {
          setExpanded(expanded => !expanded);
        }
        onRowClick && onRowClick(data, e);
      }
      return;
    },
    [isExpanded, onRowClick],
  );
  const childrenToRender =
    children &&
    children
      .slice(0, showItems)
      .map((child, id) => <TableRowTree {...props} key={id} rowData={child} depth={depth + 1} />);

  if (children && children.length > showItems) {
    childrenToRender.push(
      <ShowMoreButton key="show-more" depth={depth + 1} onClick={() => setShowItems(showItems + treeLevelItems)} />,
    );
  }

  const additionalRenderParams = {
    depth: depth,
    expanded,
  };

  return (
    <>
      <TableRow
        {...props}
        onRowClick={onClick}
        data-test="table-row-expand"
        customClassNames={classNames(classes.rowMouse, { [classes.rowColor]: depth })}
        rowData={{ ...rowData, ...additionalRenderParams }}
      />
      {expanded && childrenToRender}
    </>
  );
}
