import React, { createContext, useCallback, useState, ReactNode } from 'react';

import { ConfirmationDialog } from './ConfirmationDialog';
import { ButtonProps } from '../../Button';

export interface UseConfirmationDialogParams {
  title: string;
  content: JSX.Element | string;
  onCancel: () => void;
  onConfirmed: () => void;
  confirmationLabel?: ReactNode;
  cancelLabel?: ReactNode;
  confirmationButtonColor?: ButtonProps['color'];
  customContent?: boolean;
  confirmationDisabled?: boolean;
  hideConfirm?: boolean;
}

interface UseConfirmationDialogState extends UseConfirmationDialogParams {
  isOpen: boolean;
  isLoading: boolean;
}

const useConfirmationDialogDefaultState: UseConfirmationDialogState = {
  title: 'Confirm operation',
  content: 'Are you sure?',
  onCancel: () => undefined,
  onConfirmed: () => undefined,
  isOpen: false,
  isLoading: false,
};

export interface ContextProps {
  open: (openParams?: Partial<UseConfirmationDialogParams>) => void;
  close: () => void;
  setLoading: (loading: boolean) => void;
}

export const ConfirmationDialogContext = createContext<ContextProps>({
  close: () => undefined,
  open: () => undefined,
  setLoading: () => undefined,
});

export function ConfirmationDialogProvider({ children }): JSX.Element {
  const [confirmationDialog, setConfirmationDialog] = useState<UseConfirmationDialogState>({
    ...useConfirmationDialogDefaultState,
  });

  const close = useCallback(() => {
    setConfirmationDialog(prevConfirmationDialog => ({
      ...prevConfirmationDialog,
      isOpen: false,
    }));
  }, [setConfirmationDialog]);

  const open = useCallback(
    (openParams?: Partial<UseConfirmationDialogParams>) => {
      setConfirmationDialog(prevConfirmationDialog => ({
        ...prevConfirmationDialog,
        ...openParams,
        isOpen: true,
      }));
    },
    [setConfirmationDialog],
  );

  const setLoading = useCallback(
    (loading: boolean) => {
      setConfirmationDialog(prevConfirmationDialog => ({
        ...prevConfirmationDialog,
        isLoading: loading,
      }));
    },
    [setConfirmationDialog],
  );

  const modal = (
    <ConfirmationDialog
      title={confirmationDialog.title}
      isOpen={confirmationDialog.isOpen}
      isLoading={confirmationDialog.isLoading}
      onConfirmed={confirmationDialog.onConfirmed}
      onCancel={() => {
        confirmationDialog.onCancel();
        close();
      }}
      confirmationButtonColor={confirmationDialog.confirmationButtonColor}
      confirmLabel={confirmationDialog.confirmationLabel}
      cancelLabel={confirmationDialog.cancelLabel}
      customContent={confirmationDialog.customContent}
      confirmationDisabled={confirmationDialog.confirmationDisabled}
      hideConfirm={confirmationDialog.hideConfirm}
      data-test="confirmation-dialog"
    >
      {confirmationDialog.content}
    </ConfirmationDialog>
  );

  return (
    <ConfirmationDialogContext.Provider value={{ close, open, setLoading }}>
      {children}
      {modal}
    </ConfirmationDialogContext.Provider>
  );
}
