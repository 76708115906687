import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Button } from '../Button';

const useStyles = makeStyles<Theme>(theme => ({
  alert: {
    borderRadius: 0,
    padding: theme.spacing(0.5, 4),
    '&.MuiAlert-message': {
      padding: theme.spacing(0.5, 0),
    },
    '&.MuiAlert-icon': {
      fontSize: '18px',
      padding: theme.spacing(1, 0),
    },
  },
}));

interface Props {
  handleButtonClick: () => void;
}

export const ImpersonationBar = ({ handleButtonClick }: Props) => {
  const classes = useStyles();

  return (
    <Alert
      id="impersonation-bar"
      data-test="impersonation-bar"
      severity="info"
      className={classes.alert}
      action={
        <Button data-test="redirect-to-unity-button" variant="contained" color="secondary" onClick={handleButtonClick}>
          Go to new UI
        </Button>
      }
    >
      A new version of the Deals UI is available for beta testing.
    </Alert>
  );
};
