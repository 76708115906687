import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useQuery } from '@apollo/client';

import { ConfirmationDialog, Paper } from '@openx/components/core';
import AuidenceShare from 'graphql/query/audiences/GetAudienceShares.gql';
import { useAudience, ActionType, getAudienceShares, defaultShareRow } from 'modules/audiences/AudienceProvider';
import { AudienceShareForm } from 'modules/audiences/components/AudienceShare';
import { groupAudienceSharesByFee } from 'modules/audiences/utils';
import { useAudienceShares } from 'modules/audiences/hooks/useAudienceShares';

import { AudienceSharesByFee, Audience } from './types';

const useStyles = makeStyles(theme => ({
  container: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    maxHeight: '100%',
    overflow: 'auto',
  },
  caption: {
    lineHeight: '13px',
    fontSize: '13px',
    marginBottom: '9px',
  },
  audienceName: {
    marginBottom: theme.spacing(2),
  },
  audinceNameBold: {
    fontWeight: 600,
    lineHeight: '28px',
  },
  confirmation: {
    marginTop: theme.spacing(3),
  },
}));

interface Props {
  onClose: () => void;
  open: boolean;
  audience: Audience;
  fromList?: boolean;
}

export const ShareConfirmationDialog = ({
  audience,
  onClose = () => {},
  open,
  fromList = false,
}: Props): JSX.Element | null => {
  const { container, caption, audinceNameBold, confirmation } = useStyles();
  const { state, dispatch } = useAudience();
  const {
    createConfirmationHandler,
    loading: confirmationLoading,
    error,
    useValidation,
  } = useAudienceShares({ audienceId: audience.id });

  const { validationError, touchAllFields, isSubmissionDisabled } = useValidation;

  const [initialShareRows, setInitialShareRows] = useState<AudienceSharesByFee[] | null>(null);
  const shareRows = useMemo(() => getAudienceShares(state), [state]); // TODO: These are audienceShares from the state.

  const shareAudiences = createConfirmationHandler(initialShareRows, shareRows, onClose);

  const onConfirmed = useCallback(() => {
    touchAllFields();

    if (!validationError) {
      shareAudiences();
    }
  }, [validationError, shareAudiences, touchAllFields]);

  const {
    data: { audienceSharesByAudience: fetchedAudienceShares = [] } = {},
    loading: sharesLoading,
    error: sharesError,
  } = useQuery(AuidenceShare, {
    variables: { id: audience.id },
    skip: !fromList,
  });

  // TODO: The entry point when called from the list
  useEffect(() => {
    if (!sharesLoading && fromList) {
      dispatch({
        type: ActionType.SET_AUDIENCE_SHARES_FIELD,
        payload: { audienceShares: groupAudienceSharesByFee(fetchedAudienceShares) },
      });

      setInitialShareRows(groupAudienceSharesByFee(fetchedAudienceShares));
    }
  }, [sharesLoading, dispatch, fromList]);

  useEffect(() => {
    if (initialShareRows === null && !fromList) {
      if (!isEqual(shareRows, [defaultShareRow])) {
        // @ts-ignore TODO:
        setInitialShareRows(shareRows);
      }
    }
  }, []);

  const handleCancel = () => {
    if (fromList) {
      dispatch({ type: ActionType.RESET_AUDIENCE });
    }

    onClose();
  };

  if (sharesError || error) {
    return null;
  }

  const loading = confirmationLoading || sharesLoading;

  return (
    <ConfirmationDialog
      isOpen={open}
      title="Share Audience"
      confirmLabel="Share"
      onCancel={handleCancel}
      onConfirmed={onConfirmed}
      confirmationDisabled={isSubmissionDisabled}
      isLoading={loading}
      customContent
      confirmationButtonColor="primary"
      data-test="confirmation-dialog"
    >
      <Paper className={container}>
        <Typography variant="body1" className={caption}>
          You intend to share the below audience with the following publisher(s):
        </Typography>

        <Typography variant="body1" className={audinceNameBold} data-test="share-audience-name">
          {audience.name}
        </Typography>

        <AudienceShareForm useValidation={useValidation} audience={audience} loading={loading} />

        <Typography className={confirmation}>Do you want to proceed?</Typography>
      </Paper>
    </ConfirmationDialog>
  );
};
