import { DocumentNode } from '@apollo/client';

export const extractDocumentInstance = (document: DocumentNode): DocumentNode => {
  // @ts-ignore
  const operationName = document.definitions.find(({ kind }) => kind === 'OperationDefinition')?.name.value;
  if (!document[operationName]) {
    throw new Error(`Could not extract document instance of ${operationName} query document`);
  }
  return document[operationName];
};
