import React from 'react';

import { Loader } from '@openx/components/core';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    background: '#efefef',
    zIndex: 5,
  },
});

export function RouteLoader(): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Loader data-test="loader" />
    </div>
  );
}
