import { useState, useEffect } from 'react';
import { useQuery, ApolloError } from '@apollo/client';

import GetSegments from 'graphql/query/audiences/GetSegments.gql';
import { GetSegmentsQueryVariables, GetSegmentsQuery } from 'types/schemaTypes';
import {
  GetCongressionalSegmentsParams,
  GetDmaSegmentsParams,
  GetStateSegmentsParams,
} from 'modules/audiences/components/Locations/graphql/query';
import { TreeData, EnhancedTreeData } from 'utils';
import { LocationType } from 'modules/audiences/AudienceProvider';
import { localSegments } from 'modules/audiences/utils';

export type LocationOptions = {
  [LocationType.CONGRESSIONAL]: EnhancedTreeData[];
  [LocationType.DMA]: TreeData[];
  [LocationType.STATE]: TreeData[];
};

export const defaultLocation = {
  [LocationType.CONGRESSIONAL]: [],
  [LocationType.DMA]: [],
  [LocationType.STATE]: [],
};

export interface UseLocationOptionsResponse {
  loading: boolean;
  error?: ApolloError;
  options: LocationOptions;
}

const [localCache, memoizeSegments] = localSegments();

export const useLocationOptions = (): UseLocationOptionsResponse => {
  const [options, setOptions] = useState<LocationOptions>(defaultLocation);

  const {
    loading: congressionalDistrictsLoading,
    error: congressionalDistrictsError,
    data: { segment: congressionalDistrictSegments = [] } = {},
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegments, GetCongressionalSegmentsParams);

  const {
    loading: statesLoading,
    error: statesError,
    data: { segment: stateSegments = [] } = {},
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegments, GetStateSegmentsParams);

  const {
    loading: dmaLoading,
    error: dmaError,
    data: { segment: dmaSegments = [] } = {},
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegments, GetDmaSegmentsParams);

  const loading = statesLoading || congressionalDistrictsLoading || dmaLoading;

  useEffect(() => {
    if (!loading) {
      memoizeSegments([
        { [LocationType.CONGRESSIONAL]: congressionalDistrictSegments },
        { [LocationType.STATE]: stateSegments },
        { [LocationType.DMA]: dmaSegments },
      ]);
    }
  }, [congressionalDistrictSegments, stateSegments, dmaSegments]);

  useEffect(() => {
    if (!loading) {
      const newOptions = {
        [LocationType.CONGRESSIONAL]: localCache[LocationType.CONGRESSIONAL]?.calculatedSegments || [],
        [LocationType.STATE]: localCache[LocationType.STATE]?.calculatedSegments || [],
        [LocationType.DMA]: localCache[LocationType.DMA]?.calculatedSegments || [],
      };

      setOptions(newOptions);
    }
  }, [localCache, loading]);

  return {
    loading,
    error: congressionalDistrictsError || dmaError || statesError,
    options,
  };
};
