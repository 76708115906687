import React, { useCallback, useMemo, useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { ViewColumn } from '@material-ui/icons';
import { BaseRow, Columns, TableColumn } from '../tableHelpers';
import { Checkbox } from '@openx/components/core';

export interface ColumnFilterProps<RowT extends BaseRow> {
  handleFilterColumn: (column: TableColumn<RowT>) => void;
  updateColumns: (columns: Columns<RowT>) => void;
  columns: Columns<RowT>;
}

const ColumnFilter = ({ handleFilterColumn, updateColumns, columns }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const selectColumn = useMemo(() => columns.find(col => col.key === 'select'), [columns]);

  const columnsWithId = useMemo(
    () =>
      selectColumn
        ? columns
            .map(column => {
              return { id: column.key, ...column };
            })
            .filter(col => col.key !== 'select')
        : columns.map(column => {
            return { id: column.key, ...column };
          }),
    [selectColumn, columns],
  );

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <ViewColumn data-test="filter-column-icon" />
      </IconButton>
      <Menu
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {columnsWithId.map(column => (
          <MenuItem
            data-test={`filter-${column.title}`}
            key={column.id}
            onClick={() => {
              !column.disabled && handleFilterColumn(column);
            }}
          >
            <Checkbox data-test="filter-checkbox" checked={!column.hide} disabled={!!column.disabled} />
            <Typography data-test="filter-column-name">{column.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ColumnFilter;
