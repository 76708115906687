import getenv from 'getenv';
import project from '../package.json';

export const TOP_BAR_HEIGHT = getenv.int('NX_REACT_APP_TOP_BAR_HEIGHT', 54);
export const DEFAULT_PAGE = getenv.string('NX_REACT_APP_DEFAULT_PAGE', '/deals');
export const TABLE_PAGE_SIZE = getenv.int('NX_REACT_APP_TABLE_PAGE_SIZE', 50);

export const OA_VERSION = project.version;
export const SKIP_AUTH = getenv.bool('NX_REACT_APP_SKIP_AUTH', false);
export const ENV_CONFIGS_PATH = '/configs.json';

export const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-172639934-3';
export const HOTJAR_ID = 1968768;
export const HOTJAR_SV = 6;
export const ROLLBAR_ACCESS_TOKEN = 'b7a085a6be28475d85f0ccd67fa72402';

export const INTERNAL_AUSTRALIA_ACCOUNT_ID = getenv.string(
  'NX_REACT_APP_AUSTRALIA_ACCOUNT_ID',
  'e28733bf-7811-4d67-915d-9becf36e44a6',
);

export const XANDR_DEMAND_PARTNER_ID = getenv.int('NX_REACT_APP_XANDR_DEMAND_PARTNER_ID', 537072400);
export const DV360_DEMAND_PARTNER_ID = getenv.int('NX_REACT_APP_DV360_DEMAND_PARTNER_ID', 537073246);

export const developmentConfig = {
  env: 'devint',
  firebase: {
    apiKey: 'AIzaSyB7a62PdZzExe84KfFIkZcuYnY4DpPOzNc',
    authDomain: 'ox-openaudience-devint.firebaseapp.com',
    databaseURL: 'https://ox-openaudience-devint.firebaseio.com',
    projectId: 'ox-openaudience-devint',
    storageBucket: 'ox-openaudience-devint.appspot.com',
  },
};

export const PENDO_API_KEY = '90625ba6-dbd4-461f-49e4-f4246b7f9b19';
