import { flatMap } from 'lodash';
import { flatChildren } from './flatChildren';
interface Item {
  id: string;
  parent_segment: string | null;
  children?: Item[];
}

function flatTree<T extends Item>(tree: T[]): T[] {
  return [...flatMap(tree, treeItem => [treeItem, ...flatChildren<T>(treeItem)])];
}

export function findParent<T extends Item>(tree: T[], item: T, isFlatTree = false): T | undefined {
  return (isFlatTree ? tree : flatTree(tree)).find(treeItem => treeItem.id === item.parent_segment);
}

export function findParents<T extends Item>(tree: T[], item: T): T[] {
  const found = findParent(tree, item);
  if (found) {
    return [...findParents(tree, found), found];
  }
  return [];
}

export function findParentsInFlatTree<T extends Item>(tree: T[], item: T): T[] {
  const found = findParent(tree, item, true);
  if (found) {
    return [...findParentsInFlatTree(tree, found), found];
  }
  return [];
}
