import React, { ComponentType, ReactNode } from 'react';
import classNames from 'classnames';
import { TableRow as MaterialTableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { HighlightRules } from '../highlightRules';

import { BaseRow, Columns, RowAction } from '../tableHelpers';
import BaseTableCell, { TableCellProps } from './TableCell';

export interface TableRowProps<RowT extends BaseRow> {
  rowData?: RowT;
  onRowClick?: RowAction<RowT>;
  columns: Columns<RowT>;
  highlightRules?: HighlightRules;
  ['data-test']?: string;
  selected?: boolean;
  iconCellRenderer?: (data: RowT) => ReactNode;
  customClassNames?: string;
}

const useStyles = makeStyles({
  clickableRow: { cursor: 'pointer' },
});

export function TableRow<RowT extends BaseRow>(props: TableRowProps<RowT>): JSX.Element {
  const TableCell = BaseTableCell as ComponentType<TableCellProps<RowT>>;
  const { clickableRow } = useStyles();
  const { onRowClick, columns, rowData, highlightRules, selected, iconCellRenderer, customClassNames } = props;
  const onClick = onRowClick && rowData ? e => onRowClick(rowData, e) : undefined;
  const rowClassName = onClick && clickableRow;

  return (
    <MaterialTableRow
      onClick={onClick}
      hover
      className={classNames(customClassNames, rowClassName)}
      data-test={props['data-test'] || 'table-row'}
      selected={selected}
    >
      {columns.map(column => (
        <TableCell column={column} rowData={rowData} highlightRules={highlightRules} key={column.key} />
      ))}
      {rowData && iconCellRenderer?.(rowData)}
    </MaterialTableRow>
  );
}
