import React, { useCallback, useEffect, useState } from 'react';

import { PageHeader } from '@openx/components/header';
import { ActionBar } from 'components/ActionBar';
import { ContentWrapper } from 'components/ContentWrapper';
import { Box, Grid, Typography } from '@material-ui/core';
import { ModalDialog, ProviderForm } from '../components';
import { LinkedAccounts } from './components';

import { FormFields, initialFormState, mapRowsToLabels, ProviderFormState } from '../constants';
import { prepareProviderDataToUpdate, prepareAccountsToUnlink, prepareAccountsToLink, getAffectedRows } from '../utils';

import { useHistory, useParams } from 'react-router-dom';
import { RouteProviderEditParams } from 'routes';

import { useSnackbar } from 'notistack';

import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import GetProvider from 'graphql/query/providers/GetProvider.gql';
import EditProvider from 'graphql/mutation/providers/EditProvider.gql';
import LinkOrganizationToProvider from 'graphql/mutation/providers/LinkOrganizationToProvider.gql';
import UnlinkOrganizationFromProvider from 'graphql/mutation/providers/UnlinkOrganizationFromProvider.gql';
import { NestedErrorCode, useLocalErrorHandling } from 'modules/graphql';
import {
  EditProviderMutation,
  EditProviderMutationVariables,
  GetProviderQuery,
  GetProviderQueryVariables,
  LinkOrganizationToProviderMutation,
  LinkOrganizationToProviderMutationVariables,
} from 'types/schemaTypes';
import { useSideDrawer } from 'context';

export const ProviderEdit = () => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory<{ ref?: string }>();

  const client = useApolloClient();

  const { enqueueSnackbar } = useSnackbar();

  const { providerId } = useParams<RouteProviderEditParams>();

  const [formState, setFormState] = useState<ProviderFormState>(initialFormState);
  const [unlinkLoading, setUnlinkLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [affectedRows, setAffectedRows] = useState<string[]>([]);

  const {
    loading: providerLoading,
    error,
    data: { provider_by_pk: providerData = undefined } = {},
  } = useQuery<GetProviderQuery, GetProviderQueryVariables>(GetProvider, {
    variables: { id: providerId },
    fetchPolicy: 'network-only',
  });

  const [editProvider, { loading: editLoading }] = useMutation<EditProviderMutation, EditProviderMutationVariables>(
    EditProvider,
    {
      refetchQueries: ['GetProvidersList'],
    },
  );

  const [linkOrgToProvider, { loading: linkLoading }] = useMutation<
    LinkOrganizationToProviderMutation,
    LinkOrganizationToProviderMutationVariables
  >(LinkOrganizationToProvider);

  useEffect(() => {
    if (providerData) {
      const accounts = providerData.allowed_accounts.map(({ account }) => account);

      setFormState({
        [FormFields.PROVIDER_ID]: providerData.id,
        [FormFields.PROVIDER_NAME]: providerData.name,
        [FormFields.ALLOWED_EXPORT_TYPES]: providerData.allowed_export_types,
        [FormFields.ALLOWES_ORGANIZATIONS]: accounts,
      });
    }
  }, [providerData]);

  useEffect(() => {
    if (providerData) {
      const accounts = providerData.allowed_accounts.map(({ account }) => account);

      const providerDataToFormState = {
        [FormFields.PROVIDER_ID]: providerData.id,
        [FormFields.PROVIDER_NAME]: providerData.name,
        [FormFields.ALLOWED_EXPORT_TYPES]: providerData.allowed_export_types,
        [FormFields.ALLOWES_ORGANIZATIONS]: accounts,
      };

      setAffectedRows(getAffectedRows(providerDataToFormState, formState, providerId));
    }
  }, [formState, providerData, providerId]);

  const nameFieldHandler = value => {
    setFormState(prevState => ({ ...prevState, [FormFields.PROVIDER_NAME]: value }));
  };

  const exportTypesFieldHandler = value => {
    if (formState[FormFields.ALLOWED_EXPORT_TYPES].includes(value)) {
      setFormState(prevState => ({
        ...formState,
        [FormFields.ALLOWED_EXPORT_TYPES]: prevState[FormFields.ALLOWED_EXPORT_TYPES].filter(i => i !== value),
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        [FormFields.ALLOWED_EXPORT_TYPES]: [...prevState[FormFields.ALLOWED_EXPORT_TYPES], value],
      }));
    }
  };

  const allowedAccountFieldHandler = value => {
    setFormState(prevState => ({
      ...prevState,
      [FormFields.ALLOWES_ORGANIZATIONS]: value,
    }));
  };

  useLocalErrorHandling(
    EditProvider,
    useCallback(
      error => {
        const mapOfPathToMessage = {
          'object.name': 'Provider with such name already exists',
        };

        if (
          error.code === NestedErrorCode.SINGLE_UNIQUENESS_VIOLATION &&
          Object.keys(mapOfPathToMessage).includes(error.path)
        ) {
          enqueueSnackbar(mapOfPathToMessage[error.path], { variant: 'error' });
          return true;
        }

        return false;
      },
      [enqueueSnackbar],
    ),
  );

  const goBack = useCallback(
    () => (history.location.state?.ref ? history.goBack() : history.push('/providers')),
    [history],
  );

  const openDialog = () => {
    setModalVisible(true);
  };

  const closeDialog = () => {
    setModalVisible(false);
  };

  const onSave = async () => {
    try {
      const providerEditData = prepareProviderDataToUpdate(formState);

      const initialAccountIds = (providerData?.allowed_accounts.map(({ account }) => account) || []).map(
        ({ id }) => id,
      );
      const updatedAccountIds = formState[FormFields.ALLOWES_ORGANIZATIONS]?.map(({ id }) => id) || [];

      const accountsToLink = prepareAccountsToLink(initialAccountIds, updatedAccountIds, providerId);

      const accountsToUnlink = prepareAccountsToUnlink(initialAccountIds, updatedAccountIds, providerId);

      if (accountsToLink[0]) {
        await linkOrgToProvider({ variables: { objects: accountsToLink } });
      }

      if (accountsToUnlink[0]) {
        const promises = accountsToUnlink.map(params => {
          return client.mutate({
            mutation: UnlinkOrganizationFromProvider,
            variables: params,
          });
        });

        setUnlinkLoading(true);

        Promise.all(promises).then(() => {
          setUnlinkLoading(false);
        });
      }

      await editProvider({ variables: { data: providerEditData, id: providerId } });

      enqueueSnackbar('Provider edited successfully', { variant: 'success' });
      goBack();
    } catch (e) {
      console.error(e);
    }
  };

  const saveDisabled =
    formState[FormFields.PROVIDER_ID].length === 0 || formState[FormFields.PROVIDER_NAME].length === 0;

  const actions = [
    {
      label: 'Save',
      type: 'button',
      props: {
        onClick: () => openDialog(),
        disabled: saveDisabled,
        loading: editLoading || linkLoading || unlinkLoading,
        'data-test': 'provider-edit-save',
      },
    },
  ];

  return (
    <ActionBar onGoBack={goBack} actions={actions}>
      <PageHeader
        title={`Edit: ${providerData?.name}`}
        titlePrefix="OpenAudience"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      >
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <ProviderForm
                values={formState}
                nameFieldHandler={nameFieldHandler}
                exportTypesFieldHandler={exportTypesFieldHandler}
                loading={providerLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <LinkedAccounts
                linkedAccounts={formState[FormFields.ALLOWES_ORGANIZATIONS]}
                allowedAccountFieldHandler={allowedAccountFieldHandler}
                loading={providerLoading}
              />
            </Grid>
          </Grid>
        </ContentWrapper>
        <ModalDialog
          title="Update Provider"
          isOpen={modalVisible}
          onConfirmed={() => onSave()}
          onClose={closeDialog}
          loading={editLoading || linkLoading || unlinkLoading}
          submitButtonText="Save Changes"
        >
          <Box mt={3}>
            <Typography variant="body1" data-test="edit-provider-message">
              You intend to save changes under the following provider: <b>{providerData?.name}</b>
            </Typography>

            {affectedRows[0] && (
              <Box mt={2}>
                <Typography variant="body2" color="textSecondary" data-test="edit-provider-message-details">
                  Updated field(s):
                </Typography>
                {affectedRows.map(row => (
                  <Box key={row} mt={1.5} mb={1.5}>
                    <Typography color="textPrimary" data-test="affected-rows">
                      <b>{mapRowsToLabels[row]}:</b> {row === FormFields.PROVIDER_NAME && formState[row]}
                      {row === FormFields.ALLOWED_EXPORT_TYPES && formState[row].join(', ')}
                      {row === FormFields.ALLOWES_ORGANIZATIONS && formState[row]?.map(({ name }) => name).join(', ')}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
            <Box mt={3}>
              <Typography variant="body1" data-test="edit-provider-message-question">
                Do you want to proceed?
              </Typography>
            </Box>
          </Box>
        </ModalDialog>
      </PageHeader>
    </ActionBar>
  );
};
