import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Theme, Box } from '@material-ui/core';

import { Paper, Loader, CheckboxField } from '@openx/components/core';
import { TextField } from '@openx/components/core';
import { parseApiFormatToPercentage } from 'utils/parsePercentageValue';

interface Props {
  fees?: any;
  organizationData: any;
  loading: boolean;
  isEdit: boolean;
  isEnabled?: boolean;
  onChange?: (type: string) => (value: any) => {};
}

const useStyles = makeStyles<Theme>(theme => ({
  feesBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  textMargin: {
    marginBottom: 12,
  },
  subtitle: {
    marginTop: 20,
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3.8),
  },
  text: {
    marginTop: theme.spacing(0.8),
  },
}));

export const Fees = ({ loading, organizationData, fees, isEdit, onChange, isEnabled }: Props) => {
  const { feesBlock, subtitle, text, header, textMargin } = useStyles();

  const [sharePartnerBoolean, setSharePartnerBoolen] = useState(false);
  const [validationErrors, setValidationErrors] = useState([] as any[]);
  const [errorMessage, setErrorMessage] = useState('');
  const [platformShareFee, setPlatformShareFee] = useState(null);
  const handleChange =
    value =>
    ({ target }) => {
      if (value === 'default_platform_share_boolean') {
        setSharePartnerBoolen(target.checked);
        if (!target.checked) {
          !!onChange && onChange('default_platform_share')(null);
        } else {
          !!onChange && onChange('default_platform_share')(platformShareFee);
        }
      } else {
        if (parseFloat(target.value) < 0.0001 || parseFloat(target.value) >= 1) {
          setValidationErrors(prevState => {
            if (!prevState.includes(value)) {
              return [...prevState, value];
            }
            return prevState;
          });
          setErrorMessage('Field must be between 0.000 and 0.999 (not 100.000)');
        } else {
          const index = validationErrors.indexOf(value);
          if (index !== -1) {
            validationErrors.splice(index, 1);
          }
        }
      }
      !!onChange && onChange(value)(target.value);
    };

  useEffect(() => {
    if (fees?.default_platform_share) {
      setSharePartnerBoolen(true);
    }
    if (platformShareFee === null) {
      const initialPlatformShareFee = fees?.default_platform_share ?? null;
      setPlatformShareFee(initialPlatformShareFee);
    }
  }, [fees]);

  return (
    <Paper>
      <div className={header}>
        <Typography variant="h2" data-test="fees-title">
          Fees
        </Typography>
      </div>

      {isEdit && (
        <div className={feesBlock}>
          {loading && <Loader />}
          {!loading && (
            <>
              <CheckboxField
                label="OpenX Revenue Share Partner"
                data-test="revenue-share-partner"
                onChange={handleChange('default_platform_share_boolean')}
                disabled={!isEnabled}
                checked={sharePartnerBoolean}
              />

              <Typography variant="subtitle2" data-test="platform-fee-title" className={subtitle}>
                OpenX Platform Fee
              </Typography>
              <TextField
                label="OpenX Percent of Media (%)"
                placeholder="Type your percent"
                fullWidth
                margin="dense"
                data-test="default-platform-share"
                disabled={!sharePartnerBoolean}
                onChange={handleChange('default_platform_share')}
                value={fees.default_platform_share || ''}
                error={validationErrors.includes('default_platform_share')}
                helperText={validationErrors.includes('default_platform_share') && errorMessage}
              />

              <Typography variant="subtitle2" data-test="organization-max-fee-title" className={subtitle}>
                Organization Max Fees
              </Typography>
              <TextField
                value={fees.max_share || ''}
                label="Max Percent of Media (%)"
                placeholder="Type your percent"
                fullWidth
                disabled={!isEnabled}
                margin="dense"
                data-test="max-share"
                onChange={handleChange('max_share')}
                error={validationErrors.includes('max_share')}
                helperText={validationErrors.includes('max_share') && errorMessage}
              />
              <TextField
                value={fees.max_cpm_cap || ''}
                label="Max Fixed CPM"
                placeholder="0.00"
                fullWidth
                disabled={!isEnabled}
                margin="dense"
                data-test="max-cpm-cap"
                onChange={handleChange('max_cpm_cap')}
              />
            </>
          )}
        </div>
      )}

      {!isEdit && organizationData.default_platform_share && (
        <Box className={textMargin}>
          <Typography variant="body2" color="textSecondary" data-test="organization-default-platform-share-title">
            OpenX Platform Fee
          </Typography>
          <Typography variant="body1" className={text} data-test="organization-default-platform-share">
            {parseApiFormatToPercentage(organizationData.default_platform_share)}%
          </Typography>
        </Box>
      )}

      {!isEdit && organizationData.max_share && (
        <Box className={textMargin}>
          <Typography variant="body2" color="textSecondary" data-test="organization-max-share-title">
            Organization Max Fees (Max Percent of Media (%))
          </Typography>
          <Typography variant="body1" data-test="organization-max-share" className={text}>
            {parseApiFormatToPercentage(organizationData.max_share)}%
          </Typography>
        </Box>
      )}

      {!isEdit && organizationData.max_cpm_cap && (
        <Box>
          <Typography variant="body2" color="textSecondary" data-test="organization-max-cpm-cap-title">
            Organization Max Fees (Max Fixed CPM)
          </Typography>
          <Typography variant="body1" data-test="organization-max-cpm-cap" className={text}>
            {organizationData.max_cpm_cap} USD
          </Typography>
        </Box>
      )}

      {!isEdit &&
        !organizationData.max_share &&
        !organizationData.default_platform_share &&
        !organizationData.max_cpm_cap && (
          <Box>
            <Typography variant="body2" color="textSecondary" data-test="no-linked-providers-message">
              There are no fees to this organization.
            </Typography>
          </Box>
        )}
    </Paper>
  );
};
