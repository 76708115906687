import React, { ReactNode } from 'react';
import { Checkbox } from '@openx/components/core';

import { BaseRow, Columns } from '../tableHelpers';
import { SelectListProps, SelectOperation } from './type';
import { allItemsSelected, anyItemSelected } from './selectCheckers';

export function extendColumnsWithCheckbox<RowT extends BaseRow>(
  columns: Columns<RowT>,
  allItems: RowT[],
  selectList: SelectListProps<RowT>,
  disabled = false,
): Columns<RowT> {
  const { selectedItems, toggleSelect } = selectList;

  function isSelected(item: RowT): boolean {
    return selectedItems.includes(item);
  }

  const selectAllCheckbox: ReactNode = (
    <Checkbox
      checked={anyItemSelected(allItems, selectedItems)}
      indeterminate={anyItemSelected(allItems, selectedItems) && !allItemsSelected(allItems, selectedItems)}
      onClick={e => e.stopPropagation()}
      onChange={event => {
        const { checked } = event.target;
        toggleSelect(allItems, checked ? SelectOperation.SELECT : SelectOperation.UNSELECT);
      }}
      disabled={disabled}
      color="primary"
      key="select-all"
    />
  );

  function singleCheckboxRenderer(item: RowT): JSX.Element {
    return (
      <div>
        <Checkbox
          checked={isSelected(item)}
          onClick={e => e.stopPropagation()}
          onChange={event => {
            const { checked } = event.target;
            toggleSelect([item], checked ? SelectOperation.SELECT : SelectOperation.UNSELECT);
          }}
          color="primary"
        />
      </div>
    );
  }

  return [
    {
      key: 'select',
      title: selectAllCheckbox,
      render: singleCheckboxRenderer,
      width: '4%',
    },
    ...columns,
  ];
}
