import React, { ReactNode } from 'react';
import { Grid, Theme, makeStyles } from '@material-ui/core';

import { Paper } from '@openx/components/core';

const useStyles = makeStyles<Theme>(theme => ({
  wrapper: {
    marginTop: theme.spacing(3),
  },
}));

interface Props {
  actionCancel?: ReactNode;
  actionConfirm?: ReactNode;
  checkbox?: ReactNode;
  content?: ReactNode;
}

export const Layout = ({ actionCancel, actionConfirm, checkbox, content }: Props) => {
  const { wrapper } = useStyles();
  const actionsVisible = actionCancel || actionConfirm;

  return (
    <Grid container justifyContent="center" className={wrapper}>
      <Grid item xs={6}>
        <Paper>
          {({ ActionsSection }) => (
            <>
              {content}
              {checkbox}
              {actionsVisible && (
                <ActionsSection>
                  <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                    <Grid item>{actionCancel}</Grid>
                    <Grid item>{actionConfirm}</Grid>
                  </Grid>
                </ActionsSection>
              )}
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
