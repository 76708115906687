import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classnames from 'classnames';

import { defaultPadding, paddingMap } from './config';
import { IndentationProps, PaperPadding, StylesProps } from './types';

const useStyles = makeStyles<Theme, StylesProps>({
  body: {
    margin: ({ padding }) => `0 -${paddingMap[padding]}px`,
  },
});

export const makeIndentation = (padding: PaperPadding = defaultPadding) =>
  function ({ children, className }: IndentationProps): JSX.Element {
    const classes = useStyles({ padding });
    return <div className={classnames(classes.body, className)}>{children}</div>;
  };
