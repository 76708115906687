import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { APIKeysSection, DetailsSection, LinkedProviders, FeatureFlags, Fees } from './components';

import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { ActionBar } from 'components/ActionBar';
import { ContentWrapper } from 'components/ContentWrapper';
import { PageHeader } from '@openx/components/header';
import { Loader } from '@openx/components/core';

import { RouteOrganizationDetailsParams } from 'routes';
import GetOrganization from 'graphql/query/organizations/GetOrganization.gql';
import { GetOrganizationQuery, GetOrganizationQueryVariables } from 'types/schemaTypes';
import { useSideDrawer } from 'context';

export const OrganizationDetails = () => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();
  const { organizationId } = useParams<RouteOrganizationDetailsParams>();

  const {
    loading: organizationLoading,
    error: organizationError,
    data: { account_by_pk: organizationData = undefined } = {},
  } = useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganization, {
    variables: { id: organizationId },
    fetchPolicy: 'no-cache',
  });

  const {
    name,
    description,
    resource_id: resourceId,
    allowed_countries: allowedCountries,
    allowed_domains: allowedDomains,
    // @ts-ignore TODO:
    allowed_providers: allowedProviders = [],
    // @ts-ignore TODO:
    feature_flags: featureFlags = [],
    parent_account: parentAccount,
    exchange_account_partner_id: partnerId,
  } = organizationData || {};

  const linkedProviders = useMemo(() => allowedProviders.map(({ provider }) => ({ ...provider })), [allowedProviders]);

  const actions = [
    {
      label: 'Edit',
      type: 'button',
      props: {
        onClick: () => {
          history.push(`/organizations/${organizationId}/edit`);
        },
        'data-test': 'organization-edit',
      },
    },
  ];

  if (organizationLoading) {
    return <Loader />;
  }

  if (organizationError) {
    throw new Error('Could not get the organization data');
  }

  return (
    <>
      <ActionBar onGoBack={history.goBack} actions={actions} />
      <PageHeader
        title={`Details: ${name}`}
        titlePrefix="OpenAudience"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      >
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DetailsSection
                    name={name}
                    description={description}
                    resourceId={resourceId}
                    allowedCountries={allowedCountries}
                    allowedDomains={allowedDomains}
                    parentAccount={parentAccount}
                    partnerId={partnerId}
                    organizationId={organizationId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FeatureFlags accountFeatureFlags={featureFlags} readonly loading={organizationLoading} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <APIKeysSection organizationId={organizationId} />
                </Grid>
                <Grid item xs={12}>
                  <LinkedProviders linkedProviders={linkedProviders} loading={organizationLoading} />
                </Grid>
                <Grid item xs={12}>
                  <Fees organizationData={organizationData} loading={organizationLoading} isEdit={false} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContentWrapper>
      </PageHeader>
    </>
  );
};
