import React from 'react';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { HighlightedPhrase } from '../HighlightedPhrase';
import { Link } from '../Link';

import { CopyToClipboard } from '../CopyToClipboard';
import { OutsideLink } from '../Link';

const useStyles = makeStyles(theme => ({
  cellContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
  }),
  cellOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  rowSubtitle: {
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '22px',
  },
  rowSubtitleId: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  arrowContainer: {
    width: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  arrowIcon: {
    width: '12px',
    height: '12px',
    color: theme.palette.primary.main,
  },
  arrowRotate: {
    transform: 'rotate(90deg)',
  },
  // it can be moved to cellContainer class after update to material-ui 5.+ https://github.com/mui-org/material-ui/issues/18710
  cellContainerHover: {
    '&:hover $copyIcon': {
      visibility: 'visible',
    },
  },
  copyIcon: {
    visibility: 'hidden',
  },
}));

interface NameCellProps {
  name: string;
  pathTo: string;
  id: string;
  children?: React.ReactNode;
  searchPhrase?: string;
  subRowLabel?: string;
  isOutsideLink?: boolean;
}

export const NameCell = ({
  name,
  pathTo,
  id,
  children,
  searchPhrase = '',
  subRowLabel = 'ID:',
  isOutsideLink = false,
}: NameCellProps) => {
  const classes = useStyles();

  const CellLink = isOutsideLink ? OutsideLink : Link;

  return (
    <div className={classNames(classes.cellContainer, classes.cellContainerHover)}>
      <div className={classes.cellOverflow}>
        <CellLink data-test="row-title" to={pathTo}>
          <HighlightedPhrase searchPhrase={searchPhrase}>{name}</HighlightedPhrase>
        </CellLink>
        <div data-test="row-subtitle">
          <span className={classNames(classes.rowSubtitle, classes.rowSubtitleId)}>{subRowLabel}</span>
          <span className={classes.rowSubtitle}>
            {id} <CopyToClipboard text={id} containerClass={classes.copyIcon} />
          </span>
        </div>
        {children}
      </div>
    </div>
  );
};
