export enum Country {
  USA = 'US',
  AUSTRALIA = 'AU',
}

export enum Domain {
  OPENX = 'openx.com',
}

export const DEFAULT_ALLOWED_COUNTRIES = [Country.USA];

export const DEFAULT_ALLOWED_DOMAINS = [Domain.OPENX];

export const allowedCountriesOptions = [Country.USA, Country.AUSTRALIA];

export const allowedDomainsOptions = [Domain.OPENX];

export const mapOfAllowedCountriesToName = {
  [Country.USA]: 'United States',
  [Country.AUSTRALIA]: 'Australia',
};

export const KeyCodes = {
  comma: 188,
  enter: 13,
};

export const delimiters = [KeyCodes.comma, KeyCodes.enter];
