import React, {
  forwardRef,
  useContext,
  createContext,
  useRef,
  Children,
  cloneElement,
  useEffect,
  ComponentType,
  HTMLAttributes,
} from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

import { AutoComplete } from './AutoComplete';
import { AutocompleteProps } from './types';

const LISTBOX_PADDING = 8;
const ITEM_SIZE = 36;

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

// Adapter for react-window
export const ListboxComponent = forwardRef<HTMLDivElement, { width?: string; itemSize?: number }>((props, ref) => {
  const { children, width, itemSize = ITEM_SIZE, ...other } = props;
  const itemData = Children.toArray(children);
  const itemCount = itemData.length;

  const getHeight = (): number => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }

    return itemCount * itemSize;
  };

  const gridRef = useRef<VariableSizeList>(null);

  useEffect(() => {
    if (gridRef.current != null) {
      gridRef.current.resetAfterIndex(0, true);
    }
  }, [itemCount]);

  const rowHeights = useRef({});

  const getRowHeight = (index: number): number =>
    rowHeights.current[index] && rowHeights.current[index] > itemSize ? rowHeights.current[index] + 8 : itemSize;

  function setRowHeight(index, size) {
    rowHeights.current = { ...rowHeights.current, [index]: size };
    if (gridRef?.current != null) gridRef.current.resetAfterIndex(0);
  }

  function RenderRow(props: ListChildComponentProps & { style: any }): JSX.Element {
    const { data, index, style } = props;
    const rowRef = useRef<HTMLElement>(null);

    useEffect(() => {
      if (rowRef.current) {
        const el = rowRef.current;
        const height = el?.firstElementChild?.clientHeight;
        if (height && height > itemSize) setRowHeight(index, height);
        el.setAttribute('data-test', 'list-item-loaded');
      }
    }, [index, rowRef]);

    return cloneElement(data[index], {
      style: {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
      },
      ref: rowRef,
    });
  }

  return (
    <div ref={ref} data-test="list-of-items">
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width={width ?? '100%'}
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={idx => getRowHeight(idx)}
          overscanCount={5}
          itemCount={itemCount}
        >
          {RenderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export function AutoCompleteVirtualize<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  return (
    <AutoComplete
      disableListWrap
      ListboxComponent={ListboxComponent as ComponentType<HTMLAttributes<HTMLElement>>}
      {...props}
    />
  );
}
