import React from 'react';
import { Chip as MaterialChip, ChipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  chip: {
    height: '32px',
    borderRadius: '50px',
    backgroundColor: theme.palette.text.secondary,
    padding: `0px ${theme.spacing(1.5)}px`,
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '28px',
    margin: theme.spacing(0.5),
  },
  closeIcon: {
    width: '18px',
    height: '18px',
  },
}));

export const Chip = (props: ChipProps) => {
  const classes = useStyles();
  return (
    <MaterialChip
      className={classes.chip}
      {...props}
      deleteIcon={<CloseIcon className={classes.closeIcon} data-test="chip-delete" />}
    />
  );
};
