import { useState, useEffect } from 'react';
import { useQuery, ApolloError } from '@apollo/client';

import GetSegments from 'graphql/query/audiences/GetSegments.gql';
import { GetSegmentsQueryVariables, GetSegmentsQuery, Order_By } from 'types/schemaTypes';
import { EnhancedTreeData } from 'utils';
import { FIRST_PARTY_FIELD } from 'modules/audiences/AudienceProvider';
import { localSegments } from 'modules/audiences/utils';

const getSegmentParams = (accountId?: string | null) => ({
  variables: {
    order_by: [{ full_name: Order_By.Asc }],
    where: {
      category: { _eq: 'direct' },
      sub_category: { _eq: 'uploaded' },
      is_archived: { _eq: false },
      status: { _eq: 'ready' },
      ...(accountId && {
        account_id: {
          _eq: accountId,
        },
      }),
    },
  },
});

export interface UseFirstPartyOptionsResponse {
  loading: boolean;
  error?: ApolloError;
  options: EnhancedTreeData[];
}

const [localCache, memoizeSegments] = localSegments();

export const useFirstPartyOptions = ({ accountId }): UseFirstPartyOptionsResponse => {
  const [options, setOptions] = useState<EnhancedTreeData[]>([]);

  const {
    loading,
    error,
    data: { segment = [] } = {},
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegments, getSegmentParams(accountId));

  useEffect(() => {
    if (!loading) {
      memoizeSegments([{ [FIRST_PARTY_FIELD]: segment }]);
    }
  }, [segment]);

  useEffect(() => {
    if (!loading) {
      const calculatedSegments = localCache[FIRST_PARTY_FIELD]?.calculatedSegments || [];

      setOptions(calculatedSegments);
    }
  }, [localCache, loading]);

  return {
    loading,
    error,
    options,
  };
};
