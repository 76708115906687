import { useEffect } from 'react';
import { PENDO_API_KEY } from 'config';
import pendo from './script';
import { SessionContext } from 'modules/auth/AuthProvider';
import { getEnvironmentConfigs } from 'environmentConfigs';

/**
 * Pendo.io is a product analytics and user experience platform designed to help companies
 * better understand how users interact with their software products.
 * You can find more here {@link https://openxtechinc.atlassian.net/wiki/spaces/TECH/pages/3137634413/Pendo+user+task+tracking+tool}
 */
export const initializePendo = () => pendo(PENDO_API_KEY);

export const initializeUser = (userId: string, userEmail: string) => {
  if (window.pendo && !window.pendo?.visitorId) {
    window.pendo.initialize({
      account: {
        id: userId,
      },
      apiKey: PENDO_API_KEY,
      visitor: {
        email: userEmail,
        id: userId,
      },
    });
  }
};

export const usePendo = (session?: SessionContext | null) => {
  useEffect(() => {
    const init = async () => {
      const { env } = await getEnvironmentConfigs();

      if (env === 'prod' && session && session.uid && session.email) {
        initializeUser(session.uid, session.email);
      }
    };

    init();
  }, [session]);
};
