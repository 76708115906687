export enum DataType {
  MOBILE_AD_ID = 'maid',
  EMAIL = 'email',
  PHONE = 'phone',
}

const dataTypeLabels: Record<DataType, string> = {
  maid: 'Mobile Ad ID',
  email: 'Email',
  phone: 'Phone',
};

export function getDataTypeLabel(dataType: DataType): string {
  return dataTypeLabels[dataType];
}
