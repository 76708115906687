import React, { useMemo, FC } from 'react';
import { FormControl, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { EnhancedTreeData } from 'utils/segmentsToTreeData';
import { useAudience, ActionType, fields } from 'modules/audiences/AudienceProvider';
import { useDemographicsOptions, ANY_GENDER_VALUE } from 'modules/audiences/hooks';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
  },
});

export const GENDER_EXTERNAL_ID = 'oa_demo_gender';

interface GenderProps {
  value?: EnhancedTreeData[];
}

type MapById = {
  [id: string]: EnhancedTreeData;
};

export const Gender: FC<GenderProps> = ({ value }): JSX.Element => {
  const classes = useStyles();
  const { dispatch } = useAudience();
  const {
    options: { [fields.GENDER_FIELD]: options },
  } = useDemographicsOptions();

  let loadAny = false;
  const genders = value !== undefined ? [...(value?.map(obj => obj.id) || [])] : [ANY_GENDER_VALUE];
  if (genders.length === 0) {
    genders.push(ANY_GENDER_VALUE);
    loadAny = true;
  }

  const mapById: MapById = useMemo(
    () =>
      options.reduce((curr, { id, external_id }) => {
        curr[id] = { id, external_id };
        return curr;
      }, {}),
    [options],
  );

  const handleChange = (_, id: string[]) => {
    if (!loadAny) {
      if (id.includes(ANY_GENDER_VALUE)) {
        dispatch({
          type: ActionType.SET_DEMOGRAPHIC_GENDER,
          payload: { segments: { [fields.GENDER_FIELD]: [] } },
        });
        return;
      }
    }
    const newSegments = id && id.length > 0 ? id.map(id => mapById[id]).filter(Boolean) : [];

    dispatch({
      type: ActionType.SET_DEMOGRAPHIC_GENDER,
      payload: { segments: { [fields.GENDER_FIELD]: newSegments || ({} as EnhancedTreeData[]) } },
    });
  };

  const buttonOptions = options.map(({ name, id }) => ({ name, id }));
  buttonOptions.push({ name: ANY_GENDER_VALUE, id: ANY_GENDER_VALUE });
  buttonOptions.reverse();

  return (
    <FormControl className={classes.container} data-test="gender-buttons">
      {options.length > 1 && <FormLabel>Gender</FormLabel>}
      <ToggleButtonGroup onChange={handleChange} value={genders}>
        {options.length > 1 &&
          buttonOptions.map(({ name, id }) => (
            <ToggleButton value={id} key={id}>
              {name}
            </ToggleButton>
          ))}
      </ToggleButtonGroup>
    </FormControl>
  );
};
