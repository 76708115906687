import React, { useState, useMemo, useCallback, FC, memo } from 'react';
import { Box, makeStyles, Typography, Theme } from '@material-ui/core';
import { isEqual } from 'lodash';

import { Drawer, DrawerHeader, DrawerActionBar, MuiButtonColor, ButtonVariant } from '@openx/components/core';
import { ModalDialog } from './ModalDialog';
import {
  useAudience,
  getOpenSubgroupsRelationField,
  getOpenSubgroupsGroupIndex,
  getOpenSubgroupsSubgroupIndex,
  getOpenSubgroupsSegments,
  getDirectProvider,
  getSubgroupsSegments,
  getGroupSegments,
  getExportType,
} from 'modules/audiences/AudienceProvider';
import { areSegmentsEmpty } from 'modules/audiences/utils/areSegmentsEmpty';
import { isCustomExportSelected } from 'modules/audiences/utils/isCustomExportSelected';

import { SegmentsList } from './SegmentsList';
import { Locations } from '../Locations/Locations';
import { PredefinedAudienceSegments } from '../PredefinedAudienceSegments/PredefinedAudienceSegments';
import { Demographics } from '../Demographics/Demographics';
import { DirectSegment } from '../DirectSegment/DirectSegment';
import { FirstParty } from '../FirstParty';

interface StyleProps {
  isScrollbarShown: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  paper: {
    padding: 0,
    minWidth: '800px',
  },
  content: {
    display: 'flex',
    width: '56vw',
    minWidth: '800px',
    height: 'calc(100% - 53px)',
  },
  editor: {
    width: '37.5%',
    minWidth: '300px',
    background: '#fff',
    marginBottom: theme.spacing(6.25),
    overflow: 'overlay',
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: '15px',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-appearance': 'none',
        border: 'solid 5px transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '50px',
        boxShadow: ({ isScrollbarShown }) => (isScrollbarShown ? 'inset 0 0 10px 10px #C4C4C4' : 'none'),
        border: 'solid 5px transparent',
      },
    },
  },
  summary: {
    backgroundColor: theme.palette.background.black03,
    width: '62.5%',
    minWidth: '500px',
    marginBottom: theme.spacing(6.25),
    paddingTop: theme.spacing(3.625),
    paddingBottom: theme.spacing(2.5),
    overflow: 'overlay',
    '&:hover': {
      '&::-webkit-scrollbar': {
        width: '15px',
      },
      '&::-webkit-scrollbar-track': {
        border: 'solid 5px transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '50px',
        boxShadow: ({ isScrollbarShown }) => (isScrollbarShown ? 'inset 0 0 10px 10px #C4C4C4' : 'none'),
        border: 'solid 5px transparent',
      },
    },
  },
  matchTypeSwitch: {
    marginLeft: theme.spacing(2.5),
  },
  summaryTitle: {
    marginLeft: theme.spacing(2.5),
  },
  summarySection: {
    padding: '0 20px 24px 20px',
    marginTop: '30px',
  },
  modalText: {
    lineHeight: '28px',
  },
  noSegments: {
    marginLeft: theme.spacing(2.5),
    color: theme.palette.background.black30,
  },
}));

export interface EditSegmentDrawerProps {
  open: boolean;
  onApply: () => void;
  onOpen: () => void;
  onClose: () => void;
}

const getTitle = (groupIndex: number, subgroupIndex: number) => {
  if (groupIndex !== undefined && subgroupIndex === 0) {
    return `Group ${groupIndex}`;
  }

  if (groupIndex !== undefined && subgroupIndex !== undefined) {
    return `Subgroup ${groupIndex}.${subgroupIndex}`;
  }

  return 'Unknown Group';
};

let scrollbarTimer;

export const EditSegmentDrawer: FC<EditSegmentDrawerProps> = memo(({ onClose, onOpen, onApply, open }) => {
  const { state } = useAudience();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isScrollbarShown, setIsScrollbarShown] = useState(false);

  const classes = useStyles({ isScrollbarShown });

  const handleOpenDialog = () => {
    if (!isEqual(prevSegments, segments)) {
      setIsDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDialog = () => {
    handleDialogClose();
    onClose();
  };

  const relationField = getOpenSubgroupsRelationField(state);
  const groupIndex = getOpenSubgroupsGroupIndex(state);
  const subgroupIndex = getOpenSubgroupsSubgroupIndex(state);
  const segments = getOpenSubgroupsSegments(state);
  const groupName = useMemo(() => getTitle(groupIndex, subgroupIndex), [groupIndex, subgroupIndex]);
  const exportType = getExportType(state);
  const customExportSelected = isCustomExportSelected(exportType);

  const prevSubgroupSegments = useMemo(
    () => getSubgroupsSegments(state, relationField, groupIndex, subgroupIndex),
    [relationField, groupIndex, subgroupIndex],
  );

  const prevGroupSegments = useMemo(
    () => getGroupSegments(state, relationField, groupIndex),
    [relationField, groupIndex],
  );

  const prevSegments = useMemo(() => (subgroupIndex === 0 ? prevGroupSegments : prevSubgroupSegments), [subgroupIndex]);

  const actions = [
    {
      label: 'CANCEL',
      variant: 'text' as ButtonVariant,
      'data-test': 'cancel-edit-segment-button',
      onClick: handleOpenDialog,
    },
    {
      label: 'APPLY',
      color: 'primary' as MuiButtonColor,
      'data-test': 'apply-edit-segment-button',
      onClick: onApply,
    },
  ];

  const showDirectSegment = !!getDirectProvider(state);

  const resetScrollbarTimer = useCallback(() => {
    if (!isScrollbarShown) setIsScrollbarShown(true);

    clearTimeout(scrollbarTimer);
    scrollbarTimer = setTimeout(() => {
      if (isScrollbarShown) {
        setIsScrollbarShown(false);
      }
    }, 1000);
  }, [scrollbarTimer]);

  return (
    <Drawer onOpen={onOpen} open={open} classes={{ paper: classes.paper }}>
      <DrawerHeader onClose={handleOpenDialog}>{groupName}</DrawerHeader>
      <div className={classes.content} data-test="audience-segment-drawer">
        <div className={classes.editor} onScroll={resetScrollbarTimer}>
          {showDirectSegment && <DirectSegment />}
          {!customExportSelected && !showDirectSegment && <FirstParty />}
          {!customExportSelected && <Locations />}
          {!customExportSelected && <Demographics />}
          {!customExportSelected && <PredefinedAudienceSegments />}
        </div>
        <div className={classes.summary} onScroll={resetScrollbarTimer}>
          <Typography variant="h2" gutterBottom className={classes.summaryTitle}>
            Summary
          </Typography>
          {areSegmentsEmpty(segments) && (
            <Typography variant="body2" className={classes.noSegments}>
              No segments selected
            </Typography>
          )}
          <SegmentsList readonly={false} relationField={relationField} segments={segments} />
        </div>
      </div>
      <DrawerActionBar actions={actions} stickyBottom buttonsPosition="right" />
      <ModalDialog
        title="Changes"
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
        onConfirmed={handleConfirmDialog}
        submitButtonText="Drop changes"
      >
        <Box marginTop="16px">
          <Typography variant="body1" className={classes.modalText}>
            You’re about to lose your changes.
          </Typography>
        </Box>
        <Box marginTop="40px">
          <Typography variant="body1" className={classes.modalText}>
            Do you want to proceed?
          </Typography>
        </Box>
      </ModalDialog>
    </Drawer>
  );
});
