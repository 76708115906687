import { makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';

import { prepareHighlightedPhrase, HighlightPhraseProps } from './prepareHighlightedPhrase';

const useStyles = makeStyles(theme => ({
  highlighted: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.text.primary,
  },
}));

export interface HighlightedPhraseProps {
  children: string;
  searchPhrase: HighlightPhraseProps;
}

export function HighlightedPhrase(props: HighlightedPhraseProps): JSX.Element {
  const { children, searchPhrase } = props;
  const classes = useStyles();
  const content = children || '';

  const highlightedChunks = useMemo(() => prepareHighlightedPhrase(content, searchPhrase), [content, searchPhrase]);

  return (
    <span>
      {highlightedChunks.map((chunk, index) => (
        <span
          data-test={chunk.highlighted ? 'highlighted' : undefined}
          className={chunk.highlighted ? classes.highlighted : ''}
          key={index}
        >
          {chunk.content}
        </span>
      ))}
    </span>
  );
}
