import React from 'react';
import MaterialTableRow from '@material-ui/core/TableRow';
import MaterialTableCell from '@material-ui/core/TableCell';
import { BaseRow } from '../tableHelpers';
import { TableRow, TableRowProps } from './TableRow';

export const EXPAND_CONTENT_ROW_CLASS = 'expandContentRow';
export const EXPAND_CONTENT_CELL_CLASS = 'expandContentCell';

export interface ExpandComponentProps<RowT extends BaseRow> {
  data: RowT;
}

export interface TableRowExpandedProps<RowT extends BaseRow> extends TableRowProps<RowT> {
  expandComponent: React.ComponentType<ExpandComponentProps<RowT>>;
  rowData: RowT;
}

export function TableRowExpanded<RowT extends BaseRow>(props: TableRowExpandedProps<RowT>): JSX.Element {
  const { rowData } = props;
  const ExpandComponent = props.expandComponent;

  return (
    <>
      <TableRow {...props} selected={rowData.expanded} data-test="table-row-expand" />
      {rowData.expanded && (
        <MaterialTableRow selected={rowData.expanded} className={EXPAND_CONTENT_ROW_CLASS}>
          <MaterialTableCell padding="none" colSpan={12} className={EXPAND_CONTENT_CELL_CLASS}>
            <ExpandComponent data={rowData} />
          </MaterialTableCell>
        </MaterialTableRow>
      )}
    </>
  );
}
