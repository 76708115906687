import React from 'react';
import { Grid } from '@material-ui/core';

import { useHistory } from 'react-router-dom';

import { ActionBar } from 'components/ActionBar';
import { PageHeader } from '@openx/components/header';
import { ContentWrapper } from 'components/ContentWrapper';

import { useSession } from 'modules/auth/AuthProvider';
import { OrganizationDetailsSection, UserDetailsSection } from './components';
import { useSideDrawer } from 'context';

export const SettingsPage = () => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();

  const {
    first_name: firstName,
    last_name: lastName,
    email,
    role,
    account: organization,
    created_date: dateJoined,
  } = useSession();

  const actions = [
    {
      label: 'Edit',
      type: 'button',
      props: {
        onClick: () => {
          history.push(`/organizations/${organization.id}/edit`);
        },
        'data-test': 'settings-edit',
      },
    },
  ];

  return (
    <>
      <ActionBar onGoBack={history.goBack} actions={actions} />
      <PageHeader
        title="Settings"
        titlePrefix="OpenAudience"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      >
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <UserDetailsSection
                firstName={firstName}
                lastName={lastName}
                email={email}
                role={role}
                organization={organization}
                dateJoined={dateJoined}
              />
            </Grid>
            <Grid item xs={6}>
              <OrganizationDetailsSection organizationId={organization.id} />
            </Grid>
          </Grid>
        </ContentWrapper>
      </PageHeader>
    </>
  );
};
