import { EnhancedTreeData } from 'utils';

export enum OptionState {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
  REMOVE = 'remove',
}

export enum ListType {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}
interface UtilsArgs {
  items: EnhancedTreeData[];
  listFolded: boolean;
  lastItemsCount: number;
}

export type GetVisibleItemsArgs = {
  items: EnhancedTreeData[];
  listFolded: boolean;
  page?: number;
};

export type DetermineNextFoldedStateArgs = UtilsArgs;

export type OnOptionClickHandler = <K extends EnhancedTreeData>(action: OptionState, option: K) => void;

export type OnSpecifiedOptionClickHandler = (state: OptionState, option: EnhancedTreeData, field: string) => void;
