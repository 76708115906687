import React, { ReactNode, useCallback, useMemo } from 'react';
import MaterialTable from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/styles/makeStyles';

import { ActionsCell } from '../ActionsCell';
import { ExpandIconCell } from '../ExpandIconCell';
import { HighlightRules } from '../../highlightRules';
import { ChangeSingleCriteriaAction, CriteriaDimension, SortCriteria } from '../../tableCriteria';
import { BaseRow, Columns, RowAction, RowActionButtonBaseProps, RowActionProps } from '../../tableHelpers';
import { TableHeader } from '../TableHeader';
import { TableRow } from '../TableRow';
import { ExpandComponentProps, TableRowExpanded } from '../TableRowExpanded';
import { TableRowTree } from '../TableRowTree';
import { ACTION_ICON_CELL } from './config';

function renderRowsSkeleton<RowT extends BaseRow>(columns: Columns<RowT>, rowsAmount = 3): JSX.Element[] {
  const result: JSX.Element[] = [];

  for (let i = 0; i < rowsAmount; i += 1) {
    result.push(<TableRow columns={columns} key={i} />);
  }

  return result;
}

const useStyles = makeStyles({
  fixedColumnsWidth: {
    tableLayout: 'fixed',
  },
});

export interface DataSectionProps<RowT extends BaseRow> {
  data: RowT[];
  columns: Columns<RowT>;
  onRowClick?: RowAction<RowT>;
  loading: boolean;
  expanded?: boolean;
  isTree?: boolean;
  isForcedExpanded?: (data: RowT) => boolean;
  treeChildrenKey?: string;
  expandComponent?: React.ComponentType<ExpandComponentProps<RowT>>;
  highlightRules?: HighlightRules;
  sortCriteria?: SortCriteria;
  onSortChange: ChangeSingleCriteriaAction<CriteriaDimension.SORT>;
  hideHeader?: boolean;
  primaryRowAction?: RowActionButtonBaseProps<RowT>;
  secondaryRowAction?: RowActionButtonBaseProps<RowT>;
  rowActions?: RowActionProps<RowT>[] | ((row: RowT) => RowActionProps<RowT>[]);
  stickyHeaderPosition?: number;
  children?: ReactNode;
  fixedLayout?: boolean;
  treeLevelItems?: number;
}

export function DataSection<RowT extends BaseRow>(props: DataSectionProps<RowT>): JSX.Element {
  const {
    expanded,
    isTree,
    isForcedExpanded,
    treeChildrenKey,
    expandComponent,
    loading,
    data,
    columns,
    onRowClick,
    hideHeader,
    primaryRowAction,
    secondaryRowAction,
    rowActions,
    stickyHeaderPosition,
    children,
    fixedLayout = false,
    treeLevelItems,
  } = props;
  const { onSortChange, highlightRules, sortCriteria } = props;
  const classes = useStyles();

  const iconCellRenderer = useMemo(() => {
    if (expandComponent) {
      return (singleRowData: RowT) => <ExpandIconCell expanded={!!singleRowData.expanded} />;
    }

    if (rowActions || primaryRowAction || secondaryRowAction) {
      return (singleRowData: RowT) => (
        <ActionsCell
          rowData={singleRowData}
          actions={typeof rowActions === 'function' ? rowActions(singleRowData) : rowActions}
          primaryAction={primaryRowAction}
          secondaryAction={secondaryRowAction}
        />
      );
    }

    return undefined;
  }, [expandComponent, rowActions, primaryRowAction, secondaryRowAction]);

  const headerColumns = useMemo(() => {
    if (rowActions || expanded) {
      return [
        ...columns,
        {
          title: '',
          key: ACTION_ICON_CELL,
        },
      ];
    }

    return columns;
  }, [rowActions, columns, expanded]);

  const getRowComponent = useCallback(
    (row: RowT, index: number): JSX.Element => {
      const rowProps = {
        key: index,
        rowData: row,
        columns,
        onRowClick,
        highlightRules,
        primaryRowAction,
        secondaryRowAction,
        rowActions,
        iconCellRenderer,
        treeLevelItems,
      };

      if (isTree) {
        return <TableRowTree {...rowProps} isForcedExpanded={isForcedExpanded} treeChildrenKey={treeChildrenKey} />;
      }
      return expanded && expandComponent ? (
        <TableRowExpanded {...rowProps} expandComponent={expandComponent} />
      ) : (
        <TableRow {...rowProps} />
      );
    },
    [
      onRowClick,
      columns,
      highlightRules,
      expanded,
      expandComponent,
      primaryRowAction,
      secondaryRowAction,
      rowActions,
      iconCellRenderer,
      isTree,
      isForcedExpanded,
      treeChildrenKey,
      treeLevelItems,
    ],
  );

  const rows = useMemo(() => data.map(getRowComponent), [data, getRowComponent]);

  if (children) {
    return (
      <MaterialTable classes={{ root: fixedLayout ? classes.fixedColumnsWidth : undefined }}>
        {!hideHeader && (
          <TableHeader
            onChange={onSortChange}
            columns={headerColumns}
            criteria={sortCriteria}
            stickyHeaderPosition={stickyHeaderPosition}
          />
        )}
        <TableBody data-test={loading ? 'table-body-loading' : 'table-body'}>{children}</TableBody>
      </MaterialTable>
    );
  }

  const tableBody = loading ? renderRowsSkeleton(headerColumns) : rows;

  return (
    <MaterialTable classes={{ root: fixedLayout ? classes.fixedColumnsWidth : undefined }}>
      {!hideHeader && (
        <TableHeader
          onChange={onSortChange}
          columns={headerColumns}
          criteria={sortCriteria}
          stickyHeaderPosition={stickyHeaderPosition}
        />
      )}
      <TableBody data-test={loading ? 'table-body-loading' : 'table-body'}>{tableBody}</TableBody>
    </MaterialTable>
  );
}
