import React, { useEffect, ChangeEvent, FC, useMemo, useRef } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import { DebounceField } from '@openx/components/core';
import { useGlobalFilters } from './useGlobalFilters';

import { Dropdown } from './SearchbarDropdown';
import { prepareStatuses, supportedFields, FieldWithOptions } from './config';

export interface ByPhraseFilter {
  phrase?: string;
  fields: string[];
}

export type ByValueField = {
  field: string;
  values: string[];
  valueDeriver?: any;
};

export interface SearchFiltersValue {
  byPhrase: ByPhraseFilter;
  byValue?: ByValueField[];
}

type StyleProps = {
  advanced: boolean;
};

const useStyles = makeStyles({
  root: ({ advanced }: StyleProps) => ({
    position: 'relative',
    '& .MuiTextField-root': {
      '& .MuiInput-root': {
        '& input': {
          paddingRight: advanced ? '90px' : '15px',
        },
        '& input::-webkit-search-cancel-button': {
          position: 'relative',
          bottom: '1.5px',
          left: '4px',
        },
      },
    },
  }),
});

export function isSearchFiltersValue(filters: unknown): filters is SearchFiltersValue {
  if (typeof filters !== 'object' || !filters) return false;

  const { byPhrase, byValue } = filters as { byPhrase?: unknown; byValue?: unknown };
  if (!Object.keys(filters).every(key => ['byPhrase', 'byValue'].includes(key))) return false;

  if (byPhrase) {
    if (typeof byPhrase !== 'object' || !byPhrase) return false;
    if (!Object.keys(byPhrase).every(key => ['phrase', 'fields'].includes(key))) return false;
    const phrase = (byPhrase as { phrase?: unknown }).phrase;
    if (phrase && typeof phrase !== 'string') return false;
    const fields = (byPhrase as { fields?: unknown }).fields;
    if (!(Array.isArray(fields) && fields.every(field => typeof field === 'string'))) return false;
  }

  if (byValue) {
    if (!Array.isArray(byValue)) return false;
    if (!byValue.every(field => typeof field === 'object')) return false;
    if (!byValue.every(field => Object.keys(field).every(key => ['values', 'field', 'valueDeriver'].includes(key))))
      return false;
    if (!byValue.every(({ field }) => typeof field === 'string')) return false;
    if (!byValue.every(({ values }) => Array.isArray(values))) return false;
    if (!byValue.every(({ values }) => values.every(value => typeof value === 'string' || value === null)))
      return false;
  }

  return true;
}

export interface SearchFiltersProps {
  byPhraseFields?: string[];
  byValueFields?: FieldWithOptions[];
  onFiltersChange: (filters: SearchFiltersValue, isInitialCall?: boolean) => void;
  inputPlaceholder?: string;
  debounceTimeout?: number;
  advanced?: boolean;
  fullWidth?: boolean;
}

export const SearchFilters: FC<SearchFiltersProps> = (props): JSX.Element => {
  const isParamsInitialized = useRef(false);
  const isInitialCall = useRef(true);

  const {
    byPhraseFields = [],
    byValueFields = [],
    onFiltersChange,
    inputPlaceholder = 'Search',
    debounceTimeout,
    advanced = false,
    fullWidth,
  } = props;

  const {
    selectedStatuses,
    selectedProviders,
    selectedAccounts,
    selectedUsers,
    searchPhrase,
    filterCount,
    setSelectedStatuses,
    setSelectedProviders,
    setSelectedAccounts,
    setSelectedUsers,
    setSearchPhrase,
    setFilterCount,
  } = useGlobalFilters({
    onInitialized: () => {
      isParamsInitialized.current = true;
    },
  });

  const classes = useStyles({ advanced });

  const fields = useMemo(() => byValueFields.map(({ fieldName }) => fieldName), [byValueFields]);
  const usersByValue = useMemo(
    () => byValueFields.find(filter => filter.fieldName === supportedFields.USER)?.options ?? [],
    [byValueFields],
  );

  const statusField =
    (fields.includes(supportedFields.AUDIENCE_STATUS) && supportedFields.AUDIENCE_STATUS) ||
    (fields.includes(supportedFields.SEGMENT_STATUS) && supportedFields.SEGMENT_STATUS) ||
    (fields.includes(supportedFields.DEAL_STATUS) && supportedFields.DEAL_STATUS);

  useEffect(() => {
    const byValue: ByValueField[] = [
      { field: 'status', values: prepareStatuses(statusField, selectedStatuses) },
      {
        field: 'created_by',
        values: selectedUsers.map(username => usersByValue.find(option => option.displayName === username)?.value),
      },
    ];

    if (fields.includes(supportedFields.AUDIENCE_PROVIDER)) {
      byValue.push({ field: 'provider', values: selectedProviders });
    }

    if (fields.includes(supportedFields.SEGMENT_PROVIDER)) {
      byValue.push({ field: 'provider', values: selectedProviders, valueDeriver: ({ name }) => name });
    }

    if (fields.includes(supportedFields.DEAL_ACCOUNT)) {
      byValue.push({ field: 'account', values: selectedAccounts, valueDeriver: ({ name }) => name });
    }

    if (fields.includes(supportedFields.AUDIENCE_ACCOUNT)) {
      byValue.push({ field: 'account', values: selectedAccounts, valueDeriver: ({ name }) => name });
    }

    if (fields.includes(supportedFields.SEGMENT_ACCOUNT)) {
      byValue.push({ field: 'account', values: selectedAccounts, valueDeriver: ({ name }) => name });
    }

    onFiltersChange(
      {
        byPhrase: { fields: byPhraseFields, phrase: searchPhrase },
        byValue,
      },
      isInitialCall.current,
    );

    if (isParamsInitialized.current) {
      isInitialCall.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPhrase, selectedStatuses, selectedProviders, selectedAccounts, selectedUsers]);

  const onChangeSearchInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchPhrase(e.target.value);
  };

  const handleApplyFilters = (filterCount, statuses, providers, accounts, users) => {
    setFilterCount(filterCount);

    setSelectedUsers(users);
    setSelectedStatuses(statuses);
    setSelectedProviders(providers);
    setSelectedAccounts(accounts);
  };

  return (
    <Grid container justifyContent="flex-end">
      <Grid item xs={advanced ? 6 : fullWidth ? 12 : 3}>
        <div className={classes.root}>
          <DebounceField
            placeholder={inputPlaceholder}
            value={searchPhrase}
            onChange={onChangeSearchInput}
            debounceTimeout={debounceTimeout}
            type="search"
            fullWidth
            autoFocus
          />
          {advanced && (
            <Dropdown
              filters={{ selectedStatuses, selectedProviders, selectedAccounts, selectedUsers }}
              filterCount={filterCount}
              onApplyFilters={handleApplyFilters}
              fieldsToFilterBy={byValueFields}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
