import { object, string, array } from 'yup';

export enum FormFields {
  NAME = 'name',
  DESCRIPTION = 'description',
  PARENT_ORGANIZATION_ID = 'parentOrganizationId',
  ALLOWED_COUNTRIES = 'allowed_countries',
  ALLOWED_DOMAINS = 'allowed_domains',
  EXCHANGE_ACCOUNT_PARTNER_ID = 'exchange_account_partner_id',
  DEFAULT_PARTNER_SHARE = 'default_platform_share',
  MAX_SHARE = 'max_share',
  MAX_CPM_CAP = 'max_cpm_cap',
}

export interface FormValues {
  [FormFields.NAME]: string;
  [FormFields.DESCRIPTION]: string;
  [FormFields.PARENT_ORGANIZATION_ID]: string;
  [FormFields.ALLOWED_COUNTRIES]: string[];
  [FormFields.ALLOWED_DOMAINS]: string[];
  [FormFields.EXCHANGE_ACCOUNT_PARTNER_ID]?: string;
  [FormFields.DEFAULT_PARTNER_SHARE]?: number;
  [FormFields.MAX_SHARE]?: number;
  [FormFields.MAX_CPM_CAP]?: number;
}

export const validationSchema = object({
  [FormFields.NAME]: string().required('Name is required'),
  [FormFields.DESCRIPTION]: string(),
  [FormFields.PARENT_ORGANIZATION_ID]: string(),
  [FormFields.EXCHANGE_ACCOUNT_PARTNER_ID]: string(),
  [FormFields.ALLOWED_COUNTRIES]: array(string()).min(1, 'At least one Allowed Country is required'),
  [FormFields.ALLOWED_DOMAINS]: array(string()).min(1, 'At least one Allowed Domain is required'),
});

export const validateFeesValue = (value: any): boolean => {
  return (value !== '' && parseFloat(value) < 0.0001) || parseFloat(value) >= 1 ? false : true;
};

export const validateCPMCapValue = (value: any): boolean => {
  return value !== '' && parseFloat(value) < 0.0 ? false : true;
};

export const checkAndConvertValueToNull = (value: any): boolean => {
  return value === '' ? null : value;
};
