import React from 'react';
import { Field, Form, FormikProps } from 'formik';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { TextField, Button } from '@openx/components/core';

import { EMAIL_FIELD, PASSWORD_FIELD } from './constants';
import { FormValues } from './Login';

const useStyles = makeStyles({
  actions: {
    marginTop: 10,
  },
});

export function LoginForm({ isSubmitting }: FormikProps<FormValues>) {
  const classes = useStyles();
  const history = useHistory();

  const goToForgotPasswordPage = () => history.push('/forgot-password');

  return (
    <Form data-test="brands-form">
      <Field
        name={EMAIL_FIELD}
        label="Email"
        placeholder="Enter your email"
        component={TextField}
        required
        fullWidth
        margin="dense"
        data-test="email"
      />
      <Field
        name={PASSWORD_FIELD}
        label="Password"
        placeholder="Enter your password"
        type="password"
        component={TextField}
        required
        fullWidth
        margin="dense"
        data-test="password"
      />
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center" classes={{ root: classes.actions }}>
        <Grid item>
          <Button variant="text" data-test="forgot-password" onClick={goToForgotPasswordPage}>
            Forgot password
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" loading={isSubmitting} data-test="sign-in-button">
            Sign in
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
