import React from 'react';
import { Formik, Field, FormikConfig } from 'formik';
import { Form as FormFormik } from 'formik';
import { AutoComplete, TextField } from '@openx/components/core';
import { Typography, Grid } from '@material-ui/core';

import { FormValues, Organization } from './types';
import { validationSchema } from './validationSchema';
import { SubmitButton } from './SubmitButton';

interface FormProps {
  onSubmit: FormikConfig<FormValues>['onSubmit'];
  loading: boolean;
  disabledEmail?: boolean;
  initialValues: FormValues;
  organizations: Organization[];
}

export function Form({ onSubmit, loading, disabledEmail, initialValues, organizations }: FormProps): JSX.Element {
  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ submitForm }) => (
        <>
          <SubmitButton loading={loading} onSubmit={submitForm} />
          <FormFormik>
            <Typography variant="h2" gutterBottom data-test="form-label">
              User Details
            </Typography>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Field
                  name="firstName"
                  label="First name"
                  component={TextField}
                  placeholder="Type user first name"
                  fullWidth
                  data-test="first-name-field"
                />
              </Grid>
              <Grid item>
                <Field
                  name="lastName"
                  label="Last name"
                  component={TextField}
                  placeholder="Type user last name"
                  fullWidth
                  data-test="last-name-field"
                />
              </Grid>
              <Grid item>
                <Field
                  name="email"
                  label="Email"
                  component={TextField}
                  disabled={disabledEmail}
                  placeholder="Type user email address"
                  fullWidth
                  data-test="email-field"
                />
              </Grid>
              <Grid item>
                <Field
                  name="parentOrganizationId"
                  textFieldProps={{ label: 'Organization', placeholder: '[ Select organization ]' }}
                  getOptionLabel={option => organizations.find(({ id }) => id === option)?.name}
                  options={organizations.map(({ id }) => id)}
                  component={AutoComplete}
                  fullWidth
                  data-test="organization-dropdown"
                />
              </Grid>
            </Grid>
          </FormFormik>
        </>
      )}
    </Formik>
  );
}
