import React, { ComponentType } from 'react';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({ grow: { flexGrow: 1 }, filters: { background: '#F5F5F5', padding: '8px 32px' } });

export interface ListHeaderContentProps<FiltersProps extends {} = {}> {
  filtersProps?: FiltersProps;
  filtersComponent?: ComponentType<FiltersProps>;
}

export function ListHeaderContent<FiltersProps extends {} = {}>(
  props: ListHeaderContentProps<FiltersProps>,
): JSX.Element | null {
  const { filtersProps, filtersComponent: FiltersComponent } = props;
  const classes = useStyles();

  const filtersElement = filtersProps && FiltersComponent && (
    <Grid item xs={12} className={classes.filters}>
      <FiltersComponent {...filtersProps} />
    </Grid>
  );

  if (!filtersElement) {
    return null;
  }

  return (
    <Grid container item xs={12}>
      {filtersElement}
    </Grid>
  );
}
