import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { StatusCell } from 'modules/deals/DealsList/StatusCell';
import { Loader } from '@openx/components/core';
import { Button, Ellipsis } from '@openx/components/core';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: '13px',
  },
  row: {
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  button: {
    width: 'fit-content',
    position: 'relative',
    top: '-5px',
  },
}));

export function AssociatedDealsList({ deals, loading = false }): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  if (loading) {
    return <Loader />;
  }

  const onShowDeals = () => {
    for (const val of deals) {
      const dealId = val['id'];
      history.push(`/deals/${dealId}`);
    }
  };

  return (
    <Box marginTop={2}>
      {deals.map(({ exchange_deal_id, status, name }) => (
        <Grid container className={classes.row} key={exchange_deal_id}>
          <Grid item xs={4}>
            <Ellipsis width="90%" tooltip>
              <Typography color="textPrimary" data-test="deal-name">
                {name}
              </Typography>
            </Ellipsis>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1">{exchange_deal_id}</Typography>
          </Grid>
          <Grid item xs={3}>
            <StatusCell status={status} />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              onClick={onShowDeals}
              color="primary"
              data-test="show-deal-button"
              className={classes.button}
            >
              SHOW DEAL
            </Button>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}
