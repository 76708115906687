import React, { ReactNode, FC } from 'react';
import { DrawerClassKey, SwipeableDrawer, SwipeableDrawerProps } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import { Loader } from '../Loader';

interface DrawerProps extends Pick<Partial<SwipeableDrawerProps>, 'onClose' | 'onOpen' | 'open'> {
  loading?: boolean;
  children: ReactNode;
  classes?: Partial<ClassNameMap<DrawerClassKey>>;
}

export const Drawer: FC<DrawerProps> = (props): JSX.Element => {
  const {
    loading = false,
    children,
    onClose = () => undefined,
    onOpen = () => undefined,
    open = false,
    classes,
  } = props;

  return (
    <SwipeableDrawer onClose={onClose} onOpen={onOpen} open={open} anchor="right" classes={classes}>
      {loading ? <Loader /> : children}
    </SwipeableDrawer>
  );
};
