import {
  ExportType,
  State,
  MatchingOperator,
  getName,
  getDescription,
  getExportType,
  getExportTargets,
  getIncludesGroups,
  getExcludesGroups,
  getIncludesMatchingOperator,
  Segments,
  RelationType,
  GroupItem,
  getDirectProvider,
  fields,
  NormalizedDefinition,
  NormalizedGroup,
  NormalizedSegments,
  NormalizedSegment,
} from 'modules/audiences/AudienceProvider';
import { CreateAudienceMutationVariables, UpdateAudienceMutationVariables } from 'types/schemaTypes';
import {
  areSegmentsEmpty,
  isSegmentEmpty,
  omitEmptyGroupsAndSubgroups,
} from 'modules/audiences/utils/areSegmentsEmpty';
import { DEFAULT_PROVIDER_ID } from '../hooks/useProviders';
import { convertDemoFinalToDirect } from './normalizedDefinitionUtils';

const { PREDEFINED_FIELD, DIRECT_SEGMENT_FIELD, FIRST_PARTY_FIELD } = fields;

//TODO: Eval & Convert to Obj shape def for mutation query
// const omitFields = segment => segment.map(({ id, external_id, title }) => ({ id, external_id, title }));

const getSegmentName = segmentName => (segmentName === DIRECT_SEGMENT_FIELD ? FIRST_PARTY_FIELD : segmentName);

export const mapAudienceToCreateMutation = (
  account_id: string,
  user_id: string,
  state: State,
): CreateAudienceMutationVariables['object'] => {
  const name = getName(state);
  const description = getDescription(state);
  const export_type = getExportType(state) || ExportType.OA_MATCH;
  const direct_audience_provider = getDirectProvider(state) || DEFAULT_PROVIDER_ID;
  const normalized_definition = getNormalizedDefinition(state);
  const export_targets = getExportTargets(state);

  return {
    account_id,
    user_id,
    name,
    description,
    export_type,
    export_targets,
    direct_audience_provider,
    normalized_definition,
  };
};

export const mapAudienceToUpdateMutation = (state: State): UpdateAudienceMutationVariables['data'] => {
  const name = getName(state);
  const description = getDescription(state);
  const normalized_definition = getNormalizedDefinition(state);
  const direct_audience_provider = getDirectProvider(state) || DEFAULT_PROVIDER_ID;
  const export_targets = getExportTargets(state);
  const export_type = getExportType(state);

  return {
    name,
    description,
    export_targets,
    export_type,
    direct_audience_provider,
    normalized_definition,
  };
};

export const getNormalizedDefinition = (state: State): NormalizedDefinition => {
  const includesGroups = getIncludesGroups(state);
  const excludesGroups = getExcludesGroups(state);

  const filteredIncludesGroups = omitEmptyGroupsAndSubgroups(includesGroups);
  const filteredExcludesGroups = omitEmptyGroupsAndSubgroups(excludesGroups);

  const includesOperator = getIncludesMatchingOperator(state);

  const demoFinalNormalizedDefinition = {
    includes: {
      operator: includesOperator,
      items: mapGroupsToND(filteredIncludesGroups, RelationType.INCLUDE),
    },
    excludes: {
      operator: MatchingOperator.UNION,
      items: mapGroupsToND(filteredExcludesGroups, RelationType.EXCLUDE),
    },
  };
  const gsNormalizedDefinition = convertDemoFinalToDirect(demoFinalNormalizedDefinition);
  return gsNormalizedDefinition;
};

// TODO: Refactor return type for mutation to omitted seg types
const mapSegmentsToND = (segments: Segments, relationField: RelationType): NormalizedSegments => {
  const prepareSegments = (
    curValue: unknown,
    segments: Segments | NormalizedSegment[],
  ): Segments | NormalizedSegment[] => {
    if (curValue === PREDEFINED_FIELD) return Object.values(segments).reduce((acc, cur) => [...acc, ...cur], []);

    return segments;
  };

  const getRelationField = (acc, cur, segments) => {
    if (getSegmentName(cur) === FIRST_PARTY_FIELD) {
      return [...(acc[getSegmentName(cur)]?.[relationField] || []), ...segments[cur]];
    } else {
      return prepareSegments(cur, segments[cur]);
    }
  };

  const normalizedSegments = Object.keys(segments).reduce((acc, cur) => {
    if (isSegmentEmpty(cur, segments[cur])) return acc;

    const defSegment = {
      operator: MatchingOperator.UNION,
      [relationField]: getRelationField(acc, cur, segments),
    };

    return {
      ...acc,
      [getSegmentName(cur)]: defSegment,
    };
  }, {});

  return {
    operator: MatchingOperator.INTERSECTION,
    ...normalizedSegments,
  };
};

const mapGroupsToND = (groups: GroupItem[], relationField: RelationType): (NormalizedGroup | NormalizedSegments)[] =>
  groups.map(({ segments, subgroups, operator }) =>
    areSegmentsEmpty(segments)
      ? {
          operator,
          items: subgroups.map(({ segments }) => mapSegmentsToND(segments, relationField)),
        }
      : mapSegmentsToND(segments, relationField),
  );
