import React from 'react';
import { createStyles, Theme, Typography, LinearProgress } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { numberWithCommas } from 'utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    recordsProcessedNumber: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    linearProgressRoot: {
      height: 10,
      borderRadius: 3,
      margin: theme.spacing(2, 0),
    },
  }),
);

export interface UploadFileProgressProps {
  recordsProcessed: number;
  progress: number;
}

export function UploadFileProgress({ recordsProcessed, progress }: UploadFileProgressProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Typography>
        <strong>Publishing</strong>
      </Typography>
      <LinearProgress value={progress} variant="determinate" classes={{ root: classes.linearProgressRoot }} />
      <Typography color="textSecondary">
        <span className={classes.recordsProcessedNumber}>{numberWithCommas(recordsProcessed)}</span> lines processed
      </Typography>
    </>
  );
}
