import { SessionContext, useSession } from 'modules/auth/AuthProvider';

export type Validator = (session: SessionContext) => boolean;

export function usePermissionsCheck(validators: Validator[]): boolean {
  const session = useSession();

  if (!session) {
    return false;
  }

  const isAllowed = validators.every(validator => validator(session));

  return isAllowed;
}
