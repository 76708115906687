import React, { useEffect, ChangeEvent, FC, useMemo } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';

import { DebounceField } from '@openx/components/core';
import { useGlobalFilters } from './useGlobalFilters';

import { Dropdown } from './SearchbarDropdown';
import { prepareStatuses, supportedFields, FieldWithOptions } from './config';

export interface ByPhraseFilter {
  phrase?: string;
  fields: string[];
}

export type ByValueField = {
  field: string;
  values: string[];
  valueDeriver?: any;
};

export interface SearchFiltersValue {
  byPhrase: ByPhraseFilter;
  byValue?: ByValueField[];
}

type StyleProps = {
  advanced: boolean;
};

const useStyles = makeStyles({
  root: () => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  }),
  selectFilters: {
    display: 'flex',
  },
});

export function isSearchFiltersValue(filters: unknown): filters is SearchFiltersValue {
  if (typeof filters !== 'object' || !filters) return false;

  const { byPhrase, byValue } = filters as { byPhrase?: unknown; byValue?: unknown };
  if (!Object.keys(filters).every(key => ['byPhrase', 'byValue'].includes(key))) return false;

  if (byPhrase) {
    if (typeof byPhrase !== 'object' || !byPhrase) return false;
    if (!Object.keys(byPhrase).every(key => ['phrase', 'fields'].includes(key))) return false;
    const phrase = (byPhrase as { phrase?: unknown }).phrase;
    if (phrase && typeof phrase !== 'string') return false;
    const fields = (byPhrase as { fields?: unknown }).fields;
    if (!(Array.isArray(fields) && fields.every(field => typeof field === 'string'))) return false;
  }

  if (byValue) {
    if (!Array.isArray(byValue)) return false;
    if (!byValue.every(field => typeof field === 'object')) return false;
    if (!byValue.every(field => Object.keys(field).every(key => ['values', 'field', 'valueDeriver'].includes(key))))
      return false;
    if (!byValue.every(({ field }) => typeof field === 'string')) return false;
    if (!byValue.every(({ values }) => Array.isArray(values))) return false;
    if (!byValue.every(({ values }) => values.every(value => typeof value === 'string' || value === null)))
      return false;
  }

  return true;
}

export interface SearchFiltersProps {
  byPhraseFields?: string[];
  byValueFields?: FieldWithOptions[];
  onFiltersChange: (filters: SearchFiltersValue) => void;
  inputPlaceholder?: string;
  debounceTimeout?: number;
  advanced?: boolean;
}

export const SearchFilters: FC<SearchFiltersProps> = (props): JSX.Element => {
  const {
    byPhraseFields = [],
    byValueFields = [],
    onFiltersChange,
    inputPlaceholder = 'Search',
    debounceTimeout,
    advanced = false,
  } = props;

  const {
    selectedStatuses,
    selectedProviders,
    selectedAccounts,
    selectedCategories,
    selectedSubCategories,
    selectedTypes,
    selectedSources,
    searchPhrase,
    setSelectedStatuses,
    setSelectedProviders,
    setSelectedAccounts,
    setSelectedCategories,
    setSelectedSubCategories,
    setSelectedTypes,
    setSelectedSources,
    setSearchPhrase,
  } = useGlobalFilters();

  const classes = useStyles();

  const fields = useMemo(() => byValueFields.map(({ fieldName }) => fieldName), [byValueFields]);

  const statusField =
    (fields.includes(supportedFields.AUDIENCE_STATUS) && supportedFields.AUDIENCE_STATUS) ||
    (fields.includes(supportedFields.SEGMENT_STATUS) && supportedFields.SEGMENT_STATUS) ||
    (fields.includes(supportedFields.DEAL_STATUS) && supportedFields.DEAL_STATUS);

  useEffect(() => {
    const byValue: ByValueField[] = [{ field: 'status', values: prepareStatuses(statusField, selectedStatuses) }];

    if (fields.includes(supportedFields.AUDIENCE_PROVIDER)) {
      byValue.push({ field: 'direct_audience_provider', values: selectedProviders });
    }

    if (fields.includes(supportedFields.SEGMENT_PROVIDER)) {
      byValue.push({ field: 'sub_type', values: selectedProviders });
    }

    if (fields.includes(supportedFields.DEAL_ACCOUNT)) {
      byValue.push({ field: 'account', values: selectedAccounts, valueDeriver: ({ name }) => name });
    }

    if (fields.includes(supportedFields.AUDIENCE_ACCOUNT)) {
      byValue.push({ field: 'account', values: selectedAccounts, valueDeriver: ({ name }) => name });
    }

    if (fields.includes(supportedFields.SEGMENT_ACCOUNT)) {
      byValue.push({ field: 'account', values: selectedAccounts, valueDeriver: ({ name }) => name });
    }

    if (fields.includes(supportedFields.TAXONOMY_CATEGORY)) {
      byValue.push({ field: 'segment_category', values: selectedCategories });
    }

    if (fields.includes(supportedFields.TAXONOMY_SUB_CATEGORY)) {
      byValue.push({ field: 'segment_sub_category', values: selectedSubCategories });
    }

    if (fields.includes(supportedFields.TAXONOMY_TYPE)) {
      byValue.push({ field: 'segment_type', values: selectedTypes });
    }

    if (fields.includes(supportedFields.TAXONOMY_SOURCE)) {
      byValue.push({ field: 'segment_source', values: selectedSources });
    }

    onFiltersChange({
      byPhrase: { fields: byPhraseFields, phrase: searchPhrase },
      byValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchPhrase,
    selectedStatuses,
    selectedProviders,
    selectedAccounts,
    selectedCategories,
    selectedSubCategories,
    selectedTypes,
    selectedSources,
  ]);

  const onChangeSearchInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchPhrase(e.target.value);
  };

  const handleApplyFilters = (statuses, providers, accounts, categories, sub_categories, types, sources) => {
    setSelectedStatuses(statuses);
    setSelectedProviders(providers);
    setSelectedAccounts(accounts);
    setSelectedCategories(categories);
    setSelectedSubCategories(sub_categories);
    setSelectedTypes(types);
    setSelectedSources(sources);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={4}>
        <Box paddingY={0.7}>
          <DebounceField
            placeholder={inputPlaceholder}
            value={searchPhrase}
            onChange={onChangeSearchInput}
            debounceTimeout={debounceTimeout}
            type="search"
            fullWidth
            autoFocus
          />
        </Box>
      </Grid>
      {advanced && (
        <Grid item xs={8} className={classes.selectFilters}>
          <Dropdown
            filters={{
              selectedStatuses,
              selectedProviders,
              selectedAccounts,
              selectedCategories,
              selectedSubCategories,
              selectedTypes,
              selectedSources,
            }}
            onApplyFilters={handleApplyFilters}
            fieldsToFilterBy={byValueFields}
          />
        </Grid>
      )}
    </Grid>
  );
};
