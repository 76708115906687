import React, { FC, useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

import { Button, AutoCompleteVirtualize, SwitchField } from '@openx/components/core';

import { AudienceFee } from './AudienceFee';
import {
  AudienceWithFee,
  Recipient,
  FeeHandler,
  BulkFeeHandler,
  useValidationInterface,
  RevenueMethodHandler,
  BulkRevenueMethodHandler,
} from './types';
import { SAME_FEE_INPUT } from './constants';
import { uniqBy } from 'lodash';
import { Chip } from '@openx/components/table';

interface StyleProps {
  hasRecipients: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '56px',
  },
  recipientRow: {
    display: 'flex',
    marginTop: '3px',
    alignItems: 'center',
  },
  removeButton: {
    visibility: ({ hasRecipients }: StyleProps) => (hasRecipients ? 'visible' : 'hidden'),
    position: 'relative',
    top: '9px',
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
    '&:hover': {
      background: 'rgba(216, 49, 88, 0.04) !important',
    },
    '&:active': {
      background: 'rgba(216, 49, 88, 0.16) !important',
    },
  },
  divider: {
    width: 'calc(100% + 56px)',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.black10,
  },
}));

interface AudienceShareRowProps {
  recipients?: Recipient[];
  audiences: AudienceWithFee[];
  handleRecipientChange: (_, audienceShareRecipients: Recipient[], reason: any, detail: any) => void;
  handleRevenueMethodChange: RevenueMethodHandler | BulkRevenueMethodHandler;
  handleFeeChange: FeeHandler | BulkFeeHandler;
  handleRemoveRow: () => void;
  recipientOptions: Recipient[];
  rowIndex: number;
  useValidation: useValidationInterface;
  fromEditPage?: boolean;
}

export const AudienceShareRow: FC<AudienceShareRowProps> = ({
  recipients = [],
  handleRecipientChange = () => {},
  handleRevenueMethodChange = () => {},
  handleFeeChange = () => {},
  handleRemoveRow = () => {},
  recipientOptions = [],
  audiences = [],
  rowIndex,
  useValidation,
  fromEditPage = false,
}) => {
  const hasRecipients = recipients.length > 0;
  const isBulk = audiences.length > 1;

  const { root, removeButton, recipientRow, divider } = useStyles({ hasRecipients });
  const [isFeeEnabled, setIsFeeEnabled] = useState(!audiences.every(({ fee }) => fee === null || fee === '0'));
  const [previousAudiences, setPreviousAudiences] = useState(audiences);
  const { setValidationError, setIsFieldTouched } = useValidation;

  const getOptionSelected = (option, value) => option.id === value.id;

  const resetValidation = useCallback(() => {
    setValidationError(rowIndex, SAME_FEE_INPUT, false);
    setIsFieldTouched(rowIndex, SAME_FEE_INPUT, false);

    audiences.forEach(({ audience: { id } }) => {
      setValidationError(rowIndex, id, false);
      setIsFieldTouched(rowIndex, id, false);
    });
  }, [rowIndex, audiences]);

  useEffect(() => {
    if (hasRecipients) {
      resetValidation();
    }
  }, []);

  const createHandleFeeSwitch = useCallback(
    () => (_, isFeeEnabled: boolean) => {
      resetValidation();

      if (isFeeEnabled) {
        previousAudiences.forEach(({ audience: { id }, fee }) => {
          if (isBulk) {
            (handleFeeChange(id) as FeeHandler)(fee);
          } else {
            (handleFeeChange as FeeHandler)(fee);
          }
        });
      } else {
        setPreviousAudiences(audiences);

        audiences.forEach(({ audience: { id } }) => {
          if (isBulk) {
            (handleFeeChange(id) as FeeHandler)('0');
          } else {
            handleFeeChange('0');
          }
        });
      }

      setIsFeeEnabled(isFeeEnabled);
    },
    [isBulk, audiences, previousAudiences, setPreviousAudiences, setIsFeeEnabled],
  );

  return (
    <div className={root}>
      <div className={recipientRow}>
        <AutoCompleteVirtualize
          options={recipientOptions}
          getOptionLabel={({ name }) => name}
          getOptionSelected={getOptionSelected}
          textFieldProps={{
            placeholder: '[ Select Publisher ]',
            label: 'Select publisher(s) to share audience with',
            margin: 'dense',
          }}
          onChange={handleRecipientChange}
          renderTags={(value, getTagProps) =>
            uniqBy(value, 'id').map((item, index) => (
              <Chip label={item.name} key={item.id} {...getTagProps({ index })} />
            ))
          }
          value={recipients}
          disableClearable
          filterSelectedOptions
          multiple
          fullWidth
          limitTags={-1}
          data-test="publisher-dropdown"
        />

        <Button data-test="remove-publisher-row" variant="text" className={removeButton} onClick={handleRemoveRow}>
          REMOVE
        </Button>
      </div>

      {hasRecipients && (
        <SwitchField
          checked={isFeeEnabled}
          label={`Enable setting fee for shared audience${audiences.length > 1 ? 's' : ''}`}
          onChange={createHandleFeeSwitch()}
          data-test="fee-switch"
        />
      )}

      {isFeeEnabled && audiences.length > 0 && (
        <AudienceFee
          fromEditPage={fromEditPage}
          audiences={isBulk ? audiences : [audiences[0]]}
          onFeeChange={handleFeeChange}
          onRevenueMethodChange={handleRevenueMethodChange}
          useValidation={useValidation}
          rowIndex={rowIndex}
        />
      )}

      {hasRecipients && <Divider className={divider} />}
    </div>
  );
};
