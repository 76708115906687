import { DocumentNode } from 'graphql';
import { useContext, useEffect } from 'react';
import { configContext } from './configContext';
import { LocalErrorHandler } from './types';

export const useLocalErrorHandling = (document: DocumentNode, handler: LocalErrorHandler) => {
  const { registerLocalErrorHandler, unregisterLocalErrorHandler } = useContext(configContext);

  useEffect(() => {
    registerLocalErrorHandler(document, handler);
    return () => {
      unregisterLocalErrorHandler(document, handler);
    };
  }, [document, handler, registerLocalErrorHandler, unregisterLocalErrorHandler]);
};
