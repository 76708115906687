import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { DebounceField, TextFieldProps } from '@openx/components/core';

import { PaginationNavProps } from './PaginationNav';
import { maxPageNumber } from '../tableHelpers';

export interface PaginationTextFieldProps extends PaginationNavProps {
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
}

export function PaginationTextField(props: PaginationTextFieldProps): JSX.Element {
  const { onChange, textFieldProps, paginationParams, disabled } = props;
  const [value, setValue] = useState<string>(String(paginationParams.pageNumber));

  const onChangeField = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      const numberedValue = Number(newValue);
      const isNaN = Number.isNaN(numberedValue);
      const ignoreChange = isNaN || !newValue;
      const hasMore = paginationParams.hasMore;
      const adjustedToMax = hasMore ? numberedValue : Math.min(numberedValue, maxPageNumber(paginationParams));
      const adjustedToMin = Math.max(1, adjustedToMax);

      setValue(ignoreChange ? '' : String(adjustedToMin));

      if (!ignoreChange && adjustedToMin !== paginationParams.pageNumber) {
        onChange({
          ...paginationParams,
          pageNumber: adjustedToMin,
        });
      }
    },
    [setValue, onChange, paginationParams],
  );

  useEffect(() => setValue(String(paginationParams.pageNumber)), [paginationParams.pageNumber]);

  return <DebounceField disabled={disabled} {...textFieldProps} value={value} onChange={onChangeField} />;
}
