import { Display, Video, MobileApp, MobileWeb, Desktop, ConnectedTv, Tablet, IpAddress } from './icons';

export const DefaultDisabledMessage = 'We’re unable to provide insights for other providers’ data.';

export const tooltipText = 'Search & select publisher to see publisher level insights';

export const noTableMessage =
  'We’re unable to provide insights as the insights data no longer exists. Please resave the audience to generate insights.';

export const categoriesConfig = [
  {
    icon: Display,
    valueKey: 'display',
    title: 'DISPLAY',
  },
  {
    icon: Video,
    valueKey: 'video',
    title: 'VIDEO',
  },
  {
    icon: MobileApp,
    valueKey: 'mobileapp',
    title: 'MOBILE APP',
  },
  {
    icon: MobileWeb,
    valueKey: 'mobileweb',
    title: 'MOBILE WEB',
  },
  {
    icon: Desktop,
    valueKey: 'desktop',
    title: 'DESKTOP',
  },
  {
    icon: ConnectedTv,
    valueKey: 'connectedtv',
    title: 'CONNECTED TV',
  },
  {
    icon: IpAddress,
    valueKey: 'ip',
    title: 'IP ADDRESS',
  },
];

export const devicesConfig = [
  {
    icon: Desktop,
    valueKey: 'cookiesWithRequests',
    title: 'DESKTOP',
  },
  {
    icon: MobileApp,
    valueKey: 'mobileDevicesWithRequests',
    title: 'MOBILE',
  },
  {
    icon: ConnectedTv,
    valueKey: 'uniqueConnectedTVDeviceIDsWithRequests',
    title: 'CONNECTED TV',
  },
  {
    icon: Tablet,
    valueKey: 'tablet',
    title: 'TABLETS',
  },
];
