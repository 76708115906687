import React from 'react';
import { Theme, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    color: theme.palette.action.disabled,
  },
}));

interface ValuePlaceholderProps {
  component?: React.ElementType;
}

export const ValuePlaceholder = ({ component = 'span' }: ValuePlaceholderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Typography className={classes.container} component={component}>
      N/A
    </Typography>
  );
};
