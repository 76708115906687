import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Error, Warning } from '@material-ui/icons';

import { AlertTooltip } from 'components/AlertTooltip';
import { daysToExpiration, isExpired, isSoonToBeExpired } from 'utils/expiryDates';
import { segmentExpiredText, segmentSoonWillBeExpired } from '../../constants';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '5px',
  },
});

type Props = {
  estimatedExpiryDate?: string | null;
  name: string | number;
  status?: string | null;
};

export const SegmentCell = ({ name, estimatedExpiryDate, status }: Props) => {
  const { flex, marginRight } = useStyles();

  const showExpiredAlert = isExpired(status);
  const showExpireSoonAlert = isSoonToBeExpired(estimatedExpiryDate, status);
  const daysRemaining = daysToExpiration(estimatedExpiryDate);

  const expiredText = segmentExpiredText(false);
  const soonWillBeExpiredText = segmentSoonWillBeExpired(false, daysRemaining);

  return (
    <div className={flex}>
      <p className={marginRight}>{name}</p>
      {showExpiredAlert && (
        <AlertTooltip color="#D83158" title={expiredText} icon={<Error fontSize="small" color="inherit" />} />
      )}
      {showExpireSoonAlert && (
        <AlertTooltip
          color="#E8B046"
          title={soonWillBeExpiredText}
          icon={<Warning fontSize="small" color="inherit" />}
        />
      )}
    </div>
  );
};
