import React from 'react';
import { Field, Form, FormikProps } from 'formik';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { TextField, Button } from '@openx/components/core';
import { FormValues } from './SetPassword';

const useStyles = makeStyles({
  actions: {
    marginTop: 10,
  },
});

export function SetPasswordForm({ isSubmitting }: FormikProps<FormValues>): JSX.Element {
  const classes = useStyles();

  return (
    <Form>
      <Field
        name="newPassword"
        label="New Password"
        placeholder="Enter your password"
        component={TextField}
        type="password"
        required
        fullWidth
        margin="dense"
        data-test="password-field"
      />
      <Grid container justifyContent="flex-end" alignItems="center" classes={{ root: classes.actions }}>
        <Grid item>
          <Button type="submit" color="primary" loading={isSubmitting} data-test="submit-button">
            Set Password
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
