import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, Grid } from '@material-ui/core';

import { ChangeSingleCriteriaAction, CriteriaDimension, PaginationCriteria } from '../../tableCriteria';
import PaginationNav from '../PaginationNav';
import { CustomManagementSection } from '../../Table';

export interface ManagementSectionProps {
  CustomManagementSection?: CustomManagementSection;
  loading: boolean;
  paginationCriteria?: PaginationCriteria;
  type: 'top' | 'bottom';
  onPaginationChange: ChangeSingleCriteriaAction<CriteriaDimension.PAGINATION>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 50,
    padding: theme.spacing(0, 2.5),
    backgroundColor: theme.palette.background.boxLight,
    borderColor: theme.palette.divider,
    color: theme.palette.text.secondary,
    borderStyle: 'solid',
    borderWidth: 0,
    '&:first-child': {
      borderBottomWidth: 1,
    },
    '*:not(:first-child) > &:first-child': {
      borderTopWidth: 1,
    },
  },
  customManagementItem: {
    flexGrow: 1,
    paddingRight: '15%',
  },
}));

export function ManagementSection(props: ManagementSectionProps): JSX.Element | null {
  const classes = useStyles();
  const { CustomManagementSection, loading, paginationCriteria, onPaginationChange } = props;

  if (!CustomManagementSection && !paginationCriteria) {
    return null;
  }

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item className={classes.customManagementItem}>
        {CustomManagementSection && <CustomManagementSection type={props.type} />}
      </Grid>
      <Grid item>
        {paginationCriteria && (
          <PaginationNav onChange={onPaginationChange} loading={loading} paginationParams={paginationCriteria} />
        )}
      </Grid>
    </Grid>
  );
}
