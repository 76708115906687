import { isEqual } from 'lodash';

import { segmentsToTreeData, TreeData, EnhancedTreeData } from 'utils';
import { fields } from 'modules/audiences/AudienceProvider';
import { TreeSegmentFragment } from 'types/schemaTypes';

const {
  DEMOGRAPHICS_FIELD,
  LOCATION_CONGRESSIONAL_FIELD,
  PREDEFINED_THIRD_PARTY_FIELD,
  PREDEFINED_SITE_CATEGORY_FIELD,
  PREDEFINED_RTG_FIELD,
} = fields;

const treeSegments: string[] = [
  DEMOGRAPHICS_FIELD,
  LOCATION_CONGRESSIONAL_FIELD,
  PREDEFINED_THIRD_PARTY_FIELD,
  PREDEFINED_SITE_CATEGORY_FIELD,
  PREDEFINED_RTG_FIELD,
];

interface Segment {
  [key: string]: TreeSegmentFragment[];
}

interface CachedSegment {
  rawSegments: TreeData[] | EnhancedTreeData[];
  calculatedSegments: EnhancedTreeData[];
}

interface LocalCache {
  [key: string]: CachedSegment;
}

type LocalSegmentsResponse = [LocalCache, (segments: Segment[]) => void];

export const localSegments = (): LocalSegmentsResponse => {
  const localCache = {};

  const memoizeSegments = (segments: Segment[]) => {
    segments.forEach(segment => {
      const segmentField = Object.keys(segment)[0];
      const newSegments = segment[segmentField];

      if (!newSegments.length) return;

      const isRoot = treeSegments.indexOf(segmentField) >= 0;

      if (!localCache[segmentField]) {
        localCache[segmentField] = {};
      }

      if (!localCache[segmentField].rawSegments || !isEqual(localCache[segmentField].rawSegments, newSegments)) {
        localCache[segmentField].rawSegments = newSegments;
        localCache[segmentField].calculatedSegments = isRoot
          ? segmentsToTreeData(newSegments, null)
          : segmentsToTreeData(newSegments);
      }
    });
  };

  return [localCache, memoizeSegments];
};
