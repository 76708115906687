import { isEqual } from 'lodash';
import { useEffect, useState, SetStateAction, Dispatch } from 'react';
import { useHistory } from 'react-router-dom';

export interface SupportedParams {
  statuses?: string[];
  providers?: string[];
  search?: string;
  accounts?: string[];
  users?: string[];
}

export interface UseGlobalFiltersProps {
  onInitialized?: () => void;
}
export interface UseGlobalFiltersResponse {
  selectedStatuses: string[];
  selectedProviders: string[];
  selectedAccounts: string[];
  selectedUsers: string[];
  searchPhrase: string;
  filterCount: number;
  setSelectedStatuses: Dispatch<SetStateAction<string[]>>;
  setSelectedProviders: Dispatch<SetStateAction<string[]>>;
  setSelectedAccounts: Dispatch<SetStateAction<string[]>>;
  setSelectedUsers: Dispatch<SetStateAction<string[]>>;
  setSearchPhrase: Dispatch<SetStateAction<string>>;
  setFilterCount: Dispatch<SetStateAction<number>>;
}

const paramsWithArrayValue = ['statuses', 'providers', 'accounts', 'users'];

const getParameters = (): SupportedParams => {
  const parameters = window.location.search.replace('?', '');

  if (Object.keys(parameters).length) {
    const parsedParameters = parameters.split('&');

    return parsedParameters.reduce((acc, cur) => {
      const [param, value] = cur.split('=');

      return {
        ...acc,
        [param]: paramsWithArrayValue.includes(param) ? value.split(',') : value,
      };
    }, {});
  }

  return {};
};

const arrayToString = (array: string[]): string => array.join(',');

const prepareParams = ({
  search,
  statuses = [],
  providers = [],
  accounts = [],
  users = [],
}: SupportedParams): string => {
  if (!search && statuses.length === 0 && providers.length === 0 && accounts.length === 0 && users.length === 0)
    return '';

  const params = [
    !!search && `search=${encodeURIComponent(search)}`,
    statuses.length !== 0 && `statuses=${arrayToString(statuses)}`,
    providers.length !== 0 && `providers=${arrayToString(providers)}`,
    accounts.length !== 0 && `accounts=${arrayToString(accounts)}`,
    users.length !== 0 && `users=${arrayToString(users)}`,
  ];

  const paramsString = params.filter(param => !!param).join('&');

  return `?${paramsString}`;
};

export const useGlobalFilters = ({ onInitialized }: UseGlobalFiltersProps = {}): UseGlobalFiltersResponse => {
  const history = useHistory();

  const { search = '', statuses = [], providers = [], accounts = [], users = [] } = getParameters();

  const [searchPhrase, setSearchPhrase] = useState(decodeURIComponent(search));
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(statuses.map(decodeURIComponent));
  const [selectedProviders, setSelectedProviders] = useState<string[]>(providers.map(decodeURIComponent));
  const [selectedUsers, setSelectedUsers] = useState<string[]>(users.map(decodeURIComponent));
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>(accounts.map(decodeURIComponent));
  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    const { pathname } = window.location;

    if (
      searchPhrase ||
      selectedStatuses.length ||
      selectedProviders.length ||
      selectedAccounts.length ||
      selectedUsers.length
    ) {
      history.replace(
        `${pathname}${prepareParams({
          search: searchPhrase,
          statuses: selectedStatuses,
          providers: selectedProviders,
          accounts: selectedAccounts,
          users: selectedUsers,
        })}`,
        history.location.state,
      );
    } else {
      history.replace(pathname, history.location.state);
    }

    setFilterCount(selectedStatuses.length + selectedProviders.length + selectedAccounts.length + selectedUsers.length);
  }, [selectedStatuses, selectedProviders, searchPhrase, selectedAccounts, selectedUsers]);

  useEffect(() => {
    const paramsSearchPhrase = decodeURIComponent(search);
    const paramsStatuses = statuses.map(decodeURIComponent);
    const paramsProviders = providers.map(decodeURIComponent);
    const paramsAccounts = accounts.map(decodeURIComponent);
    const paramsUsers = users.map(decodeURIComponent);

    const isSearchEqual = isEqual(searchPhrase, paramsSearchPhrase);
    const isStatusesEqual = isEqual(selectedStatuses, paramsStatuses);
    const isProvidersEqual = isEqual(selectedProviders, paramsProviders);
    const isAccountsEqual = isEqual(selectedAccounts, paramsAccounts);
    const isUsersEqual = isEqual(selectedUsers, paramsUsers);

    if (!isSearchEqual) {
      setSearchPhrase(search);
    }
    if (!isStatusesEqual) {
      setSelectedStatuses(paramsStatuses);
    }
    if (!isProvidersEqual) {
      setSelectedProviders(paramsProviders);
    }
    if (!isAccountsEqual) {
      setSelectedAccounts(paramsAccounts);
    }
    if (!isUsersEqual) {
      setSelectedUsers(paramsUsers);
    }

    if (isSearchEqual && isStatusesEqual && isProvidersEqual && isAccountsEqual && isUsersEqual) {
      onInitialized?.();
    }
  }, [history.location.search]);

  return {
    selectedStatuses,
    selectedProviders,
    selectedAccounts,
    selectedUsers,
    searchPhrase,
    filterCount,
    setSelectedStatuses,
    setSelectedProviders,
    setSearchPhrase,
    setFilterCount,
    setSelectedAccounts,
    setSelectedUsers,
  };
};
