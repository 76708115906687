import React from 'react';
import { makeStyles } from '@material-ui/core';
import { StatusIndicator, Tooltip } from '@openx/components/core';

import { DealStatus } from 'modules/deals/constants';
import { mapStatus } from 'modules/deals/utils/mapStatus';

interface StatusCellProps {
  status: string;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
});

export function StatusCell({ status }: StatusCellProps): JSX.Element {
  const classes = useStyles();
  const isDealProcessing = status === DealStatus.PUBLISHING || status === DealStatus.READY_FOR_ACTIVATION;
  const statusData = mapStatus(status);

  return (
    <div className={classes.root}>
      <StatusIndicator {...statusData} data-test="status" />
      {isDealProcessing && (
        <Tooltip
          title={
            <>
              Deal can't be edited as it has
              <br />
              not been activated yet.
            </>
          }
        />
      )}
    </div>
  );
}
