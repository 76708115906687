import React from 'react';
import { NavigationBar } from '../../components/Navigation/NavigationBar';
import { TOP_BAR_HEIGHT } from '../../config';

import { Content } from './Content';
import { Layout } from './Layout';

export const Terms = () => (
  <>
    <NavigationBar topHeight={TOP_BAR_HEIGHT} disableMenu={true} />
    <Layout content={<Content />} />
  </>
);
