import { ApolloError, Operation } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { ReactNode } from 'react';

export interface GraphQLProviderProps {
  children: ReactNode;
}

type SingleGQLError = ApolloError['graphQLErrors'][0];

export type LocalErrorHandler = (error: NestedError) => boolean;

export type HandlersConfigMap = Map<DocumentNode, LocalErrorHandler[]>;

export interface ParseGraphQLErrorsArgs {
  graphQLErrors: readonly SingleGQLError[];
  handlersConfigMap: HandlersConfigMap;
  operation: Operation;
}

export interface MakeApolloClientArgs {
  handlersConfigMap: HandlersConfigMap;
  printError: (message: string) => void;
}

export enum NestedErrorCode {
  JOINT_UNIQUENESS_VIOLATION = 'joint-uniqueness-violation',
  SINGLE_UNIQUENESS_VIOLATION = 'single-uniqueness-violation',
  NOT_NULL_VIOLATION = 'not-null-violation',
  VALIDATION_ERROR = 'validation-error',
  VALUE_ERROR = 'value-error',
  RANGE_ERROR = 'range-error',
  NOT_UNIQUE_ERROR = 'not-unique-error',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
}

export interface NestedError {
  code: NestedErrorCode;
  choices?: string[] | number[];
  max?: string | number;
  message: string;
  min?: string | number;
  path: string;
}

type ConfigContextFunctionSignature = (document: DocumentNode, handler: LocalErrorHandler) => void;

export interface ConfigContextValue {
  registerLocalErrorHandler: ConfigContextFunctionSignature;
  unregisterLocalErrorHandler: ConfigContextFunctionSignature;
}
