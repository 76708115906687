import React, { useCallback } from 'react';
import { Title as ListTitle } from 'components/OptionsList';
import { SelectionList, ListType } from '../OptionsList';
import {
  ActionType,
  FIRST_PARTY_FIELD,
  getOpenSubgroupFirstParty,
  useAudience,
} from 'modules/audiences/AudienceProvider';
import { EnhancedTreeData, TreeData } from 'utils';

interface Props {
  values: EnhancedTreeData[];
  readonly: boolean;
  listType: ListType;
}

export function FirstPartyOptionsList({ values, listType, readonly }: Props) {
  const { state, dispatch } = useAudience();

  const onIncludeDelete = useCallback(
    (option: EnhancedTreeData) => {
      const selectedFirstParty = getOpenSubgroupFirstParty(state);
      const newFirstParty = selectedFirstParty.filter(item => item.id !== option.id);

      dispatch({
        type: ActionType.SET_FIRST_PARTY,
        payload: { segments: { [FIRST_PARTY_FIELD]: newFirstParty } },
      });
    },
    [dispatch, values],
  );

  return (
    <>
      <ListTitle>MY SEGMENTS</ListTitle>
      <SelectionList
        type={listType}
        items={values}
        onDelete={onIncludeDelete}
        data-test="first-party-segments-list"
        readonly={readonly}
      />
    </>
  );
}
