import React from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '28px',
    padding: '0px',
    textTransform: 'none',
    cursor: 'pointer',
  },
  linkDisabled: {
    pointerEvents: 'none',
    color: theme.palette.text.disabled,
  },
}));

interface LinkProps {
  to: string;
  children: React.ReactNode;
  onClick?: () => void;
  isDisabled?: boolean;
  ['data-test']?: string;
}

interface OutsideLinkProps extends LinkProps {
  openInNewTab?: boolean;
}

export const Link = (props: LinkProps) => {
  const { to, children, onClick, isDisabled = false } = props;
  const classes = useStyles();
  const dataTest = props['data-test'] || 'page-link';

  return (
    <RouterLink
      className={classNames(classes.link, { [classes.linkDisabled]: isDisabled })}
      data-test={dataTest}
      to={to}
      onClick={onClick ? onClick : () => {}}
    >
      {children}
    </RouterLink>
  );
};

export const OutsideLink = (props: OutsideLinkProps) => {
  const { to, children, onClick, isDisabled = false, openInNewTab = false } = props;
  const classes = useStyles();
  const dataTest = props['data-test'] || 'page-link';

  return (
    <a
      className={classNames(classes.link, { [classes.linkDisabled]: isDisabled })}
      onClick={onClick ? onClick : () => {}}
      href={to}
      target={openInNewTab ? '_blank' : '_self'}
      data-test={dataTest}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
