import { TableCell as MaterialTableCell } from '@material-ui/core';
import React, { ReactNode, useCallback, useMemo, memo, ComponentType } from 'react';
import { Skeleton } from '@material-ui/lab';
import { HighlightedPhrase, ValuePlaceholder, Ellipsis } from '@openx/components/core';

import { findHighlightPhraseForField, HighlightRules } from '../highlightRules';
import { BaseRow, TableColumn } from '../tableHelpers';
import MultiValueTableCell from './MultiValueTableCell';

function LoadingCell(): JSX.Element {
  return (
    <div style={{ width: '75%' }}>
      <Skeleton animation="wave" />
    </div>
  );
}

export interface TableCellProps<RowT extends BaseRow> {
  column: TableColumn<RowT>;
  rowData?: RowT;
  className?: string;
  highlightRules?: HighlightRules;
}

function TableCell<RowT extends BaseRow>(props: TableCellProps<RowT>): JSX.Element {
  const { column, className, rowData, highlightRules } = props;

  const highlightPhrase = useMemo(
    () => highlightRules && findHighlightPhraseForField(highlightRules, column.key),
    [highlightRules, column.key],
  );

  const highlighter = useCallback(
    (content: string): ReactNode => {
      if (highlightPhrase) {
        return <HighlightedPhrase searchPhrase={highlightPhrase}>{content}</HighlightedPhrase>;
      }

      return content;
    },
    [highlightPhrase],
  );

  const EllipsisWrapper = useCallback(
    ({ children }) => {
      if (column.ellipsis && column.ellipsisMaxWidth) {
        return (
          <Ellipsis tooltip width={column.ellipsisMaxWidth}>
            {children}
          </Ellipsis>
        );
      }

      return children || null;
    },
    [column.ellipsis, column.ellipsisMaxWidth],
  );

  const renderRowData = useCallback(
    (data: RowT): ReactNode => {
      if (column.render) {
        return column.render(data, highlighter);
      }

      if (column.multivalue) {
        return (
          <MultiValueTableCell
            phrase={highlightPhrase}
            mapBy={column.mapBy}
            values={data[column.key]}
            highlighter={highlighter}
            showTooltip={!!column.multivalueTooltip}
          />
        );
      }

      if (column.isDate) {
        return data[column.key] ? new Date(data[column.key]).toISOString().split('T')[0] : <ValuePlaceholder />;
      }

      const value = (column.mapBy && column.mapBy[data[column.key]]?.name) || data[column.key];
      return value === false || value ? <EllipsisWrapper>{highlighter(value)}</EllipsisWrapper> : <ValuePlaceholder />;
    },
    [highlighter, column, highlightPhrase, EllipsisWrapper],
  );

  return (
    <MaterialTableCell
      style={{
        width: column.width,
        ...(props.column.style ?? {}),
      }}
      align={column.align}
      className={className}
      variant="body"
      data-test="table-cell"
    >
      {rowData ? renderRowData(rowData) || <ValuePlaceholder /> : <LoadingCell />}
    </MaterialTableCell>
  );
}

// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/33602
export default memo(TableCell) as ComponentType<TableCellProps<{}>>;
