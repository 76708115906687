// @ts-nocheck

import { isEqual, uniqWith, uniqBy } from 'lodash';
import { Audience } from 'types/schemaTypes';
import { AudienceShare } from 'modules/audiences/AudienceProvider/types';

import { IndividualShare, RevenueMethod, ShareByRecipients, ShareRow } from './types';

export const prepareBulkShares = (audiencesWithShares: Partial<Audience>[] = []): ShareRow[] => {
  const selectedAudiences = audiencesWithShares.map(({ id, name }) => ({ id, name }));
  const audienceCount = selectedAudiences.length;

  const individualShares = audiencesWithShares.reduce<IndividualShare[]>(
    (audiences, { id, name, audience_share = [] }) => [
      ...audiences,
      ...audience_share.reduce(
        (
          shares,
          {
            recipient_account_id,
            recipient_account_name,
            third_party_revenue,
            uid,
            revenue_method,
            cpm_cap,
            id: shareId,
          },
        ) => [
          ...shares,
          {
            audience: { id, name },
            recipient: { id: recipient_account_id, name: recipient_account_name, uid },
            revenueMethod: revenue_method,
            fee: revenue_method === RevenueMethod.CPM ? cpm_cap : third_party_revenue,
            shareId,
          },
        ],
        [],
      ),
    ],
    [],
  );

  const audienceFeePairs = individualShares.map(({ audience: { id, name }, fee, revenueMethod, shareId }) => ({
    id,
    name,
    fee,
    revenueMethod,
    shareId,
  }));

  const uniqueAudienceFeePairs = uniqWith(audienceFeePairs, isEqual);

  const uniqueAudienceFeePairsWithRecipients = uniqueAudienceFeePairs.map(
    ({ id, name, fee, revenueMethod, shareId }) => ({
      audience: { id, name },
      fee,
      revenueMethod,
      shareId,
      uids: individualShares
        .filter(({ audience: { id: shareId }, fee: shareFee }) => id === shareId && fee === shareFee)
        .map(({ recipient: { uid } }) => uid),
      recipients: individualShares
        .filter(({ audience: { id: shareId }, fee: shareFee }) => id === shareId && fee === shareFee)
        .map(({ recipient }) => recipient),
    }),
  );

  const rows = (uniqueAudienceFeePairsWithRecipients as ShareByRecipients[]).reduce<ShareRow[]>(
    (acc, { audience, fee, revenueMethod, recipients, shareId, uids }) => {
      const indexOfCurrentRecipients = acc.findIndex(
        accItems => recipients.every(element => accItems.recipients.some(el => element.id === el.id)), // For some reason Lodash isEqual does not work here
      );
      const formattedFee = revenueMethod === RevenueMethod.CPM ? fee : `${(+(fee || 0) * 100).toFixed()}`;

      if (indexOfCurrentRecipients !== -1) {
        return acc.map((el, i) =>
          i === indexOfCurrentRecipients
            ? {
                ...el,
                recipients: uniqBy([...el.recipients, ...recipients], 'uid'),
                audiences: [...el.audiences, { audience, fee: formattedFee, revenueMethod, shareId, uids }],
              }
            : el,
        );
      } else {
        return [
          ...acc,
          {
            recipients,
            audiences: [{ audience, fee: formattedFee, revenueMethod, shareId, uids }],
          },
        ];
      }
    },
    [],
  );

  return rows.map(({ audiences, recipients }) => {
    if (audiences.length === audienceCount) {
      return {
        recipients,
        audiences,
      };
    } else {
      const newAudiences = selectedAudiences
        .filter(({ id }) => {
          const existingAudiences = audiences.map(({ audience: { id } }) => id);

          return existingAudiences.indexOf(id) === -1;
        })
        .map(({ id, name }) => ({
          audience: { id, name },
          fee: null,
          revenueMethod: RevenueMethod.PoM,
        }));

      return {
        recipients,
        audiences: uniqBy([...audiences, ...newAudiences], 'audience.id'),
      };
    }
  });
};

export const mapAudienceSharesToList = (audienceShares: AudienceShare[] = []): any =>
  audienceShares.reduce<Partial<IndividualShare>[]>(
    (acc, { fee, recipients, revenueMethod }) => [
      ...acc,
      ...recipients.map(recipient => ({ recipient, fee, revenueMethod })),
    ],
    [],
  );
