import React, { useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useTaxonomyDownload } from 'modules/taxonomy/utils';

export const CSVDownload = props => {
  const { handleCleanup, downloadRef, ...restProps } = props;

  useEffect(() => {
    //@ts-ignore
    downloadRef.current?.click();
  }, [downloadRef]);
  return (
    <CSVLink {...restProps}>
      <span ref={downloadRef} />
    </CSVLink>
  );
};
