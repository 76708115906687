import React, { useCallback, useState, FC, memo } from 'react';
import { FormControl, FormLabel, Grid, Theme, Typography, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Button, Loader, Paper } from '@openx/components/core';
import { useAudience, ActionType, getOpenSubgroupsLocation, LocationType } from 'modules/audiences/AudienceProvider';
import { IncludeExcludeDropdown } from 'modules/audiences/components/IncludeExcludeDropdown';
import { useLocationOptions } from 'modules/audiences/hooks';

import { ZipInput } from './components/ZipInput';
import { LocationTypeDropdown } from './components/LocationTypeDropdown';
import { BulkAdd } from './components/BulkAdd';

const placeholders = {
  [LocationType.CONGRESSIONAL]: '[ Select Congressional District ]',
  [LocationType.STATE]: '[ Select State ]',
  [LocationType.DMA]: '[ Select District Metered Area ]',
};

const useStyles = makeStyles((theme: Theme) => ({
  zipCode: {
    marginTop: -8,
    marginBottom: -4,
  },
  bulkUpload: {
    height: 28,
    marginTop: '7px',
  },
  form: {
    marginBottom: 0,
    marginTop: theme.spacing(2),
  },
  root: {
    boxShadow: 'none',
    paddingTop: '30px',
    paddingBottom: 0,
  },
  divider: {
    marginTop: '30px',
  },
}));

interface LocationsProps {
  readonly?: boolean;
}

export const Locations: FC<LocationsProps> = memo(({ readonly = false }): JSX.Element | null => {
  const { state, dispatch } = useAudience();
  const classes = useStyles();

  const [locationType, setLocationType] = useState(LocationType.STATE);
  const [openBulkAction, setOpenBulkAction] = useState(false);

  const selectedOptions = getOpenSubgroupsLocation(state);

  const { options, loading, error } = useLocationOptions();

  const setLocations = useCallback(
    newLocationOptions => {
      const newOptions = Array.isArray(newLocationOptions) ? newLocationOptions : [newLocationOptions];

      dispatch({
        type: ActionType.SET_LOCATION,
        payload: { segments: { location: [...selectedOptions, ...newOptions] } },
      });
    },
    [dispatch, state],
  );

  const onOpenBulkAction = () => {
    setLocationType(locationType);
    setOpenBulkAction(true);
  };

  const onCloseBulkAction = () => {
    setOpenBulkAction(false);
  };

  if (loading) {
    return (
      <Paper className={classes.root}>
        <Loader />
      </Paper>
    );
  }

  if (error) {
    throw new Error('Query Error');
  }

  return (
    <Paper className={classes.root}>
      {() => (
        <>
          <Typography variant="h3" gutterBottom data-test="location-title">
            Location
          </Typography>
          {!readonly && (
            <FormControl className={classes.form}>
              <FormLabel data-test="location-type-label">Location Type</FormLabel>
              <Grid container spacing={1}>
                <Grid item xs={12} data-test="location-type-dropdown">
                  <Box mb={1}>
                    <LocationTypeDropdown onChange={setLocationType} initValue={locationType} />
                  </Box>
                </Grid>
                <Grid item xs={12} data-test="location-type-select-list">
                  {locationType === LocationType.ZIP && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} className={classes.zipCode}>
                        <ZipInput setLocations={setLocations} />
                      </Grid>
                    </Grid>
                  )}
                  {[LocationType.CONGRESSIONAL, LocationType.DMA, LocationType.STATE].includes(locationType) && (
                    <IncludeExcludeDropdown
                      onClick={setLocations}
                      options={options[locationType]}
                      selected={selectedOptions}
                      nested={locationType === LocationType.CONGRESSIONAL}
                      placeholder={placeholders[locationType]}
                    />
                  )}
                </Grid>
                {[LocationType.DMA, LocationType.ZIP].includes(locationType) && (
                  <Grid item xs={12}>
                    <Button
                      className={classes.bulkUpload}
                      size="small"
                      data-test="bulk-upload-button"
                      onClick={onOpenBulkAction}
                    >
                      BULK ADD
                    </Button>
                  </Grid>
                )}
              </Grid>
            </FormControl>
          )}
          <BulkAdd
            setLocationType={setLocationType}
            locationType={locationType}
            onClose={onCloseBulkAction}
            isOpen={openBulkAction}
            setLocations={setLocations}
          />

          <Divider className={classes.divider} />
        </>
      )}
    </Paper>
  );
});
