import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { Box, FormControlLabel, Typography } from '@material-ui/core';
import { Loader, Paper, Checkbox, AutoComplete } from '@openx/components/core';

import { AudienceTemplatePicker } from 'modules/audiences/components';
import { Audience } from 'types/schemaTypes';
import {
  useAudience,
  ActionType,
  ExportType,
  getExportType,
  getDirectProvider,
  getExportTargets,
  getAccountId,
} from 'modules/audiences/AudienceProvider';
import { useProviders } from 'modules/audiences/hooks';
import { canSetExportTargets } from 'modules/audiences/utils/';

import {
  audiencePickerPlaceholders,
  audienceTypeOptions,
  audienceTypeOptionsLabels,
  exportTypeOptions,
  exportTypeLabels,
} from './constants';
import { Info } from '../Info';
import { AudienceOptionsProps, AudienceType } from './types';

const mapAudienceTypeOptionToLabel = option => audienceTypeOptionsLabels[option];
const mapExportTypeToLabel = option => exportTypeLabels[option];

export function AudienceOptions({
  type,
  audienceId,
  loading = false,
  isEdit = false,
  onlyReadyAudiences,
  options: availableOptions = audienceTypeOptions,
  skipFetchingAudience,
  onChange = () => {},
}: AudienceOptionsProps): JSX.Element {
  const { state, dispatch } = useAudience();

  const exportType = getExportType(state);
  const directProvider = getDirectProvider(state, !isEdit);
  const { allowedProviders, loadingProviders, getProviderName, getAllowedExportTypes } = useProviders(
    getAccountId(state),
  );
  const allowedExportTypes = getAllowedExportTypes(directProvider);
  const exportTargets = getExportTargets(state);

  const options = useMemo(() => {
    const optionTypeAvailability = {
      [AudienceType.CREATE]: true,
      [AudienceType.BASE]: true,
      [AudienceType.USE]: true,
      [AudienceType.USE_DIRECT_AUDIENCE]: !!allowedProviders.length,
    };
    return availableOptions.filter(option => optionTypeAvailability[option]);
  }, [allowedProviders.length, availableOptions]);

  const directProviderOptions = useMemo(() => allowedProviders.map(({ id }) => id), [allowedProviders]);

  const exportOptions = useMemo(() => {
    const exportTypeAvailability = {
      [ExportType.OA_MATCH]: allowedExportTypes.includes(ExportType.OA_MATCH),
      [ExportType.BIDSTREAM]: allowedExportTypes.includes(ExportType.BIDSTREAM),
      [ExportType.BIDSTREAM_PLUS]: allowedExportTypes.includes(ExportType.BIDSTREAM_PLUS),
    };
    return exportTypeOptions.filter(option => exportTypeAvailability[option]);
  }, [allowedExportTypes]);

  const onAudienceTypeChange = useCallback(
    (_, nextType: AudienceType | null) => {
      onChange({ type: nextType });
    },
    [onChange],
  );

  const onAudienceTemplateChange = useCallback(
    (audienceId: Audience['id'], audienceName: Audience['name']) => {
      onChange({ type, audienceId });
      dispatch({ type: ActionType.SET_TEMPLATE_NAME, payload: { templateName: audienceName || undefined } });
    },
    [type, onChange],
  );

  const onDirectProviderChange = useCallback(
    (_, directProvider: string) => {
      dispatch({ type: ActionType.SET_DIRECT_PROVIDER, payload: { directProvider } });
    },
    [dispatch],
  );

  const onExportTypeChange = useCallback(
    (_, exportType: ExportType) => {
      dispatch({ type: ActionType.SET_EXPORT_TYPE, payload: { exportType } });
    },
    [dispatch],
  );

  const onExportTargetChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.currentTarget;

      const newExportTargets = checked ? [...exportTargets, value] : exportTargets.filter(val => val !== value);
      dispatch({
        type: ActionType.SET_EXPORT_TARGETS,
        payload: { exportTargets: newExportTargets },
      });
    },
    [dispatch, exportTargets],
  );

  const isUseCustomSelected = type === AudienceType.USE;
  const showAudiencePicker = type && [AudienceType.BASE, AudienceType.USE].includes(type);
  const showDirectProviderPicker =
    !isUseCustomSelected && type === AudienceType.USE_DIRECT_AUDIENCE && !loadingProviders;
  const showExportTypePicker = [AudienceType.BASE, AudienceType.USE_DIRECT_AUDIENCE].includes(type as AudienceType);
  const showExportTargets =
    type === AudienceType.USE ? false : type === AudienceType.CREATE || (exportType && canSetExportTargets(exportType));
  const showID5Target = exportType !== ExportType.BIDSTREAM_PLUS;
  const showIPTarget = exportType !== ExportType.BIDSTREAM_PLUS;

  // Set default audience export targets
  useEffect(() => {
    if (showExportTargets && exportTargets.length === 0) {
      dispatch({
        type: ActionType.SET_EXPORT_TARGETS,
        payload: { exportTargets: ['cookie', 'device', 'idl', 'id5'] },
      });
    }
  }, [showExportTargets, exportTargets, dispatch]);

  if (loading) {
    return (
      <Paper>
        <Loader />
      </Paper>
    );
  }

  return (
    <Paper data-test="audience-options-paper">
      <Typography variant="h2" gutterBottom data-test="audience-options-title">
        Audience Options
      </Typography>
      {!isEdit && (
        <AutoComplete
          options={options}
          getOptionLabel={type => {
            const label = mapAudienceTypeOptionToLabel(type);
            return label;
          }}
          textFieldProps={{
            label: 'Audience Type',
            placeholder: '[ Select Audience Type ]',
            margin: 'dense',
          }}
          textFieldReadOnly
          onChange={onAudienceTypeChange}
          value={type || null}
          data-test="audience-type"
        />
      )}
      {showAudiencePicker && (
        <AudienceTemplatePicker
          onChange={onAudienceTemplateChange}
          margin="dense"
          placeholder={audiencePickerPlaceholders[type!]}
          value={audienceId || null}
          onlyReadyAudiences={!!onlyReadyAudiences}
          skipFetchingAudience={!!skipFetchingAudience}
        />
      )}
      {showDirectProviderPicker && (
        <AutoComplete
          options={directProviderOptions}
          getOptionLabel={getProviderName}
          textFieldProps={{
            placeholder: '[ Choose Segments Direct Provider ]',
            label: 'Choose Provider',
            margin: 'dense',
          }}
          onChange={onDirectProviderChange}
          value={directProvider}
          disabled={isEdit}
          disableClearable
          textFieldReadOnly={!isEdit}
          data-test="direct-provider"
        />
      )}
      {showExportTypePicker && (
        <AutoComplete
          options={exportOptions}
          getOptionLabel={mapExportTypeToLabel}
          textFieldProps={{
            placeholder: '[ Choose export type ]',
            label: 'Match Type',
            margin: 'dense',
          }}
          onChange={onExportTypeChange}
          value={exportType || undefined}
          disabled={isEdit}
          disableClearable
          textFieldReadOnly={!isEdit}
          data-test="export-type"
        />
      )}
      {showExportTargets && (
        <Box mt={2}>
          <Typography variant="h3" data-test="target-options-title">
            Target ID Types
          </Typography>
          <Box display="flex" mt={1} data-test="export-targets">
            <FormControlLabel
              label="Cookies"
              control={
                <Checkbox
                  value="cookie"
                  onChange={onExportTargetChange}
                  checked={exportTargets.includes('cookie')}
                  data-test="cookie-checkbox"
                />
              }
            />
            <FormControlLabel
              label="MAIDs"
              control={
                <Checkbox
                  value="device"
                  onChange={onExportTargetChange}
                  checked={exportTargets.includes('device')}
                  data-test="device-checkbox"
                />
              }
            />
            <FormControlLabel
              label="RampID"
              control={
                <Checkbox
                  value="idl"
                  onChange={onExportTargetChange}
                  checked={exportTargets.includes('idl')}
                  data-test="idl-checkbox"
                />
              }
            />
            <FormControlLabel
              label="CTV"
              control={
                <Checkbox
                  value="ctv"
                  onChange={onExportTargetChange}
                  checked={exportTargets.includes('ctv')}
                  data-test="ctv-checkbox"
                />
              }
            />
            {showIPTarget && (
              <FormControlLabel
                label="IP Address"
                control={
                  <Checkbox
                    value="ip"
                    onChange={onExportTargetChange}
                    checked={exportTargets.includes('ip')}
                    data-test="ip-checkbox"
                  />
                }
              />
            )}
            {showID5Target && (
              <FormControlLabel
                label="ID5"
                control={
                  <Checkbox
                    value="id5"
                    onChange={onExportTargetChange}
                    checked={exportTargets.includes('id5')}
                    data-test="id5-checkbox"
                  />
                }
              />
            )}
          </Box>
        </Box>
      )}
      {onlyReadyAudiences && (
        <Box marginTop={4}>
          <Info>Only audiences with status 'exported' can be selected.</Info>
        </Box>
      )}
    </Paper>
  );
}
