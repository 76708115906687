import { isEmpty, cloneDeep } from 'lodash';
import { flatChildren } from './flatChildren';

export const filterBySubtype = (items, searchSubtype: string) =>
  items.filter(({ sub_type }) => sub_type === searchSubtype);

export function filterTreeFromNotSelected<
  K extends { value: string; children?: { value: string }[] },
  T extends { value: string },
>(tree: K[], selectedItems: T[]): K[] {
  const selectedValues = selectedItems.map(({ value }) => value);

  const mark = tree => {
    return tree.map(item => {
      const markedItem = { ...item };
      if (selectedValues.includes(item.value)) {
        markedItem.keep = true;
      }
      if (!isEmpty(item.children)) {
        const markedChildren = mark(item.children);
        markedItem.children = [...markedChildren];
        if (markedChildren.some(({ keep }) => keep === true)) {
          markedItem.keep = true;
        }
      }
      return markedItem;
    });
  };

  const filterRecursive = tree => {
    return tree.filter(item => {
      if (!item.keep) {
        return false;
      }
      if (!isEmpty(item.children)) {
        item.children = [...filterRecursive(item.children)];
      }
      delete item.keep;
      return true;
    });
  };

  return filterRecursive(mark(cloneDeep(tree)));
}

export function filterTreeFromSelected<
  K extends { value: string; children?: { value: string }[] },
  T extends { value: string },
>(tree: K[], selectedItems: T[]): K[] {
  const selectedValues = selectedItems.map(({ value }) => value);

  const filterRecursive = tree =>
    tree.filter(item => {
      if (selectedValues.includes(item.value)) {
        return false;
      }
      if (!isEmpty(item.children)) {
        item.children = [...filterRecursive(item.children)];
        return !isEmpty(item.children);
      }
      return true;
    });
  return filterRecursive(cloneDeep(tree));
}

interface Option<T> {
  title: string;
  value: string;
  is_nav_only: boolean;
  is_leaf: boolean;
  id: string;
  calculated_full_name: string;
  parent_segment: string | null;
  children?: T[];
  propensity?: string;
  description?: string;
}

export function filterTree<K extends Option<K>>(options: K[] = [], filter: (option: K) => boolean): K[] {
  return options.reduce<K[]>((result, option) => {
    if (option.is_leaf) {
      if (filter(option)) {
        result.push(option);
      }

      return result;
    }

    const filteredChildrenOptions = filterTree(option.children, filter);

    if (filteredChildrenOptions.length > 0) {
      result.push({
        ...option,
        children: filteredChildrenOptions,
      });
    }

    return result;
  }, []);
}

export function filterTreeBySearch<K extends Option<K>>(tree: K[], search: string): K[] {
  const toFind = search
    .split(' ')
    .map(phrase => phrase.toLowerCase().trim())
    .filter(phrase => !!phrase);

  return tree
    .flatMap(item => [item, ...flatChildren(item)])
    .filter(item => !item.is_nav_only)
    .filter(item => {
      return toFind.every(
        phrase =>
          item.calculated_full_name.toLowerCase().includes(phrase) || item?.description?.toLowerCase().includes(phrase),
      );
    });
}
