import React from 'react';

import { IconButton, makeStyles, Typography } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { useSnackbar } from 'notistack';
import { Tooltip } from '@openx/components/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '8px 0',
  },
  nameText: {
    fontWeight: 600,
    lineHeight: `${theme.spacing(2.75)}px`,
    marginBottom: theme.spacing(0.25),
  },
  flex: { display: 'flex', justifyContent: 'flex-start' },
  idText: {
    fontSize: '12px',
    lineHeight: `${theme.spacing(2.75)}px`,
    marginRight: theme.spacing(1),
  },
  icon: {
    width: '0.5em',
    height: '0.5em',
  },
}));

export const ProviderNameCell = ({ name, id }) => {
  const { wrapper, nameText, flex, idText, icon } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const onCopy = e => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(id);
    enqueueSnackbar('Copied to clipboard', { variant: 'success' });
  };

  return (
    <div className={wrapper}>
      <Typography variant="body1" color="primary" className={nameText} data-test="table-provider-name">
        {name}
      </Typography>
      <div className={flex}>
        <Typography variant="caption" color="textSecondary" className={idText}>
          ID:{' '}
        </Typography>
        <Typography variant="caption" className={idText} data-test="table-provider-id">
          {id}
        </Typography>
        <Tooltip title="Copy to clipboard" placement="top">
          <IconButton size="small" onClick={e => onCopy(e)} data-test="copy-to-clipboard">
            <FileCopyOutlinedIcon className={icon} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
