import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

interface FormErrorsProps {
  gutterBottom?: boolean;
  title?: string;
  errors: (string | undefined)[];
}

const useStyles = makeStyles(() => ({
  list: {
    marginLeft: 20,
  },
  listOneItem: {
    marginLeft: 0,
    listStyleType: 'none',
  },
}));

export function FormErrors(props: FormErrorsProps): JSX.Element {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { errors, title } = props;

  if (errors.filter(e => e).length === 0) {
    return <></>;
  }

  const singleError = errors.length === 1;
  const alertTitle = title || t('Please correct any issues in the form and try again.');

  return (
    <Alert severity="error" data-test="error-message">
      <AlertTitle>{alertTitle}</AlertTitle>
      <ul
        className={classNames({
          [classes.list]: true,
          [classes.listOneItem]: singleError,
        })}
      >
        {errors.map((error, index) => error && <li key={index}>{error}</li>)}
      </ul>
    </Alert>
  );
}
