import React, { ComponentType, ReactNode } from 'react';
import { FormControl, TooltipProps } from '@material-ui/core';
import { Tooltip } from '@openx/components/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import { makeStyles } from '@material-ui/styles';
import { Field, FastField } from 'formik';
import { TextFieldProps as FMuiTextFieldProps, CheckboxProps as FMuiCheckboxProps } from 'formik-material-ui';

type Props<ComponentProps extends unknown> = {
  name: string;
  label?: string;
  component: ComponentType<ComponentProps>;
  disabled?: boolean;
  fastField?: boolean;
  fullWidth?: boolean;
  info?: string | ReactNode;
  tooltipTop?: string;
  tooltipPlacement?: TooltipProps['placement'];
} & ComponentProps &
  Partial<FMuiTextFieldProps> &
  Partial<FMuiCheckboxProps>;

const useStyle = makeStyles({
  container: {
    position: 'relative',
  },
  tooltip: {
    position: 'absolute',
    right: '-32px',
    top: ({ tooltipTop }: { tooltipTop?: string }) => tooltipTop || '28px',
  },
});

export function FormFieldWithInfo<ComponentProps>(props: Props<ComponentProps>): JSX.Element | null {
  const {
    name,
    label,
    component,
    info,
    tooltipTop,
    fastField = false,
    fullWidth = true,
    tooltipPlacement = 'right',
    ...otherProps
  } = props;

  const classes = useStyle({ tooltipTop });

  const FieldComponent = fastField ? FastField : Field;

  return (
    <FormControl fullWidth={fullWidth} className={classes.container}>
      <FieldComponent
        name={name}
        label={label || name}
        component={component}
        {...otherProps}
        data-test={props['data-test']}
      />
      {info && (
        <Tooltip title={<>{info}</>} placement={tooltipPlacement} className={classes.tooltip}>
          <InfoRoundedIcon />
        </Tooltip>
      )}
    </FormControl>
  );
}
