import classNames from 'classnames';
import { ComponentType } from 'react';
import * as React from 'react';
import { Theme, makeStyles, Typography } from '@material-ui/core';

import { Button, ButtonProps } from '../Button';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
  },
  infoSide: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '10%',
  },
  pictureSide: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    '& > svg': {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  title: {
    fontSize: 48,
    lineHeight: '64px',
    fontWeight: 800,
    marginBottom: 10,
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  actionsSection: {
    margin: theme.spacing(3.75, 0),
    '& > button': {
      margin: theme.spacing(0, 1, 0, 0),
    },
  },
}));

interface ErrorProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  actions?: ButtonProps[];
  PictureComponent?: ComponentType;
  className?: string;
  'data-test'?: string;
  titlePrefix?: string;
}

export function Error(props: ErrorProps): JSX.Element {
  const { title, titlePrefix, subtitle, actions, PictureComponent, className } = props;
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  React.useEffect(() => {
    document.title = `${titlePrefix || 'Unity'} - ${title}`;
  }, [title, titlePrefix]);

  return (
    <div className={rootClassName} data-test={props['data-test']}>
      <div className={classes.infoSide}>
        <div className={classes.title}>{title}</div>
        <Typography className={classes.subtitle} variant="body1">
          {subtitle}
        </Typography>
        <div className={classes.actionsSection}>
          {actions?.map((buttonProps, index) => (
            <Button key={index} {...buttonProps} />
          ))}
        </div>
      </div>
      <div className={classes.pictureSide}>{PictureComponent && <PictureComponent />}</div>
    </div>
  );
}
