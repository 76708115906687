import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles(theme => ({
  link: {
    alignItems: 'center',
    width: '100%',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.5,
    display: 'flex',
    gap: 4,
    padding: '4px 0',
    color: theme.palette.text.primary,
    '&.active': {
      color: theme.palette.primary.main,
    },
    '&:visited': {
      color: theme.palette.text.primary,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export const ExternalLink = ({ to, label }) => {
  const { link } = useStyles();

  return (
    <a className={link} href={to} target="_blank" rel="noreferrer">
      {label}
      <LaunchIcon />
    </a>
  );
};
