import React from 'react';

import { PageHeader } from '@openx/components/header';
import { Loader } from '@openx/components/core';
import { Grid } from '@material-ui/core';
import { ActionBar } from 'components/ActionBar';
import { ContentWrapper } from 'components/ContentWrapper';
import { Details } from './components';

import { useHistory, useParams } from 'react-router-dom';
import { RouteProviderDetailsParams } from 'routes';

import { useQuery } from '@apollo/client';
import GetProvider from 'graphql/query/providers/GetProvider.gql';
import { GetProviderQuery, GetProviderQueryVariables } from 'types/schemaTypes';
import { LinkedAccountsReadonly } from './components/LinkedAccountsReadonly';
import { useSideDrawer } from 'context';

export const ProviderDetails = () => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();

  const { providerId } = useParams<RouteProviderDetailsParams>();

  const {
    loading,
    error,
    data: { provider_by_pk: providerData = undefined } = {},
  } = useQuery<GetProviderQuery, GetProviderQueryVariables>(GetProvider, {
    variables: { id: providerId },
    fetchPolicy: 'network-only',
  });

  const actions = [
    {
      label: 'Edit',
      type: 'button',
      props: {
        onClick: () => {
          history.push(`/providers/${providerId}/edit`);
        },
        'data-test': 'provider-edit',
      },
    },
  ];

  if (error) {
    throw new Error('Provider error');
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <ActionBar onGoBack={history.goBack} actions={actions}>
      <PageHeader
        title={`Details: ${providerData?.name}`}
        titlePrefix="OpenAudience"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      >
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Details id={providerData?.id} allowedExportTypes={providerData?.allowed_export_types} />
            </Grid>
            <Grid item xs={6}>
              <LinkedAccountsReadonly allowedAccounts={providerData?.allowed_accounts} />
            </Grid>
          </Grid>
        </ContentWrapper>
      </PageHeader>
    </ActionBar>
  );
};
