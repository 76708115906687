import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Error, FailureImage } from '@openx/components/core';
import { getRollbar } from 'modules/analytics';

interface StateProps {
  hasError: boolean;
}

interface ErrorBoundaryProps extends RouteComponentProps {
  children: React.ReactNode;
  className?: string;
}

class ErrorBoundaryRawComponent extends React.Component<ErrorBoundaryProps> {
  state: StateProps = { hasError: false };

  static getDerivedStateFromError(): StateProps {
    return { hasError: true };
  }

  componentDidCatch(error, info): void {
    /* eslint-disable no-console */
    console.error('ErrorBoundary', error, info);
    const rollbar = getRollbar();
    if (rollbar) {
      rollbar.error('Caught by the ErrorBoundary', { error, info });
    }
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps): void {
    const locationChanged = prevProps.location.pathname !== this.props.location.pathname;
    const shouldHideError = this.state.hasError && locationChanged;

    if (shouldHideError) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hasError: false,
      });
    }
  }

  reloadPage = (): void => {
    window.location.reload();
  };

  render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <Error
          title="Ooops!"
          titlePrefix="OpenAudience"
          subtitle="Something went wrong. Please refresh your browser."
          actions={[
            {
              color: 'secondary',
              variant: 'contained',
              onClick: this.reloadPage,
              children: 'Refresh',
              'data-test': 'button-refresh',
            },
          ]}
          PictureComponent={FailureImage}
          className={this.props.className}
          data-test="error-refresh"
        />
      );
    }

    return <>{this.props.children}</>;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryRawComponent);
