import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@openx/components/core';
import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { IconCell } from './IconCell';

export function ExpandIconCell({ expanded }: { expanded: boolean }): JSX.Element {
  return (
    <IconCell>
      <Button variant="text">{expanded ? <RemoveIcon /> : <AddIcon />}</Button>
    </IconCell>
  );
}

const useStyles = makeStyles({
  root: {
    visibility: 'hidden',
    borderLeft: '1px solid',
  },
});

export function ExpandCellPlaceholder(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button variant="text">
        <AddIcon />
      </Button>
    </div>
  );
}
