import React from 'react';

import { Columns } from '@openx/components/table';

import { DateCell } from 'components/DateCell';
import { GetProviderParam, DEFAULT_PROVIDER_NAME } from 'modules/audiences/hooks/useProviders';

import { AddressableReach } from '../components/AddressableReach';
import { AudienceStatus, PROCESSING_STATUSES } from '../constants';
import { AudienceRow } from './AudiencesList';
import { mapStatus } from 'modules/audiences/utils';
import { AudienceCell } from '../components/AudienceCell';
import { StatusCell } from '../components/StatusCell';

const isAudienceBeingProcessed = status => PROCESSING_STATUSES.includes(status as AudienceStatus);

export const tableColumns = (getProviderName: (param: GetProviderParam) => string): Columns<AudienceRow> => [
  {
    title: 'Name',
    key: 'name',
    width: '40%',
    style: {
      paddingTop: '13px',
      paddingBottom: '13px',
    },
    render: ({ name, status, estimated_expiry_date, audience_segments = [] }) => {
      if (!name) return null;
      const segments = audience_segments.map(({ segment: { status, expiration_date, is_archived } }) => ({
        segmentStatus: status,
        expiryDate: expiration_date,
        isArchived: is_archived,
      }));

      return (
        <AudienceCell name={name} status={status} estimatedExpiryDate={estimated_expiry_date} segments={segments} />
      );
    },
  },
  {
    title: 'Reach',
    key: 'addressable_reach',
    width: '10%',
    render: ({ addressable_reach, id, status }) => {
      if (!isAudienceBeingProcessed(status) && !addressable_reach) return null;

      return <AddressableReach id={id} reach={addressable_reach} />;
    },
  },
  {
    title: 'Status',
    key: 'status',
    width: '10%',
    render: ({ status: itemStatus, last_export }) => {
      const status = mapStatus(itemStatus);

      if (!status) {
        return null;
      }
      return (
        <StatusCell
          label={status.label}
          status={status.status}
          exportingStatus={last_export ? last_export.status : undefined}
        />
      );
    },
  },
  {
    title: 'Provider',
    key: 'direct_audience_provider',
    width: '10%',
    render: ({ direct_audience_provider }) => getProviderName(direct_audience_provider) || DEFAULT_PROVIDER_NAME,
  },
  {
    title: 'Organization',
    key: 'account',
    width: '15%',
    render: ({ account }) => (account ? account.name : null),
  },
  {
    title: 'Date Updated',
    key: 'updated_date',
    width: '15%',
    render: ({ updated_date }) => <DateCell date={updated_date} />,
  },
  {
    title: 'Date Created',
    key: 'created_date',
    width: '15%',
    render: ({ created_date }) => <DateCell date={created_date} />,
  },
];
