import React, { memo, FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { Segments, fields, RelationType, defaultPredefined } from 'modules/audiences/AudienceProvider';
import { isPredefinedEmpty } from 'modules/audiences/utils/areSegmentsEmpty';

import { LocationOptionsList } from '../Locations/components/LocationOptionsList';
import { DemographicsOptionsList } from '../Demographics/DemographicsOptionsList';
import { DirectSegmentOptionsList } from '../DirectSegment/DirectSegmentOptionsList';
import { ListType } from '../OptionsList';
import { FirstPartyOptionsList } from '../FirstParty/FirstPartyOptionsList';
import { PredefinedOptionsList } from '../PredefinedAudienceSegments/PredefinedOptionsList';
import { theme } from '@openx/components/core';

const { LOCATION_FIELD, AGE_FIELD, GENDER_FIELD, FIRST_PARTY_FIELD, PREDEFINED_FIELD, DIRECT_SEGMENT_FIELD } = fields;

type StyleProps = {
  isSubgroup: boolean;
};

const useStyles = makeStyles({
  segmentSection: {
    padding: ({ isSubgroup }: StyleProps) => `${isSubgroup ? 20 : 0}px 20px 15px 20px`,
    borderTop: ({ isSubgroup }: StyleProps) => (isSubgroup ? `1px solid ${theme.palette.border}` : 'none'),
    borderBottom: ({ isSubgroup }: StyleProps) => (isSubgroup ? 'none' : `1px solid ${theme.palette.border}`),
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
  segmentTitle: {
    marginTop: ({ isSubgroup }: StyleProps) => (isSubgroup ? '0' : theme.spacing(2.5)),
    marginBottom: theme.spacing(2.5),
  },
});

interface SegmentsListProps {
  segments: Segments;
  readonly: boolean;
  relationField: RelationType;
  isSubgroup?: boolean;
}

const listType = {
  [RelationType.INCLUDE]: ListType.INCLUDE,
  [RelationType.EXCLUDE]: ListType.EXCLUDE,
};

export const SegmentsList: FC<SegmentsListProps> = memo(({ segments, readonly, relationField, isSubgroup = false }) => {
  const classes = useStyles({ isSubgroup });

  const {
    [LOCATION_FIELD]: locationValues = [],
    [AGE_FIELD]: ageValues = [],
    [GENDER_FIELD]: genderValues = [],
    [FIRST_PARTY_FIELD]: firstPartyValues = [],
    [PREDEFINED_FIELD]: predefinedValues = defaultPredefined,
    [DIRECT_SEGMENT_FIELD]: directSegmentValues = [],
  } = segments;

  return (
    <div data-test="segments-list">
      {(directSegmentValues.length !== 0 || firstPartyValues.length !== 0) && (
        <div className={classes.segmentSection} data-test="direct-audience-readonly">
          <Typography
            variant="h3"
            className={classes.segmentTitle}
            data-test={directSegmentValues.length === 0 ? 'direct-audience-title' : '1p-title'}
          >
            Direct Audience Segments
          </Typography>

          {directSegmentValues.length !== 0 && (
            <DirectSegmentOptionsList
              values={directSegmentValues}
              readonly={readonly}
              listType={listType[relationField]}
            />
          )}

          {firstPartyValues.length !== 0 && (
            <FirstPartyOptionsList values={firstPartyValues} listType={listType[relationField]} readonly={readonly} />
          )}
        </div>
      )}
      {locationValues.length !== 0 && (
        <div className={classes.segmentSection}>
          <Typography variant="h3" className={classes.segmentTitle} data-test="location-title">
            Location
          </Typography>
          <LocationOptionsList values={locationValues} readonly={readonly} listType={listType[relationField]} />
        </div>
      )}
      {!(ageValues.length === 0 && genderValues.length === 0) && (
        <div className={classes.segmentSection}>
          <Typography variant="h3" className={classes.segmentTitle} data-test="demographics-title">
            Demographics
          </Typography>
          <DemographicsOptionsList
            readonly={readonly}
            ageValues={ageValues}
            genderValues={genderValues}
            listType={listType[relationField]}
          />
        </div>
      )}
      {!isPredefinedEmpty(predefinedValues) && (
        <div className={classes.segmentSection} data-test="predefined-audience-segments">
          <Typography variant="h3" className={classes.segmentTitle} data-test="predefined-audience-segments-title">
            Predefined Audience Segments
          </Typography>
          <PredefinedOptionsList
            predefinedValues={predefinedValues}
            listType={listType[relationField]}
            readonly={readonly}
          />
        </div>
      )}
    </div>
  );
});
