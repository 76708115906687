import React, { ReactNode, useEffect, memo } from 'react';
import clsx from 'classnames';
import { GridJustification, Typography, Grid, Box, Drawer } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Breadcrumbs, BreadcrumbItemProps, useImpersonationBarScrollListener } from '@openx/components/core';
import { MainContainer } from './MainContainer';
import { SideMenu, MenuTypes } from './SideMenu';

const BREADCRUMBS_HEIGHT = 31;
const BASIC_HEIGHT = 111;

interface UseStylesProps {
  breadcrumbs: boolean;
  impersonationBarVisibleHeight: number;
}

const useStyles = makeStyles(theme => ({
  header: {
    height: ({ breadcrumbs }: UseStylesProps) => (breadcrumbs ? BREADCRUMBS_HEIGHT + BASIC_HEIGHT : BASIC_HEIGHT),
    background: `linear-gradient(93.1deg,${theme.palette.primary.main} 16.27%,${theme.palette.secondary.main} 74.56%)`,
    marginBottom: -theme.spacing(6.25),
    padding: theme.spacing(1.5, 4, 7.75),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: ({ breadcrumbs }: UseStylesProps) => (breadcrumbs ? 'space-between' : 'flex-end'),
    color: theme.palette.common.white,
    '& input, & .chipInput, & .MuiInputBase-input, & .MuiInput-input': {
      border: 'none',
      '&:hover': {
        border: 'none',
      },
      '&:focus': {
        boxShadow: '1px 1px 6px rgba(15, 15, 15, 0.26)',
      },
    },
  },
  headerContent: {
    position: 'relative',
    bottom: 3,
  },
  headerTitle: {
    position: 'absolute',
    bottom: -3,
    left: 0,
  },
  content: {
    padding: theme.spacing(0, 4, 4, 4),
  },
  drawer: {
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      top: ({ impersonationBarVisibleHeight }: UseStylesProps) => impersonationBarVisibleHeight + 54,
      width: 240,
      boxSizing: 'border-box',
      minWidth: 240,
      maxWidth: 240,
      padding: 32,
    },
  },
}));

export interface PageHeaderProps {
  children?: ReactNode;
  breadcrumbs?: BreadcrumbItemProps[];
  title?: string;
  alert?: ReactNode;
  render?: () => ReactNode;
  headerJustify?: GridJustification;
  titlePrefix?: string;
  openDrawer?: boolean;
  handleDrawerOpen?: () => void;
  menuItems?: MenuTypes;
  token?: string;
}

export const PageHeader = memo((props: PageHeaderProps): JSX.Element => {
  const {
    children,
    breadcrumbs,
    title,
    titlePrefix,
    alert,
    render,
    headerJustify = 'flex-end',
    openDrawer,
    menuItems = [],
    token = '',
  } = props;

  const { barVisibleHeight: impersonationBarVisibleHeight } = useImpersonationBarScrollListener();

  const classes = useStyles({
    breadcrumbs: !!breadcrumbs?.length,
    impersonationBarVisibleHeight,
  });

  useEffect(() => {
    document.title = `${titlePrefix || 'Unity'}  ${title && `- ${title}`}`;
  }, [title, titlePrefix]);

  const getPageBody = () => {
    return (
      <>
        {alert}
        <div className={classes.header} data-test="page-header">
          {breadcrumbs && <Breadcrumbs items={breadcrumbs} data-test="breadcrumbs" />}
          <Grid container justifyContent={headerJustify} className={classes.headerContent}>
            {title && (
              <Typography variant="h1" className={classes.headerTitle} data-test="page-header-title">
                {title}
              </Typography>
            )}
            {render?.()}
          </Grid>
        </div>
        <div className={clsx({ [classes.content]: children })}>{children}</div>
      </>
    );
  };

  return (
    <>
      <Box>
        <Drawer className={classes.drawer} variant="persistent" anchor="left" open={openDrawer}>
          {/* @ts-ignore */}
          <SideMenu menuItems={menuItems} token={token} />
        </Drawer>
        <MainContainer open={openDrawer} chldren={getPageBody()} />
      </Box>
    </>
  );
});
