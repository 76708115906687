const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E'];

interface Options {
  fractionSize?: 0 | 1 | 2;
}

export function abbreviatedNumber(number: number, { fractionSize = 1 }: Options = {}): string | number {
  // what tier? (determines SI symbol)
  const tier = (Math.log10(number) / 3) | 0;
  // if zero, we don't need a suffix
  if (tier === 0) {
    return number.toFixed(fractionSize);
  }

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(fractionSize) + suffix;
}
