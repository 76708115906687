import React, { useState, useEffect, useCallback, useMemo, memo, FC } from 'react';
import { makeStyles, Typography, Collapse, IconButton, Box } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { areSegmentsEmpty } from 'modules/audiences/utils/areSegmentsEmpty';
import { Button, Tooltip } from '@openx/components/core';
import {
  useAudience,
  ActionType,
  getGroupsSubgroups,
  getGroupsMatchingOperator,
  getGroupSegments,
  RelationType,
  defaultSegments,
} from 'modules/audiences/AudienceProvider';

import { MatchTypeContainer } from './MatchTypeContainer';
import { SubgroupPanel } from './SubgroupPanel';
import { ModalDialog } from './ModalDialog';
import { useSnackbar } from 'notistack';
import { SegmentsList } from './SegmentsList';

interface StyleProps {
  areSegmentsEmpty: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: ({ areSegmentsEmpty }: StyleProps) => `20px ${areSegmentsEmpty ? 20 : 0}px 20px 20px`,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 2,
  },
  segmentsContainer: {
    marginTop: theme.spacing(2.5),
    marginLeft: -theme.spacing(2.5),
  },
  h2: {
    '&:before': { display: 'none' },
    lineHeight: '28.28px',
  },
  subheader: {
    marginLeft: theme.spacing(2.5),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    alignSelf: 'flex-end',
    marginLeft: 'auto',
    minHeight: '28.28px',
    marginRight: ({ areSegmentsEmpty }: StyleProps) => (areSegmentsEmpty ? 0 : theme.spacing(1.75)),
  },
  buttonWithTooltip: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& button': {
      marginRight: theme.spacing(1.25),
      bottom: 0,
    },
  },
  arrow: {
    marginLeft: theme.spacing(0.5),
  },
  modalText: {
    lineHeight: '28px',
  },
}));

const tooltipText =
  'Add a segment subgroup to your segment group, to be combined (Match Any) or intersected (Match All) with other segment subgroups';

interface GroupPanelProps {
  readonly?: boolean;
  groupIndex: number;
  relationField: RelationType;
}

export const GroupPanel: FC<GroupPanelProps> = memo(({ readonly, relationField, groupIndex }) => {
  const { state, dispatch } = useAudience();
  const { enqueueSnackbar } = useSnackbar();

  const [matchItems, setMatchItems] = useState<JSX.Element[]>([]);
  const [expanded, setExpanded] = useState(true);
  const [removeGroupDialog, setRemoveGroupDialog] = useState(false);

  const subgroups = useMemo(
    () => getGroupsSubgroups(state, relationField, groupIndex),
    [state, relationField, groupIndex],
  );

  const subgroupsCount = useMemo(() => subgroups.length, [subgroups]);

  const segments = useMemo(
    () => getGroupSegments(state, relationField, groupIndex),
    [state, relationField, groupIndex],
  );

  const classes = useStyles({ areSegmentsEmpty: areSegmentsEmpty(segments) });

  const matchingOperator = getGroupsMatchingOperator(state, relationField, groupIndex);

  const toggleExpand = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  const handleAddSubgroup = useCallback(
    (groupIndex, subgroupIndex) => {
      setExpanded(true);
      dispatch({ type: ActionType.ADD_SUBGROUP, payload: { relationField, groupIndex, subgroupIndex } });
    },
    [dispatch, state],
  );

  const handleRemoveGroup = useCallback(
    groupIndex => {
      dispatch({ type: ActionType.REMOVE_GROUP, payload: { relationField, groupIndex } });
      closeDeleteGroupDialog();
      enqueueSnackbar(`Group ${groupIndex} deleted successfully`, { variant: 'success' });
    },
    [dispatch],
  );

  const handleAddSegment = useCallback(() => {
    dispatch({
      type: ActionType.SET_OPEN_SUBGROUP,
      payload: { relationField, groupIndex, subgroupIndex: 0, segments: defaultSegments },
    });

    dispatch({ type: ActionType.SET_IS_DRAWER_OPEN, payload: { isDrawerOpen: true } });
  }, [dispatch, relationField, groupIndex]);

  const handleEditSegment = useCallback(() => {
    dispatch({
      type: ActionType.SET_OPEN_SUBGROUP,
      payload: {
        relationField,
        groupIndex,
        subgroupIndex: 0,
        segments,
      },
    });

    dispatch({ type: ActionType.SET_IS_DRAWER_OPEN, payload: { isDrawerOpen: true } });
  }, [dispatch, relationField, groupIndex, segments]);

  const handleMatchTypeChange = useCallback(
    groupIndex => operator => {
      dispatch({ type: ActionType.SET_GROUP_OPERATOR, payload: { groupIndex, relationField, operator } });
    },
    [dispatch],
  );

  const openDeleteGroupDialog = () => {
    if (areSegmentsEmpty(segments)) {
      handleRemoveGroup(groupIndex);
    } else {
      setRemoveGroupDialog(true);
    }
  };

  const closeDeleteGroupDialog = () => {
    setRemoveGroupDialog(false);
  };

  useEffect(() => {
    const newMatchItems = subgroups.map(({ index }) => (
      <SubgroupPanel groupIndex={groupIndex} subgroupIndex={index} relationField={relationField} readonly={readonly} />
    ));

    setMatchItems(newMatchItems);
  }, [subgroups]);

  return (
    <div className={classes.root} data-test="audience-group-panel">
      <div className={classes.flex}>
        <Typography variant="h2" className={classes.h2}>
          Group {groupIndex}
        </Typography>

        {!readonly && subgroupsCount < 1 && areSegmentsEmpty(segments) && (
          <Typography color="textSecondary" className={classes.subheader}>
            Add segments or subgroups
          </Typography>
        )}

        <div className={classes.buttons}>
          {!readonly && groupIndex !== 1 && (
            <Button variant="text" color="secondary" onClick={openDeleteGroupDialog} data-test="remove-group-btn">
              Remove Group
            </Button>
          )}

          {!readonly && subgroupsCount === 0 && !areSegmentsEmpty(segments) && (
            <Button
              data-test="audience-open-edit-segment-drawer"
              variant="text"
              color="primary"
              onClick={handleEditSegment}
            >
              Edit Segment
            </Button>
          )}

          {(subgroupsCount > 0 || !areSegmentsEmpty(segments)) && (
            <IconButton size="small" onClick={toggleExpand} className={classes.arrow}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </div>
      </div>

      <Collapse in={expanded}>
        {subgroupsCount === 0 && !areSegmentsEmpty(segments) && (
          <div className={classes.segmentsContainer}>
            <SegmentsList segments={segments} readonly relationField={relationField} />
          </div>
        )}
        {subgroupsCount > 0 && (
          <MatchTypeContainer
            onChange={handleMatchTypeChange(groupIndex)}
            value={matchingOperator}
            matchItems={matchItems}
            compactDividers
            readonly={readonly}
          />
        )}
        {!readonly && (
          <div className={classes.buttonWithTooltip}>
            {subgroupsCount === 0 && areSegmentsEmpty(segments) && (
              <Button data-test="audience-open-add-segment-drawer" color="primary" onClick={handleAddSegment}>
                Add Segment
              </Button>
            )}
            <Button
              onClick={() => handleAddSubgroup(groupIndex, subgroups[subgroups.length - 1]?.index + 1 || 1)}
              data-test="add-subgroup"
            >
              Add Subgroup
            </Button>
            <Tooltip placement="right" title={tooltipText} withRipple iconDataTest="add-subgroup-info-icon" />
          </div>
        )}
      </Collapse>

      {!readonly && (
        <ModalDialog
          title="Remove Group"
          isOpen={removeGroupDialog}
          onClose={closeDeleteGroupDialog}
          onConfirmed={() => handleRemoveGroup(groupIndex)}
        >
          <Box marginTop="16px">
            <Typography variant="body1" className={classes.modalText}>
              You’re about to delete the following group:
            </Typography>
            <Typography variant="body1" className={classes.modalText}>
              <b> Group {groupIndex}</b>
            </Typography>
          </Box>
          <Box marginTop="13px">
            <Typography variant="body1" className={classes.modalText}>
              Do you want to proceed?
            </Typography>
          </Box>
        </ModalDialog>
      )}
    </div>
  );
});
