import React, { useCallback } from 'react';
import { object, string } from 'yup';
import { FormikHelpers as FormikActions, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';

import { sendPasswordResetEmail } from 'firebaseIntegration';

import { ForgotPasswordForm } from './ForgotPasswordForm';
import { EMAIL_FIELD } from './constants';
import { SsoLayout } from '../components/SsoLayout';
export interface FormValues {
  [EMAIL_FIELD]: string;
}

const initialValues = {
  [EMAIL_FIELD]: '',
};

const validationSchema = object().shape({
  [EMAIL_FIELD]: string().required('Email is required').email(),
});

export function ForgotPassword(): JSX.Element {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikActions<FormValues>): Promise<void> => {
      try {
        await sendPasswordResetEmail(values[EMAIL_FIELD]);

        history.push('/login');
        enqueueSnackbar('An email with instructions to reset your password has been sent', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [enqueueSnackbar, history],
  );

  return (
    <SsoLayout>
      <Typography variant="h2" gutterBottom data-test="set-password-text">
        Forgot Your Password
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        component={ForgotPasswordForm}
      />
    </SsoLayout>
  );
}
