import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Divider, Theme, FormLabel, FormHelperText } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';

interface FormGroupProps {
  label?: string;
  required?: boolean;
  error?: boolean;
  errorMessage?: string;
  children: ReactNode;
  gutterTop?: boolean;
  gutterBottom?: boolean;
}

const useStyles = makeStyles<Theme, FormGroupProps>(theme => ({
  container: {
    margin: theme.spacing(0, -2.5),
    marginTop: ({ gutterTop }) => (gutterTop ? theme.spacing(2.3) : 0),
    marginBottom: ({ gutterBottom }) => (gutterBottom ? theme.spacing(1.5) : 0),
    backgroundColor: theme.palette.background.boxLight,
  },
  content: {
    padding: theme.spacing(1.5, 2.5, 2.4, 2.5),
  },
  label: {
    display: 'block',
    margin: theme.spacing(2, 0),
    color: theme.palette.warning.dark,
  },
  icon: {
    marginTop: -3,
    verticalAlign: 'middle',
    color: theme.palette.warning.main,
  },
  iconError: {
    color: 'inherit',
  },
  errorMessage: {
    margin: theme.spacing(1.5, 0, 0, 0),
  },
}));

export function FormGroup(props: FormGroupProps): JSX.Element {
  const classes = useStyles(props);
  const { label, required = false, error = false, errorMessage, children } = props;

  return (
    <div className={classes.container}>
      <Divider />
      <div className={classes.content}>
        {label && (
          <FormLabel required={required} error={error} className={classes.label}>
            <ReportProblemOutlined
              fontSize="small"
              className={classNames(classes.icon, {
                [classes.iconError]: error,
              })}
            />
            &nbsp;
            {label}
          </FormLabel>
        )}
        {children}
        {error && errorMessage && (
          <FormHelperText error className={classes.errorMessage}>
            {errorMessage}
          </FormHelperText>
        )}
      </div>
      <Divider />
    </div>
  );
}
