import React, { ReactNode } from 'react';
import { Divider, Grid, GridSize, Typography, Box, Theme, TooltipProps } from '@material-ui/core';
import { Tooltip } from '@openx/components/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  pageSection: {
    padding: theme.spacing(4),
  },
  pageSectionWrapper: {
    width: '100%',
  },
  description: {
    lineHeight: '28px',
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  tooltip: {
    marginLeft: theme.spacing(1.25),
  },
}));

interface FormSectionProps {
  children?: ReactNode;
  divider?: boolean;
  title: string;
  description?: string | ReactNode;
  leftColumnSize?: GridSize;
  rightColumnSize?: GridSize;
  customGrid?: boolean;
  info?: string | ReactNode;
  tooltipPlacement?: TooltipProps['placement'];
  ['data-test']?: string;
}

export function FormSection(props: FormSectionProps): JSX.Element {
  const {
    children,
    divider,
    title,
    description,
    info,
    customGrid,
    leftColumnSize = 3,
    rightColumnSize = 9,
    tooltipPlacement = 'top',
  } = props;
  const classes = useStyles();
  const dataTest = props['data-test'] || title.toLowerCase().replace(/\s/g, '-');

  return (
    <div data-test={dataTest} className={classes.pageSectionWrapper}>
      <Grid container className={classes.pageSection}>
        <Grid item xs={leftColumnSize}>
          <Box pr={3}>
            <Typography data-test="title" variant="h2" className={classes.title}>
              <Box component={'span'} display={'flex'}>
                {title}
                {info && (
                  <Tooltip placement={tooltipPlacement} title={<>{info}</>} className={classes.tooltip}>
                    <InfoRoundedIcon />
                  </Tooltip>
                )}
              </Box>
            </Typography>
            {description && (
              <Typography variant="body1" data-test="description" color="textSecondary" className={classes.description}>
                {description}
              </Typography>
            )}
          </Box>
        </Grid>
        {!customGrid ? (
          <Grid item xs={rightColumnSize}>
            {children}
          </Grid>
        ) : (
          children
        )}
      </Grid>
      {divider && <Divider />}
    </div>
  );
}
