import { Login } from 'modules/auth/login';
import { Dashboard } from 'modules/dashboard';

import { RouteConfig } from './types';
import { SetPassword } from 'modules/auth/password';
import { ForgotPassword } from 'modules/auth/forgotPassword';
import { Terms } from 'modules/terms';

export const publicConfig: RouteConfig[] = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/set-password',
    component: SetPassword,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/terms',
    component: Terms,
  },
  {
    // Dashboard has additional top navigation
    component: Dashboard,
  },
];
