import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { CSSProperties } from '@material-ui/styles';
import { TableCell } from '@material-ui/core';
import { Tooltip } from '@openx/components/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

import { SortDirection } from '../sort';
import { TableColumn } from '../tableHelpers';

interface StyleProps {
  stickyHeaderPosition?: number;
  sortable: boolean;
  sortActive: boolean;
}

const useStyles = makeStyles(theme => ({
  headerCellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  headerCell: ({ stickyHeaderPosition, sortable, sortActive }: StyleProps) => {
    const stickyHeaderAttrs: CSSProperties =
      stickyHeaderPosition !== undefined
        ? {
            position: 'sticky',
            top: stickyHeaderPosition,
            backgroundColor: theme.palette.common.white,
            zIndex: 1,
          }
        : {};
    const sortableAttrs = sortable
      ? {
          '&:hover': {
            color: theme.palette.primary.main,
            cursor: 'pointer',
          },
        }
      : undefined;
    const sortActiveAttrs = sortActive ? { color: theme.palette.primary.main } : undefined;

    return { ...stickyHeaderAttrs, ...sortableAttrs, ...sortActiveAttrs };
  },
  arrowIcon: {
    fontSize: '14px',
    marginLeft: theme.spacing(0.5),
  },
  disabledIcon: {
    color: theme.palette.action.inactive,
  },
}));

export interface TableHeaderCellProps<RowT> {
  column: TableColumn<RowT>;
  toggleSort?: (columnKey: string) => void;
  appliedSortDirection?: SortDirection;
  stickyHeaderPosition?: number;
}

export function TableHeaderCell<RowT>(props: TableHeaderCellProps<RowT>): JSX.Element {
  const { column, toggleSort, stickyHeaderPosition, appliedSortDirection } = props;
  const sortable = !!toggleSort;
  const sortActive = !!appliedSortDirection;
  const classes = useStyles({ stickyHeaderPosition, sortable, sortActive });

  const directionIconElement = useMemo(() => {
    if (!toggleSort) {
      return;
    } else if (!sortActive) {
      return <ArrowDownward data-test="sort-down" className={classNames(classes.disabledIcon, classes.arrowIcon)} />;
    }

    return appliedSortDirection === SortDirection.ASC ? (
      <ArrowUpward className={classes.arrowIcon} data-test="sort-up" />
    ) : (
      <ArrowDownward className={classes.arrowIcon} fontSize="small" data-test="sort-down" />
    );
  }, [appliedSortDirection, sortActive, classes, toggleSort]);

  const toggleColumnSort = useCallback(() => toggleSort?.(column.key), [toggleSort, column.key]);

  const columnContent = useMemo(() => {
    if (column.tooltipMessage) {
      return (
        <Tooltip key="tooltip" title={column.tooltipMessage}>
          <span>
            {column.title}
            {directionIconElement}
          </span>
        </Tooltip>
      );
    }

    return (
      <>
        {column.title}
        {directionIconElement}
      </>
    );
  }, [directionIconElement, column.tooltipMessage, column.title]);

  return (
    <TableCell
      className={classes.headerCell}
      style={{
        width: column.width,
        ...(column.style ?? {}),
      }}
      data-test="table-cell"
      onClick={toggleColumnSort}
    >
      <div className={classes.headerCellContainer}>{columnContent}</div>
    </TableCell>
  );
}
