import { environment } from 'environments/environment';
import { ENV_CONFIGS_PATH, developmentConfig } from './config';

interface EnvironmentConfigs {
  env: string;
  firebase: { storageBucket: string; apiKey: string; projectId: string; databaseURL: string; authDomain: string };
}

let environmentConfigsPromise: Promise<EnvironmentConfigs>;

export const initialize = () => {
  if (!environment.production && environment.useLocalConfig) {
    // looks like we're trying to grab configs.json from devint but
    // hit an IAP problem, just use the hard-coded dev keys
    environmentConfigsPromise = Promise.resolve(developmentConfig);
  } else {
    environmentConfigsPromise = fetch(ENV_CONFIGS_PATH).then(response => {
      if (!response.ok) {
        throw new Error('Could not fetch environment configs');
      }
      return response.json();
    });
  }
};

export const getEnvironmentConfigs = async () => {
  if (!environmentConfigsPromise) {
    throw new Error('Environment configs cannot be accessed before initialization');
  }

  return environmentConfigsPromise;
};
