export const paddingMap = {
  none: 0,
  small: 10,
  medium: 20,
  large: 40,
};

export const defaultPadding = 'medium';

export const verticalPaddingFactor = 1.66;
