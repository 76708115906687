import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CircularProgress, Box } from '@material-ui/core';
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@material-ui/lab/Autocomplete';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FieldProps, FormikProps } from 'formik';
import { fieldToTextField } from 'formik-material-ui';

import { DebounceField } from '../DebounceField';
import { TextField } from '../TextField';
import { AutocompleteProps } from './types';

const useStyles = makeStyles({
  dropDownIconContainer: {
    top: 'auto',
  },
  inputCursor: {
    cursor: ({ textFieldReadOnly }: { textFieldReadOnly: boolean }) => (textFieldReadOnly ? 'pointer' : 'auto'),
  },
  option: {
    height: 'auto',
    fontSize: '13px',
  },
  paper: {
    margin: 0,
    padding: 0,
    '& ul': {
      padding: 0,
    },
  },
});

export function AutoComplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  textFieldProps,
  textFieldReadOnly,
  loading,
  debounced = false,
  onDebounceChange = e => {},
  ChipProps,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const { field, form, ...restProps } = props;
  const classes = useStyles({ textFieldReadOnly: !!textFieldReadOnly });
  const isFormikConnected = field && form;
  const CommonProps = {
    popupIcon: <KeyboardArrowDownIcon />,
    ChipProps: { deleteIcon: <CloseIcon />, 'data-test': 'chip-button', ...ChipProps },
    disabledItemsFocusable: true,
    limitTags: 1,
    multiple: undefined,
  };

  const commonClasses = {
    endAdornment: classes.dropDownIconContainer,
    input: classes.inputCursor,
    inputRoot: classes.inputCursor,
    option: classes.option,
    paper: classes.paper,
  };

  const TextInputComponent = debounced ? DebounceField : TextField;

  if (isFormikConnected) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const { setFieldTouched, setFieldValue } = form as FormikProps<{}>;
    const { error, helperText, ...formikProps } = fieldToTextField(props as FieldProps);
    const { name } = formikProps;

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <MuiAutocomplete
        {...formikProps}
        {...CommonProps}
        onChange={(_, value) => setFieldValue(name as string, value)}
        onBlur={() => setFieldTouched(name as string)}
        classes={commonClasses}
        {...(restProps as MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>)}
        renderInput={({ InputLabelProps, InputProps, ...restParams }) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <TextInputComponent
            onChange={onDebounceChange}
            {...restParams}
            {...textFieldProps}
            InputLabelProps={{ disableAnimation: true, shrink: true, ...InputLabelProps }}
            InputProps={{
              disableUnderline: true,
              ...InputProps,
              readOnly: textFieldReadOnly,
              endAdornment: (
                <>
                  {loading && (
                    <Box pr={1}>
                      <CircularProgress size={20} />
                    </Box>
                  )}
                  {InputProps.endAdornment}
                </>
              ),
            }}
            helperText={helperText || textFieldProps?.helperText}
            error={error}
          />
        )}
      />
    );
  }

  return (
    <MuiAutocomplete
      {...CommonProps}
      classes={commonClasses}
      {...(props as MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>)}
      renderInput={({ InputLabelProps, InputProps, ...restParams }) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <TextInputComponent
          onChange={onDebounceChange}
          {...restParams}
          {...textFieldProps}
          InputLabelProps={{ disableAnimation: true, shrink: true, ...InputLabelProps }}
          InputProps={{
            disableUnderline: true,
            ...InputProps,
            readOnly: textFieldReadOnly,
            endAdornment: (
              <>
                {loading && (
                  <Box pr={1}>
                    <CircularProgress size={20} />
                  </Box>
                )}
                {InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
