import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  drawerNotEnabled: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  drawerEnabled: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: 240,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
}));

export function MainContainer(props): JSX.Element {
  const classes = useStyles();
  return (
    <>
      <main className={props.open ? classes.drawerEnabled : classes.drawerNotEnabled}>{props.chldren}</main>
    </>
  );
}
