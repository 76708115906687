import { parse } from 'papaparse';
import { DataType } from './dataType';
import { hashData } from './dataHasher';

export interface PrepareDataToUploadParams {
  file: File;
  dataType: DataType;
  chunkSize: number;
  onProcessedDataChunk: (recordsProcessed: number, dataToUpload: string[][]) => Promise<void>;
  onComplete: () => void;
  onError: (error: unknown) => void;
}

export function prepareDataToUpload({
  file,
  dataType,
  chunkSize,
  onProcessedDataChunk,
  onComplete,
  onError,
}: PrepareDataToUploadParams): void {
  let processingError: unknown;

  parse<string[]>(file, {
    // @ts-ignore
    chunkSize,
    skipEmptyLines: true,
    chunk: async (results, parser) => {
      try {
        parser.pause();

        const data = await hashData(results.data, dataType);

        await onProcessedDataChunk(results.data.length, data);

        parser.resume();
      } catch (error) {
        processingError = error;
        parser.abort();
      }
    },
    complete: async () => {
      if (processingError) {
        onError(processingError);
        return;
      }

      try {
        await onComplete();
      } catch (e) {
        onError(e);
      }
    },
  });
}
