import { useContext, useEffect } from 'react';

import { context } from './context';
import { SetupActionBarProps } from './types';

export function SetupActionBar({ actions, onGoBack }: SetupActionBarProps): null {
  const { setActions, setOnGoBack } = useContext(context);

  useEffect(() => {
    if (!actions) return;
    setActions(actions);
    return () => {
      setActions([]);
    };
  }, [actions, setActions]);

  useEffect(() => {
    if (!onGoBack) return;
    setOnGoBack(onGoBack);
    return () => {
      setOnGoBack(null);
    };
  }, [onGoBack, setOnGoBack]);

  return null;
}
