import React, { useState, useEffect, useCallback, memo, FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import { Button, Tooltip } from '@openx/components/core';
import {
  useAudience,
  ActionType,
  getIncludesMatchingOperator,
  getIncludesGroups,
  RelationType,
} from 'modules/audiences/AudienceProvider';

import { MatchTypeContainer } from './MatchTypeContainer';
import { GroupPanel } from './GroupPanel';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 0 22px 0',
  },
  h2: {
    '&:before': { display: 'none' },
  },
  buttonWithTooltip: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& button': {
      marginRight: theme.spacing(1),
      bottom: 0,
    },
  },
}));

const tooltipText =
  'Add a segment group to your audience, to be combined (Match Any) or intersected (Match All) with other segment groups';

interface Props {
  readonly?: boolean;
}

export const IncludesPanel: FC<Props> = memo(({ readonly }) => {
  const { state, dispatch } = useAudience();
  const classes = useStyles();

  const [matchItems, setMatchItems] = useState<JSX.Element[]>([
    <GroupPanel relationField={RelationType.INCLUDE} groupIndex={1} readonly={readonly} />,
  ]);

  const groups = getIncludesGroups(state);
  const matchingOperator = getIncludesMatchingOperator(state);

  const handleMatchTypeChange = useCallback(
    operator => {
      dispatch({ type: ActionType.SET_INCLUDES_OPERATOR, payload: { operator } });
    },
    [dispatch],
  );

  const handleAddGroup = useCallback(() => {
    dispatch({
      type: ActionType.ADD_GROUP,
      payload: { relationField: RelationType.INCLUDE, groupIndex: groups[groups.length - 1].index + 1 },
    });
  }, [dispatch, groups]);

  useEffect(() => {
    const newMatchItems = groups.map(({ index }) => (
      <GroupPanel relationField={RelationType.INCLUDE} groupIndex={index} readonly={readonly} />
    ));

    setMatchItems(newMatchItems);
  }, [groups]);

  return (
    <div className={classes.root} data-test="includes-panel">
      <Typography variant="h2" className={classes.h2}>
        Includes
      </Typography>
      <MatchTypeContainer
        readonly={readonly}
        value={matchingOperator}
        onChange={handleMatchTypeChange}
        matchItems={matchItems}
        compactDividers
      />
      {!readonly && (
        <div className={classes.buttonWithTooltip}>
          <Button onClick={handleAddGroup} data-test="add-includes-group">
            Add Another Group
          </Button>
          <Tooltip placement="right" title={tooltipText} withRipple iconDataTest="add-group-info-icon" />
        </div>
      )}
    </div>
  );
});
