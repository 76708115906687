import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { BaseRow, RowActionButtonBaseProps, RowActionProps } from '../tableHelpers';
import MenuButton from './MenuButton';
import { IconCell } from './IconCell';
import { RowActionButton } from './RowActionButton';

export interface ActionsCell<RowT extends BaseRow> {
  rowData: RowT;
  ['data-test']?: string;
  primaryAction?: RowActionButtonBaseProps<RowT>;
  secondaryAction?: RowActionButtonBaseProps<RowT>;
  actions?: RowActionProps<RowT>[];
}

interface StylesProps {
  hasDropdownActions: boolean;
  hasButtonActionsAvailable: boolean;
}

//TODO: Review CSS Hover vis for Cypress Tests
const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 'calc(100% + 1px)',
    display: 'none',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.sky,
    boxShadow: `
      -${theme.spacing(2.5)}px 0 ${theme.spacing(1.5)}px -${theme.spacing(1)}px
      ${theme.palette.background.sky}
    `,
    whiteSpace: 'nowrap',
    '.expandContentRow tr:hover &, tr:not(.expandContentRow):hover &': {
      display: 'flex',
    },
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  actionsCell: ({ hasDropdownActions, hasButtonActionsAvailable }: StylesProps) => {
    const dropdownStyles = !hasDropdownActions
      ? {
          width: 0,
          padding: 0,
          margin: 0,
          borderLeft: 0,
          display: hasButtonActionsAvailable ? 'table-cell' : 'none',
        }
      : undefined;

    return {
      ...dropdownStyles,
      position: 'relative',
    };
  },
}));

export function ActionsCell<RowT extends BaseRow>(props: ActionsCell<RowT>): JSX.Element {
  const { rowData, primaryAction, secondaryAction, actions } = props;
  const hasButtonActionsAvailable = !!(primaryAction || secondaryAction);
  const hasDropdownActions = !!actions;
  const classes = useStyles({ hasDropdownActions, hasButtonActionsAvailable });

  return (
    <IconCell stopClickPropagation={true} className={classes.actionsCell}>
      {hasButtonActionsAvailable && (
        <div className={classes.actionsContainer}>
          {primaryAction && <RowActionButton {...primaryAction} rowData={rowData} color="primary" />}
          {secondaryAction && (
            <RowActionButton {...secondaryAction} rowData={rowData} color={secondaryAction?.color || 'secondary'} />
          )}
        </div>
      )}
      {actions && <MenuButton menuItems={actions} rowData={rowData} data-test="row-actions" />}
    </IconCell>
  );
}
