import React from 'react';
import MuiTextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import { TextField as FMuiTextField, TextFieldProps as FMuiTextFieldProps } from 'formik-material-ui';

export type TextFieldProps = Partial<FMuiTextFieldProps> & Partial<StandardTextFieldProps> & { 'data-test'?: string };

export const TextField = (props: TextFieldProps): JSX.Element => {
  const { field = {}, form } = props;
  const isFormikConnected = field && form;

  const defaultProps = {
    variant: 'standard',
    InputProps: { disableUnderline: true },
    InputLabelProps: { disableAnimation: true, shrink: true },
    'data-test': props['data-test'],
  } as TextFieldProps;

  if (props['no-scroll'] !== undefined && props['no-scroll'] !== null) {
    defaultProps['no-scroll'] = props['no-scroll'];
  }

  if (isFormikConnected) {
    return <FMuiTextField {...defaultProps} {...(props as FMuiTextFieldProps)} />;
  }

  return <MuiTextField {...defaultProps} {...props} />;
};
