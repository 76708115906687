import React from 'react';

import { EnhancedTreeData, TreeData } from 'utils';
import { getOpenSubgroupPredefiendSegments, useAudience } from 'modules/audiences/AudienceProvider';

import { IncludeExcludeDropdown } from '../IncludeExcludeDropdown';

interface Props {
  setValue: (option: TreeData) => void;
  options: EnhancedTreeData[];
  field: string;
}

export const SegmentsDropdown = React.memo((props: Props): JSX.Element | null => {
  const { state } = useAudience();
  const { setValue, options, field } = props;

  const selected = [...getOpenSubgroupPredefiendSegments(state)[field]];

  return (
    <IncludeExcludeDropdown
      label=" "
      onClick={option => {
        setValue(option);
      }}
      options={options}
      selected={selected}
      placeholder="[ Select Segments ]"
      nested
    />
  );
});
