import { ExportType, ReportStatus, HighPrecisionProvider } from 'modules/audiences/AudienceProvider/types';
import { isCustomExportSelected } from '.';

export function isInsightRequestSupported(
  export_type: ExportType = ExportType.OA_MATCH,
  reportStatus: ReportStatus = ReportStatus.COMPLETE,
  direct_audience_provider?: string,
): boolean {
  const customExportSelected = isCustomExportSelected(export_type);

  const validStatuses = [ReportStatus.COMPLETE, ReportStatus.SF_COMPLETE, ReportStatus.SF_PROCESSING];

  // FIXME: Merkle/Choreograph/Epsilon use HP-OAIDs.  We should
  // come back and add a new export type for HP OA Match exports, but
  // for now we'll just detect the provider explicitly.

  const externalizedOaMatchPartner = direct_audience_provider
    ? export_type === ExportType.BIDSTREAM_PLUS &&
      [
        HighPrecisionProvider.MERKLE.valueOf(),
        HighPrecisionProvider.CHOREOGRAPH.valueOf(),
        HighPrecisionProvider.EPSILON.valueOf(),
        HighPrecisionProvider.ANALYTICSIQ.valueOf(),
      ].includes(direct_audience_provider)
    : false;

  const isReportStatusValid = validStatuses.includes(reportStatus);

  return (!customExportSelected || externalizedOaMatchPartner) && isReportStatusValid;
}
