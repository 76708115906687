import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';

import { useAuth } from '../AuthProvider';
import { SsoLayout } from '../components/SsoLayout';
import { EMAIL_FIELD, PASSWORD_FIELD } from './constants';
import { LoginForm } from './LoginForm';
import { validationSchema } from './validationSchema';

export interface FormValues {
  [EMAIL_FIELD]: string;
  [PASSWORD_FIELD]: string;
}

const initialValues = {
  [EMAIL_FIELD]: '',
  [PASSWORD_FIELD]: '',
};

export function Login(): JSX.Element {
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikActions<FormValues>): Promise<void> => {
      try {
        await login({
          email: values[EMAIL_FIELD],
          password: values[PASSWORD_FIELD],
          redir: new URLSearchParams(location.search).get('redir') ?? undefined,
        });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [enqueueSnackbar, location.search, login],
  );

  return (
    <SsoLayout>
      <Typography variant="h2" gutterBottom data-test="login-text">
        Log in
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        component={LoginForm}
      />
    </SsoLayout>
  );
}
