import React, { memo } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { isEqual } from 'lodash';

import { RouteConfig } from './types';
import { PrivateRoute } from './PrivateRoute';

interface RoutesProps {
  routes: RouteConfig[];
}

const RoutesRaw = ({ routes }: RoutesProps): JSX.Element => {
  const renderRoutes = (): JSX.Element[] =>
    routes.map(({ path, exact, component, redirectTo, permissionValidators }: RouteConfig, index) => {
      if (redirectTo) {
        return <Route key={index} exact={exact} path={path as string} render={() => <Redirect to={redirectTo} />} />;
      }

      return (
        <PrivateRoute
          key={index}
          path={path}
          exact={exact}
          component={component}
          permissionValidators={permissionValidators}
        />
      );
    });

  return <Switch>{renderRoutes()}</Switch>;
};

export const Routes = memo(RoutesRaw, (prevProps, nextProps) => isEqual(prevProps, nextProps));
