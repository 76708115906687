import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Tooltip, TextField, theme } from '@openx/components/core';

import { SAME_FEE_INPUT } from './constants';
import { useValidationInterface, Fee, RevenueMethod } from './types';
import { Typography } from '@material-ui/core';

interface StyleProps {
  revenueMethod: RevenueMethod;
  fromEditPage: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  feeField: ({ revenueMethod, fromEditPage }: StyleProps) => ({
    ouline: 'none',
    '& input': {
      paddingLeft: revenueMethod === RevenueMethod.PoM ? theme.spacing(1.5) : theme.spacing(3.5),
    },
    maxWidth: fromEditPage ? 'auto' : '100px',
    '& .MuiInput-underline': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  }),
  startAdornment: {
    position: 'absolute',
    left: '12px',
  },
  endAdornment: {
    position: 'relative',
    right: '20px',
    width: 0,
    marginLeft: 0,
  },
  tooltip: {
    position: 'relative',
    top: '7.5px',
    marginLeft: '14px',
  },
});

interface FeeInputProps {
  fee?: Fee;
  isBulk: boolean;
  audienceId: string;
  handleFeeChange: (value: string, audienceId: string) => void;
  useValidation: useValidationInterface;
  isSameFeeInput: boolean;
  rowIndex: number;
  revenueMethod: RevenueMethod;
  fromEditPage?: boolean;
  inputDisabled?: boolean;
}

export const FeeInput: FC<FeeInputProps> = ({
  fee,
  audienceId,
  isBulk,
  isSameFeeInput,
  handleFeeChange,
  useValidation,
  rowIndex,
  revenueMethod,
  fromEditPage = false,
  inputDisabled = false,
}) => {
  const { feeField, startAdornment, endAdornment, root, tooltip } = useStyles({ revenueMethod, fromEditPage });

  const { setIsFieldTouched, setValidationError, getInputError } = useValidation;

  const handleBlur = useCallback(() => {
    if (isSameFeeInput) {
      setIsFieldTouched(rowIndex, SAME_FEE_INPUT, true);
    } else {
      setIsFieldTouched(rowIndex, audienceId, true);
    }
  }, [audienceId, rowIndex, setIsFieldTouched]);

  const isError = useMemo(
    () => getInputError(rowIndex, isSameFeeInput ? SAME_FEE_INPUT : audienceId),
    [getInputError, rowIndex, audienceId],
  );

  useEffect(() => {
    if (isSameFeeInput) {
      setValidationError(rowIndex, SAME_FEE_INPUT, !fee);
    } else {
      setValidationError(rowIndex, audienceId, !fee);
    }
  }, [fee, rowIndex, audienceId, isSameFeeInput]);

  const tooltipCaption = (
    <div>
      <Typography>
        {revenueMethod === RevenueMethod.PoM
          ? 'Bid CPM is reduced by partner fee % (i.e. $1 CPM bid with 10% fee results in $0.90 bid & $0.10 partner fee)'
          : 'Bid CPM is reduced by the partner fixed CPM fee (i.e. $1.50 CPM and a $0.50 fixed CPM fee results in a $1 bid and a $0.50 partner fee)'}
      </Typography>
      {isBulk && (
        <>
          <br />
          <Typography>The same fee will be applied for all audiences</Typography>
        </>
      )}
    </div>
  );

  const placeholder = {
    [RevenueMethod.PoM]: '0',
    [RevenueMethod.CPM]: '0.00',
  };

  const label = {
    [RevenueMethod.PoM]: 'Fee',
    [RevenueMethod.CPM]: 'CPM',
  };

  return (
    <div className={root}>
      <TextField
        label={label[revenueMethod]}
        margin="none"
        disabled={inputDisabled}
        type="number"
        data-test="fee-input"
        autoComplete="off"
        onChange={({ target: { value } }) => handleFeeChange(value, audienceId)}
        value={fee || ''}
        className={feeField}
        onBlur={handleBlur}
        fullWidth
        inputProps={{ min: 0, max: 99.9, step: '0.1' }}
        InputProps={{
          startAdornment: revenueMethod === RevenueMethod.CPM && (
            <InputAdornment classes={{ root: startAdornment }} position="start">
              $
            </InputAdornment>
          ),
          endAdornment: revenueMethod === RevenueMethod.PoM && (
            <InputAdornment classes={{ root: endAdornment }} position="end">
              %
            </InputAdornment>
          ),
        }}
        placeholder={placeholder[revenueMethod]}
        error={isError}
        required={isError}
      />

      <Tooltip className={tooltip} title={tooltipCaption} iconDataTest="common-fee-tooltip" />
    </div>
  );
};
