import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GetSegmentsQuery, GetSegmentsQueryVariables } from 'types/schemaTypes';
import GetSegments from 'graphql/query/audiences/GetSegments.gql';
import { TreeData, segmentsToTreeData } from 'utils';
import { LocationType } from 'modules/audiences/AudienceProvider';

import { SearchLocationSegmentsParams } from '../graphql/query';

interface ValidateLocationsParam {
  locationType: LocationType;
  search: string[];
}

export function useValidateLocations({
  locationType,
  search = [],
}: ValidateLocationsParam): [TreeData[], string[], boolean] {
  const [valids, setValids] = React.useState<TreeData[]>([]);
  const [invalids, setInvalids] = React.useState<string[]>([]);
  const [validating, setValidating] = React.useState<boolean>(false);

  const { loading, data, refetch } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegments, {
    ...SearchLocationSegmentsParams(search, locationType),
    skip: !search[0],
  });

  useEffect(() => {
    if (search[0]) {
      setValids([]);
      setInvalids([]);
      setValidating(true);
      refetch();
    }
    if (!search[0]) {
      setValidating(false);
    }
  }, [locationType, search, refetch]);

  useEffect(() => {
    if (!data || loading) {
      return;
    }

    const valids = new Set(data.segment.map(({ name }) => name));

    setValids(locationType === LocationType.DMA ? segmentsToTreeData(data.segment) : (data.segment as TreeData[]));

    const invalids = search.filter(value => !valids.has(value));

    setInvalids(invalids);

    setValidating(false);
  }, [data, search, loading]);

  return [valids, invalids, validating];
}
