import React, { FC } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Box, Checkbox, FormControlLabel, Theme, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Paper, StatusIndicator } from '@openx/components/core';

import { mapStatus } from 'modules/audiences/utils';
import { useProviders, DEFAULT_PROVIDER_NAME } from 'modules/audiences/hooks/useProviders';
import { exportTypeLabels } from 'modules/deals/components/AudienceOptions/constants';
import { canSetExportTargets } from 'modules/audiences/utils/';

import {
  useAudience,
  getCTV,
  getDescription,
  getExportType,
  getDirectProvider,
  getStatus,
  getUpdatedDate,
  ErrorCodes,
  getOrganization,
} from 'modules/audiences/AudienceProvider';
import { getExportTargets } from '../../AudienceProvider';

const useStyles = makeStyles<Theme>(theme => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3.8),
  },
  lastEdited: {
    fontWeight: 600,
  },
  spaceBottom: {
    marginBottom: theme.spacing(2),
  },
  errorAlert: {
    background: '#FCEEF2',
    padding: '5px 15px',
    marginBottom: theme.spacing(1.5),
  },
  alertBody: {
    alignItems: 'center',
  },
}));

type Props = {
  errorCode?: string | null;
};

const mapErrorCodeToMessage = {
  [ErrorCodes.SEGMENT_EXPIRED_OR_MISSING]:
    'The audience contains one or more segments that have either expired or not been populated yet. Modify your audience definition before trying again.',
  [ErrorCodes.INVALID_AUDIENCE_NO_INCLUDES]:
    'The audience definition has no included segments. All audiences must specify at least one segment to be included.',
  [ErrorCodes.RATE_LIMIT_EXCEEDED]:
    'Rate limit exceeded: your application is making too many API calls, try again later.',
  [ErrorCodes.INTERNAL_ERROR]:
    'Internal error has occurred, please try again later and contact support if the problem persists.',
};

export const DetailsReadOnly: FC<Props> = ({ errorCode }): JSX.Element => {
  const classes = useStyles();
  const { getProviderName } = useProviders();
  const { state } = useAudience();

  const description = getDescription(state);
  const exportType = getExportType(state);
  const directProvider = getDirectProvider(state);
  const status = getStatus(state);
  const organization = getOrganization(state);
  const updatedDate = getUpdatedDate(state);
  const exportTargets = getExportTargets(state);

  const showExportType = !!directProvider && exportType;
  const showExportTargetsInfo = canSetExportTargets(exportType) && exportTargets.length > 0;
  const statusData = mapStatus(status);
  const parseUpdatedDate = date => moment.utc(date).from(new Date());

  return (
    <Paper>
      <div className={classes.header}>
        <Typography variant="h2" data-test="audience-details-title">
          Audience Details
        </Typography>
        {statusData && <StatusIndicator label={statusData.label} status={statusData.status} />}
      </div>
      {errorCode && (
        <div className={classes.errorAlert}>
          <Alert severity="error" className={classes.alertBody}>
            {mapErrorCodeToMessage[errorCode]}
          </Alert>
        </div>
      )}
      {updatedDate && (
        <Typography className={classNames(classes.lastEdited, classes.spaceBottom)} data-test="last-edited">
          Last Edited: {parseUpdatedDate(updatedDate)}
        </Typography>
      )}
      {description && (
        <Typography className={classes.spaceBottom} data-test="description-name-readonly">
          {description}
        </Typography>
      )}
      <Typography variant="body2" color="textSecondary">
        Organization Name
      </Typography>
      <Typography variant="body1" data-test="organization-name-readonly" className={classes.spaceBottom}>
        {organization?.name}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Provider
      </Typography>
      <Typography variant="body1" data-test="direct-segment-readonly" className={classes.spaceBottom}>
        {getProviderName(directProvider) || DEFAULT_PROVIDER_NAME}
      </Typography>

      {showExportType && (
        <>
          <Typography variant="body2" color="textSecondary">
            Match Type
          </Typography>
          <Typography variant="body1" data-test="match-type-readonly" className={classes.spaceBottom}>
            {exportTypeLabels[exportType!]}
          </Typography>
        </>
      )}

      {showExportTargetsInfo && (
        <Box mt={2}>
          <Typography variant="h3" data-test="target-options-title-readonly">
            Target ID Types
          </Typography>
          <Box display="flex" mt={1} data-test="export-targets-readonly">
            {exportTargets.includes('cookie') && (
              <FormControlLabel
                label="Cookies"
                control={<Checkbox value="cookie" checked disabled data-test="cookie-checkbox-readonly" />}
              />
            )}
            {exportTargets.includes('device') && (
              <FormControlLabel
                label="MAIDs"
                control={<Checkbox value="device" checked disabled data-test="device-checkbox-readonly" />}
              />
            )}
            {exportTargets.includes('idl') && (
              <FormControlLabel
                label="RampID"
                control={<Checkbox value="idl" checked disabled data-test="idl-checkbox-readonly" />}
              />
            )}
            {exportTargets.includes('ctv') && (
              <FormControlLabel
                label="CTV"
                control={<Checkbox value="ctv" checked disabled data-test="ctv-checkbox-readonly" />}
              />
            )}
            {exportTargets.includes('ip') && (
              <FormControlLabel
                label="IP Address"
                control={<Checkbox value="ip" checked disabled data-test="ip-checkbox-readonly" />}
              />
            )}
            {exportTargets.includes('id5') && (
              <FormControlLabel
                label="ID5"
                control={<Checkbox value="id5" checked disabled data-test="id5-checkbox-readonly" />}
              />
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};
