import React, { useCallback, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

import { Paper } from '@openx/components/core';
import { Table, SortDirection, AllTableCriteria, applyCriteriaChange } from '@openx/components/table';
import { Provider } from 'types/schemaTypes';
import { TOP_BAR_HEIGHT } from 'config';

import { linkedProviders as tableColumns } from './tableColumns';

export type LinkedProvidersRow = Pick<Provider, 'id' | 'name' | 'allowed_export_types'>;

interface Props {
  linkedProviders: LinkedProvidersRow[];
  loading: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
    overflow: 'hidden',
  },
}));

const initialCriteria: AllTableCriteria = {
  sort: {
    column: 'name',
    direction: SortDirection.ASC,
    sortableColumns: ['name', 'oa_match', 'bidstream', 'bidstream_plus'],
  },
};

export const LinkedProviders = ({ linkedProviders, loading }: Props) => {
  const { root } = useStyles();
  const [criteria, setCriteria] = useState<AllTableCriteria>(initialCriteria);
  const handleCriteriaChange = useCallback(
    newCriteria => {
      setCriteria(applyCriteriaChange(criteria, newCriteria));
    },
    [criteria],
  );

  const showTable = (linkedProviders.length < 1 && loading) || (linkedProviders.length > 0 && !loading);

  return (
    <Paper className={root}>
      <Box ml={3}>
        <Typography variant="h2" data-test="linked-providers-title" gutterBottom>
          Linked Providers
        </Typography>
      </Box>
      <Box>
        {showTable ? (
          <Table
            data-test="provider-table"
            data={linkedProviders}
            columns={tableColumns}
            loading={loading}
            stickyHeaderPosition={TOP_BAR_HEIGHT}
            onCriteriaChange={handleCriteriaChange}
            criteria={criteria}
            fixedLayout
          />
        ) : (
          <Box margin="20px">
            <Typography variant="body2" color="textSecondary" data-test="no-linked-providers-message">
              There are no providers linked to this organization.
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
