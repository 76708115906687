import React from 'react';

import { AutoComplete } from '@openx/components/core';
import { LocationType } from 'modules/audiences/AudienceProvider';

interface Props {
  onChange: (type: LocationType) => void;
  initValue: LocationType;
  forBulkUpload?: boolean;
}

export function LocationTypeDropdown(props: Props): JSX.Element {
  const { onChange, initValue, forBulkUpload } = props;

  const mapTypeToActionLabel = {
    [LocationType.STATE]: 'State',
    [LocationType.DMA]: 'DMA',
    [LocationType.ZIP]: 'Zip code',
    [LocationType.CONGRESSIONAL]: 'Congressional District',
  };

  const options = forBulkUpload
    ? [LocationType.DMA, LocationType.ZIP]
    : [LocationType.STATE, LocationType.DMA, LocationType.ZIP, LocationType.CONGRESSIONAL];

  return (
    <AutoComplete
      options={options}
      getOptionLabel={option => mapTypeToActionLabel[option]}
      textFieldReadOnly
      disableClearable
      filterOptions={option => option}
      onChange={(_, value) => onChange(value)}
      value={initValue}
    />
  );
}
