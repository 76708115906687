import React, { ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { BaseRow } from '../tableHelpers';
import { BulkAction } from './type';
import { BulkActionsButtons } from './BulkActionsButtons';
import { CustomManagementSection } from '../Table';

export interface SelectionManagementPanelProps<RowT extends BaseRow> {
  loading: boolean;
  allItems: BaseRow[];
  selectedItems: RowT[];
  bulkActions?: BulkAction<RowT>[];
  CustomManagementSection?: CustomManagementSection;
}

export const SelectionManagementPanel = <RowT extends BaseRow>(
  props: SelectionManagementPanelProps<RowT>,
): CustomManagementSection => {
  const { selectedItems, bulkActions = [], CustomManagementSection } = props;

  return ({ type }) => (
    <Grid container alignItems="center">
      {CustomManagementSection ? (
        <CustomManagementSection type={type} />
      ) : (
        <>
          <BulkActionsButtons bulkActions={bulkActions} selectedItems={selectedItems} />
          <Typography variant="body1" data-test="selected-items-label">
            Selected {selectedItems.length} item{selectedItems.length !== 1 && 's'}
          </Typography>
        </>
      )}
    </Grid>
  );
};
