import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormikHelpers as FormikActions } from 'formik';

import { PageHeader } from '@openx/components/header';
import { Loader, Paper } from '@openx/components/core';
import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';

import { ActionBar } from 'components/ActionBar';
import {
  CreateUserUnderParentAccountMutation,
  CreateUserUnderParentAccountMutationVariables,
  GetOrganizationsListQuery,
  GetOrganizationsListQueryVariables,
  CreateUserUnderRootAccountMutation,
  CreateUserUnderRootAccountMutationVariables,
} from 'types/schemaTypes';
import GetOrganizationsList from 'graphql/query/organizations/GetOrganizationsList.gql';
import CreateUserUnderParentAccount from 'graphql/mutation/users/CreateUserUnderParentAccount.gql';
import CreateUserUnderRootAccount from 'graphql/mutation/users/CreateUserUnderRootAccount.gql';

import { Form, FormValues } from '../components/Form';
import { OPEN_AUDIENCE_NAME } from '../components/Form/constants';
import { useSideDrawer } from 'context';

export function UserCreate() {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    loading: organizationsLoading,
    error: organizationsError,
    data: { account: organizations = [] } = {},
  } = useQuery<GetOrganizationsListQuery, GetOrganizationsListQueryVariables>(GetOrganizationsList);

  const [createUserUnderParentAccount, { loading: parentAccountLoading }] = useMutation<
    CreateUserUnderParentAccountMutation,
    CreateUserUnderParentAccountMutationVariables
  >(CreateUserUnderParentAccount, {
    refetchQueries: ['GetUsersList'],
  });

  const [createUserUnderRootAccount, { loading: rootAccountLoading }] = useMutation<
    CreateUserUnderRootAccountMutation,
    CreateUserUnderRootAccountMutationVariables
  >(CreateUserUnderRootAccount, {
    refetchQueries: ['GetUsersList'],
  });

  const initialValues: FormValues = useMemo(
    () => ({
      firstName: '',
      lastName: '',
      email: '',
      parentOrganizationId: organizations.find(organization => organization.name === OPEN_AUDIENCE_NAME)?.id || '',
    }),
    [organizations],
  );

  const handleSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    const { firstName, lastName, email, parentOrganizationId } = values;

    try {
      const openAudienceId = organizations.find(organization => organization.name === OPEN_AUDIENCE_NAME)?.id;

      const creatingUnderRootAccount = [null, '', openAudienceId].includes(parentOrganizationId);

      const createUser = creatingUnderRootAccount ? createUserUnderRootAccount : createUserUnderParentAccount;

      await createUser({
        variables: {
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          email_address: email,
          parent_account_id: parentOrganizationId,
        },
      });

      history.push('/users');
      enqueueSnackbar('User created successfully', { variant: 'success' });
    } finally {
      actions.setSubmitting(false);
    }
  };

  if (organizationsLoading || parentAccountLoading || rootAccountLoading) {
    return (
      <Paper>
        <Loader />
      </Paper>
    );
  }

  if (organizationsError) {
    throw new Error('Could not get data from the server');
  }

  return (
    <ActionBar onGoBack={() => history.push('/users')}>
      <PageHeader
        title="Create new User"
        titlePrefix="OpenAudience"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      />
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <Paper>
            <Form
              initialValues={initialValues}
              loading={organizationsLoading}
              onSubmit={handleSubmit}
              organizations={organizations}
            />
          </Paper>
        </Grid>
      </Grid>
    </ActionBar>
  );
}
