import React, { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useQuery, useLazyQuery } from '@apollo/client';
import { AutoCompleteVirtualize } from '@openx/components/core';
import { PropTypes } from '@material-ui/core';

import GetAudienceTemplates from 'graphql/query/audiences/GetAudienceTemplates.gql';
import GetAudience from 'graphql/query/audiences/GetAudience.gql';
import {
  GetAudienceTemplatesQuery,
  GetAudienceTemplatesQueryVariables,
  GetAudienceQuery,
  GetAudienceQueryVariables,
  Audience,
} from 'types/schemaTypes';
import { useAudience, ActionType, fields } from 'modules/audiences/AudienceProvider';
import { AudienceStatus, UNSUPPORTED_STATUSES_FOR_DEAL } from 'modules/audiences/constants';
import { useProviders, useDemographicsOptions, defaultAgeRange } from 'modules/audiences/hooks';

import { mapAudienceToProvider } from '../../utils';

const { NORMALIZED_DEFINITION_FIELD } = fields;

export interface AudienceTemplatePickerProps {
  value?: Audience['id'] | null;
  onChange?: (audienceId: Audience['id'], audienceName: Audience['name']) => void;
  margin?: PropTypes.Margin;
  placeholder?: string;
  onlyReadyAudiences?: boolean;
  skipFetchingAudience?: boolean;
}

export function AudienceTemplatePicker({
  value,
  onChange = () => {},
  margin,
  placeholder = '[ Load existing Custom Audience as template ]',
  onlyReadyAudiences,
  skipFetchingAudience,
}: AudienceTemplatePickerProps): JSX.Element {
  const { dispatch } = useAudience();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTemplate, setSelectedTemplate] = useState<(Partial<Audience> & { id: string; name: string }) | null>(
    null,
  );
  const { providers } = useProviders();
  const { rangeToSegments } = useDemographicsOptions();
  const variables = onlyReadyAudiences
    ? {
        status: { _eq: AudienceStatus.EXPORTED },
        // we still need to show current audience even in case it is not exported yet
        id: { _in: value ? [value] : [] },
      }
    : {
        status: {
          _nin: UNSUPPORTED_STATUSES_FOR_DEAL,
        },
        id: { _in: [] },
      };

  const {
    loading: templateLoading,
    error: templateError,
    data: { audience: templates = [] } = {},
  } = useQuery<GetAudienceTemplatesQuery, GetAudienceTemplatesQueryVariables>(GetAudienceTemplates, {
    variables: variables,
  });
  const [getAudience, { error: audienceError, data: audienceData, loading: audienceLoading }] = useLazyQuery<
    GetAudienceQuery,
    GetAudienceQueryVariables
  >(GetAudience);

  const onTemplateChange = useCallback(
    template => {
      if (!template) {
        dispatch({ type: ActionType.RESET_AUDIENCE });
        setSelectedTemplate(null);
        onChange(null, '');
        return;
      }

      setSelectedTemplate(template);
      onChange(template.id, template.name);
    },
    [dispatch, onChange],
  );

  useEffect(() => {
    const audience = audienceData?.audience_by_pk;

    if (audienceLoading || !audience || !selectedTemplate) return;

    dispatch({
      type: ActionType.SET_AUDIENCE,
      payload: mapAudienceToProvider(audience, providers),
    });
  }, [audienceData, audienceLoading, dispatch, providers, selectedTemplate, rangeToSegments]);

  useEffect(() => {
    if (templateLoading || value === undefined) return;

    const nextTemplate = value === null ? null : templates.find(({ id }) => id === value);

    if (nextTemplate === undefined) {
      throw new Error('Provided audience is not available');
    }

    setSelectedTemplate(nextTemplate);
  }, [value, getAudience, templates, templateLoading]);

  useEffect(() => {
    if (!selectedTemplate || skipFetchingAudience) return;

    getAudience({ variables: { id: selectedTemplate.id } });
  }, [getAudience, selectedTemplate, skipFetchingAudience]);

  if (templateError) {
    throw new Error('Query Error');
  }

  if (audienceError) {
    enqueueSnackbar('Failed to use existing Audience', { variant: 'error' });
  }

  return (
    <AutoCompleteVirtualize
      data-test="audience-template"
      getOptionLabel={({ name }) => name || ''}
      options={templates}
      value={selectedTemplate}
      textFieldProps={{
        placeholder,
        label: 'Choose Audience',
        margin,
      }}
      loading={templateLoading || audienceLoading}
      onChange={(e, template) => onTemplateChange(template)}
    />
  );
}
