import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import { Button } from '@openx/components/core';

import { BaseRow } from '../tableHelpers';
import { BulkAction } from './type';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginRight: theme.spacing(1),
  },
}));

interface BulkActionsButtonsProps<RowT extends BaseRow> {
  bulkActions: BulkAction<RowT>[];
  selectedItems: RowT[];
}

export function BulkActionsButtons<RowT extends BaseRow>({
  bulkActions,
  selectedItems,
}: BulkActionsButtonsProps<RowT>): JSX.Element {
  const { root } = useStyles();

  function createActionButton({ action, label, allowed = true }: BulkAction<RowT>, index: number): JSX.Element {
    return (
      <Button
        key={index}
        color="default"
        onClick={() => action(selectedItems)}
        disabled={selectedItems.length === 0}
        classes={{ root }}
        data-test="bulk-operation-button"
        allowed={allowed}
      >
        {label}
      </Button>
    );
  }

  return <>{bulkActions.map(createActionButton)}</>;
}
