import { useEffect, useState, SetStateAction, Dispatch } from 'react';
import { useHistory } from 'react-router-dom';

export interface SupportedParams {
  statuses?: string[];
  providers?: string[];
  search?: string;
  accounts?: string[];
  categories?: string[];
  sub_categories?: string[];
  types?: string[];
  sources?: string[];
}

export interface UseGlobalFiltersResponse {
  selectedStatuses: string[];
  selectedProviders: string[];
  selectedAccounts: string[];
  selectedCategories: string[];
  selectedSubCategories: string[];
  selectedTypes: string[];
  selectedSources: string[];
  searchPhrase: string;
  setSelectedStatuses: Dispatch<SetStateAction<string[]>>;
  setSelectedProviders: Dispatch<SetStateAction<string[]>>;
  setSelectedAccounts: Dispatch<SetStateAction<string[]>>;
  setSelectedCategories: Dispatch<SetStateAction<string[]>>;
  setSelectedSubCategories: Dispatch<SetStateAction<string[]>>;
  setSelectedTypes: Dispatch<SetStateAction<string[]>>;
  setSelectedSources: Dispatch<SetStateAction<string[]>>;
  setSearchPhrase: Dispatch<SetStateAction<string>>;
}

const paramsWithArrayValue = ['statuses', 'providers', 'accounts', 'categories', 'sub_categories', 'types', 'sources'];

const getParameters = (): SupportedParams => {
  const parameters = window.location.search.replace('?', '');

  if (Object.keys(parameters).length) {
    const parsedParameters = parameters.split('&');

    return parsedParameters.reduce((acc, cur) => {
      const [param, value] = cur.split('=');

      return {
        ...acc,
        [param]: paramsWithArrayValue.includes(param) ? value.split(',') : value,
      };
    }, {});
  }

  return {};
};

const arrayToString = (array: string[]): string => array.join(',');

const prepareParams = ({
  search,
  statuses = [],
  providers = [],
  accounts = [],
  categories = [],
  sub_categories = [],
  types = [],
  sources = [],
}: SupportedParams): string => {
  if (
    !search &&
    statuses.length === 0 &&
    providers.length === 0 &&
    accounts.length === 0 &&
    categories.length === 0 &&
    sub_categories.length === 0 &&
    types.length === 0 &&
    sources.length === 0
  )
    return '';

  const params = [
    !!search && `search=${encodeURIComponent(search)}`,
    statuses.length !== 0 && `statuses=${arrayToString(statuses)}`,
    providers.length !== 0 && `providers=${arrayToString(providers)}`,
    accounts.length !== 0 && `accounts=${arrayToString(accounts)}`,
    categories.length !== 0 && `categories=${arrayToString(categories)}`,
    sub_categories.length !== 0 && `sub_categories=${arrayToString(sub_categories)}`,
    types.length !== 0 && `types=${arrayToString(types)}`,
    sources.length !== 0 && `sources=${arrayToString(sources)}`,
  ];

  const paramsString = params.filter(param => !!param).join('&');

  return `?${paramsString}`;
};

export const useGlobalFilters = (): UseGlobalFiltersResponse => {
  const history = useHistory();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);

  useEffect(() => {
    const { search, statuses, providers, accounts, categories, sub_categories, types, sources } = getParameters();

    if (search) {
      setSearchPhrase(decodeURIComponent(search));
    }

    if (statuses) {
      setSelectedStatuses(statuses.map(status => decodeURIComponent(status)));
    }

    if (providers) {
      setSelectedProviders(providers.map(provider => decodeURIComponent(provider)));
    }

    if (accounts) {
      setSelectedAccounts(accounts);
    }

    if (categories) {
      setSelectedCategories(categories);
    }
    if (sub_categories) {
      setSelectedSubCategories(sub_categories);
    }
    if (types) {
      setSelectedTypes(types);
    }
    if (sources) {
      setSelectedSources(sources);
    }
  }, []);

  useEffect(() => {
    const { pathname } = window.location;

    if (
      searchPhrase ||
      selectedStatuses.length ||
      selectedProviders.length ||
      selectedAccounts.length ||
      selectedCategories.length ||
      selectedSubCategories.length ||
      selectedTypes.length ||
      selectedTypes.length
    ) {
      history.push(
        `${pathname}${prepareParams({
          search: searchPhrase,
          statuses: selectedStatuses,
          providers: selectedProviders,
          accounts: selectedAccounts,
          categories: selectedCategories,
          sub_categories: selectedSubCategories,
          types: selectedTypes,
          sources: selectedSources,
        })}`,
      );
    } else {
      history.push(pathname);
    }
  }, [
    selectedStatuses,
    selectedProviders,
    searchPhrase,
    selectedAccounts,
    selectedCategories,
    selectedSubCategories,
    selectedTypes,
    selectedSources,
  ]);

  return {
    selectedStatuses,
    selectedProviders,
    selectedAccounts,
    selectedCategories,
    selectedSubCategories,
    selectedTypes,
    selectedSources,
    searchPhrase,
    setSelectedStatuses,
    setSelectedProviders,
    setSearchPhrase,
    setSelectedCategories,
    setSelectedSubCategories,
    setSelectedTypes,
    setSelectedSources,
    setSelectedAccounts,
  };
};
