import React from 'react';
import { Grid } from '@material-ui/core';
import { Button, ButtonColor, ButtonVariant } from '@openx/components/core';

export interface ActionButtonProps {
  label: string;
  color: ButtonColor;
  variant?: ButtonVariant;
  onClick?: () => void;
  href?: string;
  isAllowed?: boolean;
  loading?: boolean;
  disabled?: boolean;
  notAllowedMessage?: string;
  'data-test'?: string;
}

export function ActionButton(props: ActionButtonProps): JSX.Element {
  const { isAllowed = true, label, loading = false, ...restProps } = props;

  return (
    <Grid item>
      <Button data-test={props['data-test'] || 'action-button'} allowed={isAllowed} loading={loading} {...restProps}>
        {label}
      </Button>
    </Grid>
  );
}
