import { isEmpty } from 'lodash';

export function sortRecursive<T extends { name: string; children?: { name: string }[] }>(tree: T[]): T[] {
  return tree
    .map(item => {
      if (isEmpty(item.children)) return item;
      return {
        ...item,
        children: sortRecursive(item.children!),
      };
    })
    .sort((a, b) => {
      return a.name.localeCompare(b.name, undefined, { numeric: true });
    });
}
