import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { NavLink } from 'react-router-dom';
import { ImpersonationBar, UserPanel, UserPanelProps } from '@openx/components/core';

import { Logo } from 'components/Logo';

import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useSideDrawer } from 'context';
import { useVersionRedirect } from 'modules/auth/AuthProvider/useVersionRedirect';
import { useSession } from 'modules/auth/AuthProvider';

interface StyleProps {
  topHeight: number;
}

const useStyles = makeStyles(theme => ({
  header: ({ topHeight }: StyleProps) => ({
    height: topHeight,
    position: 'sticky',
    zIndex: 4,
    top: 0,
    left: 0,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
    padding: theme.spacing(0, 4),
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
  logo: {
    width: 170,
    display: 'flex',
  },
  topMenu: {
    marginBottom: -1,
  },
  userContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  placeholder: ({ topHeight }: StyleProps) => ({
    paddingTop: topHeight,
  }),
  menuIcon: {
    position: 'relative',
  },
  logoWrapper: {
    width: 208,
  },
  menuIconWrapper: {
    borderRight: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
}));

export interface NavigationBarProps extends Partial<StyleProps> {
  disableMenu?: boolean;
  userPanelProps?: UserPanelProps;
}

export function NavigationBar(props: NavigationBarProps): JSX.Element {
  const { disableMenu, userPanelProps, topHeight = 54 } = props;
  const classes = useStyles({ topHeight });
  const { handleDrawerOpen } = useSideDrawer();
  const { isV2UIAllowed, setRedirectionFlag } = useVersionRedirect();

  const session = useSession();

  const isImpersonationBarVisible = window.location.pathname === '/deals' && isV2UIAllowed(session);

  return (
    <>
      {isImpersonationBarVisible && <ImpersonationBar handleButtonClick={setRedirectionFlag} />}
      <header className={classes.header}>
        <div className={classes.logoWrapper}>
          <NavLink to="/" className={classes.logo}>
            <Logo darkMode={true} openAudience={true} data-test="logo" />
          </NavLink>
        </div>
        {!disableMenu && (
          <div className={classes.menuIconWrapper} data-test="menu">
            <IconButton size="medium" color="inherit" className={classes.menuIcon} onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>
          </div>
        )}
        {userPanelProps && (
          <div className={classes.userContainer}>
            <UserPanel {...userPanelProps} />
          </div>
        )}
      </header>
    </>
  );
}
