import { useHistory } from 'react-router-dom';

interface HistoryState {
  ref?: string;
}

/**
 * Automatically add the `ref` to history state
 * `ref` can be used to determine whether the page was opened from the application or directly from the browser.
 * This information may be necessary before calling `goBack`, since those who came from the browser will leave the app
 */
export const useHistoryWithRef = <HistoryLocationState extends HistoryState>() => {
  const history = useHistory<HistoryLocationState>();

  const push = (location: string, state = {} as HistoryLocationState) => {
    const ref = history.location.pathname + history.location.search;

    history.push(location, { ref, ...state });
  };

  return {
    ...history,
    push,
  };
};
