import React, { useMemo } from 'react';
import { Theme, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { defaultPadding, paddingMap } from './config';
import { makeIndentation } from './Indentation';
import { HighlightedSectionProps, PaperPadding, StylesProps } from './types';

const useStyles = makeStyles<Theme, StylesProps>(theme => ({
  body: {
    background: theme.palette.background.boxLight,
    padding: ({ padding = defaultPadding }) => paddingMap[padding],
  },
}));

export const makeHighlightedSection = (padding: PaperPadding = defaultPadding) =>
  function ({ children, className }: HighlightedSectionProps): JSX.Element {
    const classes = useStyles({ padding });
    const Indentation = useMemo(() => makeIndentation(padding), []);

    return (
      <Indentation className={className}>
        <Divider />
        <div className={classes.body}>{children}</div>
        <Divider />
      </Indentation>
    );
  };
