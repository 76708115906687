import React, { useCallback, useState, memo, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Theme, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { isEqual } from 'lodash';

import { ConfirmationDialog } from '@openx/components/core';
import { Table } from '@openx/components/tablev2';

import { getCurrentUser } from 'firebaseIntegration/utils';
import {
  GetSegmentsWithLinkedAudiencesQuery,
  GetSegmentsWithLinkedAudiencesQueryVariables,
  UpdateSegmentMutation,
  UpdateSegmentMutationVariables,
} from 'types/schemaTypes';

import UpdateSegment from 'graphql/mutation/segments/UpdateSegment.gql';
import createLog from 'graphql/mutation/error/createLog.gql';
import GetSegmentsWithLinkedAudiences from 'graphql/query/segments/GetSegmentsWithLinkedAudiences.gql';

import { associatedAudiencesTableColumns, confirmationDialogTableColumns } from './tableColumns';
import { SegmentRow } from './SegmentsList';

const useStyles = makeStyles<Theme>(theme => ({
  audiencesTableWraper: {
    background: theme.palette.background.black03,

    '& .MuiTableCell-head': {
      color: theme.palette.text.secondary,
      fontSize: '12px',
      lineHeight: '22px',
      padding: '4px 0',
    },

    '& .MuiTableCell-body': {
      padding: '8px 0',
    },
  },
  boldText: {
    fontWeight: 600,
  },
  contentWrapper: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  segmentsTableWraper: {
    maxHeight: 344,
    overflowY: 'scroll',
  },
  warningAlert: {
    backgroundColor: '#FDF9F0',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    margin: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.5),
  },
  warningText: {
    color: theme.palette.text.primary,
    padding: 0,
  },
}));

interface Props {
  data?: SegmentRow[];
  onClose: (success: boolean) => void;
  open: boolean;
}

function ArchiveConfirmationDialogRaw({ data = [], onClose, open }: Props): JSX.Element {
  const { audiencesTableWraper, boldText, contentWrapper, segmentsTableWraper, warningAlert, warningText } =
    useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const segmentIds = useMemo(() => data.map(segment => segment.id), [data]);

  const {
    loading: segmentsLoading,
    data: { segments = [] } = {},
    refetch,
  } = useQuery<GetSegmentsWithLinkedAudiencesQuery, GetSegmentsWithLinkedAudiencesQueryVariables>(
    GetSegmentsWithLinkedAudiences,
    {
      variables: {
        segmentIds,
      },
    },
  );

  const [pushLog] = useMutation(createLog);
  const [updateSegment] = useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(UpdateSegment);

  const hasAssociatedAudiences = useMemo(() => segments.some(segment => segment.segment_audiences.length), [segments]);

  const onConfirmed = useCallback(async () => {
    setLoading(true);
    try {
      const { errors } = await updateSegment({
        variables: {
          where: { id: { _in: segmentIds } },
          set: { is_archived: true },
        },
      });
      if (errors) throw new Error(errors.join(', '));
      enqueueSnackbar(`Segment${data.length > 1 ? 's' : ''} successfully archived`, { variant: 'success' });
      onClose(true);
    } catch (e) {
      enqueueSnackbar(`Failed to archive segment${data.length > 1 ? 's' : ''}`, { variant: 'error' });

      const location = window.location.href;
      const user = await getCurrentUser();
      await pushLog({
        variables: {
          type: 'error',
          location: location,
          msg: `Failed to archive segment${data.length > 1 ? 's' : ''}`,
          name: user?.displayName,
          email: user?.email,
        },
      });
    }
    setLoading(false);
  }, [data, enqueueSnackbar, onClose, pushLog, updateSegment]);

  const onCancel = useCallback(() => {
    if (loading) return;
    onClose(false);
  }, [loading, onClose]);

  const AlertText = () => (
    <>
      <Typography className={boldText} variant="body1" component="span">
        Segments
      </Typography>{' '}
      you're trying to archive are currently linked to{' '}
      <Typography className={boldText} variant="body1" component="span">
        Audiences
      </Typography>
      . Please remove these associations{' '}
      <Typography className={boldText} variant="body1" component="span">
        before
      </Typography>{' '}
      proceeding with the archive.
    </>
  );

  return (
    <ConfirmationDialog
      isOpen={open}
      title="Archive Segments"
      confirmLabel="Archive"
      onCancel={onCancel}
      onConfirmed={onConfirmed}
      customContent={true}
      confirmationDisabled={hasAssociatedAudiences}
      isLoading={loading || segmentsLoading}
      confirmationButtonColor="danger"
      refreshButton={true}
      refreshProps={{
        onClick: () => refetch(),
      }}
      data-test="confirmation-dialog"
    >
      <Box className={contentWrapper} data-test="confirmation-dialog-content">
        {hasAssociatedAudiences && (
          <Box data-test="confirmation-dialog-alert" className={warningAlert}>
            <Alert className={warningText} severity="warning" data-test="alert-message">
              <AlertText />
            </Alert>
          </Box>
        )}

        <Box className={segmentsTableWraper}>
          <Table
            columns={confirmationDialogTableColumns}
            data-test="confirmation-dialog-table"
            data={segments}
            enableTableHeaderCustomziation={false}
            expandComponent={({ data }) => {
              const { segment_audiences: segmentAudiences } = data;
              const associatedAudiences = segmentAudiences.map(segmentAudience => segmentAudience.audience);

              return (
                <Box className={audiencesTableWraper}>
                  {associatedAudiences.length ? (
                    <Table
                      columns={associatedAudiencesTableColumns}
                      data-test="associated-audiences-dialog-table"
                      data={associatedAudiences}
                      enableTableHeaderCustomziation={false}
                      loading={false}
                    />
                  ) : (
                    <Box
                      data-test="associated-audiences-dialog-table-placeholder"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height={36}
                    >
                      <Typography
                        variant="h3"
                        component="span"
                        color="textSecondary"
                        style={{
                          fontSize: 12,
                          textTransform: 'uppercase',
                        }}
                      >
                        No Audiences Found
                      </Typography>
                    </Box>
                  )}
                </Box>
              );
            }}
            loading={segmentsLoading}
          />
        </Box>
      </Box>
    </ConfirmationDialog>
  );
}

export const ArchiveConfirmationDialog = memo(ArchiveConfirmationDialogRaw, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
