import React from 'react';
import { Columns } from '@openx/components/table';

import { DateCell } from 'components/DateCell';

import { OrganizationRow } from './OrganizationsList';
import { mapOfAllowedCountriesToName } from '../constants';

export const tableColumns: Columns<OrganizationRow> = [
  {
    title: 'Name',
    key: 'name',
    width: '40%',
    ellipsisMaxWidth: '400px',
    ellipsis: true,
  },
  {
    title: 'Parent Organization',
    key: 'parent_account',
    width: '20%',
    render: ({ parent_account }) => (parent_account ? <span>{parent_account.name}</span> : null),
  },
  {
    title: 'Date Joined',
    key: 'created_date',
    width: '20%',
    render: ({ created_date }) => <DateCell date={created_date} />,
  },
  {
    title: 'Allowed Countries',
    key: 'allowed_countries',
    width: '20%',
    render: ({ allowed_countries = [] }) =>
      allowed_countries.map(country => mapOfAllowedCountriesToName[country] || country).join(', '),
  },
];
