import React, { useCallback, memo } from 'react';
import { useMutation } from '@apollo/client';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Formik, Field } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import { isEqual } from 'lodash';

import UpdateSegment from 'graphql/mutation/segments/UpdateSegment.gql';
import { Segment, UpdateSegmentMutation, UpdateSegmentMutationVariables } from 'types/schemaTypes';
import { extractMessageFromError } from 'utils/extractMessageFromError';
import { useSession } from 'modules/auth/AuthProvider';

import { Button, DialogTitle, TextField } from '@openx/components/core';
import { getCurrentUser } from 'firebaseIntegration/utils';
import createLog from 'graphql/mutation/error/createLog.gql';

import { createFullName } from './utils';
interface Props {
  onClose: (success: boolean) => void;
  segment: Segment;
}

const RenameDialogRaw = ({ onClose, segment }: Props): JSX.Element => {
  const { id: userId } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [pushLog] = useMutation(createLog);
  const [updateSegment] = useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(UpdateSegment);

  const onSubmit = useCallback(
    async ({ name }, actions) => {
      try {
        await updateSegment({
          variables: {
            where: { id: { _eq: segment.id } },
            set: { name, full_name: createFullName(name) },
          },
        });
        enqueueSnackbar('Segment successfully renamed', { variant: 'success' });
        onClose(true);
      } catch (error) {
        actions.setSubmitting(false);
        const message = extractMessageFromError(error);
        actions.setFieldError('name', message);
        const location = window.location.href;
        const user = await getCurrentUser();
        await pushLog({
          variables: {
            type: error,
            location: location,
            msg: message,
            name: user?.displayName,
            email: user?.email,
          },
        });
        enqueueSnackbar('Failed to rename segment', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, onClose, pushLog, segment.id, updateSegment],
  );

  const onCancel = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <Dialog open={true}>
      <DialogTitle onClose={onCancel}>Rename Segment</DialogTitle>
      <Formik
        onSubmit={onSubmit}
        initialValues={{ name: segment.name }}
        validationSchema={yup.object().shape({
          name: yup
            .string()
            .required('Name cannot be empty')
            .notOneOf([segment.name], 'New name has to be different than current'),
        })}
      >
        {({ submitForm, isValid, isSubmitting, dirty }) => (
          <>
            <DialogContent dividers data-test="dialog-content">
              <Field name="name" component={TextField} label="New name" fullWidth autoFocus />
            </DialogContent>
            <DialogActions data-test="dialog-actions">
              <Button onClick={onCancel} variant="text" disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={submitForm}
                disabled={!isValid || !dirty || isSubmitting}
                loading={isSubmitting}
              >
                Rename
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export const RenameDialog = memo(RenameDialogRaw, (prevProps, nextProps) => isEqual(prevProps, nextProps));
