import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { Columns, TableColumn } from '@openx/components/table';
import { Ellipsis, StatusIndicator } from '@openx/components/core';
import { mapStatus as mapSegmentStatus } from './utils';
import { abbreviatedNumber } from '@openx/utils';

import { DateCell } from 'components/DateCell';
import { mapStatus as mapAudienceStatus } from 'modules/audiences/utils';

import { ReachTooltip } from './ReachTooltip';
import { SegmentRow } from './SegmentsList';
import { NameCell, SegmentCell } from './components';
import { AssociatedAudienceTableRow, ConfirmationTableSegmentRow } from './types';

const tableNameColumn: TableColumn<SegmentRow> = {
  title: 'Name',
  key: 'name',
  style: {
    paddingTop: '13px',
    paddingBottom: '13px',
  },
  render: ({ name, expiration_date, status }) => {
    if (!name) return null;

    return <SegmentCell name={name} estimatedExpiryDate={expiration_date} status={status} />;
  },
};

const tableReachColumn: TableColumn<SegmentRow> = {
  title: 'Segment Reach',
  key: 'reach',
  render: ({ id, reach }) =>
    reach ? (
      <div style={{ display: 'flex' }}>
        <span>{abbreviatedNumber(reach)}</span>
        <ReachTooltip id={id} reach={reach} />
      </div>
    ) : null,
};

const tableCreatedDateColumn: TableColumn<SegmentRow> = {
  title: 'Date Created',
  key: 'created_date',
  render: ({ created_date }) => <DateCell date={created_date} />,
};

const tableUpdatedDateColumn: TableColumn<SegmentRow> = {
  title: 'Date Updated',
  key: 'updated_date',
  render: ({ updated_date }) => <DateCell date={updated_date} />,
};

const confirmationTableNameColumn: TableColumn<ConfirmationTableSegmentRow> = {
  title: 'Segment Name',
  key: 'name',
  style: {
    padding: '8px 4px',
    paddingLeft: 0,
  },
  render: data => <NameCell data={data} />,
};

const confirmationtableLinkedAudiencesColumn: TableColumn<ConfirmationTableSegmentRow> = {
  title: 'Linked Audiences',
  key: 'segment_audiences',
  style: {
    padding: '8px 4px',
  },
  render: ({ segment_audiences }) => {
    return <SegmentCell name={segment_audiences.length} />;
  },
};

const audienceNameTableColumn: TableColumn<AssociatedAudienceTableRow> = {
  title: 'Audience Name',
  key: 'name',
  style: {
    height: 'max-content',
    paddingLeft: '80px',
  },
  render: data => {
    return (
      <Link to={`/audiences/${data.id}`} target="_blank">
        <Typography
          variant="body1"
          component="span"
          color="primary"
          data-test="segment-name"
          style={{ fontWeight: 600 }}
        >
          <Ellipsis tooltip width={400}>
            {data.name}
          </Ellipsis>
        </Typography>
      </Link>
    );
  },
};

const audienceStatusTableColumn: TableColumn<AssociatedAudienceTableRow> = {
  title: 'Status',
  key: 'status',
  style: {
    height: 'max-content',
    padding: '4px',
  },
  render: ({ status: itemStatus }) => {
    const status = mapAudienceStatus(itemStatus);

    if (!status) {
      return null;
    }

    return <StatusIndicator label={status.label} status={status.status} />;
  },
};

export const listTableColumns: Columns<SegmentRow> = [
  {
    ...tableNameColumn,
    width: '35%',
  },
  {
    ...tableReachColumn,
    width: '15%',
  },
  {
    title: 'Status',
    key: 'status',
    width: '10%',
    render: ({ status: itemStatus }) => {
      const status = mapSegmentStatus(itemStatus);

      if (!status) {
        return null;
      }

      return <StatusIndicator label={status.label} status={status.status} />;
    },
  },
  {
    title: 'Provider',
    key: 'provider',
    width: '10%',
    render: ({ provider }) => (provider ? provider.name : null),
  },
  {
    title: 'Organization',
    key: 'account',
    width: '10%',
    render: ({ account }) => (account ? account.name : null),
  },
  {
    ...tableUpdatedDateColumn,
    width: '10%',
  },
  {
    ...tableCreatedDateColumn,
    width: '10%',
  },
];

export const confirmationDialogTableColumns: Columns<ConfirmationTableSegmentRow> = [
  {
    ...confirmationTableNameColumn,
    width: '55%',
  },
  {
    ...confirmationtableLinkedAudiencesColumn,
    width: '25%',
  },
  {
    ...tableUpdatedDateColumn,
    style: {
      padding: '8px 4px',
    },
    title: 'Updated',
    width: '20%',
  },
];

export const associatedAudiencesTableColumns: Columns<AssociatedAudienceTableRow> = [
  {
    ...audienceNameTableColumn,
    width: '70%',
  },
  {
    ...audienceStatusTableColumn,
    width: '15%',
  },
];
