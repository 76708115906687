import React from 'react';
import UIBreadcrumbs from '@material-ui/core/Breadcrumbs';

import { BreadcrumbItem, BreadcrumbItemProps } from './BreadcrumbItem';

interface BreadcrumbsProps {
  items: BreadcrumbItemProps[];
}

export const Breadcrumbs = (props: BreadcrumbsProps): JSX.Element => {
  const { items } = props;
  const noBreadcrumbsToShow = items.length === 0;

  if (noBreadcrumbsToShow) {
    return <></>;
  }

  return (
    <UIBreadcrumbs data-test={props['data-test']}>
      <span />
      {items.map((item, index) => (
        <BreadcrumbItem key={index} {...item} />
      ))}
    </UIBreadcrumbs>
  );
};
