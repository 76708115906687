import React, { useMemo, FC, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { createStyles, CircularProgress, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Paper, Tooltip } from '@openx/components/core';
import { bigNumberWithCommas } from '@openx/utils';
import GetEnrichEstimate from 'graphql/query/audiences/GetEnrichEstimate.gql';
import { useAudience, getIncludesGroups } from 'modules/audiences/AudienceProvider';
import { isGroupEmpty } from 'modules/audiences/utils';

import { getNormalizedDefinition } from '../../utils';
import { MobileApp, Cookies, IDs } from './icons';
import { useSnackbar } from 'notistack';
import { NestedErrorCode, useLocalErrorHandling } from 'modules/graphql';
import { parseSegmentErrorMessages } from 'modules/audiences/utils/parseSegmentErrorMessages';

interface Props {
  customExportSelected?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0 0 31px',
      boxShadow: 'none',
    },
    estimatedReachLabel: ({ customExportSelected }: Props) => ({
      color: theme.palette.text.hint,
      fontWeight: 600,
    }),
    estimatedReachValue: ({ customExportSelected }: Props) => ({
      alignItems: 'center',
      marginBottom: customExportSelected ? '20px' : '0px',
      marginTop: customExportSelected ? '7px' : '0px',
      display: 'flex',
      '&::before': {
        display: 'none',
      },
      minHeight: '22px',
    }),
    loader: {
      height: '16px !important',
      width: '16px !important',
      '&:not(:first-child)': {
        marginLeft: theme.spacing(0.5),
      },
    },
    sourceIDLoader: {
      marginTop: theme.spacing(3.5),
    },
    row: {
      display: 'flex',
      alignItems: 'center',
    },
    sourceID: {
      flexBasis: '25%',
    },
    reachNumber: {
      fontSize: 17,
      lineHeight: '22px',
      fontWeight: 600,
      width: '50%',
    },
    sourceIDContainer: {
      borderTop: `1px solid ${theme.palette.border}`,
      margin: '0 -20px -10px -20px',
      padding: '20px 20px 0 20px',
      minHeight: '106px',
    },
    sourceIDs: {
      paddingTop: theme.spacing(2),
    },
    sourceIDType: {
      marginLeft: theme.spacing(1.5),
    },
    noDataError: {
      color: theme.palette.background.black30,
    },
    errorTooltip: {
      marginLeft: '5px',
      marginTop: '3px',
    },
    sourceIDLabel: {
      '&:before': {
        display: 'none',
      },
    },
    tooltip: {
      marginLeft: '6px',
      position: 'relative',
      bottom: '0px',
    },
    label: ({ customExportSelected }: Props) => ({
      marginTop: customExportSelected ? '0px' : '-7px',
    }),
  }),
);

const formatTotalReach = (reachValue, rangeFactor = 0.05) =>
  reachValue
    ? [reachValue * (1 - rangeFactor), reachValue * (1 + rangeFactor)]
        .map(Math.round)
        .map(bigNumberWithCommas)
        .join(' - ')
    : '0';

export const EstimatedReach: FC<Props> = ({ customExportSelected = false }): JSX.Element => {
  const { state } = useAudience();
  const { enqueueSnackbar } = useSnackbar();

  const includesGroups = getIncludesGroups(state);
  const isIncludesEmpty = includesGroups.every(isGroupEmpty);

  const {
    root,
    loader,
    estimatedReachLabel,
    estimatedReachValue,
    row,
    sourceID,
    sourceIDType,
    sourceIDs,
    noDataError,
    reachNumber,
    errorTooltip,
    sourceIDContainer,
    sourceIDLabel,
    tooltip,
    label,
    sourceIDLoader,
  } = useStyles({ customExportSelected });

  const {
    loading,
    error,
    data: {
      enrichEstimate: {
        reachTotal: legacyReachTotal = 0, // deprecated, can be removed after OA-117
        oaid: { reachTotal: OAID = 0, error: OAIDError = false } = {},
        device: { reachTotal: device = 0, error: deviceError = false } = {},
        liveintent: { reachTotal: liveintent = 0, error: liveintentError = false } = {},
        idl: { reachTotal: IDL = 0, error: IDLError = false } = {},
        cookie: { reachTotal: cookie = 0, error: cookieError = false } = {},
        id5: { reachTotal: ID5 = 0, error: ID5Error = false } = {},
        ip: { reachTotal: IP = 0, error: IPError = false } = {},
      },
    } = { enrichEstimate: {} },
  } = useQuery(GetEnrichEstimate, {
    variables: { normalized_definition: getNormalizedDefinition(state) },
  });

  useLocalErrorHandling(
    GetEnrichEstimate,
    useCallback(
      error => {
        if (error.code === NestedErrorCode.BAD_USER_INPUT) {
          enqueueSnackbar(parseSegmentErrorMessages(error.message), { variant: 'error' });
          return true;
        }
        return false;
      },
      [enqueueSnackbar],
    ),
  );

  const renderReach = useMemo((): JSX.Element => {
    if (isIncludesEmpty && !loading) {
      return <span>0</span>;
    }

    if (loading) {
      return <CircularProgress className={loader} />;
    }

    if (error || OAIDError) {
      return (
        <div className={row}>
          <Typography className={classnames(noDataError, sourceIDLabel)} variant="h2">
            N/A
          </Typography>
          <Tooltip className={errorTooltip} withRipple title="Unable to generate reach estimates" />
        </div>
      );
    }

    return <span>{formatTotalReach(OAID > 0 ? OAID : legacyReachTotal)}</span>;
  }, [customExportSelected, error, OAID > 0 ? OAID : legacyReachTotal, OAIDError, loading]);

  const renderSourceIDReach = useMemo((): JSX.Element | null => {
    const showSourceIDPanel = !!(
      customExportSelected &&
      ((!loading && (cookie || device || IDL || liveintent || ID5 || IP)) || loading)
    );

    const sourceIDConfig = [
      { name: 'COOKIES', icon: Cookies, value: cookie, error: cookieError },
      { name: 'DEVICES', icon: MobileApp, value: device, error: deviceError },
      { name: 'RAMPIDS', icon: IDs, value: IDL, error: IDLError },
      { name: 'NONIDS', icon: IDs, value: liveintent, error: liveintentError },
      { name: 'ID5', icon: IDs, value: ID5, error: ID5Error },
      { name: 'IP', icon: IDs, value: IP, error: IPError },
    ];

    if (!showSourceIDPanel) {
      return null;
    }

    return (
      showSourceIDPanel && (
        <div className={sourceIDContainer}>
          <div className={row}>
            <Typography variant="h3" data-test="estimated-source-id-reach-label">
              Estimated Source ID Reach
            </Typography>
          </div>

          {loading && <CircularProgress className={classnames(loader, sourceIDLoader)} />}

          {!loading && (
            <div className={classnames(sourceIDs, row)}>
              {sourceIDConfig.map(
                ({ name, icon: Icon, value, error }) =>
                  ((value > 0 && !error) || error) && (
                    <div className={sourceID} key={name}>
                      <div className={row}>
                        <Icon />
                        <Typography variant="caption" className={sourceIDType}>
                          {name}
                        </Typography>
                      </div>

                      {error && (
                        <div className={row}>
                          <Typography className={noDataError} variant="h3">
                            N/A
                          </Typography>
                          <Tooltip className={errorTooltip} withRipple title="Unable to generate reach estimates" />
                        </div>
                      )}

                      {!error && (
                        <Typography className={reachNumber} variant="h3" data-test="estimated-source-id-reach-value">
                          {bigNumberWithCommas(value)}
                        </Typography>
                      )}
                    </div>
                  ),
              )}
            </div>
          )}
        </div>
      )
    );
  }, [
    customExportSelected,
    cookie,
    device,
    IDL,
    liveintent,
    loading,
    deviceError,
    IDLError,
    cookieError,
    liveintentError,
  ]);

  return (
    <Paper data-test="estimated-reach" className={root}>
      {({ HighlightedSection }) => (
        <HighlightedSection>
          <div className={classnames(row, label)}>
            <Typography variant="body2" className={estimatedReachLabel} data-test="estimated-reach-label">
              {customExportSelected ? 'Estimated Unique User Reach' : 'Estimated Reach'}
            </Typography>

            {!customExportSelected && (
              <Tooltip title="Add segments below for reach estimates" withRipple className={tooltip} />
            )}
          </div>

          <Typography variant="h2" className={estimatedReachValue} data-test="estimated-reach-value">
            {renderReach}
          </Typography>

          {!error && renderSourceIDReach}
        </HighlightedSection>
      )}
    </Paper>
  );
};
