import React from 'react';
import { FormikProps, Field, ErrorMessage } from 'formik';
import { AutoComplete, TextField, Dropzone } from '@openx/components/core';
import { makeStyles, FormHelperText } from '@material-ui/core';

import { FormikState } from './UploadFileDialog';
import { NAME_FIELD, DATA_TYPE_FIELD, FILE_FIELD } from './constants';
import { FormattingGuidelines } from './FormattingGuidelines';
import { DataType, getDataTypeLabel } from './dataType';
import { supportedFileTypes } from './validationSchema';

const useStyles = makeStyles({
  dropzone: {
    marginBottom: 0,
  },
});

const dataTypeOptions = Object.values(DataType);

export type UploadFileFormFieldsProps = FormikProps<FormikState>;

export function UploadFileFormFields({
  isSubmitting,
  values,
  setFieldValue,
  setFieldTouched,
}: UploadFileFormFieldsProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Field
        name={NAME_FIELD}
        label="Segment name"
        placeholder="Type your new segments name"
        component={TextField}
        fullWidth
        margin="none"
        data-test="name"
      />

      <Field
        name={DATA_TYPE_FIELD}
        component={AutoComplete}
        options={dataTypeOptions}
        getOptionLabel={option => getDataTypeLabel(option) || ''}
        textFieldReadOnly
        disableClearable
        textFieldProps={{
          label: 'Data type',
          placeholder: '[ Choose segments data type to upload ]',
          margin: 'dense',
        }}
        value={values.dataType || ''}
        fullWidth
        data-test="data-type"
      />

      <FormattingGuidelines dataType={values.dataType} />

      <Dropzone
        accept={supportedFileTypes}
        disabled={isSubmitting}
        file={values[FILE_FIELD]}
        onDropFile={file => {
          setFieldValue(FILE_FIELD, file, true);
          setFieldTouched(FILE_FIELD, true);
        }}
        onCancel={() => {
          setFieldValue(FILE_FIELD, undefined, true);
          setFieldTouched(FILE_FIELD, true);
        }}
        className={classes.dropzone}
      />

      <Field name={FILE_FIELD} type="hidden" value={values[FILE_FIELD] || ''} />
      <ErrorMessage name={FILE_FIELD}>
        {message => (
          <FormHelperText error data-test="drop-zone-error">
            {message}
          </FormHelperText>
        )}
      </ErrorMessage>
    </>
  );
}
