import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, makeStyles, TableRow, TableCell } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '168px',
  },
});

interface SearchResultPlaceholderProps {
  columnsAmount: number;
}

export const SearchResultPlaceholder = ({ columnsAmount }: SearchResultPlaceholderProps) => {
  const clases = useStyles();

  return (
    <TableRow>
      <TableCell colSpan={columnsAmount}>
        <div className={clases.root}>
          <Typography variant="h3">No results found</Typography>
          <Box marginTop="10px">
            <Typography variant="body1">Please try different criteria in order to find matching results</Typography>
          </Box>
        </div>
      </TableCell>
    </TableRow>
  );
};
