import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { Loader, Paper } from '@openx/components/core';
import { PageHeader } from '@openx/components/header';
import { FormikHelpers as FormikActions } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ActionBar } from 'components/ActionBar';
import CreateOrganization from 'graphql/mutation/organizations/CreateOrganization.gql';
import GetDefaultParentOrganizationId from 'graphql/query/organizations/GetDefaultParentOrganizationId.gql';
import { NestedErrorCode, useLocalErrorHandling } from 'modules/graphql';
import {
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables,
  GetDefaultParentOrganizationIdQuery,
  GetDefaultParentOrganizationIdQueryVariables,
} from 'types/schemaTypes';

import { Form, FormFields, FormValues } from '../components/Form';
import { OPEN_AUDIENCE_NAME } from '../components/Form/constants';
import { DEFAULT_ALLOWED_COUNTRIES } from '../constants';
import { DEFAULT_ALLOWED_DOMAINS } from '../constants';
import { mapOrganizationToMutation } from '../mapOrganizationToMutation';
import { useSideDrawer } from 'context';

export function OrganizationCreate(): JSX.Element {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const [createOrganization, { loading: creatingLoading }] = useMutation<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >(CreateOrganization, {
    refetchQueries: ['GetOrganizationsList'],
  });

  useLocalErrorHandling(
    CreateOrganization,
    useCallback(
      error => {
        const mapOfPathToMessage = {
          'object.name': 'Organization with such name already exists',
        };

        if (
          error.code === NestedErrorCode.SINGLE_UNIQUENESS_VIOLATION &&
          Object.keys(mapOfPathToMessage).includes(error.path)
        ) {
          enqueueSnackbar(mapOfPathToMessage[error.path], { variant: 'error' });
          return true;
        }

        return false;
      },
      [enqueueSnackbar],
    ),
  );

  const {
    error: parentOrganizationError,
    loading: parentOrganizationLoading,
    data: { account = [] } = {},
  } = useQuery<GetDefaultParentOrganizationIdQuery, GetDefaultParentOrganizationIdQueryVariables>(
    GetDefaultParentOrganizationId,
  );

  const accountId = account?.[0]?.id || null;

  const organizations = useMemo(
    () => [
      {
        id: accountId,
        name: OPEN_AUDIENCE_NAME,
      },
    ],
    [accountId],
  );

  const initialValues: FormValues = useMemo(
    () => ({
      [FormFields.NAME]: '',
      [FormFields.DESCRIPTION]: '',
      [FormFields.PARENT_ORGANIZATION_ID]: accountId,
      [FormFields.ALLOWED_COUNTRIES]: DEFAULT_ALLOWED_COUNTRIES,
      [FormFields.ALLOWED_DOMAINS]: DEFAULT_ALLOWED_DOMAINS,
      [FormFields.EXCHANGE_ACCOUNT_PARTNER_ID]: '',
    }),
    [accountId],
  );

  const handleSubmit = async (values: FormValues, actions: FormikActions<FormValues>) => {
    try {
      actions.setSubmitting(true);

      const organization = mapOrganizationToMutation({ ...values });

      await createOrganization({
        variables: {
          object: organization,
        },
      });

      history.push('/organizations');
      enqueueSnackbar('Organization created successfully', { variant: 'success' });
    } catch (error) {
      // this catch is necessary to handle mutation errors, because ErrorLink forwards them
      // snackback is shown by useLocalErrorHandling
    } finally {
      actions.setSubmitting(false);
    }
  };

  if (parentOrganizationLoading) {
    return (
      <Paper>
        <Loader />
      </Paper>
    );
  }

  if (parentOrganizationError) {
    throw new Error('Could not get the parent organization data');
  }

  return (
    <ActionBar onGoBack={() => history.push('/organizations')}>
      <PageHeader
        title="Create new Organization"
        data-test="page-header-title"
        titlePrefix="OpenAudience"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      />
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <Paper>
            <Form
              onSubmit={handleSubmit}
              loading={creatingLoading}
              initialValues={initialValues}
              organizations={organizations}
            />
          </Paper>
        </Grid>
      </Grid>
    </ActionBar>
  );
}
