import { SortDirection } from '../sort';
import { CriteriaDimension, TableCriteria } from '../tableCriteria';

export type QueryStringParamValue = string | string[] | undefined | null;

export interface QueryStringParams {
  [key: string]: QueryStringParamValue;
}

export interface QueryStringParamKeyChunks {
  prefix?: string;
  dimension: CriteriaDimension;
  param: string;
}

export type QueryCriteria<D extends CriteriaDimension, F extends {} = {}> = {
  [key in keyof TableCriteria<D>]?: TableCriteria<D>[key] | undefined;
};

export type AllQueryCriteria<F extends {} = {}> = {
  [key in CriteriaDimension]?: QueryCriteria<key, F>;
};

export function criterionQueryParamKey({ prefix, dimension, param }: QueryStringParamKeyChunks): string {
  const chunks = prefix ? [prefix, dimension, param] : [dimension, param];
  return chunks.join('.');
}

export function criterionQueryParamValue(
  queryParams: QueryStringParams,
  paramKeyChunks: QueryStringParamKeyChunks,
): QueryStringParamValue {
  const paramKey = criterionQueryParamKey(paramKeyChunks);

  return queryParams[paramKey];
}

export type CriterionExtractor<D extends CriteriaDimension, C extends TableCriteria<D>, P extends keyof C> = (
  queryStringValue?: QueryStringParamValue,
) => C[P] | undefined;

export type DimensionCriteriaExtractors<D extends CriteriaDimension> = {
  [key in keyof TableCriteria<D>]?: CriterionExtractor<D, TableCriteria<D>, key>;
};

export type CriteriaExtractors = {
  [key in CriteriaDimension]?: DimensionCriteriaExtractors<key>;
};

export type FiltersCriteriaExtractors = DimensionCriteriaExtractors<CriteriaDimension.FILTERS>;

export interface ProcessQueryCriteriaOptions {
  queryCriteria?: AllQueryCriteria;
  queryParamsPrefix?: string;
}

export interface ExtractQueryCriteriaOptions extends ProcessQueryCriteriaOptions {
  filtersCriteriaExtractors?: FiltersCriteriaExtractors;
}

export const defaultQueryCriteria: AllQueryCriteria = {
  [CriteriaDimension.PAGINATION]: {
    pageNumber: 1,
  },
  [CriteriaDimension.SORT]: {
    column: undefined,
    direction: SortDirection.ASC,
  },
};

export function overrideQueryCriteria<F extends {} = {}>(
  customQueryCriteria?: AllQueryCriteria<F>,
): AllQueryCriteria<F> {
  return Object.values(CriteriaDimension).reduce((accumulated, dimension) => {
    const defaultForDimension = defaultQueryCriteria[dimension];
    const customForDimension = customQueryCriteria && customQueryCriteria[dimension];

    if (!defaultForDimension && !customForDimension) {
      return accumulated;
    }

    return {
      ...accumulated,
      [dimension]: {
        ...defaultForDimension,
        ...customForDimension,
      },
    };
  }, {});
}

export const defaultCriteriaExtractors: CriteriaExtractors = {
  [CriteriaDimension.PAGINATION]: {
    pageNumber: queryValue => {
      if (!queryValue) {
        return undefined;
      }

      const numberedPageNumber = Number(queryValue);

      if (numberedPageNumber > 0) {
        return numberedPageNumber;
      }

      return undefined;
    },
  },
  [CriteriaDimension.SORT]: {
    direction: queryValue => {
      if (!queryValue || typeof queryValue !== 'string' || !Object.values<string>(SortDirection).includes(queryValue)) {
        return undefined;
      }

      return queryValue as SortDirection;
    },
  },
};

export function arrayExtractor(paramValue: string): string[] {
  return paramValue ? paramValue.split(',') : [];
}
