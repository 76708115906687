import React, { Fragment, ReactNode } from 'react';
import { isNumber } from 'lodash';
import { Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ValuePlaceholder } from '@openx/components/core';

import { numberWithCommas } from 'utils';

const useStyles = makeStyles({
  container: {
    width: 240,
  },
  boldText: {
    fontWeight: 600,
  },
  text: {
    marginBottom: 15,
  },
});

export enum SectionType {
  SOURCE_PROVIDED_COUNT = 'source_provided_count',
  SOURCE_MATCHED_COUNT = 'source_matched_count',
  COOKIES = 'cookie',
  DEVICES = 'device',
  TOTAL_IDLS = 'idl',
  CONNECTED_TV_DEVICES = 'ctv',
  IPS = 'ip',
  ID5 = 'id5',
}

interface Sections {
  [SectionType.SOURCE_PROVIDED_COUNT]?: number;
  [SectionType.SOURCE_MATCHED_COUNT]?: number;
  [SectionType.COOKIES]?: number;
  [SectionType.DEVICES]?: number;
  [SectionType.CONNECTED_TV_DEVICES]?: number;
  [SectionType.TOTAL_IDLS]?: number;
  [SectionType.IPS]?: number;
  [SectionType.ID5]?: number;
}

export interface ReachTooltipContentProps {
  loading: boolean;
  sections: Sections;
  text: string;
}

function renderReachValue(value?: number): string | JSX.Element {
  if (isNumber(value)) {
    return numberWithCommas(value);
  }

  return <ValuePlaceholder />;
}

const mapOfSectionTypeToName = {
  [SectionType.SOURCE_PROVIDED_COUNT]: 'Source id provided',
  [SectionType.SOURCE_MATCHED_COUNT]: 'Source id matched',
  [SectionType.COOKIES]: 'Cookies',
  [SectionType.DEVICES]: 'Mobile Devices',
  [SectionType.CONNECTED_TV_DEVICES]: 'Connected TV Devices',
  [SectionType.TOTAL_IDLS]: 'RampIDs',
  [SectionType.IPS]: 'IP Addresses',
  [SectionType.ID5]: 'ID5',
};

const renderSections = (sections: Sections): ReactNode =>
  Object.entries(sections).map(([sectionType, value], index) => (
    <Fragment key={`${sectionType}.${value}`}>
      {index !== 0 && <br />}
      {mapOfSectionTypeToName[sectionType]}: {renderReachValue(value)}
    </Fragment>
  ));

export function ReachTooltipContent({ loading, sections, text }: ReachTooltipContentProps): JSX.Element {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.container}>
        <Skeleton animation="wave" width={50} />
        <Skeleton animation="wave" height={60} />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Typography variant="body1" gutterBottom className={classes.boldText} data-test="reach-title">
        Breakdown
      </Typography>
      <Typography variant="body2" gutterBottom className={classes.text} data-test="reach-description">
        {text}
      </Typography>
      <Typography variant="body2" className={classes.boldText} data-test="reach-values">
        {renderSections(sections)}
      </Typography>
    </div>
  );
}
