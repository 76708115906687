import React, { useState } from 'react';
import MaterialTableRow from '@material-ui/core/TableRow';
import MaterialTableCell from '@material-ui/core/TableCell';
import { BaseRow } from '../tableHelpers';
import { TableRow, TableRowProps } from './TableRow';
import { ExpandIconCell } from './ExpandIconCell';

export const EXPAND_CONTENT_ROW_CLASS = 'expandContentRow';
export const EXPAND_CONTENT_CELL_CLASS = 'expandContentCell';

export interface ExpandComponentProps<RowT extends BaseRow> {
  data: RowT;
}

export interface TableRowExpandedProps<RowT extends BaseRow> extends TableRowProps<RowT> {
  expandComponent: React.ComponentType<ExpandComponentProps<RowT>>;
  rowData: RowT;
}

export function TableRowExpanded<RowT extends BaseRow>(props: TableRowExpandedProps<RowT>): JSX.Element {
  const { rowData } = props;
  const ExpandComponent = props.expandComponent;

  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <TableRow
        {...props}
        expandIcon={<ExpandIconCell expanded={isExpanded} onClick={expanded => setExpanded(!expanded)} />}
        selected={rowData.expanded}
        data-test="table-row-expand"
      />
      {isExpanded && (
        <MaterialTableRow selected={isExpanded} className={EXPAND_CONTENT_ROW_CLASS}>
          <MaterialTableCell padding="none" colSpan={12} className={EXPAND_CONTENT_CELL_CLASS}>
            <ExpandComponent data={rowData} />
          </MaterialTableCell>
        </MaterialTableRow>
      )}
    </>
  );
}
