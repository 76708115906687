import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Formik, Field, FormikHelpers as FormikActions } from 'formik';
import { Form as FormFormik } from 'formik';
import { AutoComplete, TextField, MultiInput } from '@openx/components/core';
import { Typography, Grid } from '@material-ui/core';

import { SetupActionBar } from 'components/ActionBar';

import { validationSchema, FormFields, FormValues } from './utils';
import { OPEN_AUDIENCE_NAME } from './constants';
import { allowedCountriesOptions, mapOfAllowedCountriesToName } from '../../constants';

interface Organization {
  id: string;
  name: string;
}

interface FormProps {
  onSubmit: (values: FormValues, actions: FormikActions<FormValues>) => Promise<void>;
  loading: boolean;
  initialValues: FormValues;
  organizations?: Organization[];
  isEdit?: boolean;
  onExchangePartnerChange?: (status: boolean) => void;
}

export function Form({
  onSubmit,
  loading,
  initialValues,
  organizations = [],
  isEdit = false,
  onExchangePartnerChange,
}: FormProps): JSX.Element {
  const getParentOrganizationOptionLabel = useCallback(
    option => organizations.find(({ id }) => option === id)?.name || '',
    [organizations],
  );
  const [exchangePartnerId, setExchangePartnerIdValue] = useState('');

  const parentOrganizationOptions = useMemo(() => organizations.map(({ id }) => id), [organizations]);

  useEffect(() => {
    if (onExchangePartnerChange) {
      if (exchangePartnerId === '' || exchangePartnerId === null) {
        onExchangePartnerChange(false);
      } else {
        onExchangePartnerChange(true);
      }
    }
  }, [exchangePartnerId]);

  const getAllowedCountriesOptionLabel = useCallback(option => mapOfAllowedCountriesToName[option], []);

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ submitForm }) => (
        <>
          <SetupActionBar
            actions={[
              {
                type: 'button',
                label: 'Save',
                props: {
                  color: 'primary',
                  type: 'submit',
                  instantClick: true,
                  onClick: submitForm,
                  loading,
                  'data-test': 'action-button',
                },
              },
            ]}
          />
          <FormFormik>
            <Typography variant="h2" data-test="organization-details-title" gutterBottom>
              Organization Details
            </Typography>
            <Field
              name={FormFields.NAME}
              label="Organization name"
              component={TextField}
              placeholder="Type your organization name"
              fullWidth
              margin="dense"
              data-test="organization-name"
            />
            <Field
              name={FormFields.DESCRIPTION}
              label="Organization description"
              component={TextField}
              placeholder="Type organization description here"
              helperText="Description must have less than 1000 characters"
              fullWidth
              multiline
              minRows={4}
              maxRows={4}
              margin="dense"
              data-test="organization-description"
            />
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Field
                  name={FormFields.ALLOWED_COUNTRIES}
                  textFieldProps={{
                    label: 'Allowed Countries',
                    placeholder: isEdit ? undefined : '[ Select all allowed countries ]',
                  }}
                  ChipProps={{ 'data-test': 'allowed-countries-item' }}
                  options={allowedCountriesOptions}
                  getOptionLabel={getAllowedCountriesOptionLabel}
                  component={AutoComplete}
                  limitTags={isEdit ? -1 : 1}
                  multiple
                  disableClearable
                  textFieldReadOnly
                  fullWidth
                  disableCloseOnSelect
                  disabled={isEdit}
                  margin="dense"
                  data-test="allowed-countries"
                />
              </Grid>
              <Grid item>
                <Field
                  name={FormFields.ALLOWED_DOMAINS}
                  label="Allowed Domains"
                  placeholder="Enter one or more email domains"
                  helperText="Press Enter after each domain to add it"
                  clearOnBlur
                  limitTags={isEdit ? -1 : 1}
                  component={MultiInput}
                  fullWidth
                  margin="dense"
                  data-test="organization-allowed-domains"
                />
              </Grid>
              <Grid item>
                <Field
                  name={FormFields.PARENT_ORGANIZATION_ID}
                  textFieldProps={{ label: 'Parent Organization', placeholder: OPEN_AUDIENCE_NAME }}
                  getOptionLabel={getParentOrganizationOptionLabel}
                  options={parentOrganizationOptions}
                  disabled
                  component={AutoComplete}
                  disableClearable
                  fullWidth
                  margin="dense"
                  data-test="organization-parent-id"
                />
              </Grid>
              <Grid item>
                <Field
                  name={FormFields.EXCHANGE_ACCOUNT_PARTNER_ID}
                  label="Partner ID"
                  placeholder="Enter partner id(s) here to view Sigview reporting for this organization"
                  clearOnBlur
                  limitTags={isEdit ? -1 : 1}
                  inputMask={/^\d{1,10}$/}
                  inputMinLength={9}
                  component={TextField}
                  fullWidth
                  margin="dense"
                  data-test="organization-partner-id"
                  onInput={event => setExchangePartnerIdValue(event.target.value)}
                />
              </Grid>
            </Grid>
          </FormFormik>
        </>
      )}
    </Formik>
  );
}
