import React from 'react';
import { Tooltip } from '@material-ui/core';
import { bigNumberWithCommas, formatCompactNumber } from '@openx/utils';

export const DisplayBigNumber = ({ number, compact = false }: { number: number; compact?: boolean }) => {
  const numInt = parseInt(number + '');
  if (compact) {
    return (
      <Tooltip title={bigNumberWithCommas(numInt)} aria-label={bigNumberWithCommas(numInt)}>
        <span>{formatCompactNumber(numInt)}</span>
      </Tooltip>
    );
  }

  return <span>{bigNumberWithCommas(numInt)}</span>;
};
export default DisplayBigNumber;
