import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Account } from 'types/schemaTypes';
import { Paper } from '@openx/components/core';
import { utcDate } from 'utils';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3.8),
  },
  text: {
    marginTop: theme.spacing(0.8),
  },
}));

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  organization: Account;
  dateJoined: string;
}

export const UserDetailsSection = ({ firstName, lastName, email, role, organization, dateJoined }: Props) => {
  const { header, text } = useStyles();

  return (
    <Paper>
      <div className={header}>
        <Typography variant="h2" data-test="user-details-title">
          User Details
        </Typography>
      </div>

      {firstName && (
        <Box>
          <Typography variant="body2" color="textSecondary" data-test="first-name-heading">
            First name
          </Typography>
          <Typography variant="body1" data-test="first-name" className={text}>
            {firstName}
          </Typography>
        </Box>
      )}

      {lastName && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="last-name-heading">
            Last name
          </Typography>
          <Typography variant="body1" data-test="last-name" className={text}>
            {lastName}
          </Typography>
        </Box>
      )}

      {email && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="email-heading">
            Email
          </Typography>
          <Typography variant="body1" data-test="email" className={text}>
            {email}
          </Typography>
        </Box>
      )}

      {role && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="role-heading">
            Role
          </Typography>
          <Typography variant="body1" data-test="role" className={text}>
            {role}
          </Typography>
        </Box>
      )}

      {organization && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="organization-heading">
            Organization
          </Typography>
          <Typography variant="body1" data-test="organization" className={text}>
            {organization.name}
          </Typography>
        </Box>
      )}

      {organization && organization.id && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="organization-id-heading">
            Organization ID
          </Typography>
          <Typography variant="body1" data-test="organization-id" className={text}>
            {organization.id}
          </Typography>
        </Box>
      )}

      {dateJoined && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="date-joined-heading">
            Date Joined
          </Typography>
          <Typography variant="body1" data-test="date-joined" className={text}>
            {utcDate(dateJoined)}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};
