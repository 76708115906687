import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import { currentPageRange, PaginationCriteria } from '../tableCriteria';
import { hasNextItems, hasPrevItems } from '../tableHelpers';
import { PaginationTextField } from './PaginationTextField';

const useStyles = makeStyles((theme: Theme) => ({
  paginationPanel: {
    display: 'flex',
    alignItems: 'center',
  },
  changePageButton: {
    width: 30,
    height: 30,
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.common.white,
    padding: 0,
    margin: theme.spacing(0, 5 / 8),
    '&:hover': {
      backgroundColor: theme.palette.text.hint,
      color: theme.palette.common.white,
      border: 'transparent',
    },
  },
  input: {
    textAlign: 'center',
    width: 49,
    '&:hover': {
      borderColor: theme.palette.action.disabled,
      backgroundColor: theme.palette.common.white,
    },
    '&:focus': {
      borderColor: theme.palette.text.secondary,
    },
  },
  text: {
    marginRight: 15,
  },
}));

export interface PaginationNavProps {
  paginationParams: PaginationCriteria;
  loading?: boolean;
  onChange: (paginationParams: PaginationCriteria) => void;
}

const PaginationNav = (props: PaginationNavProps): JSX.Element => {
  const { paginationParams, loading, onChange } = props;
  const classes = useStyles();
  const canLoadPrevItems = !loading && hasPrevItems(paginationParams);
  const canLoadNextItems = !loading && hasNextItems(paginationParams);

  const pageRange = currentPageRange(
    Math.min(Math.ceil(paginationParams.totalCount / paginationParams.pageSize), paginationParams.pageNumber),
    paginationParams.pageSize,
    paginationParams.totalCount,
  );

  const shiftPage = (shift: -1 | 1): void =>
    onChange({
      ...paginationParams,
      pageNumber: paginationParams.pageNumber + shift,
    });

  const onClickPrev = canLoadPrevItems ? () => shiftPage(-1) : undefined;
  const onClickNext = canLoadNextItems ? () => shiftPage(1) : undefined;

  return (
    <div className={classes.paginationPanel}>
      <Typography variant="body1" data-test="number-of-pages" className={classes.text}>
        {pageRange.min} - {pageRange.max} of {paginationParams.hasMore && 'more than '}
        {paginationParams.totalCount}
      </Typography>
      <IconButton
        onClick={onClickPrev}
        disabled={!canLoadPrevItems}
        data-test="prev-page"
        className={classes.changePageButton}
        disableRipple
      >
        <KeyboardArrowLeftIcon fontSize="small" />
      </IconButton>
      <PaginationTextField
        {...props}
        disabled={loading || (!canLoadPrevItems && !canLoadNextItems)}
        textFieldProps={{
          inputProps: {
            className: classes.input,
            'data-test': 'current-page',
          },
        }}
      />
      <IconButton
        onClick={onClickNext}
        disabled={!canLoadNextItems}
        data-test="next-page"
        className={classes.changePageButton}
        disableRipple
      >
        <KeyboardArrowRightIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

export default PaginationNav;
