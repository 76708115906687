import React, { useMemo, useCallback } from 'react';
import { Menu, makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Button } from '@openx/components/core';

import MenuButtonItem from './MenuButtonItem';
import { BaseRow, RowActionProps, RowActionState } from '../../tableHelpers';

export interface MenuButtonProps<RowT extends BaseRow> {
  rowData: RowT;
  menuItems: RowActionProps<RowT>[];
}

const useStyles = makeStyles(() => ({ menuIcon: { width: '25px' } }));

export default function MenuButton<RowT extends BaseRow>(props: MenuButtonProps<RowT>): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const classes = useStyles();
  const { menuItems, rowData } = props;

  const handleToggle = (): void => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const isActionAllowed = useCallback(
    (action: RowActionState<RowT>): boolean => {
      if (typeof action === 'boolean') {
        return action;
      }

      return action(rowData);
    },
    [rowData],
  );

  const isMenuLoading = useMemo(
    () => menuItems.some(item => isActionAllowed(item.loading || false)),
    [isActionAllowed, menuItems],
  );

  const MenuItems = useMemo(
    (): JSX.Element => (
      <div data-test="actions-sub-menu">
        {menuItems.map(
          (
            {
              onClick,
              onHover = () => {},
              disabled = false,
              loading = false,
              allowed = true,
              notAllowedMessage,
              label,
              ...restProps
            }: RowActionProps<RowT>,
            index,
          ) => {
            const isItemAllowed = isActionAllowed(allowed);
            const isItemDisabled = isActionAllowed(disabled) || isActionAllowed(loading) || !isItemAllowed;
            const notAllowedMessageString =
              typeof notAllowedMessage === 'function' ? notAllowedMessage(rowData) : notAllowedMessage;
            const isAllowedMessage = !isItemAllowed
              ? notAllowedMessageString || 'You need permission to perform this action'
              : '';
            const labelString = typeof label === 'function' ? label(rowData) : label;

            return (
              <MenuButtonItem
                onClick={e => {
                  onClick(rowData, e);
                  handleClose();
                }}
                onHover={onHover}
                isAllowedMessage={isAllowedMessage}
                disabled={isItemDisabled}
                key={index}
                label={labelString}
                {...restProps}
              />
            );
          },
        )}
      </div>
    ),
    [isActionAllowed, rowData, menuItems],
  );

  return (
    <span data-test={props['data-test']}>
      <Button
        loading={isMenuLoading}
        variant="text"
        className={classes.menuIcon}
        onClick={handleToggle}
        data-test="action-button"
      >
        <MoreVertIcon ref={anchorRef} />
      </Button>
      <Menu open={open} anchorEl={anchorRef.current} onClose={handleClose} MenuListProps={{ disablePadding: true }}>
        {MenuItems}
      </Menu>
    </span>
  );
}
