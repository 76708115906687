import { isString } from 'lodash';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export function prepareSortAlphabeticallyHandler<T extends {}>(
  extractValueFromItem: (T) => string,
  sortDirection: SortDirection,
): (itemA: T, itemB: T) => number {
  return (itemA: T, itemB: T) => {
    let extractedAValue = extractValueFromItem(itemA);
    let extractedBValue = extractValueFromItem(itemB);

    if (Array.isArray(extractedAValue) && Array.isArray(extractedBValue)) {
      extractedAValue = Array.from(extractedAValue).sort(prepareSortAlphabeticallyHandler(v => v, sortDirection))[0];
      extractedBValue = Array.from(extractedBValue).sort(prepareSortAlphabeticallyHandler(v => v, sortDirection))[0];
    }

    if (isString(extractedAValue) && isString(extractedBValue)) {
      return (sortDirection === SortDirection.ASC ? extractedAValue : extractedBValue).localeCompare(
        sortDirection === SortDirection.ASC ? extractedBValue : extractedAValue,
        'en',
        { sensitivity: 'accent' },
      );
    }

    return (
      Number(sortDirection === SortDirection.ASC ? extractedAValue : extractedBValue) -
      Number(sortDirection === SortDirection.ASC ? extractedBValue : extractedAValue)
    );
  };
}
