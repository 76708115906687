import { BaseRow, Columns } from '../../tableHelpers';
import { CustomColumnConfig } from './config';

export const recalculateColumsWidth = <RowT extends BaseRow>(columns: Columns<RowT>) => {
  const newWidth = columns.reduce((acc, col) => {
    if (!col.hide) {
      return acc + parseInt(col.width || '0');
    }
    return acc;
  }, 0);

  const diff = 100 - newWidth;
  return columns.map(col => {
    const currentWidth = parseInt(col.width || '0');
    return { ...col, calcWidth: `${currentWidth + (currentWidth / newWidth) * diff}%` };
  });
};
