import React, { useState, MouseEvent, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grid, Box } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';

import { ChipContainer } from './ChipContainer';
import { FilterCategoryMenu } from './FilterCategoryMenu';
import { FilterItemsMenu } from './FilterItemsMenu';
import { FiltersConfig, ChipFilterValue } from './types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  filtersViewContainer: {
    minHeight: '46px',
    width: '100%',
    alignItems: 'stretch',
    flexWrap: 'nowrap',
    marginLeft: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  innerBox: {
    display: 'flex',
    alignItems: 'center',
  },
  rightBox: {
    minWidth: '150px',
    cursor: 'pointer',
    flex: 1,
  },
  addFilterText: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '28px',
    color: theme.palette.text.secondary,
    textTransform: 'none',
    whiteSpace: 'nowrap',
  },
  icon: {
    width: '28px',
    height: '28px',
  },
  closeIcon: {
    marginLeft: 'auto',
  },
}));

interface ChipFiltersProps<FiltersValue extends {}> {
  filtersConfig: FiltersConfig;
  filters: FiltersValue;
  onFilterChange: (newFilters: { [categoryName: string]: ChipFilterValue }) => void;
}

export function ChipFiltersView<FiltersValue>({
  filtersConfig,
  onFilterChange,
  filters,
}: ChipFiltersProps<FiltersValue>) {
  const { t } = useTranslation();
  const classes = useStyles();
  const filterTextRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [currentCategory, setCurrentCategory] = useState<string | null>(null);

  const clearAllFilters = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onFilterChange(
      Object.keys(filtersConfig).reduce((acc, categoryName) => {
        acc[categoryName] = null;
        return acc;
      }, {}),
    );
  };

  const openFiltersClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeFilterWindow = () => {
    setAnchorEl(null);
    setCurrentCategory(null);
  };

  const selectFilterCategory = (categoryName: string) => {
    setCurrentCategory(categoryName);
    const { fetchOptions } = filtersConfig[categoryName];
    fetchOptions && fetchOptions();
  };

  const applyFilter = (categoryName: string, value: ChipFilterValue): void => {
    onFilterChange({ [categoryName]: value });
    closeFilterWindow();
  };

  const activeFilters: FiltersValue = useMemo((): FiltersValue => {
    return Object.entries(filters).reduce((acc, [categoryName, value]) => {
      if (value && categoryName in filtersConfig) {
        acc[categoryName] = value;
      }
      return acc;
    }, {} as FiltersValue);
  }, [filters, filtersConfig]);

  const isAnyFilterActive = !!Object.keys(activeFilters).length;

  return (
    <Grid container className={classes.filtersViewContainer}>
      <Box className={classes.innerBox}>
        <IconButton className={classes.icon} data-test="filter-icon-button" onClick={openFiltersClick}>
          <FilterListIcon />
        </IconButton>
        <ChipContainer
          filters={activeFilters}
          filtersConfig={filtersConfig}
          onDelete={categoryName => onFilterChange({ [categoryName]: null })}
        />
      </Box>
      <Box
        onClick={() => {
          !anchorEl && filterTextRef && setAnchorEl(filterTextRef.current);
        }}
        className={classNames(classes.innerBox, classes.rightBox)}
      >
        <div className={classes.addFilterText} data-test="filter-button" ref={filterTextRef}>
          <span>{t('Add new filter')}</span>
        </div>
        {isAnyFilterActive && (
          <IconButton
            className={classNames(classes.icon, classes.closeIcon)}
            onClick={clearAllFilters}
            data-test="clean-filters-button"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <FilterCategoryMenu
        filtersConfig={filtersConfig}
        anchorEl={anchorEl}
        onSelect={selectFilterCategory}
        onClose={closeFilterWindow}
        open={Boolean(anchorEl && !currentCategory)}
        activeFilters={activeFilters}
      />
      {currentCategory && (
        <FilterItemsMenu
          category={currentCategory}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          currentValue={filters[currentCategory]}
          onApplyFilter={applyFilter}
          onClose={closeFilterWindow}
          filterConfig={filtersConfig[currentCategory]}
        />
      )}
    </Grid>
  );
}
