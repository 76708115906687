import { useEffect, useState } from 'react';

export const useImpersonationBarScrollListener = () => {
  const [scrollOver, setScrollOver] = useState<number>(0);
  const [barHeight, setBarHeight] = useState<number>(0);

  useEffect(() => {
    const impersonationBarEl = document.querySelector<HTMLElement>('#impersonation-bar');

    const { height: barHeight } = impersonationBarEl?.getBoundingClientRect() || {
      height: 0,
    };

    setBarHeight(barHeight);

    const onScroll = (): void => {
      setScrollOver(window.scrollY > barHeight ? barHeight : window.scrollY);
    };

    if (impersonationBarEl) {
      window.addEventListener('scroll', onScroll);
    }

    return () => {
      if (impersonationBarEl) {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  return { barHeight, scrollOver, barVisibleHeight: barHeight - scrollOver };
};
