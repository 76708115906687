import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Account } from 'types/schemaTypes';
import { Paper } from '@openx/components/core';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3.8),
  },
  text: {
    marginTop: theme.spacing(0.8),
  },
}));

interface Props {
  name?: string;
  description?: string | null;
  resourceId?: string;
  allowedCountries?: string[];
  allowedDomains?: string[];
  parentAccount?: Partial<Account | null>;
  partnerId?: Account['exchange_account_partner_id'];
  organizationId?: string | null;
}

export const DetailsSection = ({
  name,
  description,
  resourceId,
  allowedCountries,
  allowedDomains,
  parentAccount,
  partnerId,
  organizationId,
}: Props) => {
  const { header, text } = useStyles();

  return (
    <Paper>
      <div className={header}>
        <Typography variant="h2" data-test="organization-details-title">
          Organization Details
        </Typography>
      </div>

      {name && (
        <Box>
          <Typography variant="body2" color="textSecondary" data-test="organization-name-heading">
            Organization name
          </Typography>
          <Typography variant="body1" className={text} data-test="organization-name">
            {name}
          </Typography>
        </Box>
      )}

      {description && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="organization-description-heading">
            Description
          </Typography>
          <Typography variant="body1" data-test="organization-description" className={text}>
            {description}
          </Typography>
        </Box>
      )}

      {resourceId && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="resource-id-heading">
            Resource ID
          </Typography>
          <Typography variant="body1" data-test="resource-id" className={text}>
            {resourceId}
          </Typography>
        </Box>
      )}

      {allowedCountries && allowedCountries.length > 0 && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="allowed-countries-heading">
            Allowed Countries
          </Typography>
          <Typography variant="body1" data-test="allowed-countries" className={text}>
            {allowedCountries.join(', ')}
          </Typography>
        </Box>
      )}

      {allowedDomains && allowedDomains.length > 0 && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="allowed-email-domains-heading">
            Allowed Email Domains
          </Typography>
          <Typography variant="body1" data-test="allowed-email-domains" className={text}>
            {allowedDomains.join(', ')}
          </Typography>
        </Box>
      )}

      {parentAccount && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="parent-account-heading">
            Parent Account
          </Typography>
          <Typography variant="body1" data-test="parent-account" className={text}>
            {parentAccount?.name}
          </Typography>
        </Box>
      )}

      {partnerId && !isEmpty(partnerId) && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="partner-id-heading">
            Partner ID
          </Typography>

          {Array.isArray(partnerId) ? (
            <Typography variant="body1" data-test="partner-id" className={text}>
              {partnerId.join(', ')}
            </Typography>
          ) : (
            <Typography variant="body1" data-test="partner-id" className={text}>
              {partnerId}
            </Typography>
          )}
        </Box>
      )}

      {organizationId && (
        <Box marginTop="12px">
          <Typography variant="body2" color="textSecondary" data-test="organization-id-heading">
            Organization ID
          </Typography>
          <Typography variant="body1" data-test="organization-id" className={text}>
            {organizationId}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};
