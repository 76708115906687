import { INTERNAL_AUSTRALIA_ACCOUNT_ID } from 'config';
import { SessionContext } from 'modules/auth/AuthProvider';
import { AclFlag } from '@openx/types';

type SingleCharDecompressed = [AclFlag, AclFlag, AclFlag, AclFlag];

enum Role {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export function isAdmin(session: SessionContext): boolean {
  return session.role === Role.ADMIN;
}

export function isMember(session: SessionContext): boolean {
  return session.role === Role.MEMBER;
}

export function isInternalUser(session: SessionContext): boolean {
  return session.account.parent_account_id === null;
}

export function isInternalAustralianUser(session: SessionContext): boolean {
  return [INTERNAL_AUSTRALIA_ACCOUNT_ID].includes(session.account.id);
}

// char represents 0-9a-f string
function decompressSingleChar(char: string): SingleCharDecompressed {
  const decimalCode: number = parseInt(char, 16); // f.e. decimalCode = 15
  const binaryCode: string = decimalCode.toString(2).padStart(4, '0'); // f.e. binaryCode = '1111'

  return Array.from(binaryCode).reverse().map(Number) as SingleCharDecompressed;
}

// compressed acl in format: a112356ff...
function unpackAcl(compressedAcl: string): AclFlag[] {
  return ([] as AclFlag[]).concat(...Array.from(compressedAcl).map(decompressSingleChar));
}

export function isAclFlagSet(session: SessionContext, aclKey: string): boolean {
  const indexKey = aclKey && session?.exchange_session?.acl_mapping[aclKey];
  const compiledAcl = session?.exchange_session?.compiled_acl;
  const decodeAcl = unpackAcl(compiledAcl)[indexKey];
  return decodeAcl === 1 ? true : false;
}
