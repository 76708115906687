import React, { useCallback, useMemo, useState } from 'react';
import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Button, Paper, AutoComplete, ButtonColor, TextField } from '@openx/components/core';

import {
  Table,
  SortDirection,
  AllTableCriteria,
  applyCriteriaChange,
  clientSideCriteriaProcessing,
} from '@openx/components/table';
import { tableColumns } from './tableColumns';
import { TOP_BAR_HEIGHT } from 'config';

import { CreateApiKeyModal } from './CreateApiKeyModal';
import { ApiKeyCreatedModal } from './ApiKeyCreatedModal';

import { useMutation, useQuery } from '@apollo/client';

import CreateApiKey from 'graphql/mutation/users/CreateApiKey.gql';
import GetApiKeys from 'graphql/query/users/GetApiKeys.gql';
import DeleteUser from 'graphql/query/users/DeleteUser.gql';

import {
  GetApiKeysQuery,
  GetApiKeysQueryVariables,
  MutationCreateServiceAccountUnderParentAccountArgs,
  User,
} from 'types/schemaTypes';

import { useSnackbar } from 'notistack';
import { DeleteApiKeyModal } from './DeleteApiKeyModal';

const useStyles = makeStyles(theme => ({
  infoAlert: {
    padding: '5px 19.5px',
    backgroundColor: '#EBF4FA',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3.8),
  },
  warningAlert: {
    padding: '12px 16px',
    backgroundColor: '#FDF9F0',
    color: '#8B5C00',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDiraction: 'row',
  },
  table: {
    margin: '-20px',
  },
  keyName: {
    fontWeight: 'bold',
  },
}));

const expirationOptions = [
  {
    label: '90 Days',
    value: 'P90D',
  },
];

export type ApiKeysRow = Pick<User, 'id' | 'first_name' | 'expires_at' | 'created_date'>;

const initialCriteria: AllTableCriteria = {
  sort: {
    column: 'created_date',
    direction: SortDirection.DESC,
    sortableColumns: ['first_name', 'expires_at', 'created_date'],
  },
};

type Props = {
  organizationId: string;
};

type ToDelete = {
  id: string;
  name: string;
} | null;

export const OrganizationDetailsSection = ({ organizationId }: Props) => {
  const { header, infoAlert, warningAlert, flexRow, table, keyName } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [createdVisible, setCreatedVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [toDelete, setToDelete] = useState<ToDelete>(null);
  const [name, setName] = useState('');
  const [expiresIn, setExpiresIn] = useState(expirationOptions[0]);
  const [keyData, setKeyData] = useState<any>();

  const [criteria, setCriteria] = useState<AllTableCriteria>(initialCriteria);

  const {
    data: { user: apiKeys = [] } = {},
    error: getKeysError,
    loading: keysLoading,
  } = useQuery<GetApiKeysQuery, GetApiKeysQueryVariables>(GetApiKeys, {
    variables: { organizationId },
    fetchPolicy: 'no-cache',
  });

  const [createApiKey, { loading: createKeyLoading }] = useMutation<
    any,
    MutationCreateServiceAccountUnderParentAccountArgs
  >(CreateApiKey, {
    refetchQueries: ['GetApiKeys'],
    awaitRefetchQueries: true,
  });

  const [deleteKey, { loading: deleteLoading }] = useMutation(DeleteUser, {
    refetchQueries: ['GetApiKeys'],
    awaitRefetchQueries: true,
  });

  const onChangeName = e => {
    const value = e.target.value;
    setName(value);
  };

  const onChangeExpiration = value => {
    setExpiresIn(value);
  };

  const handleOpenCreateApikey = () => {
    setCreateModalVisible(true);
  };

  const handleCloseCreateApikey = () => {
    setCreateModalVisible(false);
  };

  const handleOpenCreated = () => {
    setCreatedVisible(true);
  };

  const handleCloseCreated = () => {
    setCreatedVisible(false);
  };

  const createKey = async () => {
    try {
      const { data: { createServiceAccountUnderParentAccount = {} } = {} } = await createApiKey({
        variables: { expiresIn: expiresIn.value, name, parent_account_id: organizationId },
      });

      enqueueSnackbar('API Key created successfully', { variant: 'success' });

      if (createServiceAccountUnderParentAccount) {
        setKeyData(createServiceAccountUnderParentAccount);
        setName('');
        handleCloseCreateApikey();
        handleOpenCreated();
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Failed to create API Key', { variant: 'error' });
    }
  };

  const handleConfirmCreation = () => {
    createKey();
  };

  const handleCriteriaChange = useCallback(
    newCriteria => {
      setCriteria(applyCriteriaChange(criteria, newCriteria));
    },
    [criteria],
  );

  const handleOpenDeleteDialog = (id, name) => {
    setDeleteVisible(true);
    setToDelete({ id, name });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteVisible(false);
    setToDelete(null);
  };

  const handleDeleteKey = async id => {
    try {
      await deleteKey({ variables: { id } });
      enqueueSnackbar('API Key deleted successfully', { variant: 'success' });
      setToDelete(null);
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Failed to delete API Key', { variant: 'error' });
    }
  };

  const secondaryRowAction = {
    label: 'delete',
    color: 'danger' as ButtonColor,
    'data-test': 'delete-api-key',
    onClick: ({ id, first_name }) => handleOpenDeleteDialog(id, first_name),
  };

  const getData = useMemo(() => {
    return clientSideCriteriaProcessing(apiKeys, criteria, { onCriteriaAdjust: setCriteria });
  }, [apiKeys, criteria]);

  const showTable = (apiKeys.length < 1 && keysLoading) || (apiKeys.length > 0 && !keysLoading);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(keyData?.apiKey);
    enqueueSnackbar('Copied to clipboard', { variant: 'success' });
  };

  if (getKeysError) {
    console.error(getKeysError);
    enqueueSnackbar('Failed to fetch API Keys list', { variant: 'error' });
  }

  return (
    <>
      <Paper>
        <div className={header}>
          <Typography variant="h2" data-test="organization-details-title">
            Organization Details
          </Typography>
        </div>
        <Box marginBottom="24px">
          <div className={infoAlert}>
            <Alert severity="info" data-test="warning-allert">
              API Keys are generated for the whole organization, so store them securely.
            </Alert>
          </div>
        </Box>

        <div className={header}>
          <Typography variant="h2" data-test="api-keys-title">
            API Keys
          </Typography>
        </div>

        <Box marginBottom={'24px'}>
          <Button color="primary" onClick={handleOpenCreateApikey} data-test="open-create-modal-button">
            CREATE NEW API KEY
          </Button>
        </Box>

        <Box className={table}>
          {showTable ? (
            <Table
              data={getData}
              columns={tableColumns}
              loading={keysLoading}
              stickyHeaderPosition={TOP_BAR_HEIGHT}
              secondaryRowAction={secondaryRowAction}
              onCriteriaChange={handleCriteriaChange}
              criteria={criteria}
              fixedLayout
            />
          ) : (
            <Box margin="20px">
              <Typography variant="body2" color="textSecondary" data-test="no-api-keys-message">
                No API Keys were generated.
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
      <CreateApiKeyModal
        title="Create API Key"
        isOpen={createModalVisible || createKeyLoading}
        onClose={handleCloseCreateApikey}
        onConfirmed={handleConfirmCreation}
        loading={createKeyLoading}
      >
        <>
          <TextField
            value={name}
            data-test="api-key-name"
            label="API Key Name"
            placeholder="Type your API Key name"
            fullWidth
            margin="dense"
            onChange={onChangeName}
          />
          <AutoComplete
            data-test="expiration"
            options={expirationOptions}
            textFieldProps={{ label: 'Expiration' }}
            getOptionLabel={option => option.label || ''}
            value={expiresIn || {}}
            onChange={(_, value) => onChangeExpiration(value)}
            textFieldReadOnly
            disableClearable
          />
        </>
      </CreateApiKeyModal>
      <ApiKeyCreatedModal
        title="The API Key has been created"
        isOpen={createdVisible && !createKeyLoading}
        onClose={handleCloseCreated}
      >
        <>
          <div className={warningAlert}>
            <Alert severity="warning" data-test="alert-created">
              Make sure to copy your API Key now. You won’t be able to see it again!
            </Alert>
          </div>
          <Box marginTop={'12px'}>
            <Typography variant="body2" color="textSecondary" data-test="created-key-title">
              API Key
            </Typography>
            <Box className={flexRow}>
              <Typography variant="h3" data-test="api-key-value">
                {keyData?.apiKey || ''}
              </Typography>
              <IconButton onClick={copyToClipboard} data-test="copy-to-clipboard">
                <FileCopyOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      </ApiKeyCreatedModal>
      <DeleteApiKeyModal
        title="Delete API key"
        isOpen={deleteVisible && !!toDelete}
        onConfirmed={() => handleDeleteKey(toDelete?.id)}
        onClose={handleCloseDeleteDialog}
        loading={deleteLoading}
      >
        <Box>
          <Typography variant="body2" color="textSecondary" data-test="delete-key-message">
            You're about to delete API key
          </Typography>

          <Typography color="textPrimary" className={keyName} noWrap data-test="key-to-delete-name">
            {toDelete?.name}
          </Typography>
        </Box>
      </DeleteApiKeyModal>
    </>
  );
};
