import React, { FC } from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import { ConfirmationDialog } from '@openx/components/core';
import { useQuery } from '@apollo/client';
import { Alert } from '@material-ui/lab';
import GetAssociatedDeals from 'graphql/query/audiences/GetAssociatedDeals.gql';
import { AssociatedDealsList } from 'modules/audiences/components/AssociatedDeals';

export const useStyles = makeStyles<Theme>(theme => ({
  warningAlert: {
    padding: 0,
    color: '#8B5C00',
    margin: '15px 0',
  },
}));

interface Props {
  audienceId: string;
  audienceName: string;
  onCancel: () => void;
  onConfirmed: () => void;
  secondaryAction?: () => void;
  isOpen: boolean;
  isUpdateOnlyAvaliable?: boolean;
  isLoading: boolean;
}

export const EditAudienceConfirmationDialog: FC<Props> = ({
  audienceId,
  audienceName,
  onCancel,
  onConfirmed,
  secondaryAction,
  isOpen,
  isUpdateOnlyAvaliable,
  isLoading,
}): JSX.Element | null => {
  const { warningAlert } = useStyles();

  const { error, data: { deal: associatedDeals = [] } = {} } = useQuery(GetAssociatedDeals, {
    variables: { id: audienceId },
    fetchPolicy: 'network-only',
    skip: !audienceId,
  });

  if (error) {
    return null;
  }

  return (
    <ConfirmationDialog
      title="Confirmation"
      confirmLabel="Save"
      secondaryActionTitle="Save & re-activate"
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirmed={onConfirmed}
      secondaryAction={secondaryAction}
      isLoading={isLoading}
      secondaryActionAvaliable={isUpdateOnlyAvaliable}
    >
      <Typography variant="body1">
        You're about to save changes to the <b>{audienceName}</b>
      </Typography>
      {!!associatedDeals.length && (
        <>
          <Alert className={warningAlert} severity="warning" data-test="alert-message">
            The following deal IDs will be affected by the change.
          </Alert>

          <AssociatedDealsList deals={associatedDeals} />
        </>
      )}
    </ConfirmationDialog>
  );
};
