import validator from 'validator';

import { DataType } from './dataType';

const US_PHONE_COUNTRY_CODE = '1';

const dataTypeValidators: Record<DataType, (value: string) => boolean> = {
  maid: validator.isUUID,
  email: validator.isEmail,
  phone: (value: string) => validator.isMobilePhone(value, 'en-US'),
};

export function normalizePhoneNumber(phoneNumber: string): string {
  const result = phoneNumber
    .trim()
    .replace(/["'\t ,]/g, '')
    .replace(/^\+/g, '')
    .replace(/[()-]/g, '')
    .replace(/(\([a-zA-Z]+\)|[a-zA-Z]+)/, '')
    .toLowerCase();

  const noCountryCode = result.length === 10;

  return noCountryCode ? US_PHONE_COUNTRY_CODE + result : result;
}

export function normalizeValue(value: string, type: DataType): string {
  if (type === DataType.PHONE) {
    return normalizePhoneNumber(value);
  }

  return value.trim().toLowerCase();
}

export function isValueValid(value: string, type: DataType): boolean {
  return dataTypeValidators[type](value);
}

export async function hashValue(value: string, type: DataType): Promise<string> {
  if (type === DataType.MOBILE_AD_ID) {
    return value;
  }

  const msgUint8 = new TextEncoder().encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}

export async function hashData(data: string[][], type: DataType): Promise<string[][]> {
  const promises = data
    .map(values => {
      const value = values[0];
      const alreadyHashed = validator.isHash(value, 'sha256');

      return {
        value: alreadyHashed ? value : normalizeValue(value, type),
        alreadyHashed,
      };
    })
    .filter(({ value, alreadyHashed }) => alreadyHashed || isValueValid(value, type))
    .map(({ value, alreadyHashed }) =>
      (alreadyHashed ? Promise.resolve(value) : hashValue(value, type)).then(value => [value]),
    );

  return Promise.all(promises);
}
