import React from 'react';
import { Tooltip } from '@openx/components/core';
import moment from 'moment';

interface DateCellProps {
  date: string;
}

export function DateCell({ date }: DateCellProps): JSX.Element {
  return (
    <Tooltip title={new Date(date).toLocaleString()}>
      <span>{moment(date).fromNow()}</span>
    </Tooltip>
  );
}
