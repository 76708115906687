import React, { FC } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import { Button, ButtonProps, ButtonVariant } from '../Button';

export interface DrawerAction extends Omit<ButtonProps, 'children'> {
  label: string;
  'data-test'?: string;
}

export type DrawerActions = DrawerAction[];

export type DrawerActionBarVariant = ButtonVariant;

export interface DrawerActionBarProps {
  actions: DrawerActions;
  gutterTop?: boolean;
  gutterBottom?: boolean;
  variant?: DrawerActionBarVariant;
  stickyBottom?: boolean;
  hideDivider?: boolean;
  buttonsPosition?: 'left' | 'right';
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: ({ gutterTop, gutterBottom, stickyBottom }: DrawerActionBarProps) =>
      theme.spacing(gutterTop ? 2 : 0, stickyBottom ? 0 : -2.5, gutterBottom ? 2 : 0, stickyBottom ? 0 : -2.5),
    position: ({ stickyBottom }) => (stickyBottom ? 'absolute' : 'relative'),
    bottom: 0,
    right: 0,
    left: 0,
    padding: theme.spacing(1.25, 2.5),
    background: '#ffffff',
    borderTop: ({ hideDivider, stickyBottom }) => (!hideDivider && stickyBottom ? '1px solid #E6E6E6' : 'none'),
  },
  content: {
    display: 'flex',
    width: '100%',
    justifyContent: ({ buttonsPosition }) => buttonsPosition,
  },
  button: {
    margin: ({ buttonsPosition }) =>
      theme.spacing(0, buttonsPosition === 'right' ? 0 : 1.25, 0, buttonsPosition === 'right' ? 1.25 : 0),
  },
}));

export const DrawerActionBar: FC<DrawerActionBarProps> = (props): JSX.Element => {
  const classes = useStyles(props);

  const { actions, variant = 'contained' } = props;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {actions.map(({ label, ...restActionProps }) => (
          <Button variant={variant} {...restActionProps} key={label} className={classes.button}>
            {label}
          </Button>
        ))}
      </div>
    </div>
  );
};
