import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface StylesProps {
  gutterTop: boolean;
  uppercase: boolean;
}

interface Props {
  children: React.ReactNode;
  gutterTop?: boolean;
  uppercase?: boolean;
  ['data-test']?: string;
}

const useStyles = makeStyles<Theme, StylesProps>(theme => ({
  listTitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    marginTop: ({ gutterTop }) => theme.spacing(gutterTop ? 2 : 1),
    textTransform: ({ uppercase }) => (uppercase ? 'uppercase' : 'none'),
  },
}));

export function Title({ children, gutterTop, uppercase, ...rest }: Props) {
  const classes = useStyles({
    gutterTop: !!gutterTop,
    uppercase: !!uppercase,
  });

  return (
    <Typography variant="caption" className={classes.listTitle} component="p" data-test={rest['data-test']}>
      {children}
    </Typography>
  );
}
