import React from 'react';
import { ExpiresAtCell } from './ExpiresAtCell';
import { DateCell } from 'components/DateCell';
import { ApiKeysRow } from './OrganizationDetailsSection';
import { Columns } from '@openx/components/table';

export const tableColumns: Columns<ApiKeysRow> = [
  {
    title: 'Name',
    key: 'first_name',
    width: '50%',
    style: {
      paddingTop: '13px',
      paddingBottom: '13px',
    },
    render: ({ first_name }) => <b>{first_name}</b>,
  },
  {
    title: 'Expiration Date',
    key: 'expires_at',
    width: '25%',
    render: ({ expires_at }) => {
      return <ExpiresAtCell expiresAt={expires_at} />;
    },
  },
  {
    title: 'Date Created',
    key: 'created_date',
    width: '25%',
    render: ({ created_date }) => <DateCell date={created_date} />,
  },
];
