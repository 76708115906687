import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Paper } from '@openx/components/core';
import {
  AllTableCriteria,
  SortDirection,
  applyCriteriaChange,
  Table,
  clientSideCriteriaProcessing,
  SearchFiltersValue,
} from '@openx/components/table';
import { useQuery } from '@apollo/client';

import { Account, GetOrganizationsListQuery, GetOrganizationsListQueryVariables } from 'types/schemaTypes';
import GetOrganizationsList from 'graphql/query/organizations/GetOrganizationsList.gql';
import { TABLE_PAGE_SIZE, TOP_BAR_HEIGHT } from 'config';

import { tableColumns } from './tableColumns';
import { OrganizationsHeader } from './OrganizationsHeader';
import { EmptyTablePlaceholder } from 'components/TablePlaceholder/EmptyTablePlaceholder';
import { SearchResultPlaceholder } from 'components/TablePlaceholder/SearchResultsPlaceholder';
import { useHistoryWithRef } from 'utils/useHistoryWithRef';

export type OrganizationRow = Pick<Account, 'id' | 'name' | 'created_date' | 'allowed_countries'> & {
  parent_account?: Pick<Account, 'id' | 'name'> | null;
};

const initialCriteria = {
  pagination: {
    pageSize: TABLE_PAGE_SIZE,
    pageNumber: 1,
    totalCount: 100,
  },
  sort: {
    column: 'created_date',
    direction: SortDirection.DESC,
    sortableColumns: ['name', 'created_date', 'allowed_countries'],
  },
  filters: {
    byPhrase: {
      fields: ['name'],
      phrase: '',
    },
  },
};

interface HistoryState {
  page?: number;
  ref?: string;
}

export function OrganizationsList(): JSX.Element {
  const history = useHistoryWithRef<HistoryState>();

  const {
    loading,
    error,
    data: { account: organization = [] } = {},
  } = useQuery<GetOrganizationsListQuery, GetOrganizationsListQueryVariables>(GetOrganizationsList);

  const [criteria, setCriteria] = useState<AllTableCriteria>({
    ...initialCriteria,
    pagination: {
      ...initialCriteria.pagination,
      pageNumber: history.location.state?.page ?? 1,
    },
  });

  const rowActions = [
    {
      label: 'Edit',
      onClick: values => {
        const { id } = values;
        history.push(`/organizations/${id}/edit`);
      },
      'data-test': 'organization-edit',
    },
  ];

  const handleCriteriaChange = useCallback(
    (newCriteria, isInitialCall) => {
      setCriteria(applyCriteriaChange(criteria, newCriteria, { skipPaginationReset: isInitialCall }));
    },
    [criteria],
  );

  const getData = useMemo(() => {
    return clientSideCriteriaProcessing(organization, criteria, { onCriteriaAdjust: setCriteria });
  }, [organization, criteria]);

  useEffect(() => {
    history.replace({
      ...history.location,
      state: {
        ...history.location.state,
        page: criteria.pagination?.pageNumber,
      },
    });
  }, [criteria.pagination?.pageNumber]);

  if (error) throw new Error('Organizations Error');

  const isTableEmpty = !loading && !criteria.filters && getData.length === 0;
  const noSearchResults = !loading && criteria.filters && getData.length === 0;

  if (isTableEmpty) {
    return (
      <OrganizationsHeader handleCriteriaChange={handleCriteriaChange}>
        <Paper padding="none">
          <EmptyTablePlaceholder />
        </Paper>
      </OrganizationsHeader>
    );
  }

  return (
    <OrganizationsHeader handleCriteriaChange={handleCriteriaChange}>
      <Paper padding="none">
        <Table
          data={getData}
          columns={tableColumns}
          loading={loading}
          onCriteriaChange={handleCriteriaChange}
          onRowClick={({ id }) => history.push(`/organizations/${id}`)}
          highlightRules={(criteria.filters as SearchFiltersValue)?.byPhrase}
          criteria={criteria}
          stickyHeaderPosition={TOP_BAR_HEIGHT}
          rowActions={rowActions}
          fixedLayout
        >
          {noSearchResults && <SearchResultPlaceholder columnsAmount={tableColumns.length} />}
        </Table>
      </Paper>
    </OrganizationsHeader>
  );
}
