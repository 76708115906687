import React from 'react';
import _ from 'lodash';

import { AutoComplete } from '@openx/components/core';

export enum Propensity {
  ALL = '*',
  LOW = 'L',
  MEDIUM = 'M',
  HIGH = 'H',
}

interface PropensityDropdownProps {
  onChange: (propensity: Propensity) => void;
  value: Propensity;
}

const options = [Propensity.ALL, Propensity.LOW, Propensity.MEDIUM, Propensity.HIGH];
const optionsLabels: Record<Propensity, string> = {
  [Propensity.ALL]: 'All',
  [Propensity.LOW]: 'Low',
  [Propensity.MEDIUM]: 'Medium',
  [Propensity.HIGH]: 'High',
};

export function PropensityDropdown({ onChange, value }: PropensityDropdownProps): JSX.Element {
  return (
    <AutoComplete
      textFieldProps={{ label: 'Propensity' }}
      options={options}
      getOptionLabel={option => optionsLabels[option]}
      textFieldReadOnly
      disableClearable
      onChange={(_, value) => onChange(value)}
      value={value}
    />
  );
}
