export const MOBILE_AD_ID_DATA_TEMPLATE = [
  '00000000-0000-0000-0000-000000000001',
  '00000000-0000-0000-0000-000000000002',
  '00000000-0000-0000-0000-000000000003',
  '00000000-0000-0000-0000-000000000004',
  '00000000-0000-0000-0000-000000000005',
  '00000000-0000-0000-0000-000000000006',
  '00000000-0000-0000-0000-000000000007',
  '00000000-0000-0000-0000-000000000008',
  '00000000-0000-0000-0000-000000000009',
  '00000000-0000-0000-0000-00000000000a',
  '00000000-0000-0000-0000-00000000000b',
  '00000000-0000-0000-0000-00000000000c',
  '00000000-0000-0000-0000-00000000000d',
  '00000000-0000-0000-0000-00000000000e',
  '00000000-0000-0000-0000-00000000000f',
].join('\n');

export const EMAIL_DATA_TEMPLATE = [
  '"user1@domain.tld"',
  '"user2@domain.tld"',
  '"user3@domain.tld"',
  '"user4@domain.tld"',
  '"user5@domain.tld"',
  '"user6@domain.tld"',
  '"user7@domain.tld"',
  '"user8@domain.tld"',
  '"user9@domain.tld"',
  '"user10@domain.tld"',
  '"user11@domain.tld"',
  '"user12@domain.tld"',
  '"user13@domain.tld"',
  '"user14@domain.tld"',
  '"user15@domain.tld"',
  '"user16@domain.tld"',
  '"user17@domain.tld"',
  '"user18@domain.tld"',
].join('\n');

export const PHONE_DATA_TEMPLATE = ['15558675309', '15557265000', '15556221701', '15551235467'].join('\n');
