import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import classNames from 'classnames';
import { Theme } from '@material-ui/core';
import { ChevronRight, ExpandMore, Error } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@openx/components/core';

import { AlertTooltip } from 'components/AlertTooltip';
import { segmentExpiredText } from 'modules/segments/constants';
import { filterTreeFromNotSelected, EnhancedTreeData, TreeData, shortenPhrase } from 'utils';
import { isExpired } from 'utils/expiryDates';

import { Item } from './Item';
import { Icon as IncludeExcludeIcon } from './Icon';
import { ListType } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    flex: '1 1 100%',
    display: 'flex',
    cursor: 'pointer',
  },
  titleIcon: {
    marginLeft: theme.spacing(3),
  },
  subOptions: {
    display: 'none',
  },
  show: {
    display: 'block',
  },
}));

type NestedListProps = {
  items: TreeData[];
  onClick?: (item: EnhancedTreeData) => void;
  onDelete: (item: EnhancedTreeData) => void;
  tree: EnhancedTreeData[];
  type: ListType;
  clickedOptions?: TreeData[];
  readonly: boolean;
};

const mapTypeToActionLabel = {
  [ListType.INCLUDE]: 'EXCLUDE',
  [ListType.EXCLUDE]: 'INCLUDE',
};

export const NestedList = React.memo(
  (props: NestedListProps): JSX.Element => {
    const { items, onClick, onDelete, tree, type, readonly, clickedOptions = [] } = props;
    const classes = useStyles();
    const [expandedSubOptions, setExpandedSubOptions] = useState({});
    const [maxLength, setMaxLength] = useState(57); //maximal amount of characters that can be visible in minimal possible width
    const nestedListItem = useRef<HTMLDivElement>(null);

    const optionKey = (option, type) => `${option.value}-${type}`;
    const isExpanded = useCallback((option, type) => expandedSubOptions[optionKey(option, type)], [expandedSubOptions]);
    const hasChildren = option => !isEmpty(option.children);

    const hasExpiredSegment = useCallback((option: EnhancedTreeData) => {
      return option.children?.some(item => isExpired(item.status));
    }, []);

    const expiredText = useMemo(() => segmentExpiredText(false), []);

    const selectedTree = useMemo(() => filterTreeFromNotSelected(tree, items), [tree, items]);

    const onExpand = useCallback(
      (option, type) => {
        if (!hasChildren(option)) return;
        setExpandedSubOptions({ ...expandedSubOptions, [optionKey(option, type)]: !isExpanded(option, type) });
      },
      [expandedSubOptions, isExpanded],
    );

    const phraseShortener = useCallback(
      (title: string, limit: number = maxLength) => shortenPhrase(title, limit),
      [maxLength],
    );

    useEffect(() => {
      if (nestedListItem?.current?.offsetWidth) {
        setMaxLength(Math.ceil(nestedListItem?.current?.offsetWidth / 9));
      }
    }, [nestedListItem?.current?.offsetWidth]);

    const renderTree = useCallback(
      (option: EnhancedTreeData, level = 0) => {
        const Icon = isExpanded(option, type) ? ExpandMore : ChevronRight;
        const isClicked = clickedOptions.find(clicked => clicked.value === option.value);

        if (
          !isClicked &&
          hasChildren(option) &&
          level === 0 &&
          option.children?.every(({ is_nav_only }) => !!is_nav_only)
        ) {
          return option.children?.map(subOption => renderTree(subOption, level));
        }
        return (
          <div key={option.id}>
            <Item
              item={option}
              icon={
                <>
                  <IncludeExcludeIcon type={type} />
                  {level === 0 && hasExpiredSegment(option) && !isExpanded(option, type) && (
                    <AlertTooltip
                      color="#D83158"
                      title={expiredText}
                      icon={<Error fontSize="small" color="inherit" data-test="expired-alert" />}
                    />
                  )}
                </>
              }
              indentationLevel={level}
              onClick={onClick}
              clickLabel={mapTypeToActionLabel[type]}
              onDelete={onDelete}
              readonly={readonly}
              isNavOnly={option.is_nav_only}
              title={
                <Tooltip title={option.description || ''} placement="top">
                  <span className={classes.title} onClick={() => onExpand(option, type)}>
                    {option.is_nav_only && level === 0 && phraseShortener(option.calculated_full_name, maxLength)}
                    {(!option.is_nav_only || level > 0) && option.title}
                    {hasChildren(option) && <Icon className={classes.titleIcon} fontSize="small" />}
                  </span>
                </Tooltip>
              }
            />
            {hasChildren(option) &&
              isExpanded(option, type) &&
              option.children?.map(subOption => (
                <div
                  key={subOption.value}
                  className={classNames(classes.subOptions, { [classes.show]: isExpanded(option, type) })}
                >
                  {renderTree(subOption, level + 1)}
                </div>
              ))}
          </div>
        );
      },
      [
        isExpanded,
        type,
        clickedOptions,
        hasExpiredSegment,
        expiredText,
        onClick,
        onDelete,
        readonly,
        classes.title,
        classes.titleIcon,
        classes.subOptions,
        classes.show,
        phraseShortener,
        maxLength,
        onExpand,
      ],
    );

    return <div ref={nestedListItem}>{selectedTree.map(option => renderTree(option))}</div>;
  },
  (prevProps, nextProps) => {
    const itemsChanged = isEqual(prevProps.items, nextProps.items);
    const typeChanged = prevProps.type !== nextProps.type;

    return itemsChanged && typeChanged;
  },
);
