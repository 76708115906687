import { firstLetterUppercase } from '@openx/utils';

export enum supportedFields {
  SEGMENT_PROVIDER = 'segment_provider',
  AUDIENCE_PROVIDER = 'audience_provider',
  SEGMENT_STATUS = 'segment_status',
  AUDIENCE_STATUS = 'audience_status',
  DEAL_STATUS = 'deal_status',
  DEAL_ACCOUNT = 'deal_account',
  AUDIENCE_ACCOUNT = 'audience_account',
  SEGMENT_ACCOUNT = 'segment_account',
  TAXONOMY_CATEGORY = 'taxonomy_category',
  TAXONOMY_SUB_CATEGORY = 'taxonomy_sub_category',
  TAXONOMY_TYPE = 'taxonomy_type',
  TAXONOMY_SOURCE = 'taxonomy_source',
}

export interface FieldWithOptions {
  fieldName: supportedFields;
  options: (string | null)[];
}

const statusMap = {
  [supportedFields.DEAL_STATUS]: {
    Active: ['Active'],
    Paused: ['Paused'],
    Publishing: ['Publishing', 'ReadyForActivation'],
    Expired: ['Expired'],
    Error: ['Error'],
  },
};

const reversedDealStatusMap = {
  Active: 'Active',
  Paused: 'Paused',
  Publishing: 'Publishing',
  ReadyForActivation: 'Publishing',
  Expired: 'Expired',
  Error: 'Error',
};

export const deriveAudienceStatusValue = (value: string): string =>
  value
    .split('_')
    .map(value => firstLetterUppercase(value))
    .join(' ');

export const deriveSegmentStatusValue = (value: string): string =>
  value
    .split('_')
    .map(value => firstLetterUppercase(value))
    .join(' ');

export const deriveDealStatusValue = (value: string): string => reversedDealStatusMap[value];

export const prepareStatuses = (statusField, statuses) => {
  if ([supportedFields.AUDIENCE_STATUS, supportedFields.SEGMENT_STATUS].includes(statusField)) {
    // Reverse back
    return statuses.reduce(
      (acc, cur) => [
        ...acc,
        cur
          .split(' ')
          .map(word => word.toLowerCase())
          .join('_'),
      ],
      [],
    );
  } else {
    return statuses.reduce((acc, cur) => [...acc, ...statusMap[statusField][cur]], []);
  }
};
export const filtersConfig = {
  [supportedFields.AUDIENCE_PROVIDER]: {
    'data-test': 'audience-provider-filter',
    title: 'Provider',
    textFieldProps: {
      placeholder: '[ Select Provider ]',
    },
    getOptionLabel: option => (option ? option : 'Invalid Provider'),
  },
  [supportedFields.SEGMENT_PROVIDER]: {
    'data-test': 'segment-provider-filter',
    title: 'Provider',
    textFieldProps: {
      placeholder: '[ Select Provider ]',
    },
    getOptionLabel: option => option,
  },
  [supportedFields.AUDIENCE_STATUS]: {
    'data-test': 'audience-status-filter',
    title: 'Status',
    textFieldProps: {
      placeholder: '[ Select Status ]',
    },
    getOptionLabel: option => option,
  },
  [supportedFields.SEGMENT_STATUS]: {
    'data-test': 'segment-status-filter',
    title: 'Status',
    textFieldProps: {
      placeholder: '[ Select Status ]',
    },
    getOptionLabel: option => option,
  },
  [supportedFields.DEAL_STATUS]: {
    'data-test': 'deal-status-filter',
    title: 'Status',
    textFieldProps: {
      placeholder: '[ Select Status ]',
    },
    getOptionLabel: option => option,
  },
  [supportedFields.DEAL_ACCOUNT]: {
    'data-test': 'deal-account-filter',
    title: 'Organizations',
    textFieldProps: {
      placeholder: '[Select Account]',
    },
    getOptionLabel: option => option,
  },
  [supportedFields.AUDIENCE_ACCOUNT]: {
    'data-test': 'audience-account-filter',
    title: 'Organizations',
    textFieldProps: {
      placeholder: '[Select Account]',
    },
    getOptionLabel: option => option,
  },
  [supportedFields.SEGMENT_ACCOUNT]: {
    'data-test': 'segment-account-filter',
    title: 'Organizations',
    textFieldProps: {
      placeholder: '[Select Account]',
    },
    getOptionLabel: option => option,
  },
  [supportedFields.TAXONOMY_CATEGORY]: {
    'data-test': 'taxonomy-category-filter',
    title: 'Category',
    textFieldProps: {
      placeholder: '[Select Segment Category]',
    },
    getOptionLabel: (option: string) =>
      option.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase())),
  },
  [supportedFields.TAXONOMY_SUB_CATEGORY]: {
    'data-test': 'taxonomy-sub-category-filter',
    title: 'Sub Category',
    textFieldProps: {
      placeholder: '[Select Segment Sub Category]',
    },
    getOptionLabel: (option: string) =>
      option.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase())),
  },
  [supportedFields.TAXONOMY_TYPE]: {
    'data-test': 'taxonomy-type-filter',
    title: 'Type',
    textFieldProps: {
      placeholder: '[Select Segment Type]',
    },
    getOptionLabel: (option: string) =>
      option.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase())),
  },
  [supportedFields.TAXONOMY_SOURCE]: {
    'data-test': 'taxonomy-source-filter',
    title: 'Source',
    textFieldProps: {
      placeholder: '[Select Segment Source]',
    },
    getOptionLabel: (option: string) =>
      option.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase())),
  },
};
