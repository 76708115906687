import React, { useCallback, useMemo } from 'react';
import { FieldProps } from 'formik';
import { DateTimePicker, DateTimePickerProps } from '@material-ui/pickers';
import { InputAdornment, IconButton, FormControl, FormLabel, makeStyles } from '@material-ui/core';
import { Clear as ClearIcon, InsertInvitation as CalendarIcon } from '@material-ui/icons';

type DateTimePickerFieldProps = DateTimePickerProps &
  Partial<FieldProps> & {
    'data-test'?: string;
    label?: string;
    helperText?: string;
    withTime?: boolean;
  };

const defaultProps: Partial<DateTimePickerProps> = {
  inputVariant: 'outlined',
  variant: 'inline',
  autoOk: true,
  format: 'YYYY-MM-DD hh:mm A',
  placeholder: 'YYYY-MM-DD HH:mm',
};

const makeInputProps = ({ clearable, clearableCallback, inputValue }) => ({
  endAdornment:
    clearable && inputValue ? (
      <InputAdornment position="end">
        UTC
        <IconButton data-testid="clear-button" onClick={clearableCallback}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ) : (
      <InputAdornment position="end">
        UTC{' '}
        <IconButton>
          <CalendarIcon />
        </IconButton>
      </InputAdornment>
    ),
});

const useStyles = makeStyles({
  input: {
    marginTop: 0,
  },
});

export const DateTimePickerField = (props: DateTimePickerFieldProps): JSX.Element => {
  const {
    name,
    value,
    label,
    'data-test': dataTest,
    error,
    onChange,
    helperText,
    clearable,
    form,
    field,
    margin = 'none',
    ...otherProps
  } = props;

  const classes = useStyles();

  let pickerProps = {
    name,
    value,
    error,
    onChange,
    helperText,
  };

  if (props.form && props.field) {
    const {
      form: { errors, setFieldValue },
      field: { name: fieldName, value: fieldValue },
    } = props as FieldProps;

    const fieldError = errors[fieldName];
    pickerProps = {
      name: fieldName,
      value: fieldValue,
      error: Boolean(fieldError),
      helperText: fieldError ? (fieldError as string) : undefined,
      onChange: date => setFieldValue(fieldName, date),
    };
  }

  const clearableCallback = useCallback(
    e => {
      e.stopPropagation();
      if (props.form && props.field) {
        props.form.setFieldValue(props.field.name, null);
        return;
      }
      if (pickerProps.onChange) {
        pickerProps.onChange(null);
      }
    },
    [pickerProps, props.field, props.form],
  );

  const inputValue = props.field ? props.field.value : props.value;

  const InputProps = useMemo(
    () => makeInputProps({ clearable, clearableCallback, inputValue }),
    [clearable, clearableCallback, inputValue],
  );

  return (
    <FormControl data-test={dataTest} fullWidth margin={margin}>
      {label && <FormLabel>{label}</FormLabel>}
      <DateTimePicker
        className={classes.input}
        data-testid="date-picker"
        {...defaultProps}
        {...pickerProps}
        InputProps={InputProps}
        {...otherProps}
      />
    </FormControl>
  );
};
