import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { createStyles, CircularProgress, Theme, Typography, Grid, Divider } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Alert } from '@material-ui/lab';
import { CSVLink } from 'react-csv';

import { Paper, Loader, AutoCompleteVirtualize, Tooltip, Button } from '@openx/components/core';
import GetAudienceCategoriesReach from 'graphql/query/audiences/GetAudienceCategoriesReach.gql';
import GetSfAccountNames from 'graphql/query/audiences/GetSfAccountNames.gql';
import { bigNumberWithCommas } from '@openx/utils';
import { isInternalAustralianUser, isInternalUser } from 'permissions';
import { useSession } from 'modules/auth/AuthProvider';

import { Bar } from './Bar';
import { tooltipText, categoriesConfig, devicesConfig } from './constants';
import { useIsInsightsPanelDisabled } from './useIsInsightsPanelDisabled';
import { Maybe } from 'types/schemaTypes';
import { useAudience, ActionType, ReportStatus } from 'modules/audiences/AudienceProvider';
import UpdatePublisherReportStatus from 'graphql/mutation/audiences/UpdatePublisherReportStatus.gql';
import { DisplayBigNumber } from 'components/DataDisplay';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalRequestsLabel: {
      color: theme.palette.text.hint,
      marginBottom: theme.spacing(0.5),
      textTransform: 'uppercase',
    },
    totalRequestsValue: {
      alignItems: 'center',
      display: 'flex',
      '&::before': {
        display: 'none',
      },
    },
    totalRequestsTitle: {
      marginTop: '10px',
      marginBottom: '20px',
    },
    divider: {
      width: 'calc(100% + 40px)',
      margin: '20px -20px 10px -20px',
    },
    estimatedReachLoader: {
      height: '16px !important',
      width: '16px !important',
      '&:not(:first-child)': {
        marginLeft: theme.spacing(0.5),
      },
    },
    loader: {
      width: '100%',
    },
    bar: {
      height: 6,
      marginLeft: 50,
      width: '100%',
    },
    requestsTitle: {
      marginTop: 30,
      marginBottom: 30,
    },
    categoryTitle: {
      marginLeft: theme.spacing(1.5),
    },
    categoryReachNumber: {
      fontSize: 17,
      lineHeight: '22px',
      fontWeight: 600,
      width: '50%',
    },
    categoryPercentageReach: {
      marginLeft: 'auto',
    },
    fullWidth: {
      width: '100%',
    },
    categorySubContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    barContainer: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(),
    },
    evenBox: {
      padding: theme.spacing(2, 4, 2, 0),
    },
    oddBox: {
      padding: theme.spacing(2, 0, 2, 4),
    },
    box: {
      width: '50%',
      display: 'inline-block',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    requestBlock: {
      flexBasis: '33%',
    },
    deviceBlock: {
      flexBasis: '25%',
    },
    publisherSelectWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(3.75),
    },
    publisherSelect: {
      width: '100%',
      marginRight: theme.spacing(1.25),
    },
    info: {
      bottom: '0',
    },
    headerCaption: {
      display: 'inline',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '9px',
      marginBottom: '30px',
    },
    reportButton: {
      marginTop: '5px',
      marginRight: '5px',
    },
    downloadButton: {
      marginTop: '5px',
    },
    linkColor: {
      color: theme.palette.background.paper,
    },
    infoAlert: {
      backgroundColor: '#EBF4FA',
      padding: '6px 20px 6px 19.5px',
      marginBottom: '15px',
    },
    successAlert: {
      backgroundColor: '#EEF9F6',
      padding: '6px 20px 6px 19.5px',
      marginBottom: '15px',
    },
  }),
);

interface InsightsProps {
  audienceId: string;
  disabled?: boolean;
  disabledText?: string;
  disableReportGeneration?: boolean;
  reportStatus?: Maybe<string>;
  audienceStatus?: string;
}

interface GetAudienceCategories {
  audience_categories_reach_all: {
    sfAccountName: string;
    sfAccountId: string;
    cookiesWithRequests: number;
    total: number;
    display: number;
    video: number;
    mobileapp: number;
    mobileweb: number;
    desktop: number;
    connectedtv: number;
    tablet: number;
    mobileDevicesWithRequests: number;
    totalmau: number;
    idlXyWithRequests: number;
    idlXiWithRequests: number;
    uniqueUsersViaConnectedTVWithRequests: number;
    uniqueConnectedTVDeviceIDsWithRequests: number;
    ip: number;
    totalmaip: number;
    totalmaid5: number;
  }[];

  audience_reach: {
    Mobile_Devices_With_Requests: number;
    Tablet_Devices_With_Requests: number;
    Unique_ConnectedTVDeviceIDs_With_Requests: number;
    Cookies_With_Requests: number;
  };
}
interface GetReachQueryVariables {
  id: string;
  sfAccountName?: string;
}

enum TotalRequestsScope {
  TOTAL = 'total',
  TOTAL_MAU = 'totalmau',
  TOTAL_MARID = 'totalmarid',
  TOTAL_MAIP = 'totalmaip',
  TOTAL_MAID5 = 'totalmaid5',
}

enum EstimatesScope {
  DEVICES = 'devices',
  REQUESTS = 'requests',
}

const getCategoryPercentageReach = (categoryValue: number, totalValue: number) => {
  if (totalValue === 0) {
    return 0;
  }

  return Math.round((categoryValue * 100) / totalValue);
};

/**
 * Renders MAU reach for a saved audience
 */
export function Insights({
  audienceId,
  disabled = false,
  disabledText,
  disableReportGeneration = false,
  reportStatus,
  audienceStatus,
}: InsightsProps): JSX.Element {
  const classes = useStyles();

  const session = useSession();
  const { state, dispatch } = useAudience();

  const {
    loading,
    error,
    data: audienceCategoriesReach,
    refetch: refetchMetrics,
  } = useQuery<GetAudienceCategories, GetReachQueryVariables>(GetAudienceCategoriesReach, {
    variables: { id: audienceId },
    skip: disabled,
    notifyOnNetworkStatusChange: true,
    onCompleted: (audienceCategoriesReach: any) => {
      if (audienceCategoriesReach.audience_categories_reach_all) {
        dispatch({
          type: ActionType.SET_AUDIENCE_INSIGHTS,
          payload: audienceCategoriesReach?.audience_categories_reach_all.find(data => {
            return data.sfAccountName === 'TOTAL';
          }),
        });
      }
    },
  });

  const {
    loading: sfLoading,
    error: sfError,
    data: { sf_account_names: sfAccountNames = [] } = {},
    refetch: refetchSfAccountNames,
  } = useQuery(GetSfAccountNames, {
    variables: { id: audienceId },
  });

  const initialAudienceCategoriesReachData = audienceCategoriesReach?.audience_categories_reach_all.find(data => {
    return data.sfAccountName === 'TOTAL';
  });
  const [isPublisherSelected, setIsPublisherSelected] = useState(false);
  const [publisherAudienceCategoriesReach, setPublisherAudienceCategoriesReach] = useState(
    initialAudienceCategoriesReachData,
  );

  const renderTotalRequests = (scope: TotalRequestsScope) => {
    if (error || sfError) {
      return <span>-</span>;
    }

    if (loading) {
      return <CircularProgress className={classes.estimatedReachLoader} />;
    }
    let rawTotal;
    rawTotal = isPublisherSelected
      ? publisherAudienceCategoriesReach?.[scope] || 0
      : initialAudienceCategoriesReachData?.[scope] || 0;
    if (scope === TotalRequestsScope.TOTAL_MARID) {
      rawTotal = isPublisherSelected
        ? publisherAudienceCategoriesReach?.idlXyWithRequests && publisherAudienceCategoriesReach?.idlXiWithRequests
          ? publisherAudienceCategoriesReach?.idlXyWithRequests + publisherAudienceCategoriesReach?.idlXiWithRequests
          : 0
        : initialAudienceCategoriesReachData?.idlXyWithRequests && initialAudienceCategoriesReachData?.idlXiWithRequests
        ? initialAudienceCategoriesReachData?.idlXyWithRequests + initialAudienceCategoriesReachData?.idlXiWithRequests
        : 0;
    }

    return <DisplayBigNumber number={rawTotal} compact={true} />;
  };

  const renderEstimates = (scope: EstimatesScope): JSX.Element => {
    if (error) {
      return <span>Couldn't get the data...</span>;
    }

    if (loading || sfLoading) {
      return (
        <div className={classes.loader}>
          <Loader />
        </div>
      );
    }

    const config = scope === EstimatesScope.DEVICES ? devicesConfig : categoriesConfig;

    return (
      <div
        className={classNames({
          [classes.fullWidth]: scope !== EstimatesScope.DEVICES,
          [classes.row]: scope === EstimatesScope.DEVICES,
        })}
      >
        {config.map((category, i) => (
          <div
            className={classNames({
              [classes.box]: scope !== EstimatesScope.DEVICES,
              [classes.deviceBlock]: scope === EstimatesScope.DEVICES,
              [classes.oddBox]: i % 2 !== 0 && scope !== EstimatesScope.DEVICES,
              [classes.evenBox]: i % 2 === 0 && scope !== EstimatesScope.DEVICES,
            })}
            key={category.valueKey}
            data-test={`insights-${category.valueKey}`}
          >
            {renderCategoryReach(category)}
          </div>
        ))}
      </div>
    );
  };

  const fetchMetrics = sfAccountName => {
    const data = audienceCategoriesReach?.audience_categories_reach_all.find(data => {
      return data.sfAccountName === sfAccountName;
    });
    setIsPublisherSelected(true);
    setPublisherAudienceCategoriesReach(data);
  };

  const renderCategoryReach = ({ icon: Icon, title, valueKey }): JSX.Element => {
    const categoryValue = isPublisherSelected
      ? publisherAudienceCategoriesReach?.[valueKey] || 0
      : initialAudienceCategoriesReachData?.[valueKey] || 0;

    return (
      <div>
        <div className={classes.categorySubContainer}>
          <Icon />
          <Typography variant="caption" color="textSecondary" className={classes.categoryTitle}>
            {title}
          </Typography>
        </div>
        <div
          className={classNames({
            [classes.categorySubContainer]: true,
            [classes.barContainer]: true,
          })}
        >
          <Typography className={classes.categoryReachNumber}>{bigNumberWithCommas(categoryValue)}</Typography>
        </div>
      </div>
    );
  };

  const { isDisabled, disabledMessage } = useIsInsightsPanelDisabled({ disabled, disabledText, sfError, error });

  const [updatePublisherReportStatus, { loading: reportStatusLoading }] = useMutation(UpdatePublisherReportStatus, {
    refetchQueries: ['GetAudience'],
  });

  const generateSFReport = () => {
    updatePublisherReportStatus({ variables: { id: audienceId } });
  };

  const onPageRefresh = () => {
    window.location.reload();
  };

  const isGeneratingReport = reportStatus === ReportStatus.SF_PROCESSING;
  const isSFButtonDisabled = isGeneratingReport || audienceStatus !== 'exported';

  const isReportGenerated = reportStatus === ReportStatus.SF_COMPLETE;

  const isUserInternal = isInternalUser(session) || isInternalAustralianUser(session);
  const isSfAccountsDropdownDisabled = !isUserInternal || !isReportGenerated;
  const showInfoAlert = isUserInternal && isGeneratingReport;
  const showSuccessAlert = isUserInternal && isReportGenerated;

  const audienceCategoriesReachCsvHeaders = [
    { label: 'Publisher', key: 'sfAccountName' },
    { label: 'Requests', key: 'total' },
    { label: 'Unique_Users_With_Requests', key: 'totalmau' },
    { label: 'Cookies_With_Requests', key: 'cookiesWithRequests' },
    { label: 'Mobile_Devices_With_Requests', key: 'mobileDevicesWithRequests' },
    { label: 'Tablet_Devices_With_Requests', key: 'tablet' },
    { label: 'IDL_XY_With_Requests', key: 'idlXyWithRequests' },
    { label: 'IDL_Xi_With_Requests', key: 'idlXiWithRequests' },
    { label: 'ConnectedTVDeviceID_Requests', key: 'connectedtv' },
    {
      label: 'Unique_ConnectedTVDeviceIDs_With_Requests',
      key: 'uniqueConnectedTVDeviceIDsWithRequests',
    },
    { label: 'Unique_Users_Via_ConnectedTV_With_Requests', key: 'uniqueUsersViaConnectedTVWithRequests' },
  ];

  useEffect(() => {
    if (reportStatus === ReportStatus.SF_COMPLETE) {
      refetchSfAccountNames({ id: audienceId });
    }
  }, [reportStatus, refetchSfAccountNames, audienceId]);

  return (
    <Paper data-test="insights-component">
      {({ HighlightedSection }) => (
        <>
          <div className={classes.header}>
            <Typography variant="h2" data-test="insights-title" className={classes.headerCaption}>
              Insights
            </Typography>

            {isUserInternal && (
              <div>
                <Button
                  color="primary"
                  onClick={generateSFReport}
                  disabled={isSFButtonDisabled || disableReportGeneration}
                  className={classes.reportButton}
                  loading={reportStatusLoading}
                >
                  Generate Publisher Estimates
                </Button>
                {!loading && (
                  <Button className={classes.reportButton} disabled={!isReportGenerated}>
                    <CSVLink
                      headers={audienceCategoriesReachCsvHeaders}
                      data={
                        audienceCategoriesReach?.audience_categories_reach_all !== undefined
                          ? audienceCategoriesReach?.audience_categories_reach_all
                          : []
                      }
                      filename={'AudienceReachReport'}
                      className={classes.linkColor}
                      target="_blank"
                    >
                      Download
                    </CSVLink>
                  </Button>
                )}
              </div>
            )}
          </div>

          {isUserInternal && (
            <>
              {showInfoAlert && (
                <div className={classes.infoAlert}>
                  <Alert
                    severity="info"
                    action={
                      <Button color="primary" variant="text" onClick={onPageRefresh}>
                        REFRESH PAGE
                      </Button>
                    }
                  >
                    Report being generated, check back in 10 minutes.
                  </Alert>
                </div>
              )}
              {showSuccessAlert && (
                <div className={classes.successAlert}>
                  <Alert severity="success">
                    Report generated successfully. Now you can select publishers below to check insights.
                  </Alert>
                </div>
              )}
              <div className={classes.publisherSelectWrapper}>
                <AutoCompleteVirtualize
                  className={classes.publisherSelect}
                  textFieldProps={{ label: 'Publisher', placeholder: '[ Search and Select Publisher ]' }}
                  options={sfAccountNames}
                  onChange={(_, value) => fetchMetrics(value as string)}
                  disableClearable
                  disabled={isSfAccountsDropdownDisabled}
                />
                <Tooltip
                  className={classes.info}
                  placement="right"
                  title={tooltipText}
                  withRipple
                  iconDataTest="select-publisher-info-icon"
                />
              </div>
            </>
          )}

          {isDisabled && (
            <HighlightedSection>
              <Alert severity="warning" data-test="disabled-text">
                {disabledMessage}
              </Alert>
            </HighlightedSection>
          )}

          {!isDisabled && (
            <>
              <HighlightedSection>
                <Typography
                  variant="h3"
                  className={classes.totalRequestsTitle}
                  data-test="monthly-active-reach-estimates-title"
                >
                  Monthly Active Reach Estimates
                </Typography>
                <div className={classes.row}>
                  <div className={classes.requestBlock}>
                    <Typography variant="caption" className={classes.totalRequestsLabel}>
                      Requests
                    </Typography>
                    <Typography variant="h2" className={classes.totalRequestsValue} data-test="insights-requests">
                      {renderTotalRequests(TotalRequestsScope.TOTAL)}
                    </Typography>
                  </div>
                  <div className={classes.requestBlock}>
                    <Typography variant="caption" className={classes.totalRequestsLabel}>
                      Active Users
                    </Typography>
                    <Typography variant="h2" className={classes.totalRequestsValue} data-test="insights-users">
                      {renderTotalRequests(TotalRequestsScope.TOTAL_MAU)}
                    </Typography>
                  </div>
                  <div className={classes.requestBlock}>
                    <Typography variant="caption" className={classes.totalRequestsLabel}>
                      Active RampIDs
                    </Typography>
                    <Typography variant="h2" className={classes.totalRequestsValue} data-test="insights-rampids">
                      {renderTotalRequests(TotalRequestsScope.TOTAL_MARID)}
                    </Typography>
                  </div>
                  <div className={classes.requestBlock}>
                    <Typography variant="caption" className={classes.totalRequestsLabel}>
                      Active IPs
                    </Typography>
                    <Typography variant="h2" className={classes.totalRequestsValue} data-test="insights-ips">
                      {renderTotalRequests(TotalRequestsScope.TOTAL_MAIP)}
                    </Typography>
                  </div>
                  <div className={classes.requestBlock}>
                    <Typography variant="caption" className={classes.totalRequestsLabel}>
                      Active ID5 IDS
                    </Typography>
                    <Typography variant="h2" className={classes.totalRequestsValue} data-test="insights-id5s">
                      {renderTotalRequests(TotalRequestsScope.TOTAL_MAID5)}
                    </Typography>
                  </div>
                </div>
              </HighlightedSection>

              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h3" className={classes.requestsTitle} data-test="monthly-device-estimates-title">
                    Monthly Device Estimates
                  </Typography>
                </Grid>
                {renderEstimates(EstimatesScope.DEVICES)}

                <Divider className={classes.divider} />
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    className={classes.requestsTitle}
                    data-test="monthly-request-estimates-title"
                  >
                    Monthly Request Estimates
                  </Typography>
                </Grid>
                {renderEstimates(EstimatesScope.REQUESTS)}
              </Grid>
            </>
          )}
        </>
      )}
    </Paper>
  );
}
