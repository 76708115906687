import { Box, Typography, makeStyles } from '@material-ui/core';
import { Block, Check, ChevronRight } from '@material-ui/icons';
import { Ellipsis, HighlightedPhrase, Loader, Tooltip } from '@openx/components/core';
import React from 'react';

interface StyleProps {
  expanded?: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  name: {
    fontSize: '13px',
    fontWeight: 600,
  },
});

export const NameCell = ({ data }) => {
  const { name: segmentName, segment_audiences: segmentAudiences = [] } = data;
  const { root, name } = useStyles();

  const hasAssociatedAudiences = !!segmentAudiences.length;

  return (
    <Box className={root} alignItems="center">
      <Box data-test={`archive-${hasAssociatedAudiences ? 'forbidden' : 'allowed'}-icon`}>
        {hasAssociatedAudiences ? (
          <Tooltip title="Cannot be archived because of associated Audience">
            <Block color="error" fontSize="small" />
          </Tooltip>
        ) : (
          <Tooltip title="Ready to archive">
            <Check fontSize="small" htmlColor="#2CBA8F" />
          </Tooltip>
        )}
      </Box>

      <Box paddingLeft={1} paddingRight={1}>
        <Typography variant="body1" component="span" color="textPrimary" className={name} data-test="segment-name">
          <Ellipsis tooltip width={286}>
            {segmentName}
          </Ellipsis>
        </Typography>
      </Box>
    </Box>
  );
};
