import { Theme, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import cx from 'classnames';
import React, { useMemo } from 'react';

import { defaultPadding, paddingMap, verticalPaddingFactor } from './config';
import { makeIndentation } from './Indentation';
import { HighlightedSectionProps, PaperPadding, StylesProps } from './types';

const useStyles = makeStyles<Theme, StylesProps>(theme => ({
  wrapper: {
    marginBottom: ({ padding = defaultPadding }) => -(paddingMap[padding] * verticalPaddingFactor),
    marginTop: ({ padding = defaultPadding }) => paddingMap[padding] * verticalPaddingFactor,
  },
  body: {
    background: theme.palette.background.boxLight,
    padding: ({ padding = defaultPadding }) => `${theme.spacing(1)}px ${paddingMap[padding]}px`,
  },
}));

export const makeActionsSection = (padding: PaperPadding = defaultPadding) =>
  function ({ children, className }: HighlightedSectionProps): JSX.Element {
    const classes = useStyles({ padding });
    const Indentation = useMemo(() => makeIndentation(padding), []);

    return (
      <Indentation className={cx(classes.wrapper, className)}>
        <Divider />
        <div className={classes.body}>{children}</div>
      </Indentation>
    );
  };
