import React from 'react';
import { Field, Form, FormikProps } from 'formik';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { TextField, Button } from '@openx/components/core';

import { FormValues } from './ForgotPassword';
import { EMAIL_FIELD } from './constants';

const useStyles = makeStyles({
  actions: {
    marginTop: 10,
  },
});

export function ForgotPasswordForm({ isSubmitting }: FormikProps<FormValues>): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Form>
      <Field
        name={EMAIL_FIELD}
        label="Email"
        placeholder="Enter your email"
        component={TextField}
        type="text"
        required
        fullWidth
        margin="dense"
        data-test="forgot-password-field"
      />
      <Grid container justifyContent="flex-end" alignItems="center" spacing={2} classes={{ root: classes.actions }}>
        <Grid item>
          <Button variant="text" onClick={() => history.push('/login')}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" loading={isSubmitting} data-test="submit-button">
            Reset Password
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
}
