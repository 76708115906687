import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';

import { RevenueMethod } from '../AudienceShare';

export const AllowedOrganizationsList = ({ publisherList }) => {
  return (
    <Box>
      {publisherList.map(({ recipients, fee, revenueMethod, shareId }, index) => (
        <Box key={shareId} mt={1.5}>
          <Typography variant="caption" color="textSecondary" data-test="publisher-title">
            Publisher(s)
          </Typography>
          {recipients.map(({ name, id }) => (
            <Box key={id} mt={0.6} mb={0.6}>
              <Typography variant="body1" data-test="publisher-name">
                {name}
              </Typography>
            </Box>
          ))}
          <Box mt={2.4} mb={3}>
            {fee !== '0' && (
              <Box>
                {revenueMethod === RevenueMethod.PoM && (
                  <>
                    <Typography variant="h3" data-test="publisher-fee-type">
                      Percentage of Media
                    </Typography>
                    <Box mt={1.5} mb={1.5}>
                      <Typography variant="caption" color="textSecondary" data-test="publisher-fee-type-caption">
                        Fee
                      </Typography>
                    </Box>
                  </>
                )}
                {revenueMethod === RevenueMethod.CPM && (
                  <>
                    <Typography variant="h3" data-test="publisher-fee-type">
                      Fixed CPM
                    </Typography>
                    <Box mt={1.5} mb={1.5}>
                      <Typography variant="caption" color="textSecondary" data-test="publisher-fee-type-caption">
                        CPM
                      </Typography>
                    </Box>
                  </>
                )}
                <Typography variant="body1" data-test="fee-value">
                  {revenueMethod === RevenueMethod.CPM && '$'}
                  {fee}
                  {revenueMethod === RevenueMethod.PoM && '%'}
                </Typography>
              </Box>
            )}
          </Box>

          {index < publisherList.length - 1 && <Divider />}
        </Box>
      ))}
    </Box>
  );
};
