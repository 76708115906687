import React from 'react';
import classnames from 'classnames';
import { CheckSharp, Block } from '@material-ui/icons';
import { createStyles, Theme, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Alert } from '@material-ui/lab';

import { numberWithCommas } from 'utils';
import { DataType, getDataTypeLabel } from './dataType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      padding: 0,
    },
    recordsNumber: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    recordsStatus: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    successIcon: {
      color: theme.palette.success.main,
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
  }),
);

export enum UploadFileSummaryStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
}

interface UploadFileSuccessSummaryProps {
  status: UploadFileSummaryStatus.SUCCESS;
  dataType: DataType;
  processingTime: number; // in seconds
  recordsProcessed: number;
  recordsFailed: number;
}

interface UploadFileFailedSummaryProps {
  status: UploadFileSummaryStatus.FAILED;
}

export type UploadFileSummaryProps = UploadFileSuccessSummaryProps | UploadFileFailedSummaryProps;

export function UploadFileSummary(props: UploadFileSummaryProps): JSX.Element {
  const classes = useStyles();

  if (props.status === UploadFileSummaryStatus.FAILED) {
    return (
      <>
        <Typography paragraph>
          <strong>Upload failed</strong>
        </Typography>
        <Alert severity="error" className={classes.alert} data-test="alert-message">
          There was a problem with uploading your file. Please try again later.
        </Alert>
      </>
    );
  }

  const { dataType, processingTime, recordsProcessed, recordsFailed } = props;

  const recordsProcessedSuccesfully = recordsProcessed - recordsFailed;
  const dataTypeLabel = getDataTypeLabel(dataType);

  return (
    <>
      <Typography paragraph data-test="upload-summary">
        <strong>Upload completed</strong>
      </Typography>
      <Typography color="textSecondary" paragraph data-test="upload-summary">
        <span className={classes.recordsNumber}>{numberWithCommas(recordsProcessed)}</span>
        &nbsp;{dataTypeLabel} records processed in {processingTime} second{processingTime > 1 ? 's' : ''}.
      </Typography>
      <Typography color="textSecondary" className={classes.recordsStatus} data-test="upload-summary">
        <CheckSharp className={classnames([classes.icon, classes.successIcon])} data-test="success-icon" />
        <span className={classes.recordsNumber}>{numberWithCommas(recordsProcessedSuccesfully)}</span>
        &nbsp;records uploaded successful
      </Typography>
      <Typography color="textSecondary" className={classes.recordsStatus} data-test="upload-summary">
        <Block className={classnames([classes.icon, classes.errorIcon])} data-test="error-icon" />
        <span className={classes.recordsNumber}>{numberWithCommas(recordsFailed)}</span>
        &nbsp;records skipped due to invalid data
      </Typography>
    </>
  );
}
