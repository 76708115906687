import React, { memo, FC, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Loader } from '@openx/components/core';

import { Title as ListTitle } from 'components/OptionsList';
import {
  fields,
  Demographics,
  AgeType,
  NormalizedSegment,
  ActionType,
  useAudience,
} from 'modules/audiences/AudienceProvider';
import { HoverableItem } from 'components/OptionsList';
import { useDemographicsOptions } from 'modules/audiences/hooks';
import { isAgeRangeEmpty } from 'modules/audiences/utils';

import { ListType, Icon } from '../OptionsList';
import { EnhancedTreeData } from 'utils';

interface DemographicsOptionsListProps {
  readonly: boolean;
  listType: ListType;
  ageValues: EnhancedTreeData[];
  genderValues: EnhancedTreeData[];
}

const { AGE_FIELD, GENDER_FIELD } = fields;

const getAgeRange = ({ min, max }: AgeType) => `${min || 'Any'} - ${max || 'Any'}`;

export const DemographicsOptionsList: FC<DemographicsOptionsListProps> = memo(
  ({ readonly, listType, ageValues, genderValues }): JSX.Element | null => {
    const { dispatch } = useAudience();
    const {
      loading,
      error,
      options: { [fields.GENDER_FIELD]: genderOptions },
      segmentsToRange,
      segmentsToRangeGroups,
    } = useDemographicsOptions();

    const getGender = (gender?: NormalizedSegment[]) => {
      if (!gender || gender.length === 0) return 'Any';

      const genderNames = gender.map(
        ({ external_id }) => genderOptions.find(({ external_id: optionsID }) => optionsID === external_id)?.name,
      );
      return genderNames.join(', ');
    };

    const ageRange = useMemo(() => segmentsToRange(ageValues), [ageValues, segmentsToRange]);
    const ageRanges = useMemo(() => segmentsToRangeGroups(ageValues), [ageValues, segmentsToRangeGroups]);

    ageRanges.forEach(element => (element.caption = getAgeRange(element)));

    if (loading) {
      return <Loader />;
    }

    if (error) {
      throw new Error('Query Error');
    }

    const handleClearAge = () => {
      dispatch({ type: ActionType.SET_DEMOGRAPHIC_AGE, payload: { segments: { [AGE_FIELD]: [] } } });
    };

    const handleEditAge = (min: number | undefined) => {
      dispatch({ type: ActionType.SET_SELECTED_AGE, payload: { selectedAge: min } });
    };

    const handleClearGender = () => {
      dispatch({ type: ActionType.SET_DEMOGRAPHIC_GENDER, payload: { segments: { [GENDER_FIELD]: [] } } });
    };

    const ageDataTest = readonly ? 'age-range-readonly' : 'included-excluded-item';
    const genderDataTest = readonly ? 'gender-readonly' : 'included-excluded-item';

    const ageReadonly = readonly || (!readonly && isAgeRangeEmpty(ageRange));
    const genderReadonly = readonly || (!readonly && !genderValues?.[0]);

    return (
      <>
        <ListTitle data-test="segments-sub-title" uppercase>
          Age range
        </ListTitle>
        {ageRanges.map(({ caption, min }) => (
          <HoverableItem
            key={min}
            icon={<Icon type={listType} />}
            onClick={handleEditAge.bind(null, min)}
            clickLabel={<EditIcon fontSize="small" />}
            onDelete={handleClearAge}
            readonly={ageReadonly}
          >
            <Typography variant="body1" data-test={ageDataTest}>
              {caption}
            </Typography>
          </HoverableItem>
        ))}
        <ListTitle data-test="segments-sub-title" uppercase>
          Gender
        </ListTitle>
        <HoverableItem icon={<Icon type={listType} />} onDelete={handleClearGender} readonly={genderReadonly}>
          <Typography variant="body1" data-test={genderDataTest}>
            {getGender(genderValues)}
          </Typography>
        </HoverableItem>
      </>
    );
  },
);
