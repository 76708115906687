import { createContext } from 'react';
import { AuthContext } from './types';

export const authContext = createContext<AuthContext>({
  login: async () => {
    throw new Error('No proper login functionality was provided');
  },
  reloadSession: () => {
    throw new Error('No proper reloadSession functionality was provided');
  },
  logout: () => {
    throw new Error('No proper logout functionality was provided');
  },
});
