import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ga } from 'modules/analytics';

export function AnalyticsIntegration(): null {
  const location = useLocation();
  useEffect(() => {
    ga('send', 'pageview', `${location.pathname}${location.search}`);
  }, [location]);

  return null;
}
