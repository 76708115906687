import React from 'react';
import { AllTableCriteria, Columns } from '@openx/components/table';
import { TaxonomyRow } from './TaxonomyList';
import { NameCell, SourceCell } from './components';

const snakeCaseToText = string => {
  return string.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()));
};

export const tableColumns: Columns<TaxonomyRow> = [
  {
    title: 'Name',
    key: 'name',
    width: '40%',
    ellipsisMaxWidth: '650px',
    ellipsis: true,
    render: (data, highlighter) => <NameCell segment={data} highlighter={highlighter} />,
  },
  {
    title: 'Sub Category',
    key: 'segment_sub_category',
    width: '14%',
    render: ({ segment_sub_category }) => (segment_sub_category ? snakeCaseToText(segment_sub_category) : ' '),
  },
  {
    title: 'Category',
    key: 'segment_category',
    width: '14%',
    render: ({ segment_category }) => (segment_category ? snakeCaseToText(segment_category) : ' '),
  },
  {
    title: 'Type',
    key: 'segment_type',
    width: '14%',
    render: ({ segment_type }) => (segment_type ? snakeCaseToText(segment_type) : ' '),
  },
  {
    title: 'Source',
    key: 'segment_source',
    width: '14%',
    render: ({ segment_source, segment_source_description }) => (
      <SourceCell source={segment_source} sourceDescription={segment_source_description} />
    ),
  },
];
