enum SegmentErrorMessagesMap {
  NOT_EXISTENT = 'non-existent segment ids in normalized definition',
  MALFORMED = 'malformed segment ids in normalized definition',
  NOT_READY = 'non-ready segments in normalized definition',
  ARCHIVED = 'archived segments in normalized definition',
  NOT_LEAF = 'non-leaf segments in normalized definition',
}

export const parseSegmentErrorMessages = (message: string, removeToSave = false) => {
  if (message === SegmentErrorMessagesMap.NOT_EXISTENT) {
    return `Audience includes non existing segments.${removeToSave ? ' Remove to save.' : ''}`;
  }
  if (message === SegmentErrorMessagesMap.MALFORMED) {
    return `Audience includes malformed segments.${removeToSave ? ' Remove to save.' : ''}`;
  }
  if (message === SegmentErrorMessagesMap.NOT_READY) {
    return `Audience includes expired segments.${removeToSave ? ' Remove to save.' : ''}`;
  }
  if (message === SegmentErrorMessagesMap.ARCHIVED) {
    return `Audience includes archived segments.${removeToSave ? ' Remove to save.' : ''}`;
  }
  if (message === SegmentErrorMessagesMap.NOT_LEAF) {
    return `Audience includes non leaf segments.${removeToSave ? ' Remove to save.' : ''}`;
  }

  return message;
};

export const isSegmentErrorMessage = (message: string) => {
  return (
    message === SegmentErrorMessagesMap.NOT_EXISTENT ||
    message === SegmentErrorMessagesMap.MALFORMED ||
    message === SegmentErrorMessagesMap.NOT_READY ||
    message === SegmentErrorMessagesMap.ARCHIVED ||
    message === SegmentErrorMessagesMap.NOT_LEAF
  );
};
