import { EnhancedTreeData } from 'utils';

import fields from './fields';
import { State, defaultSegments } from './AudienceProvider';
import {
  RelationType,
  GroupItem,
  SubgroupItem,
  MatchingOperator,
  OpenSubgroup,
  NormalizedDefinitionItem,
  Segments,
  Predefined,
  AudienceShare,
  BulkOption,
} from './types';
import { ExportType } from '../AudienceProvider';
import { DEFAULT_PROVIDER_ID } from '../hooks/useProviders';
import { Account } from '@openx/types';

export const getGroup = (state: State, relationField: RelationType, groupIndex: number): GroupItem =>
  state[relationField][fields.GROUPS_FIELD].find(({ index }) => index === groupIndex) || ({} as GroupItem);

export const getSubgroup = (
  state: State,
  relationField: RelationType,
  groupIndex: number,
  subgroupIndex: number,
): SubgroupItem =>
  getGroup(state, relationField, groupIndex)?.[fields.SUBGROUPS_FIELD]?.find(({ index }) => index === subgroupIndex) ||
  ({} as SubgroupItem);

export const getGroupsSubgroups = (state: State, relationField: RelationType, groupIndex: number): SubgroupItem[] => {
  const group = state[relationField][fields.GROUPS_FIELD]?.find(group => group.index === groupIndex) || [];

  return group[fields.SUBGROUPS_FIELD] || [];
};

export const getGroupsMatchingOperator = (
  state: State,
  relationField: RelationType,
  groupIndex: number,
): MatchingOperator => getGroup(state, relationField, groupIndex)?.[fields.OPERATOR_FIELD];

export const getOpenSubgroup = (state: State): OpenSubgroup => state[fields.OPEN_SUBGROUP_FIELD];

export const getIncludesField = (state: State): NormalizedDefinitionItem => state[fields.INCLUDE_FIELD];

export const getExcludesField = (state: State): NormalizedDefinitionItem => state[fields.EXCLUDE_FIELD];

export const getIsDrawerOpen = (state: State): boolean => state[fields.IS_DRAWER_OPEN_FIELD];

export const getSelectedAge = (state: State): number | undefined => state[fields.SELECTED_AGE];

export const getIncludesGroups = (state: State): GroupItem[] => getIncludesField(state)[fields.GROUPS_FIELD];

export const getExcludesGroups = (state: State): GroupItem[] => getExcludesField(state)[fields.GROUPS_FIELD];

export const getIncludesMatchingOperator = (state: State): MatchingOperator =>
  getIncludesField(state)[fields.OPERATOR_FIELD] as MatchingOperator;

export const getOpenSubgroupsRelationField = (state: State): RelationType =>
  getOpenSubgroup(state)[fields.RELATION_FIELD];

export const getOpenSubgroupsGroupIndex = (state: State): number => getOpenSubgroup(state)[fields.GROUP_INDEX_FIELD];

export const getOpenSubgroupsSubgroupIndex = (state: State): number =>
  getOpenSubgroup(state)[fields.SUBGROUP_INDEX_FIELD];

export const getOpenSubgroupsSegments = (state: State): Segments => getOpenSubgroup(state)[fields.SEGMENTS_FIELD];

export const getOpenSubgroupDirectSegment = (state: State): EnhancedTreeData[] =>
  getOpenSubgroupsSegments(state)[fields.DIRECT_SEGMENT_FIELD] || [];

export const getOpenSubgroupsLocation = (state: State): EnhancedTreeData[] =>
  getOpenSubgroupsSegments(state)[fields.LOCATION_FIELD] || [];

export const getOpenSubgroupDemographicAge = (state: State): EnhancedTreeData[] =>
  getOpenSubgroupsSegments(state)[fields.AGE_FIELD] || [];

export const getOpenSubgroupDemographicGender = (state: State): EnhancedTreeData[] =>
  getOpenSubgroupsSegments(state)[fields.GENDER_FIELD] || [];

export const getSubgroupsSegments = (
  state: State,
  relationField: RelationType,
  groupIndex: number,
  subgroupIndex: number,
): Segments => getSubgroup(state, relationField, groupIndex, subgroupIndex)?.[fields.SEGMENTS_FIELD];

export const getGroupSegments = (state: State, relationField: RelationType, groupIndex: number): Segments =>
  getGroup(state, relationField, groupIndex)?.[fields.SEGMENTS_FIELD] || defaultSegments;

export const getOpenSubgroupPredefiendSegments = (state: State): Predefined =>
  getOpenSubgroupsSegments(state)[fields.PREDEFINED_FIELD] || ({} as Predefined);

export const getDirectProvider = (state: State, skipDefaultProvider = true) => {
  const directProvider = state[fields.DIRECT_PROVIDER_FIELD];

  if (skipDefaultProvider) {
    return directProvider !== DEFAULT_PROVIDER_ID ? directProvider : undefined;
  }

  return directProvider;
};

export const getAccountId = (state: State): string | undefined => state[fields.ACCOUNT_ID_FIELD] || undefined;

export const getName = (state: State): string | undefined => state[fields.NAME_FIELD];

export const getDescription = (state: State): string | undefined => state[fields.DESCRIPTION_FIELD] || undefined;

export const getTemplateName = (state: State): string | undefined => state[fields.TEMPLATE_NAME_FIELD] || undefined;

export const getExportType = (state: State): ExportType | undefined => state[fields.EXPORT_TYPE_FIELD] || undefined;

export const getOpenSubgroupFirstParty = (state: State): EnhancedTreeData[] =>
  getOpenSubgroupsSegments(state)[fields.FIRST_PARTY_FIELD] || [];

export const getIsStateSynced = (state: State): boolean => state[fields.IS_STATE_SYNCED_FIELD];

export const getCTV = (state: State): boolean => state[fields.CTV_FIELD];

export const getStatus = (state: State): string | undefined => state[fields.STATUS_FIELD];

export const getOrganization = (state: State): Account | undefined => state[fields.ACCOUNT];

export const getUpdatedDate = (state: State): string | undefined => state[fields.UPDATED_DATE_FIELD];

export const getAudienceShares = (state: State): AudienceShare[] => state[fields.AUDIENCE_SHARES_FIELD] || [];

export const getBulkAction = (state: State): BulkOption => state[fields.BULK_ACTION_FIELD] || ({} as BulkOption);

export const getExportTargets = (state: State): string[] => state[fields.EXPORT_TARGETS_FIELD] || [];
