import { isEmpty } from 'lodash';
import { TaxonomyTreeSegmentFragment } from 'types/schemaTypes';

export function generateUniqueId(): string {
  return Math.random().toString(36).substr(2, 9);
}

export const mapTaxonomymetadataToTree = (segments: TaxonomyTreeSegmentFragment[], isSearchApplied = false) => {
  const segmentsWithFixedPath: TaxonomyTreeSegmentFragment[] = segments.map(segment => ({
    ...segment,
    path: [segment.segment_type, segment.segment_category, segment.segment_sub_category].filter(n => n),
  }));

  function buildHierarchy(data: TaxonomyTreeSegmentFragment[]): any[] {
    const tree: any[] = [];

    data.forEach(segment => {
      let currentLevel = tree;

      segment.path.forEach((level, index) => {
        let existingNode = currentLevel.find(node => node.name === level);

        if (!existingNode) {
          existingNode = {
            id: generateUniqueId(),
            name: level,
            children: [],
            is_nav_only: true,
            is_leaf: false,
            segment_category: index >= 1 ? segment.segment_category : '',
            segment_sub_category: index >= 2 ? segment.segment_sub_category : '',
            segment_type: segment.segment_type,
            segment_source: segment.segment_source,
          };
          currentLevel.push(existingNode);
        }

        if (index === segment.path.length - 1 && isSearchApplied) {
          existingNode.children.push({
            ...segment,
            id: segment.id || generateUniqueId(),
            children: [],
          });
        }
        currentLevel = existingNode.children;
      });
    });

    return tree;
  }

  const hierarchicalData = buildHierarchy(segmentsWithFixedPath);

  return hierarchicalData;
};

export function recursiveTreeSort(tree, sotringMethod, criteria, criteriaSetter) {
  return sotringMethod(tree, criteria, { onCriteriaAdjust: criteriaSetter }).map(item => {
    if (isEmpty(item.children)) return item;
    return {
      ...item,
      children: recursiveTreeSort(item.children, sotringMethod, criteria, criteriaSetter),
    };
  });
}
