import React from 'react';
import { createStyles, Theme, TableRow, TableCell } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '@openx/components/core';

export interface ShowMoreButtonProps {
  onClick?: () => void;
  depth?: number;
  label?: string;
}
const useStyles = (depth: number) =>
  makeStyles((theme: Theme) =>
    createStyles({
      row: {
        backgroundColor: `rgba(0, 0, 0, ${depth * 0.02})`,
      },
      cell: {
        padding: theme.spacing(1.2),
      },
      button: {
        color: theme.palette.text.secondary,
        fontSize: '12px',
        lineHeight: '20px',
      },
    }),
  );

export function ShowMoreButton({ depth = 0, onClick = () => {}, label }: ShowMoreButtonProps): JSX.Element {
  const classes = useStyles(depth)();

  return (
    <TableRow data-test="show-more-button" className={classes.row}>
      <TableCell colSpan={5} align="center" className={classes.cell}>
        <Button onClick={onClick} color="default" variant="text" className={classes.button}>
          {label || 'Show More'}
        </Button>
      </TableCell>
    </TableRow>
  );
}
