import React from 'react';
import { Chip, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { prepareHighlightRegExp, HighlightPhraseProps, Tooltip } from '@openx/components/core';

import { Highlighter } from '../tableHelpers';

export interface MultiValueTableCellProps {
  values: string[];
  phrase?: HighlightPhraseProps;
  highlighter?: Highlighter;
  mapBy?: { [key: string]: { name: string } | undefined };
  showTooltip?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    width: 22,
    height: 22,
    borderRadius: 50,
    backgroundColor: theme.palette.background.grayLight,
    color: theme.palette.common.black,
  },
  content: {
    maxWidth: '82%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    float: 'left',
  },
}));

export function MultiValueTableCell(props: MultiValueTableCellProps): JSX.Element | null {
  const classes = useStyles();
  const { values: dataValues, mapBy, phrase = '', showTooltip = false } = props;
  const hasEmptyValues = !dataValues || dataValues.length === 0;

  if (hasEmptyValues) {
    return null;
  }
  const values = mapBy ? dataValues.map(value => mapBy[value]?.name || value) : dataValues;
  const regexp = prepareHighlightRegExp(phrase);
  const matchedPhrase = values.find(value => value.match(regexp));
  const isSingleValue = values.length === 1;
  const firstValue = values[0];
  const firstHighlightedValue = matchedPhrase || firstValue;
  const { highlighter = () => firstHighlightedValue } = props;

  if (isSingleValue) {
    return <span className={classes.content}>{highlighter(firstValue)}</span>;
  }

  const tooltipTitle = showTooltip && (
    <span data-test="multi-value-tooltip-span">
      {values.map((val, k) => (
        <p key={k}>{val}</p>
      ))}
    </span>
  );

  return (
    <>
      <span className={classes.content} style={{ marginTop: 2 }}>
        {highlighter(firstHighlightedValue)}
      </span>
      <Tooltip title={tooltipTitle}>
        <Chip
          size="small"
          label={values.length}
          clickable
          classes={{ root: classes.root }}
          data-test="multi-value-button"
        />
      </Tooltip>
    </>
  );
}

export default MultiValueTableCell;
