import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';

import { PageHeader, ListHeaderContent } from '@openx/components/header';
import { CriteriaChange, CriteriaDimension, SearchFilters } from '@openx/components/table';
import { useSideDrawer } from 'context';

interface ProvidersHeaderProps {
  children: React.ReactNode;
  handleCriteriaChange: (props: CriteriaChange<CriteriaDimension.FILTERS>, isInitialCall?: boolean) => void;
}

export const ProvidersHeaderRaw = ({ children, handleCriteriaChange }: ProvidersHeaderProps): JSX.Element => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();

  const headerFilters = () => (
    <ListHeaderContent
      filtersComponent={SearchFilters}
      filtersProps={{
        byPhraseFields: ['name'],
        onFiltersChange: (filters, isInitialCall) => {
          handleCriteriaChange({ dimension: CriteriaDimension.FILTERS, value: filters }, isInitialCall);
        },
        inputPlaceholder: 'Search Provider',
      }}
      actions={[
        {
          label: 'Create New Provider',
          color: 'primary',
          onClick: () => {
            history.push('/providers/create');
          },
        },
      ]}
    />
  );

  return (
    <PageHeader
      title="Providers List"
      titlePrefix="OpenAudience"
      render={headerFilters}
      openDrawer={openDrawer}
      handleDrawerOpen={handleDrawerOpen}
      menuItems={menuItems}
      token={token}
    >
      {children}
    </PageHeader>
  );
};

export const ProvidersHeader = memo(ProvidersHeaderRaw, (prevProps, nextProps) => isEqual(prevProps, nextProps));
