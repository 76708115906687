import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FiltersConfig } from './types';

interface MenuProps<FiltersValue extends {}> {
  filtersConfig: FiltersConfig;
  anchorEl: HTMLElement | null;
  onSelect: (categoryName: string) => void;
  onClose: () => void;
  open: boolean;
  activeFilters: FiltersValue;
}

const useStyles = makeStyles({
  menu: {
    marginTop: '50px',
  },
});

export function FilterCategoryMenu<FiltersValue>({
  filtersConfig,
  anchorEl,
  onSelect,
  onClose,
  open,
  activeFilters,
}: MenuProps<FiltersValue>) {
  const classes = useStyles();
  return (
    <Menu id="filter-categories" anchorEl={anchorEl} keepMounted open={open} onClose={onClose} className={classes.menu}>
      {Object.keys(filtersConfig).map((categoryName, id) => (
        <MenuItem
          onClick={() => onSelect(categoryName)}
          key={id}
          data-test="chip-filter-menu-item"
          disabled={!!activeFilters[categoryName]}
        >
          {filtersConfig[categoryName].name}
        </MenuItem>
      ))}
    </Menu>
  );
}
