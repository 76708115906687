import { ExportType } from 'modules/audiences/AudienceProvider';

import { AudienceType } from './types';

export const audienceTypeOptions = [
  AudienceType.CREATE,
  AudienceType.BASE,
  AudienceType.USE,
  AudienceType.USE_DIRECT_AUDIENCE,
];

export const audienceTypeOptionsLabels: Record<AudienceType, string> = {
  [AudienceType.CREATE]: 'Create New Audience',
  [AudienceType.BASE]: 'Load Custom Audience',
  [AudienceType.USE]: 'Use Custom Audience',
  [AudienceType.USE_DIRECT_AUDIENCE]: 'Use Direct Audience',
};

export const audiencePickerPlaceholders: Partial<Record<AudienceType, string | undefined>> = {
  BASE: '[ Load existing Custom Audience as template ]',
  USE: '[ Use existing Custom Audience as template ]',
};

export const exportTypeOptions = [ExportType.OA_MATCH, ExportType.BIDSTREAM, ExportType.BIDSTREAM_PLUS];

export const exportTypeLabels = {
  [ExportType.OA_MATCH]: 'OA Match',
  [ExportType.BIDSTREAM]: 'Bidstream',
  [ExportType.BIDSTREAM_PLUS]: 'Bidstream Plus',
};
