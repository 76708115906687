import React, { useCallback, useState } from 'react';
import { Box, Divider, FormControl, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AutoComplete, Loader, Paper } from '@openx/components/core';

import { calculateClickedOptions } from 'utils';
import {
  useAudience,
  ActionType,
  RTG_FIELD,
  THIRD_PARTY_FIELD,
  SITE_CATEGORY_FIELD,
  getOpenSubgroupPredefiendSegments,
  PredefinedSegmentType,
} from 'modules/audiences/AudienceProvider';
import { usePredefinedOptions } from 'modules/audiences/hooks';

import { PropensityDropdown, Propensity } from './PropensityDropdown';
import { SegmentsDropdown } from './SegmentsDropdown';
import { OptionState } from '../OptionsList';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginBottom: theme.spacing(2),
  },
  root: {
    boxShadow: 'none',
    paddingTop: 0,
    paddingBottom: 0,
  },
  divider: {
    marginTop: '30px',
  },
}));

const mapTypeToActionLabel = {
  [RTG_FIELD]: 'Partner Audiences',
  [THIRD_PARTY_FIELD]: 'Third Party Data',
  [SITE_CATEGORY_FIELD]: 'Past Browsing Interests',
};

export const PredefinedAudienceSegments = (): JSX.Element | null => {
  const classes = useStyles();
  const { state, dispatch } = useAudience();

  const [propensity, setPropensity] = useState<Propensity>(Propensity.ALL);
  const { loading, error, options, clickedOptions, setClickedOptions } = usePredefinedOptions({ propensity });
  const [segmentType, setSegmentType] = useState<PredefinedSegmentType>(PredefinedSegmentType.RTG_FIELD);

  const showPropensitySegmentsFilter = segmentType === PredefinedSegmentType.THIRD_PARTY_FIELD;

  const selectedOptions = getOpenSubgroupPredefiendSegments(state);

  const setValue = useCallback(
    newPredefined => {
      const newOptions = { ...selectedOptions, [segmentType]: [...selectedOptions[segmentType], newPredefined] };

      dispatch({
        type: ActionType.SET_PREDEFINED,
        payload: { segments: { predefined: newOptions } },
      });

      setClickedOptions(
        calculateClickedOptions(OptionState.INCLUDE, options[segmentType], clickedOptions, newPredefined),
      );
    },
    [dispatch, state, segmentType],
  );

  if (loading) {
    return (
      <div className={classes.root}>
        <Loader />
      </div>
    );
  }

  if (error) {
    throw new Error('Query Error');
  }

  return (
    <Paper className={classes.root} data-test="predefined-audience-segments">
      <Typography variant="h3" gutterBottom data-test="predefined-audience-segments-title">
        Predefined Audience Segments
      </Typography>
      <FormControl>
        <Box marginTop="10px" data-test="segment-type-dropdown">
          <AutoComplete
            textFieldProps={{ label: 'Segment Type' }}
            options={[RTG_FIELD, THIRD_PARTY_FIELD, SITE_CATEGORY_FIELD]}
            getOptionLabel={option => mapTypeToActionLabel[option]}
            textFieldReadOnly
            disableClearable
            onChange={(e, value) => setSegmentType(value as PredefinedSegmentType)}
            value={segmentType}
          />
        </Box>
        {showPropensitySegmentsFilter && (
          <Box marginTop="10px" data-test="propensity-dropdown">
            <PropensityDropdown value={propensity} onChange={setPropensity} />
          </Box>
        )}
        <Box data-test="segment-type-select-list">
          <SegmentsDropdown field={segmentType} setValue={setValue} options={options[segmentType]} />
        </Box>
      </FormControl>
      <Divider className={classes.divider} />
    </Paper>
  );
};
