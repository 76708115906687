import React, { ReactNode, useMemo } from 'react';

import { HighlightRules } from './highlightRules';
import { DataSection, DataSectionProps } from './parts/sections/DataSection';
import { ManagementSection, ManagementSectionProps } from './parts/sections/ManagementSection';
import { ExpandComponentProps } from './parts/TableRowExpanded';
import { AllTableCriteria, ChangeCriteriaHandler, CriteriaDimension } from './tableCriteria';
import {
  BaseRow,
  Columns,
  prepareChangeCriteriaHandler,
  RowAction,
  RowActionButtonBaseProps,
  RowActionProps,
} from './tableHelpers';

export type CustomManagementSection = ((props: { type: 'top' | 'bottom' }) => JSX.Element) | null;

export interface TableProps<RowT extends BaseRow, FiltersT extends {} = {}> {
  data: RowT[];
  columns: Columns<RowT>;
  onRowClick?: RowAction<RowT>;
  loading: boolean;
  children?: ReactNode;
  expanded?: boolean;
  isTree?: boolean;
  isForcedExpanded?: (data: RowT) => boolean;
  treeChildrenKey?: string;
  treeLevelItems?: number;
  hideHeader?: boolean;
  expandComponent?: React.ComponentType<ExpandComponentProps<RowT>>;
  criteria?: AllTableCriteria<FiltersT>;
  onCriteriaChange?: ChangeCriteriaHandler<CriteriaDimension, FiltersT>;
  highlightRules?: HighlightRules;
  CustomManagementSection?: CustomManagementSection;
  primaryRowAction?: RowActionButtonBaseProps<RowT>;
  secondaryRowAction?: RowActionButtonBaseProps<RowT>;
  rowActions?: RowActionProps<RowT>[] | ((row: RowT) => RowActionProps<RowT>[]);
  stickyHeaderPosition?: number;
  fixedLayout?: boolean;
}

export function Table<RowT extends BaseRow, FiltersT extends {} = {}>(props: TableProps<RowT, FiltersT>): JSX.Element {
  const { onCriteriaChange, criteria, highlightRules, children, stickyHeaderPosition } = props;

  const { onPaginationChange, onSortChange } = useMemo(
    () => ({
      onPaginationChange: prepareChangeCriteriaHandler(CriteriaDimension.PAGINATION, onCriteriaChange),
      onSortChange: prepareChangeCriteriaHandler(CriteriaDimension.SORT, onCriteriaChange),
    }),
    [onCriteriaChange],
  );

  const managementSectionProps: Omit<ManagementSectionProps, 'type'> = {
    ...props,
    onPaginationChange,
    paginationCriteria: criteria && criteria.pagination,
  };

  const dataSectionProps: DataSectionProps<RowT> = {
    ...props,
    onSortChange,
    sortCriteria: criteria && criteria.sort,
    highlightRules,
    stickyHeaderPosition,
    children,
  };

  return (
    <div data-test={props['data-test']}>
      {(managementSectionProps.CustomManagementSection || managementSectionProps.CustomManagementSection === null) && (
        <ManagementSection {...managementSectionProps} type="top" />
      )}
      <DataSection {...dataSectionProps} />
      <ManagementSection {...managementSectionProps} type="bottom" />
    </div>
  );
}
