import React from 'react';
import { useQuery } from '@apollo/client';

import GetExportsByAudienceId from 'graphql/query/audiences/GetExportsByAudienceId.gql';
import { ReachTooltipContent } from 'components/ReachTooltip';
import { abbreviatedNumber } from '@openx/utils';

interface ReachTooltipProps {
  id: string;
  reach?: number | null;
}

const getAppropriateReachData = reachData =>
  reachData.reduce((acc, { id_type: idType, id_count: idCount }) => ({ ...acc, [idType]: idCount }), {});

export function TooltipContent({ id, reach }: ReachTooltipProps): JSX.Element {
  const { loading, data } = useQuery(GetExportsByAudienceId, {
    variables: { id },
    skip: !reach,
  });

  if (!reach) {
    return (
      <span data-test="reach-tooltip-pending">
        Your audience is currently processed by our services and its reach is calculated. It may take up to few hours
        for audience reach to be estimated.
      </span>
    );
  }

  const reachData = data?.audience_by_pk?.last_export.export_id_types || [];

  const text = `The estimated monthly reach of ${abbreviatedNumber(
    reach,
  )} unique users is associated with the following cookie, device & id counts:`;

  return <ReachTooltipContent text={text} loading={loading} sections={getAppropriateReachData(reachData)} />;
}
