import { CustomManagementSection } from '../Table';
import { BaseRow } from '../tableHelpers';

export enum SelectOperation {
  SELECT = 'select',
  UNSELECT = 'unselect',
}

export type ToggleSelect<RowT extends BaseRow> = (items: RowT[], operation: SelectOperation) => void;

export interface SelectListProps<RowT extends BaseRow> {
  selectedItems: RowT[];
  toggleSelect: ToggleSelect<RowT>;
}

export interface SelectableTableProps<RowT extends BaseRow> extends SelectListProps<RowT> {
  bulkActions?: BulkAction<RowT>[];
  CustomManagementSection?: CustomManagementSection;
}

export interface BulkAction<RowT extends BaseRow> {
  label: string;
  action: (selectedItems: RowT[]) => void;
  allowed?: boolean;
}
