import React, { useCallback, useEffect } from 'react';
import { Formik, Field, FormikHelpers as FormikActions } from 'formik';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { Dialog, DialogActions, DialogContent, Typography, makeStyles, Theme } from '@material-ui/core';

import { Button, DialogTitle, TextField } from '@openx/components/core';

import DuplicateAudience from 'graphql/mutation/audiences/DuplicateAudience.gql';
import ActivateAudience from 'graphql/mutation/audiences/ActivateAudience.gql';
import { useSession } from 'modules/auth/AuthProvider';
import {
  ActivateAudienceMutation,
  ActivateAudienceMutationVariables,
  Audience,
  DuplicateAudienceMutation,
  DuplicateAudienceMutationVariables,
} from 'types/schemaTypes';

import { generateSchema } from './validationSchema';

import { getCurrentUser } from 'firebaseIntegration/utils';
import createLog from 'graphql/mutation/error/createLog.gql';
interface Props {
  onClose: (success: boolean) => void;
  audience: Audience;
}

interface FormValues {
  name: string;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  fieldSubtitle: {
    marginBottom: theme.spacing(2),
  },
}));

export function AudienceDuplicate({ onClose, audience }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { id } = useSession();

  const [duplicateAudience] = useMutation<DuplicateAudienceMutation, DuplicateAudienceMutationVariables>(
    DuplicateAudience,
  );

  const [pushLog] = useMutation(createLog);

  const [activateAudience] = useMutation<ActivateAudienceMutation, ActivateAudienceMutationVariables>(
    ActivateAudience,
    {
      refetchQueries: ['GetAudienceFeed'],
    },
  );
  const onSubmit = useCallback(
    async (values: FormValues, actions: FormikActions<FormValues>) => {
      const { name } = values;

      try {
        const response = await duplicateAudience({
          variables: {
            id: audience.id,
            name,
            user_id: id,
          },
        });

        const clonedAudienceId = response?.data?.cloneAudience?.id;

        await activateAudience({ variables: { id: clonedAudienceId } });

        enqueueSnackbar('Audience successfully duplicated', { variant: 'success' });
        onClose(true);
      } catch (error) {
        const location = window.location.href;
        const user = await getCurrentUser();
        await pushLog({
          variables: {
            type: error,
            location: location,
            msg: 'Failed to duplicate audience',
            name: user?.displayName,
            email: user?.email, // eslint-disable-line
          },
        });
        enqueueSnackbar('Failed to duplicate audience', {
          variant: 'error',
        });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [duplicateAudience, audience.id, id, activateAudience, enqueueSnackbar, onClose, pushLog],
  );

  const onCancel = useCallback(() => {
    onClose(false);
  }, [onClose]);

  return (
    <Dialog open={true}>
      <DialogTitle onClose={onCancel}>Duplicate Audience</DialogTitle>
      <Formik onSubmit={onSubmit} initialValues={{ name: '' }} validationSchema={generateSchema(audience)}>
        {({ submitForm, isValid, dirty, isSubmitting }) => (
          <>
            <DialogContent dividers data-test="dialog-content">
              <Typography variant="body1" gutterBottom>
                You are duplicating audience: <b>{audience.name}</b>
              </Typography>
              <Typography variant="body1" gutterBottom className={classes.fieldSubtitle}>
                Organization: <b>{audience.account?.name}</b>
              </Typography>
              <Field name="name" component={TextField} label="New name" fullWidth autoFocus data-test="new-name" />
            </DialogContent>
            <DialogActions data-test="dialog-actions">
              <Button onClick={onCancel} variant="text" disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={submitForm}
                disabled={!isValid || !dirty || isSubmitting}
                loading={isSubmitting}
              >
                Duplicate
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
}
