import { Operation } from '@apollo/client';
import { extractDocumentInstance } from './extractDocumentInstance';
import { HandlersConfigMap, NestedError, ParseGraphQLErrorsArgs } from './types';

const makeHandlersRunner =
  (handlersConfigMap: HandlersConfigMap, operation: Operation) =>
  (error: NestedError): boolean[] => {
    const currentDocumentInstance = extractDocumentInstance(operation.query);
    const handlers = handlersConfigMap.get(currentDocumentInstance) || [];

    return handlers.map(handler => handler(error));
  };

export const runLocalHandlers = ({
  graphQLErrors,
  handlersConfigMap,
  operation,
}: ParseGraphQLErrorsArgs): boolean[] => {
  return graphQLErrors
    .map(({ extensions: { code, errors, segmentIds, exception } = {} }) => ({
      code,
      errors,
      segmentIds,
      exception,
    }))
    .filter(({ code, errors, segmentIds }) => code === 'BAD_USER_INPUT' && (!!errors?.length || !!segmentIds?.length))
    .flatMap(({ errors, exception, code }) =>
      errors
        ? errors.flatMap(makeHandlersRunner(handlersConfigMap, operation))
        : [{ message: exception.message, code, path: '' }].flatMap(makeHandlersRunner(handlersConfigMap, operation)),
    );
};
