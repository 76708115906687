import { FormFields, ProviderFormState } from '../constants';

export const prepareProviderDataToUpdate = (formData: ProviderFormState) => ({
  [FormFields.PROVIDER_NAME]: formData[FormFields.PROVIDER_NAME],
  [FormFields.ALLOWED_EXPORT_TYPES]: formData[FormFields.ALLOWED_EXPORT_TYPES],
});

export const prepareAccountsToLink = (initialIds: string[], updatedIds: string[], providerId: string) => {
  const difference = updatedIds.filter(updatedId => !initialIds.includes(updatedId));

  return difference.map(accountId => ({ account_id: accountId, provider_id: providerId }));
};

export const prepareAccountsToUnlink = (initialIds: string[], updatedIds: string[], providerId: string) => {
  const difference = initialIds.filter(initialId => !updatedIds.includes(initialId));

  return difference.map(id => ({ account_id: id, provider_id: providerId }));
};
