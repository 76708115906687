export enum SegmentStatus {
  READY = 'ready',
  AWAITING_DATA = 'awaiting_data',
  PUBLISHING = 'publishing',
  ERROR = 'error',
  EXPIRED = 'expired',
}

export const segmentExpiredText = fromDeal =>
  fromDeal
    ? 'Audience associated with a given deal has expired segment.'
    : 'Segment data is expired and cannot be used for audience creation';

export const segmentSoonWillBeExpired = (fromDeal, segmentDaysRemaining) =>
  fromDeal
    ? `Audience associated with a given deal has segments that will expire in next ${segmentDaysRemaining} ${
        segmentDaysRemaining === 1 ? 'day' : 'days'
      }.`
    : `Segment is going to expire within the next ${segmentDaysRemaining} ${
        segmentDaysRemaining === 1 ? 'day' : 'days'
      }`;

export const segmentArchivedText = fromDeal =>
  fromDeal
    ? 'Audience includes a segment that is archived'
    : 'Segment is archived and cannot be used for audience creation';

export const visibleAssociatedAudiencesCount = 6;
