import React, { ReactEventHandler, FC } from 'react';
import { IconButton, makeStyles, Typography, Divider } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export interface DrawerHeaderProps {
  children: string | JSX.Element;
  onClose?: ReactEventHandler;
}

const useStyles = makeStyles({
  container: {
    padding: '16px 7px 16px 20px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '20px',
  },
});

export const DrawerHeader: FC<DrawerHeaderProps> = (props): JSX.Element => {
  const classes = useStyles();

  const { children, onClose } = props;

  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant="h3" data-test="details-title">
            {children}
          </Typography>
          {onClose && (
            <IconButton data-test="close-drawer" onClick={onClose}>
              <Close />
            </IconButton>
          )}
        </div>
      </div>
      <Divider />
    </>
  );
};
