import React, { useCallback, useEffect, useState } from 'react';
import { TableHead, TableRow, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { TableHeaderCell } from './TableHeaderCell';
import { SortDirection } from '../sort';
import { ChangeSingleCriteriaAction, CriteriaDimension, SortCriteria } from '../tableCriteria';
import { BaseRow, Columns } from '../tableHelpers';

export interface TableHeaderProps<RowT extends BaseRow> {
  columns: Columns<RowT>;
  onChange: ChangeSingleCriteriaAction<CriteriaDimension.SORT>;
  criteria?: SortCriteria;
  stickyHeaderPosition?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  shadow: {
    '& th:first-child': {
      zIndex: 3,
      boxShadow: `-${theme.spacing(5)}px -1px ${theme.palette.common.white}`,
    },
    '& th': {
      zIndex: 3,
      boxShadow: `${theme.spacing(5)}px -1px ${theme.palette.common.white}`,
    },
  },
}));

export function TableHeader<RowT extends BaseRow>(props: TableHeaderProps<RowT>): JSX.Element {
  const { columns, onChange, criteria, stickyHeaderPosition } = props;
  const classes = useStyles();

  const [stickyClass, setStickyClass] = useState('');

  useEffect(() => {
    const tableHeaderEl = document.querySelector<HTMLElement>('thead>tr');
    const { top: tableHeaderTopY, bottom: tableHeaderBottomY } = tableHeaderEl?.getBoundingClientRect() || {
      top: 0,
      bottom: 0,
    };

    const onScroll = (): void => {
      setStickyClass(
        stickyHeaderPosition && tableHeaderEl && window.scrollY > tableHeaderTopY - tableHeaderBottomY + tableHeaderTopY
          ? classes.shadow
          : '',
      );
    };

    if (stickyHeaderPosition && tableHeaderEl) {
      window.addEventListener('scroll', onScroll);
    }
    return () => {
      if (stickyHeaderPosition && tableHeaderEl) {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, [stickyHeaderPosition, classes]);

  const toggleSort = useCallback(
    (columnKey: string) => {
      if (!criteria || !criteria.sortableColumns.includes(columnKey)) {
        return;
      }

      const columnAlreadySortedDesc = criteria.column === columnKey && criteria.direction === SortDirection.ASC;
      const newDirection = columnAlreadySortedDesc ? SortDirection.DESC : SortDirection.ASC;
      onChange({
        ...criteria,
        column: columnKey,
        direction: newDirection,
      });
    },
    [criteria, onChange],
  );

  return (
    <TableHead data-test="table-header">
      <TableRow data-test="table-row" className={stickyClass}>
        {columns.map(column => (
          <TableHeaderCell
            column={column}
            toggleSort={criteria?.sortableColumns.includes(column.key) ? toggleSort : undefined}
            appliedSortDirection={criteria?.column === column.key ? criteria?.direction : undefined}
            stickyHeaderPosition={stickyHeaderPosition}
            key={column.key}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}
