import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Order_By } from 'types/schemaTypes';

import GetTaxonomyDownload from 'graphql/query/taxonomy/GetTaxonomyDownload.gql';

interface TaxonomyDownloadResponse {
  taxonomyLoading: boolean;
  downloadActions: any;
  handleDownloading: () => void;
}

export const taxonomyHeaders = [
  { label: 'Segment', key: 'name' },
  { label: 'Segment Full Name', key: 'full_name' },
  { label: 'Segment Description', key: 'description' },
  { label: 'Segment Sub Category', key: 'segment_sub_category' },
  { label: 'Segment Category', key: 'segment_category' },
  { label: 'Segment Type', key: 'segment_type' },
  { label: 'Segment Source', key: 'segment_source' },
  { label: 'Segment Source Description', key: 'segment_source_description' },
];

const prepareTaxonomyDataToDownload = data => {
  return data.map(taxonomy => ({
    ...taxonomy,
    description: taxonomy.description.replace(/"/g, '""'),
  }));
};

export const useTaxonomyDownload = (): TaxonomyDownloadResponse => {
  const [taxonomyToDownload, setTaxonomyToDownload] = useState([]);

  const [getTaxonomyForDownload, { loading: taxonomyLoading, data: { segment = [] } = {} }] = useLazyQuery(
    GetTaxonomyDownload,
    {
      variables: {
        order_by: [
          { segment_type: Order_By.Asc },
          { segment_category: Order_By.Asc },
          { segment_sub_category: Order_By.Asc },
          { name: Order_By.Asc },
        ],
        where: {
          is_archived: { _eq: false },
          is_leaf: { _eq: true },
          category: { _neq: 'direct' },
          name: { _neq: 'All' },
        },
      },
    },
  );

  useEffect(() => {
    if (segment.length > 0) {
      setTaxonomyToDownload(segment);
    }
  }, [segment]);

  const downloadActions = useMemo(
    () => ({
      headers: taxonomyHeaders,
      data: prepareTaxonomyDataToDownload(taxonomyToDownload),
      filename: 'OpenX OpenAudience Segment Taxonomy - OpenX Confidential',
    }),
    [taxonomyToDownload],
  );

  const handleDownloading = () => {
    getTaxonomyForDownload();
  };

  return { taxonomyLoading, downloadActions, handleDownloading };
};
