import { useCallback, useEffect, useState } from 'react';
import { User } from '@firebase/auth-types';
import firebase from 'firebase/compat/app';

interface State {
  user: User | null;
  loading: boolean;
}

export interface FirebaseListenerResult extends State {
  reset: () => void;
}

export function useFirebaseListener(): FirebaseListenerResult {
  const auth = firebase.auth();
  const [state, setUser] = useState<State>({ user: null, loading: true });

  const handleAuthChange = async (user: User | null) => {
    setUser({ user, loading: false });
  };

  useEffect(() => {
    const authListener = auth.onAuthStateChanged(handleAuthChange);

    return () => {
      authListener();
    };
  }, [auth]);

  const reset = useCallback(() => {
    setUser({ user: null, loading: true });
  }, []);

  return {
    user: state.user,
    loading: state.loading,
    reset,
  };
}
