import { theme } from './theme';

export const snackbarStyles = {
  variantSuccess: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    '& > *:first-child svg': {
      color: theme.palette.success.main,
    },
  },
  variantError: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    '& > *:first-child svg': {
      color: theme.palette.error.main,
    },
  },
  variantWarning: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    '& > *:first-child svg': {
      color: theme.palette.warning.main,
    },
  },
  variantInfo: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    '& > *:first-child svg': {
      color: theme.palette.info.main,
    },
  },
};
