import Typography from '@material-ui/core/Typography';
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '168px',
  },
});

export function EmptyTablePlaceholder() {
  const clases = useStyles();
  return (
    <div className={clases.root}>
      <Typography variant="h3">No data available</Typography>
      <Box marginTop="10px">
        <Typography variant="body1">Created entries will be shown here</Typography>
      </Box>
    </div>
  );
}
