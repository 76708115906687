import React, { ReactNode, SyntheticEvent } from 'react';
import { IconButton, Typography, DialogTitle as MuiDialogTitle, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export interface DialogTitleProps {
  onClose?: (e: SyntheticEvent<HTMLElement>) => void;
  onCloseDisabled?: boolean;
  children?: ReactNode;
  dataTest?: string;
}

const useStyles = makeStyles({
  root: {
    padding: '0 4px 0 20px',
  },
  titleContainer: {
    height: 53,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export function DialogTitle(props: DialogTitleProps): JSX.Element {
  const { onClose, onCloseDisabled = false, children, dataTest = 'modal-title' } = props;

  const { root, titleContainer } = useStyles();

  return (
    <MuiDialogTitle classes={{ root }} disableTypography>
      <div className={titleContainer} data-test={dataTest}>
        <Typography component="h2" variant="h3">
          {children}
        </Typography>
        {onClose && (
          <IconButton aria-label="close" onClick={onClose} disabled={onCloseDisabled}>
            <CloseIcon data-test="close-dialog" />
          </IconButton>
        )}
      </div>
    </MuiDialogTitle>
  );
}
