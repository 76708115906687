import React, { useCallback, useState, useEffect } from 'react';
import { object, string } from 'yup';
import { FormikHelpers as FormikActions, Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { parse } from 'query-string';
import { Typography } from '@material-ui/core';

import { confirmPasswordReset, verifyPasswordResetCode } from 'firebaseIntegration';

import { SetPasswordForm } from './SetPasswordForm';
import { SsoLayout } from '../components/SsoLayout';

import { getCurrentUser } from 'firebaseIntegration/utils';
import createLog from 'graphql/mutation/error/createLog.gql';
import { useMutation } from '@apollo/client';

export interface FormValues {
  newPassword: string;
}

const initialValues = {
  newPassword: '',
};

const validationSchema = object().shape({
  newPassword: string().required('Password is required'),
});

export function SetPassword(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const [pushLog] = useMutation(createLog);
  const { enqueueSnackbar } = useSnackbar();

  const [userEmail, setUserEmail] = useState('');
  const params = parse(location.search);

  const actionCode = !params.oobCode || Array.isArray(params.oobCode) ? '' : params.oobCode;

  useEffect(() => {
    const verifyPasswordCode = async () => {
      try {
        if (!actionCode) {
          throw new Error(
            'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.',
          );
        }

        const email = await verifyPasswordResetCode(actionCode);

        setUserEmail(email);
      } catch (error) {
        history.replace('/login');
      }
    };

    verifyPasswordCode();
  }, [actionCode, enqueueSnackbar, history]);

  const handleSubmit = useCallback(
    async (values: FormValues, actions: FormikActions<FormValues>): Promise<void> => {
      try {
        const { newPassword } = values;

        await confirmPasswordReset(actionCode, newPassword);

        history.push('/login');
        enqueueSnackbar('New password set successfully', { variant: 'success' });
      } catch (error) {
        if (error instanceof Error) {
          const location = window.location.href;
          const user = await getCurrentUser();
          await pushLog({
            variables: {
              type: error,
              location: location,
              msg: error?.message,
              name: user?.displayName,
              email: user?.email,
            },
          });
          enqueueSnackbar(error?.message, { variant: 'error' });
        }
      } finally {
        actions.setSubmitting(false);
      }
    },
    [actionCode, enqueueSnackbar, history],
  );

  return (
    <SsoLayout>
      <Typography variant="h2" gutterBottom data-test="set-password-text">
        New Password
      </Typography>
      <Typography variant="body1" data-test="set-password-subtitle">
        Set password for: {<b>{userEmail}</b>}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        component={SetPasswordForm}
      />
    </SsoLayout>
  );
}
