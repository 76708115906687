import React, { useCallback, useMemo, useState, MouseEvent, KeyboardEvent } from 'react';
import { Menu, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import { ExpandIcon } from './icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
    outline: 0,
    fontSize: 12,
  },
  initials: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    margin: theme.spacing(1),
  },
  expandOptionsIcon: {
    display: 'flex',
    marginLeft: 10,
  },
  warn: {
    color: theme.palette.error.main,
  },
  menuItem: { minWidth: 150 },
  verticalDivider: {
    height: 28,
    width: 1,
    backgroundColor: theme.palette.border,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  organization: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 56,
  },
  organizationCaption: {
    fontSize: 10,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  organizationName: {
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: 11,
    fontWeight: 600,
  },
}));

interface UserOption {
  action: () => void;
  label: string;
  warn?: boolean;
}

export interface UserPanelProps {
  userName: string;
  accountName: string;
  options: UserOption[];
  hideUserName?: boolean;
}

export function UserPanel(props: UserPanelProps): JSX.Element {
  const { userName, options, hideUserName, accountName } = props;
  const {
    root,
    verticalDivider,
    initials,
    menuItem,
    expandOptionsIcon,
    button,
    organization,
    organizationName,
    organizationCaption,
  } = useStyles();

  const [optionsAnchor, setOptionsAnchor] = useState<Element | undefined>();
  const closeMenu = useCallback(() => setOptionsAnchor(undefined), [setOptionsAnchor]);
  const onOptionsExpand = (event: MouseEvent | KeyboardEvent): void => setOptionsAnchor(event.currentTarget);
  const optionsItems = useMemo(
    () =>
      options.map(({ action, label, warn }, key) => {
        const onClick = (): void => {
          action();
          closeMenu();
        };

        return (
          <MenuItem
            onClick={onClick}
            key={key}
            className={classNames(menuItem, { danger: warn })}
            data-test="menu-item"
          >
            {label}
          </MenuItem>
        );
      }),
    [options, closeMenu, menuItem],
  );

  const userInitials = userName
    .split(/ +/)
    .slice(0, 2)
    .map(name => name.charAt(0).toUpperCase())
    .join('');

  return (
    <div className={root}>
      <div className={organization}>
        <div className={organizationCaption}>Organization</div>
        <div className={organizationName}>{accountName}</div>
      </div>
      <div className={verticalDivider} />
      <div
        role="button"
        onClick={onOptionsExpand}
        onKeyPress={onOptionsExpand}
        tabIndex={0}
        className={button}
        data-test="self-user"
      >
        <div className={initials} data-test="initials">
          {userInitials}
        </div>
        {!hideUserName && (
          <>
            {userName}
            <span className={expandOptionsIcon}>
              <ExpandIcon />
            </span>
          </>
        )}
      </div>
      <Menu
        open={!!optionsAnchor}
        anchorEl={optionsAnchor}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        getContentAnchorEl={null}
        MenuListProps={{ disablePadding: true }}
      >
        {optionsItems}
      </Menu>
    </div>
  );
}
