import React, { memo, FC } from 'react';
import { makeStyles, Typography, Chip, Divider } from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import classnames from 'classnames';

import { Tooltip } from '@openx/components/core';
import { MatchingOperator } from 'modules/audiences/AudienceProvider';

const getKey = ({ props: { groupIndex, subgroupIndex, relationField } }) =>
  `${relationField}-${groupIndex}${!!subgroupIndex && '-'}${!!subgroupIndex && subgroupIndex}`;

interface StyleProps {
  compactDividers: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '15px',
    overflow: 'hidden',
  },
  matchTypeSwitch: {
    marginBottom: '15px',
  },
  switchCaption: {
    fontSize: '11px',
    padding: '7px 0 7px 12px',
    marginRight: theme.spacing(1.5),
  },
  chip: {
    marginLeft: ({ compactDividers }: StyleProps) => (compactDividers ? '24px' : '0'),
    borderRadius: '50px',
    backgroundColor: theme.palette.background.black10,
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: theme.palette.background.black10,
    },
    marginTop: '20px',
    marginBottom: '20px',
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  divider: {
    width: '100%',
    backgroundColor: theme.palette.background.black10,
  },
}));

const tooltipText =
  "'Match All' targets users that belong to all selected segments/segment groups. 'Match Any' targets users that belong to at least one of the selected segments/segment groups.";

interface MatchTypeContainerProps {
  matchItems: JSX.Element[];
  readonly?: boolean;
  compactDividers?: boolean;
  matchTypeClassName?: string;
  onChange?: (value: MatchingOperator) => void;
  value?: MatchingOperator;
}

export const MatchTypeContainer: FC<MatchTypeContainerProps> = memo(
  ({
    matchItems = [],
    readonly,
    compactDividers = false,
    matchTypeClassName,
    onChange = () => {},
    value = MatchingOperator.INTERSECTION,
  }) => {
    const classes = useStyles({ compactDividers });

    const handleMatchTypeChange = (_, value) => {
      if (value) {
        onChange(value);
      }
    };

    const renderMatchTypeDivider = (matchType: MatchingOperator) => {
      const labelMap = {
        [MatchingOperator.INTERSECTION]: 'AND',
        [MatchingOperator.UNION]: 'OR',
      };

      const label = labelMap[matchType];
      const containerClassName = !compactDividers ? classes.chipContainer : undefined;

      return (
        <div className={containerClassName}>
          {!compactDividers && <Divider className={classes.divider} />}
          <Chip classes={{ root: classes.chip }} label={label} clickable={false} data-test="match-type-chip" />
          {!compactDividers && <Divider className={classes.divider} />}
        </div>
      );
    };

    return (
      <div className={classes.root}>
        {!readonly && matchItems.length > 1 && (
          <div className={classnames(classes.matchTypeSwitch, matchTypeClassName)}>
            <ToggleButtonGroup exclusive onChange={handleMatchTypeChange} value={value}>
              <ToggleButton
                id={MatchingOperator.INTERSECTION}
                value={MatchingOperator.INTERSECTION}
                data-test="match-all-btn"
              >
                MATCH ALL
              </ToggleButton>
              <ToggleButton id={MatchingOperator.UNION} value={MatchingOperator.UNION} data-test="match-any-btn">
                MATCH ANY
              </ToggleButton>
            </ToggleButtonGroup>
            <Typography variant="button" color="textSecondary" className={classes.switchCaption}>
              OF THE FOLLOWING:
            </Typography>
            <Tooltip title={tooltipText} withRipple iconDataTest="match-type-info-icon" />
          </div>
        )}

        {!!matchItems.length &&
          matchItems.map((MatchItem, index) => (
            <div key={getKey(MatchItem)}>
              {MatchItem}
              {index !== matchItems.length - 1 && renderMatchTypeDivider(value)}
            </div>
          ))}
      </div>
    );
  },
);
