import React, { FC, useMemo, useState } from 'react';
import { FormControl, FormControlLabel, InputLabel, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AutoComplete, Button, Loader } from '@openx/components/core';

import { useAudience, ActionType, fields, AgeType } from 'modules/audiences/AudienceProvider';
import { EnhancedTreeData, TreeData } from 'utils';

import { useDemographicsOptions, MIN_AGE, MAX_AGE, ANY_AGE_VALUE } from 'modules/audiences/hooks';

const useStyles = makeStyles<Theme>({
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  inputContainer: {
    width: 214,
    display: 'inline-block',
  },
  text: {
    marginLeft: 20,
    marginRight: 20,
    display: 'inline',
    position: 'relative',
    top: 7,
  },
  button: {
    marginTop: 16,
  },
});

enum FieldType {
  MIN = 'min',
  MAX = 'max',
}

type ageState = {
  min?: number;
  max?: number;
  editAge?: number;
};

interface AgeProps {
  value: EnhancedTreeData[];
  editAge?: number;
}

export const Age: FC<AgeProps> = ({ value, editAge }): JSX.Element => {
  const classes = useStyles();
  const { dispatch } = useAudience();
  const { segmentsToRange, rangeToSegments, ageList, segmentsToRangeGroups } = useDemographicsOptions();

  const ageRanges = segmentsToRangeGroups(value);

  const selected = ageRanges.filter(({ min }) => min === editAge)[0];

  const [ageState, setAgeState] = useState<ageState>({ editAge: editAge });

  if (ageState?.editAge !== editAge && (ageState?.min !== selected?.min || ageState?.max !== selected?.max))
    setAgeState({ editAge: editAge, min: selected?.min, max: selected?.max });

  const handleAgeChange = (age: number, type: FieldType) => {
    if (age === ANY_AGE_VALUE) {
      setAgeState({ ...ageState, min: ANY_AGE_VALUE, max: ANY_AGE_VALUE });
      return;
    }

    if (type === FieldType.MIN) {
      if (ageState.max === ANY_AGE_VALUE || ageState.max === undefined) {
        setAgeState({ ...ageState, min: age, max: age });
      } else {
        setAgeState({ ...ageState, min: age, max: age < (ageState.max || Math.max(...ageList)) ? ageState.max : age });
      }
    } else {
      if (ageState.min === ANY_AGE_VALUE || ageState.min === undefined) {
        setAgeState({ ...ageState, min: age, max: age });
      } else {
        setAgeState({
          ...ageState,
          min: age > (ageState.min || Math.min(...ageList.filter(age => age !== -1))) ? ageState.min : age,
          max: age,
        });
      }
    }
  };

  const handleAddAgeGroup = () => {
    if (ageState?.min === ANY_AGE_VALUE || ageState?.max === ANY_AGE_VALUE) {
      // dispatch({ type: ActionType.SET_DEMOGRAPHIC_AGE, payload: { segments: { [fields.AGE_FIELD]: [] } } });
      return;
    }

    const newRanges = ageRanges.filter(({ min }) => min !== editAge);
    newRanges.push({ min: ageState?.min, max: ageState?.max });
    const newSegments = newRanges.map(range => rangeToSegments(range)).reduce((acc, val) => acc.concat(val), []);

    const uniqueArray = Array.from(new Set(newSegments.map(obj => obj.id))).map(id => {
      return newSegments.find(obj => obj.id === id) || ({} as EnhancedTreeData);
    });
    dispatch({
      type: ActionType.SET_DEMOGRAPHIC_AGE,
      payload: {
        segments: { [fields.AGE_FIELD]: uniqueArray },
      },
    });

    dispatch({ type: ActionType.SET_SELECTED_AGE, payload: { selectedAge: void 0 } });
    setAgeState({ editAge: void 0, min: ANY_AGE_VALUE, max: ANY_AGE_VALUE });
  };

  const getOptionLabel = option => (option === ANY_AGE_VALUE ? 'Any' : option.toString());

  if (ageList.length <= 1) return <Loader />; // Only Render when Options are Available.

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputContainer}>
        <AutoComplete
          value={ageState?.min || ANY_AGE_VALUE}
          data-test="min-age"
          options={ageList}
          defaultValue={MIN_AGE}
          textFieldProps={{ label: 'Age From' }}
          textFieldReadOnly
          disableClearable
          renderOption={option => <span data-test="min-age-item">{getOptionLabel(option)}</span>}
          getOptionLabel={getOptionLabel}
          onChange={(_, age) => {
            handleAgeChange(Number(age), FieldType.MIN);
          }}
        />
      </div>
      <div className={classes.inputContainer}>
        <AutoComplete
          data-test="max-age"
          value={ageState?.max || ANY_AGE_VALUE}
          options={ageList}
          defaultValue={MAX_AGE}
          textFieldProps={{ label: 'Age To' }}
          textFieldReadOnly
          disableClearable
          renderOption={option => <span data-test="max-age-item">{getOptionLabel(option)}</span>}
          getOptionLabel={getOptionLabel}
          onChange={(_, age) => {
            handleAgeChange(Number(age), FieldType.MAX);
          }}
        />
      </div>
      <div className={classes.inputContainer}>
        <FormControl>
          <Button
            classes={{
              root: classes.button,
            }}
            onClick={handleAddAgeGroup}
            data-test="add-age-group"
          >
            {selected === undefined ? 'Add Ages' : 'Update'}
          </Button>
        </FormControl>
      </div>
    </div>
  );
};
