import React from 'react';
import { COLORS } from '@openx/components/core';

export const Display = (): JSX.Element => (
  <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5 4.75H9.5V3.25H15.5V4.75ZM15.5 0.25H9.5V1.75H15.5V0.25ZM9.5 7.75H15.5V6.25H9.5V7.75ZM8 1.75V6.25C8 7.075 7.325 7.75 6.5 7.75H2C1.175 7.75 0.5 7.075 0.5 6.25V1.75C0.5 0.925 1.175 0.25 2 0.25H6.5C7.325 0.25 8 0.925 8 1.75ZM6.875 6.25L5.1875 4L3.875 5.695L2.9375 4.5625L1.625 6.25H6.875Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);

export const Video = (): JSX.Element => (
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 0.25H2.25C1.4175 0.25 0.75 0.9175 0.75 1.75V10.75C0.75 11.575 1.4175 12.25 2.25 12.25H6V13.75H12V12.25H15.75C16.575 12.25 17.2425 11.575 17.2425 10.75L17.25 1.75C17.25 0.9175 16.575 0.25 15.75 0.25ZM15.75 10.75H2.25V1.75H15.75V10.75ZM12 6.25L6.75 9.25V3.25L12 6.25Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);

export const MobileApp = (): JSX.Element => (
  <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.75 0.75H2.25C1.425 0.75 0.75 1.425 0.75 2.25V15.75C0.75 16.575 1.425 17.25 2.25 17.25H9.75C10.575 17.25 11.25 16.575 11.25 15.75V2.25C11.25 1.425 10.575 0.75 9.75 0.75ZM9.75 14.25H2.25V3.75H9.75V14.25ZM3 4.5H9V6H3V4.5Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);

export const MobileWeb = (): JSX.Element => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 0H2C1.175 0 0.5075 0.675 0.5075 1.5L0.5 10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V1.5C15.5 0.675 14.825 0 14 0ZM10.25 10.5H2V7.5H10.25V10.5ZM10.25 6.75H2V3.75H10.25V6.75ZM14 10.5H11V3.75H14V10.5Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);

export const Desktop = (): JSX.Element => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 0.5H2.25C1.425 0.5 0.75 1.175 0.75 2V11C0.75 11.825 1.425 12.5 2.25 12.5H7.5L6 14.75V15.5H12V14.75L10.5 12.5H15.75C16.575 12.5 17.25 11.825 17.25 11V2C17.25 1.175 16.575 0.5 15.75 0.5ZM15.75 9.5H2.25V2H15.75V9.5Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);

export const ConnectedTv = (): JSX.Element => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 3.5H10.0575L12.525 1.0325L12 0.5L9 3.5L6 0.5L5.4675 1.0325L7.9425 3.5H2.25C1.425 3.5 0.75 4.1675 0.75 5V14C0.75 14.825 1.425 15.5 2.25 15.5H15.75C16.575 15.5 17.25 14.825 17.25 14V5C17.25 4.1675 16.575 3.5 15.75 3.5ZM15.75 14H2.25V5H15.75V14ZM6.75 6.5V12.5L12 9.5L6.75 6.5Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);

export const IpAddress = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 12.3563C10.2375 11.3563 11.1719 10.3938 11.8031 9.46875C12.4344 8.54375 12.75 7.675 12.75 6.8625C12.75 6.1625 12.6219 5.56563 12.3656 5.07188C12.1094 4.57812 11.7938 4.17813 11.4188 3.87188C11.0438 3.56562 10.6375 3.34375 10.2 3.20625C9.7625 3.06875 9.3625 3 9 3C8.6375 3 8.2375 3.06875 7.8 3.20625C7.3625 3.34375 6.95625 3.56562 6.58125 3.87188C6.20625 4.17813 5.89062 4.57812 5.63438 5.07188C5.37813 5.56563 5.25 6.1625 5.25 6.8625C5.25 7.675 5.56563 8.54375 6.19688 9.46875C6.82813 10.3938 7.7625 11.3563 9 12.3563ZM9 14.25C7.2375 12.95 5.92188 11.6875 5.05313 10.4625C4.18438 9.2375 3.75 8.0375 3.75 6.8625C3.75 5.975 3.90938 5.19688 4.22813 4.52813C4.54688 3.85938 4.95625 3.3 5.45625 2.85C5.95625 2.4 6.51875 2.0625 7.14375 1.8375C7.76875 1.6125 8.3875 1.5 9 1.5C9.6125 1.5 10.2313 1.6125 10.8563 1.8375C11.4813 2.0625 12.0438 2.4 12.5438 2.85C13.0438 3.3 13.4531 3.85938 13.7719 4.52813C14.0906 5.19688 14.25 5.975 14.25 6.8625C14.25 8.0375 13.8156 9.2375 12.9469 10.4625C12.0781 11.6875 10.7625 12.95 9 14.25ZM9 8.25C9.4125 8.25 9.76562 8.10313 10.0594 7.80938C10.3531 7.51562 10.5 7.1625 10.5 6.75C10.5 6.3375 10.3531 5.98438 10.0594 5.69063C9.76562 5.39688 9.4125 5.25 9 5.25C8.5875 5.25 8.23438 5.39688 7.94063 5.69063C7.64688 5.98438 7.5 6.3375 7.5 6.75C7.5 7.1625 7.64688 7.51562 7.94063 7.80938C8.23438 8.10313 8.5875 8.25 9 8.25ZM3.75 16.5V15H14.25V16.5H3.75Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);

export const Tablet = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.875 0H3.37499C2.33999 0 1.49999 0.84 1.49999 1.875V16.125C1.49999 17.16 2.33999 18 3.37499 18H13.875C14.91 18 15.75 17.16 15.75 16.125V1.875C15.75 0.84 14.91 0 13.875 0ZM8.62499 17.25C8.00249 17.25 7.49999 16.7475 7.49999 16.125C7.49999 15.5025 8.00249 15 8.62499 15C9.24749 15 9.74999 15.5025 9.74999 16.125C9.74999 16.7475 9.24749 17.25 8.62499 17.25ZM14.25 14.25H2.99999V2.25H14.25V14.25Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);

export const Cookies = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.875 7.5C8.49632 7.5 9 6.99632 9 6.375C9 5.75368 8.49632 5.25 7.875 5.25C7.25368 5.25 6.75 5.75368 6.75 6.375C6.75 6.99632 7.25368 7.5 7.875 7.5Z"
      fill={COLORS.TEXT_LIGHT}
    />
    <path
      d="M6.375 11.25C6.99632 11.25 7.5 10.7463 7.5 10.125C7.5 9.50368 6.99632 9 6.375 9C5.75368 9 5.25 9.50368 5.25 10.125C5.25 10.7463 5.75368 11.25 6.375 11.25Z"
      fill={COLORS.TEXT_LIGHT}
    />
    <path
      d="M11.25 12C11.6642 12 12 11.6642 12 11.25C12 10.8358 11.6642 10.5 11.25 10.5C10.8358 10.5 10.5 10.8358 10.5 11.25C10.5 11.6642 10.8358 12 11.25 12Z"
      fill={COLORS.TEXT_LIGHT}
    />
    <path
      d="M16.4625 8.24262C15.12 8.22012 13.6875 6.78012 14.4525 5.07762C12.225 5.82762 10.1175 3.88512 10.56 1.65762C5.3325 0.555119 1.5 4.80762 1.5 9.00012C1.5 13.1401 4.86 16.5001 9 16.5001C13.4175 16.5001 16.905 12.6901 16.4625 8.24262ZM9 15.0001C5.6925 15.0001 3 12.3076 3 9.00012C3 6.51762 5.0475 2.86512 9.06 2.98512C9.375 4.89012 10.89 6.40512 12.8025 6.69012C12.855 6.96012 13.1925 8.60262 14.9925 9.41262C14.775 12.6451 12.045 15.0001 9 15.0001Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);

export const IDs = (): JSX.Element => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 9.75V12C16.5 12.825 15.825 13.5 15 13.5H12.75L13.5 14.25V15.75H4.5V14.25L5.25 13.5H3C2.175 13.5 1.5 12.825 1.5 12V3.75C1.5 2.925 2.175 2.25 3 2.25H9V3.75H3V12H15V9.75H16.5ZM11.25 11.25L7.5 7.5H10.5V2.25H12V7.5H15L11.25 11.25Z"
      fill={COLORS.TEXT_LIGHT}
    />
  </svg>
);
