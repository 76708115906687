import React, { useCallback } from 'react';
import { Grid, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button, Logo } from '@openx/components/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh',
    backgroundColor: theme.palette.background.boxLight,
  },
  formContainer: {
    padding: '30px 50px',
    display: 'flex',
    flexDirection: 'column',
  },
  promoContainer: {
    background: 'linear-gradient(90.34deg, #0076BD 16.27%, #1FBAB0 74.56%)',
    padding: '20px 50px',
    color: '#FFF',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: 80,
  },
  centeredContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  title: {
    fontSize: 36,
    lineHeight: '22px',
    fontWeight: 800,
  },
  subtitle: {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 400,
  },
  copyright: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export function SsoLayout({ children }): JSX.Element {
  const classes = useStyles();
  const history = useHistory();

  const goToTerms = useCallback(() => {
    history.push('/terms');
  }, [history]);

  return (
    <Grid container classes={{ root: classes.container }}>
      <Grid item xs={4} classes={{ root: classes.formContainer }}>
        <Logo darkMode className={classes.logo} data-test="logo" />
        <div className={classes.centeredContainer}>{children}</div>
        <div>
          <Button variant="text" onClick={goToTerms}>
            TERMS AND CONDITIONS
          </Button>
        </div>
      </Grid>
      <Grid item xs={8} classes={{ root: classes.promoContainer }}>
        <div className={classes.centeredContainer} data-test="advert-text">
          <Typography variant="h1" gutterBottom className={classes.title}>
            Reach your target audience
          </Typography>
          <Typography variant="h2" className={classes.subtitle}>
            Welcome to OpenX, where you can manage media buys to reach your target audience and maximize your ROI
          </Typography>
        </div>
        <div className={classes.copyright}>Copyright © 2020 OpenX. All rights reserved.</div>
      </Grid>
    </Grid>
  );
}
