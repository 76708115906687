import React, { useMemo, useCallback } from 'react';
import { Loader } from '@openx/components/core';

import { anySelected, EnhancedTreeData, segmentsToTreeData } from 'utils';
import {
  useAudience,
  RTG_FIELD,
  THIRD_PARTY_FIELD,
  SITE_CATEGORY_FIELD,
  getOpenSubgroupPredefiendSegments,
  Predefined,
  ActionType,
  PredefinedSegmentType,
} from 'modules/audiences/AudienceProvider';
import { Title as ListTitle } from 'components/OptionsList';
import { usePredefinedOptions } from 'modules/audiences/hooks';

import { ListType, NestedList } from '../OptionsList';

interface SegmentsOptionsListProps {
  readonly: boolean;
  listType: ListType;
  predefinedValues: Predefined;
}

export const PredefinedOptionsList = React.memo((props: SegmentsOptionsListProps): JSX.Element | null => {
  const { listType, readonly, predefinedValues } = props;
  const { state, dispatch } = useAudience();
  const { loading, error, options, clickedOptions } = usePredefinedOptions({ fetchExpiredSegments: true });

  const actionListDefinitions = useMemo(
    () => [
      {
        title: 'PARTNER AUDIENCES',
        field: PredefinedSegmentType.RTG_FIELD,
        values: predefinedValues[RTG_FIELD],
        treeOptions: options[RTG_FIELD],
      },
      {
        title: 'THIRD PARTY DATA',
        field: PredefinedSegmentType.THIRD_PARTY_FIELD,
        values: predefinedValues[THIRD_PARTY_FIELD],
        treeOptions: options[THIRD_PARTY_FIELD],
      },
      {
        title: 'PAST BROWSING INTERESTS',
        field: PredefinedSegmentType.SITE_CATEGORY_FIELD,
        values: predefinedValues[SITE_CATEGORY_FIELD],
        treeOptions: options[SITE_CATEGORY_FIELD],
      },
    ],
    [options, predefinedValues],
  );

  const handleRemoveClick = useCallback(
    (optionToDelete: EnhancedTreeData, field: PredefinedSegmentType) => {
      const selectedOptions = getOpenSubgroupPredefiendSegments(state);

      const newOptions = selectedOptions[field].filter(({ id }: EnhancedTreeData) => id !== optionToDelete.id);

      dispatch({
        type: ActionType.SET_PREDEFINED,
        payload: { segments: { predefined: { ...selectedOptions, [field]: newOptions } } },
      });
    },
    [state, dispatch],
  );

  if (loading) {
    return <Loader />;
  }

  if (error) {
    throw new Error('Query Error');
  }

  return (
    <>
      {actionListDefinitions.map(({ title, field, values, treeOptions }) => (
        <span key={title}>
          {anySelected(values) && (
            <>
              <ListTitle data-test="segment-type-title">{title}</ListTitle>
              <NestedList
                clickedOptions={clickedOptions}
                items={segmentsToTreeData(values)}
                type={listType}
                onDelete={item => handleRemoveClick(item, field)}
                tree={treeOptions}
                readonly={readonly}
              />
            </>
          )}
        </span>
      ))}
    </>
  );
});
