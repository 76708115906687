import { Box, Typography, makeStyles } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { HighlightedPhrase, Loader } from '@openx/components/core';
import React from 'react';

interface StyleProps {
  expanded?: boolean;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  isExpanded: ({ expanded }: StyleProps) => ({
    transform: expanded ? 'rotate(270deg)' : 'rotate(90deg)',
  }),
  name: {
    fontSize: '13px',
  },
});

export const NameCell = ({ segment, highlighter }) => {
  const { expanded, depth, description, name: segmentName, is_nav_only, isFetching } = segment;
  const { root, isExpanded, name } = useStyles({ expanded });

  const showDescription = !is_nav_only && description;

  const highlitedName = highlighter(segmentName);

  if (!showDescription) {
    return (
      <Box className={root} paddingLeft={1.5 + depth * 4} paddingY={is_nav_only ? 2.5 : 2} alignItems="center">
        {is_nav_only && (
          <ChevronRight data-expandicon="expandRow" color="primary" className={isExpanded} data-test="expand-icon" />
        )}
        <Box paddingLeft={2} paddingRight={1} data-test="taxonomy-name">
          <Typography variant="body1" color={is_nav_only ? 'primary' : 'textPrimary'} className={name}>
            <b>{highlitedName}</b>
          </Typography>
        </Box>
        {isFetching && <Loader noPadding size={18} />}
      </Box>
    );
  }

  const highlitedDescription = highlighter(description);

  return (
    <Box className={root} paddingLeft={1.5 + depth * 4} paddingY={is_nav_only ? 2.5 : 2} alignItems="center">
      {is_nav_only && <ChevronRight data-expandicon="expandRow" color="primary" className={isExpanded} />}
      <Box paddingLeft={2} paddingRight={1}>
        <>
          <Typography
            variant="body1"
            color={is_nav_only ? 'primary' : 'textPrimary'}
            className={name}
            data-test="segment-name"
          >
            <b>{highlitedName}</b>
          </Typography>
          <Typography color={is_nav_only ? 'primary' : 'textSecondary'} variant="caption" data-test="segment-caption">
            {highlitedDescription}
          </Typography>
        </>
      </Box>
      {isFetching && <Loader noPadding size={18} />}
    </Box>
  );
};
