import React, { useState } from 'react';
import { Dialog, FormControl, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button, DebounceField, Paper } from '@openx/components/core';
import { useValidateDirectSegments } from 'modules/audiences/components/DirectSegment/utils/useValidateDirectSegments';
import { TreeData } from 'utils';

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 20px',
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  bulkField: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 13,
  },
}));

interface Props {
  onClose: () => void;
  isOpen: boolean;
  setDirectSegments: (options: TreeData[]) => void;
  directProvider: string;
}

export function BulkAdd(props: Props): JSX.Element {
  const { onClose, isOpen, setDirectSegments, directProvider } = props;
  const classes = useStyles();

  const mapBulkPlaceHolder = `Direct segments may be entered as either semicolon, or new line separated values.

Examples:
 
HOME_AND_GARDEN
AGE_18_24`;

  const [search, setSearch] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const [valids, invalids] = useValidateDirectSegments({ search, directProvider });

  const onAdd = () => {
    setDirectSegments(valids);
    onDialogClose();
  };

  const onChange = ({ target: { value } }) => {
    setValue(value);

    const toSearch = new Set<string>(
      value
        .split(';')
        .flatMap(item => item.split('\n'))
        .map(option => option.trim())
        .filter(option => option),
    );

    setSearch(Array.from(toSearch));
  };

  const onDialogClose = () => {
    setValue('');
    setSearch([]);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onDialogClose}>
      <Paper padding="none">
        {({ HighlightedSection }) => (
          <>
            <Paper>
              <Typography variant="h2" gutterBottom data-test="bulk-add-title">
                Bulk Add
              </Typography>

              <FormControl>
                <DebounceField
                  data-test="bulk-add-area"
                  multiline={true}
                  minRows={10}
                  fullWidth
                  placeholder={mapBulkPlaceHolder}
                  className={classes.bulkField}
                  onChange={onChange}
                  value={value}
                />
              </FormControl>
              {value && invalids.length > 0 && (
                <div className={classes.error} data-test="bulk-add-error">
                  The following invalid entries will be ignored on add: {invalids.join(', ')}
                </div>
              )}
            </Paper>
            <HighlightedSection>
              <div className={classes.actionsContainer} data-test="bulk-add-action-buttons">
                <Button variant="text" onClick={onDialogClose}>
                  CLOSE
                </Button>
                <Button className={classes.addButton} onClick={onAdd}>
                  ADD
                </Button>
              </div>
            </HighlightedSection>
          </>
        )}
      </Paper>
    </Dialog>
  );
}
