import React from 'react';
import { makeStyles, Theme, Grid, Typography } from '@material-ui/core';
import { StatusCell } from 'modules/deals/DealsList/StatusCell';
import { Ellipsis } from '@openx/components/core';

interface Props {
  deals: Deal[];
}

export interface Deal {
  exchange_deal_id?: string | null;
  status: string;
  name?: string | null;
  id?: string;
}

const useStyles = makeStyles<Theme>(theme => ({
  dealsGap: {
    marginTop: theme.spacing(0.5),
  },
}));

export function DealList({ deals }: Props): JSX.Element | null {
  const classes = useStyles();

  if (!deals || !deals.length) {
    return null;
  }

  return (
    <Grid container direction="column">
      {deals.map(({ exchange_deal_id, status, name }) => (
        <Grid container key={exchange_deal_id} className={classes.dealsGap}>
          <Grid item xs={5}>
            <Typography color="textPrimary" data-test="deal-name">
              <Ellipsis width="90%" tooltip>
                {name}
              </Ellipsis>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="textPrimary" data-test="exchange-deal-id">
              {exchange_deal_id}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <StatusCell status={status} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
