import React, { useState } from 'react';
import classnames from 'classnames';
import { Button } from '@openx/components/core';
import { makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { DataType } from '../dataType';
import { downloadDataTemplateFile } from './downloadDataTemplateFile';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    maxHeight: 150,
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  },
  contentOpen: {
    maxHeight: 'auto',
  },
  contentClose: {
    maxHeight: 0,
  },
});

export interface FormattingGuidelinesProps {
  dataType?: DataType;
}

export function FormattingGuidelines({ dataType }: FormattingGuidelinesProps): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function onToggleButtonClick() {
    setOpen(!open);
  }

  function onDownloadTemplateButtonClick() {
    if (!dataType) {
      return;
    }

    downloadDataTemplateFile(dataType);
  }

  const toggleButtonLabel = open ? 'Hide details' : 'Show details';
  const showDataHashingInfo = dataType !== DataType.MOBILE_AD_ID;
  const showDownloadTemplateButton = dataType !== undefined;

  return (
    <>
      <div className={classes.header} data-test="formatting-alert">
        <Alert severity="warning">Formatting guidelines and template.</Alert>
        <Button variant="text" color="primary" onClick={onToggleButtonClick}>
          {toggleButtonLabel}
        </Button>
      </div>
      <div
        className={classnames(classes.content, {
          [classes.contentOpen]: open,
          [classes.contentClose]: !open,
        })}
        data-test="details-info"
      >
        {showDataHashingInfo && (
          <Typography paragraph>
            Your data will be hashed locally on your browser before sending to us. <br />
            If you want to pre-hash the data manually: <br />
            <br />
            1. Normalize the data by converting all characters to lowercase and trimming all whitespace. <br />
            2. Hash the normalized data using SHA256.
          </Typography>
        )}

        {showDownloadTemplateButton && (
          <Button size="small" onClick={onDownloadTemplateButtonClick}>
            Download template
          </Button>
        )}
      </div>
    </>
  );
}
