import React from 'react';

import { makeStyles } from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import { AlertTooltip } from 'components/AlertTooltip';

import { utcDate } from 'utils';
import moment from 'moment';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '5px',
  },
});

const tooltipText = 'This API key is expired';

export const ExpiresAtCell = ({ expiresAt }) => {
  const { flex, marginRight } = useStyles();

  const currentDate = new Date();

  const expirationDate = expiresAt ? new Date(expiresAt) : null;

  const showExpiredAlert = !!expiresAt && moment(currentDate).isAfter(expirationDate);

  return (
    <div className={flex}>
      <p className={marginRight}>{expiresAt ? utcDate(expiresAt) : 'N/A'}</p>
      {showExpiredAlert && (
        <AlertTooltip color="#E8B046" title={tooltipText} icon={<Warning fontSize="small" color="inherit" />} />
      )}
    </div>
  );
};
