import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'relative',
    top: -theme.spacing(1.5),
  },
}));

export function ContentWrapper({ children }: { children: React.ReactNode }): JSX.Element {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
}
