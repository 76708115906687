import React, { ComponentType } from 'react';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ActionButtonProps } from './HeaderActions/ActionButton';
import { HeaderActions } from './HeaderActions/HeaderActions';

const useStyles = makeStyles({ grow: { flexGrow: 1 } });

export interface ListHeaderContentProps<FiltersProps extends {} = {}> {
  filtersProps?: FiltersProps;
  filtersComponent?: ComponentType<FiltersProps>;
  actions?: ActionButtonProps[];
}

export function ListHeaderContent<FiltersProps extends {} = {}>(
  props: ListHeaderContentProps<FiltersProps>,
): JSX.Element | null {
  const { filtersProps, filtersComponent: FiltersComponent, actions } = props;
  const classes = useStyles();

  const filtersElement = filtersProps && FiltersComponent && (
    <Grid item className={classes.grow}>
      <FiltersComponent {...filtersProps} />
    </Grid>
  );

  const actionsButtons = actions && (
    <Grid item>
      <HeaderActions actions={actions} />
    </Grid>
  );

  if (!filtersElement && !actionsButtons) {
    return null;
  }

  return (
    <Grid container item xs={10} wrap="nowrap" spacing={1} justifyContent="flex-end">
      {filtersElement}
      {actionsButtons}
    </Grid>
  );
}
