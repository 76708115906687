import React, { useState, FC } from 'react';
import { FieldProps } from 'formik';
import MuiAutocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';

import { Ellipsis } from '@openx/components/core';

import { TextField } from '../TextField';

const useStyles = makeStyles(theme => ({
  popper: {
    display: 'none',
  },
  inputRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  input: {
    marginLeft: '4px !important',
  },
  endAdornment: {
    top: 'auto',
  },
}));

export interface MultiInputProps {
  error: any;
  helperText: any;
  inputMinLength: number;
  inputMask: RegExp;
  placeholder: string;
  label: string;
}

export const MultiInput: FC<MultiInputProps> = ({
  label,
  placeholder,
  helperText,
  error,
  inputMask,
  inputMinLength,
  ...props
}): JSX.Element => {
  const { popper, inputRoot, input } = useStyles();

  const {
    field: { name, value },
    form: { setFieldValue },
    ...rest
  } = props as FieldProps;

  const [inputValue, setInputValue] = useState('');

  const renderTags = (tags, getTagProps) =>
    tags.map((tag, index) => (
      <Chip
        deleteIcon={<CloseIcon />}
        data-test="multiinput-chip-button"
        {...getTagProps({ index })}
        label={
          <Ellipsis tooltip width="100%">
            {tag}
          </Ellipsis>
        }
      />
    ));

  const renderInput = ({ InputLabelProps, InputProps, ...restParams }) => {
    return (
      <TextField
        {...restParams}
        label={label}
        placeholder={placeholder}
        helperText={helperText}
        error={error}
        InputLabelProps={{ disableAnimation: true, shrink: true, ...InputLabelProps }}
        InputProps={{ disableUnderline: true, ...InputProps }}
      />
    );
  };

  const handleInputChange = ({ target }) => {
    const { value = '' } = target || {};

    if (inputMask) {
      if (value === '' || inputMask.test(value)) {
        setInputValue(value);
      }
    } else {
      setInputValue(value);
    }
  };

  const handleChange = (_, value) => {
    if (inputMinLength) {
      if (value.every(s => s.length >= inputMinLength)) {
        setFieldValue(name, value);
        setInputValue('');
      }
    } else {
      setFieldValue(name, value);
      setInputValue('');
    }
  };

  return (
    <MuiAutocomplete
      options={[]}
      freeSolo
      disableClearable
      multiple
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={renderInput}
      renderTags={renderTags}
      classes={{ popper, inputRoot, input }}
      {...rest}
    />
  );
};
