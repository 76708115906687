import React from 'react';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';

import { IconCheckedDisabled, IconChecked, IconIndeterminate, IconUncheckedDisabled, IconUnchecked } from './icons';

export function Checkbox(props: MuiCheckboxProps): JSX.Element {
  return (
    <MuiCheckbox
      color="primary"
      checkedIcon={props.disabled ? <IconCheckedDisabled /> : <IconChecked />}
      indeterminateIcon={<IconIndeterminate />}
      icon={props.disabled ? <IconUncheckedDisabled /> : <IconUnchecked />}
      {...props}
    />
  );
}
