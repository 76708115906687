import React, { useMemo } from 'react';

import { TableProps } from '../Table';
import { BaseRow } from '../tableHelpers';
import { extendColumnsWithCheckbox } from './extendColumnsWithCheckbox';
import { SelectionManagementPanel } from './SelectionManagementPanel';
import { SelectableTableProps } from './type';

export function SelectableTable<
  RowT extends BaseRow,
  FiltersT extends {} = {},
  TablePropsT extends TableProps<RowT, FiltersT> = TableProps<RowT, FiltersT>,
>(
  WrappedTable: React.ComponentType<TablePropsT>,
  showManagementSection = true,
): (props: TablePropsT & SelectableTableProps<RowT>) => JSX.Element {
  function WrappingTable(props: TablePropsT & SelectableTableProps<RowT>): JSX.Element {
    const { columns, bulkActions, data, loading, selectedItems, toggleSelect, CustomManagementSection } = props;

    const extendedColumns = useMemo(
      () => extendColumnsWithCheckbox(columns, data, { selectedItems, toggleSelect }, loading),
      [columns, data, selectedItems, toggleSelect, loading],
    );

    const wrappedTableProps: TablePropsT = {
      ...props,
      columns: extendedColumns,
    };

    if (showManagementSection) {
      wrappedTableProps.CustomManagementSection = SelectionManagementPanel({
        loading,
        bulkActions,
        selectedItems,
        CustomManagementSection,
        allItems: data,
      });
    }

    return <WrappedTable {...wrappedTableProps} />;
  }

  return WrappingTable;
}
