import { Order_By } from 'types/schemaTypes';
import { LocationType } from 'modules/audiences/AudienceProvider';

export const GetCongressionalSegmentsParams = {
  variables: {
    order_by: [{ full_name: Order_By.Asc }],
    where: {
      category: { _eq: 'location' },
      sub_category: { _eq: 'congressional' },
      is_archived: { _eq: false },
    },
  },
};

export const GetDmaSegmentsParams = {
  variables: {
    order_by: [{ full_name: Order_By.Asc }],
    where: {
      category: { _eq: 'location' },
      sub_category: { _eq: 'dma' },
      is_archived: { _eq: false },
      is_nav_only: { _neq: true },
    },
  },
};

export const SearchLocationSegmentsParams = (search: string[], locationType: LocationType) => ({
  variables: {
    order_by: [{ full_name: Order_By.Asc }],
    where: {
      name: { _in: search },
      category: { _eq: 'location' },
      sub_category: { _eq: locationType },
      is_archived: { _eq: false },
    },
  },
});

export const GetStateSegmentsParams = {
  variables: {
    order_by: [{ full_name: Order_By.Asc }],
    where: {
      category: { _eq: 'location' },
      sub_category: { _eq: 'state' },
      is_archived: { _eq: false },
      is_nav_only: { _neq: true },
    },
  },
};
