import React, { useContext } from 'react';
import { context } from './context';

const useSideDrawer = () => {
  const sideDrawerContext = useContext(context);

  return {
    ...sideDrawerContext,
  };
};

export default useSideDrawer;
