import { useState, useEffect } from 'react';
import { useQuery, ApolloError } from '@apollo/client';

import GetSegments from 'graphql/query/audiences/GetSegments.gql';
import { GetSegmentsQueryVariables, GetSegmentsQuery, Order_By } from 'types/schemaTypes';
import { EnhancedTreeData } from 'utils';
import { DIRECT_SEGMENT_FIELD } from 'modules/audiences/AudienceProvider';
import { localSegments } from 'modules/audiences/utils';

const getSegmentParams = (accountId?: string | null, directProvider?: string) => ({
  variables: {
    order_by: [{ full_name: Order_By.Asc }],
    where: {
      category: { _eq: 'direct' },
      provider_id: { _eq: directProvider },
      is_archived: { _eq: false },
      status: { _eq: 'ready' },
      ...(accountId && {
        account_id: {
          _eq: accountId,
        },
      }),
    },
  },
  skip: !directProvider,
});

export interface UseDirectOptionsResponse {
  loading: boolean;
  error?: ApolloError;
  options: EnhancedTreeData[];
}

const [localCache, memoizeSegments] = localSegments();

export const useDirectOptions = ({ accountId, directProvider, directSegment }): UseDirectOptionsResponse => {
  const [options, setOptions] = useState<EnhancedTreeData[]>([]);

  const {
    loading,
    error,
    data: { segment: directSegments = [] } = {},
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegments, getSegmentParams(accountId, directProvider));

  useEffect(() => {
    if (!loading) {
      memoizeSegments([{ [DIRECT_SEGMENT_FIELD]: directSegments }]);
    }
  }, [directSegments, loading]);

  useEffect(() => {
    if (!loading) {
      const calculatedSegments = localCache[DIRECT_SEGMENT_FIELD]?.calculatedSegments || [];

      const newOptions = calculatedSegments
        .filter(segment => directSegment.findIndex(({ id }) => id === segment.id) === -1)
        .map(({ description, ...segment }) => ({ ...segment }));

      setOptions(newOptions);
    }
  }, [loading, directSegment]);

  return {
    loading,
    error,
    options,
  };
};
