import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { AudienceStatus, ExportingStatus } from 'modules/audiences/constants';
import { Tooltip } from '@openx/components/core';

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    width: 6,
    height: 6,
    borderRadius: '100%',
    marginRight: 5,
    display: 'inline-block',
  },
  active: {
    backgroundColor: theme.palette.success.main,
  },
  inactive: {
    backgroundColor: theme.palette.action.inactive,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  banned: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '62px',
    marginLeft: '11px',
  },
  progressSegment: {
    height: '6px',
    backgroundColor: theme.palette.action.inactive,
    width: '30%',
  },
  exportDone: {
    backgroundColor: theme.palette.primary.main,
  },
}));

interface StatusCellProps {
  status?: string;
  label?: string;
  className?: string;
  exportingStatus?: string;
}

const exportStatuses = [
  ExportingStatus.INIT,
  ExportingStatus.EXPORT_WORKER_DONE,
  ExportingStatus.EXPORT_DOWNLOADING,
  ExportingStatus.EXPORT_DOWNLOADING_DONE,
  ExportingStatus.EXPORT_PROCESSING,
  ExportingStatus.EXPORT_PROCESSING_DONE,
];

const barsMapping = [
  ExportingStatus.EXPORT_WORKER_DONE,
  ExportingStatus.EXPORT_DOWNLOADING_DONE,
  ExportingStatus.EXPORT_PROCESSING_DONE,
];

const Progress = props => {
  const classes = useStyles();
  const { currentStep } = props;

  const tooltipText =
    'Audience is currently exporting, which may take multiple hours to complete. Please refresh the page for an updated status.';

  return (
    <Tooltip title={tooltipText} placement="top">
      <div className={classes.flex}>
        {barsMapping.map(status => (
          <span
            className={classNames(classes.progressSegment, {
              [classes.exportDone]: exportStatuses.indexOf(status) <= exportStatuses.indexOf(currentStep),
            })}
            key={status}
          ></span>
        ))}
      </div>
    </Tooltip>
  );
};

export const StatusCell = (props: StatusCellProps) => {
  const classes = useStyles();

  const { status = 'inactive', className, label, exportingStatus } = props;

  const showProgress = label?.toLowerCase() === AudienceStatus.EXPORTING || status === AudienceStatus.EXPORTING;

  return (
    <>
      <span className={className} data-test={props['data-test']}>
        <span className={classNames(classes.circle, classes[status.toLowerCase()])} />
        <Typography variant="caption" color="textSecondary">
          {label?.toUpperCase() || status.toUpperCase()}
        </Typography>
      </span>
      {showProgress && <Progress currentStep={exportingStatus} />}
    </>
  );
};
