import { AllTableCriteria, FiltersT } from '@openx/components/table';
import { cloneDeep } from 'lodash';
import moment from 'moment';

export const statusCriteriaProcessing = (
  criteriaParam: AllTableCriteria<FiltersT>,
  expirationDateFieldName: 'estimated_expiry_date' | 'expiration_date',
): AllTableCriteria<FiltersT> => {
  if (!criteriaParam.filters?.byValue) {
    return criteriaParam;
  }

  const filtersByValue = cloneDeep(criteriaParam.filters.byValue);
  const statusFilterIndex = filtersByValue.findIndex(filter => filter.field === 'status');
  if (statusFilterIndex === -1) {
    return criteriaParam;
  }

  const statuses = filtersByValue[statusFilterIndex].values.filter(item => item !== 'expiring_soon');
  if (statuses.length === filtersByValue[statusFilterIndex].values.length) {
    return criteriaParam;
  }

  const tenDaysFromToday = moment({ minutes: 0, seconds: 0, milliseconds: 0 }).add(10, 'days').toISOString();
  const now = moment({ minutes: 0, seconds: 0, milliseconds: 0 }).toISOString();
  filtersByValue.splice(
    statusFilterIndex,
    1,
    { field: 'status', values: statuses },
    { field: expirationDateFieldName, values: [tenDaysFromToday, now] },
  );

  return {
    ...criteriaParam,
    filters: {
      ...criteriaParam.filters,
      byValue: filtersByValue,
    },
  };
};
