import React, { useCallback, MouseEvent } from 'react';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { makeStyles, Theme, Typography, IconButton, FormHelperText } from '@material-ui/core';
import { Close, Description, CloudUpload } from '@material-ui/icons';
import { humanFileSize } from '@openx/utils';

import { Button } from '../Button';

interface DropzoneProps {
  accept?: string | string[];
  disabled?: boolean;
  file?: File;
  onDropFile: (file: File) => void;
  onCancel: () => void;
  className?: string;
  errorMessage?: string;
  helpMessage?: string;
}

interface StylesProps {
  disabled: boolean;
  error: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>(theme => ({
  wrapper: {
    margin: theme.spacing(1.5, 0),
  },
  dropzone: {
    height: 150,
    border: ({ error }) => `1px dashed ${error ? theme.palette.error.main : theme.palette.divider}`,
    backgroundColor: theme.palette.background.boxLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: ({ disabled }) => (disabled ? 'none' : 'auto'),
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    opacity: ({ disabled }) => (disabled ? 0.4 : 1),
    '&:hover': {
      border: `1px dashed ${theme.palette.primary.main}`,
    },
    '&:focus': { outline: 'none' },
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '2em',
  },
  uploadHints: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& > span': {
      margin: theme.spacing(0, 2.5),
    },
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      marginRight: theme.spacing(1.2),
    },
  },
  cancelButton: {
    position: 'absolute',
    right: '35px',
  },
}));

export const Dropzone = ({
  accept,
  disabled = false,
  onDropFile,
  onCancel,
  file,
  className,
  errorMessage,
  helpMessage,
}: DropzoneProps): JSX.Element => {
  const classes = useStyles({ disabled, error: !!errorMessage });

  const onDrop = React.useCallback(
    (uploadedFiles: File[]) => {
      onDropFile(uploadedFiles[0]);
    },
    [onDropFile],
  );

  const { getRootProps, getInputProps } = useDropzone({ accept, onDrop, disabled, multiple: false });

  const onCancelCallback = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      onCancel();
    },
    [onCancel],
  );

  const renderContent = (): JSX.Element => {
    if (!file) {
      return (
        <div className={classes.uploadHints}>
          <CloudUpload color="primary" className={classes.icon} data-test="upload-icon" />
          <Typography variant="caption" component="p" data-test="drag-n-drop-text">
            DRAG & DROP FILE
          </Typography>
          <span>or</span>
          <Button data-test="browse-button">Browse</Button>
        </div>
      );
    }

    return (
      <>
        <div className={classes.fileInfo}>
          <Description color="primary" className={classes.icon} data-test="description-icon" />
          <Typography variant="body1" color="textSecondary">
            {humanFileSize(file.size)}
          </Typography>
          <Typography variant="body1">{file.name}</Typography>
        </div>
        <IconButton onClick={onCancelCallback} className={classes.cancelButton} data-test="remove-button">
          <Close />
        </IconButton>
      </>
    );
  };

  return (
    <div className={classnames(className, classes.wrapper)}>
      <div className={classes.dropzone} {...getRootProps()} data-test="drop-zone">
        <input {...getInputProps()} />
        {renderContent()}
      </div>
      {helpMessage && !errorMessage && <FormHelperText>{helpMessage}</FormHelperText>}
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </div>
  );
};
