import React, { FC, memo } from 'react';
import { isEqual } from 'lodash';

import { PageHeader, ListHeaderContent } from '@openx/components/header';
import { SearchFilters, supportedFields, CriteriaDimension, CriteriaChange } from '@openx/components/table';
import { ButtonColor } from '@openx/components/core';
import { isInternalAustralianUser, usePermissionsCheck } from 'permissions';
import { useSideDrawer } from 'context';

interface SegmentsHeaderProps {
  children: React.ReactNode;
  handleCriteriaChange: (props: CriteriaChange<CriteriaDimension.FILTERS>) => void;
  onUploadFile: () => void;
  statuses: string[];
  providers: string[];
  segmentAccounts: string[];
  isInternalOxUser: boolean;
}

const SegmentsHeaderRaw: FC<SegmentsHeaderProps> = ({
  children,
  handleCriteriaChange,
  onUploadFile,
  statuses,
  providers,
  segmentAccounts,
  isInternalOxUser = false,
}): JSX.Element => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const canUploadFile = !usePermissionsCheck([isInternalAustralianUser]);

  const actions = canUploadFile
    ? [
        {
          label: 'Upload File',
          color: 'primary' as ButtonColor,
          onClick: onUploadFile,
          'data-test': 'upload-file-button',
        },
      ]
    : [];

  const HeaderFilters = () => (
    <ListHeaderContent
      filtersComponent={SearchFilters}
      filtersProps={{
        advanced: true,
        byPhraseFields: ['name'],
        byValueFields: [
          { fieldName: supportedFields.SEGMENT_STATUS, options: statuses.map(status => ({ value: status })) },
          {
            fieldName: supportedFields.SEGMENT_PROVIDER,
            options: [...providers]
              .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
              .map(provider => ({ value: provider })),
          },
          ...(isInternalOxUser
            ? [
                {
                  fieldName: supportedFields.SEGMENT_ACCOUNT,
                  options: segmentAccounts
                    .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
                    .map(account => ({ value: account })),
                },
              ]
            : []),
        ],
        onFiltersChange: filters => {
          handleCriteriaChange({ dimension: CriteriaDimension.FILTERS, value: filters });
        },
        inputPlaceholder: 'Search Segments',
      }}
      actions={actions}
    />
  );

  return (
    <PageHeader
      title="Your Segments"
      titlePrefix="OpenAudience"
      render={HeaderFilters}
      openDrawer={openDrawer}
      handleDrawerOpen={handleDrawerOpen}
      menuItems={menuItems}
      token={token}
    >
      {children}
    </PageHeader>
  );
};

export const SegmentsHeader = memo(SegmentsHeaderRaw, (prevProps, nextProps) => isEqual(prevProps, nextProps));
