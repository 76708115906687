import { FormFields, FormValues } from './components/Form';
import { validateFeesValue, validateCPMCapValue, checkAndConvertValueToNull } from './components/Form/utils';

export function mapOrganizationToMutation(values: FormValues, fees?: any, defaultParentOrganizationId?: string) {
  return {
    name: values[FormFields.NAME],
    description: values[FormFields.DESCRIPTION],
    parent_account_id: values[FormFields.PARENT_ORGANIZATION_ID] || defaultParentOrganizationId,
    allowed_countries: values[FormFields.ALLOWED_COUNTRIES],
    allowed_domains: values[FormFields.ALLOWED_DOMAINS],
    exchange_account_partner_id: values[FormFields.EXCHANGE_ACCOUNT_PARTNER_ID],
    default_platform_share: validateFeesValue(values[FormFields.DEFAULT_PARTNER_SHARE])
      ? checkAndConvertValueToNull(values[FormFields.DEFAULT_PARTNER_SHARE])
      : fees.default_platform_share,
    max_share: validateFeesValue(values[FormFields.MAX_SHARE])
      ? checkAndConvertValueToNull(values[FormFields.MAX_SHARE])
      : fees.max_share,
    max_cpm_cap: validateCPMCapValue(values[FormFields.MAX_CPM_CAP])
      ? checkAndConvertValueToNull(values[FormFields.MAX_CPM_CAP])
      : fees.max_cpm_cap,
  };
}
