import Typography from '@material-ui/core/Typography';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';

export interface BreadcrumbItemProps {
  title: string;
  path: string;
}

const useStyles = makeStyles({
  linkTypography: {
    color: 'inherit',
  },
});

export function BreadcrumbItem(props: BreadcrumbItemProps): JSX.Element {
  const { title, path } = props;

  const classes = useStyles(props);

  return (
    <NavLink to={path}>
      <Typography className={classes.linkTypography} variant="body2" data-test="breadcrumb-item">
        {title}
      </Typography>
    </NavLink>
  );
}
