import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  circle: {
    width: 6,
    height: 6,
    borderRadius: '100%',
    marginRight: 5,
    display: 'inline-block',
  },
  active: {
    backgroundColor: theme.palette.success.main,
  },
  inactive: {
    backgroundColor: theme.palette.action.inactive,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  banned: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
}));

interface StatusIndicatorProps {
  /** Status: 'active' | 'inactive' | 'error' | 'warning' */
  status?: string;
  label?: string;
  className?: string;
  'data-test'?: string;
}

export function StatusIndicator(props: StatusIndicatorProps): JSX.Element {
  const classes = useStyles();

  const { status = 'inactive', className, label } = props;

  return (
    <span className={className} data-test={props['data-test']}>
      <span className={classNames(classes.circle, classes[status.toLowerCase()])} />
      <Typography variant="caption" color="textSecondary">
        {label?.toUpperCase() || status.toUpperCase()}
      </Typography>
    </span>
  );
}
