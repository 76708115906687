import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import MaterialTable from '@material-ui/core/Table/Table';
import TableBody from '@material-ui/core/TableBody';
import makeStyles from '@material-ui/styles/makeStyles';

import { ActionsCell } from '../ActionsCell';
import { ExpandIconCell } from '../ExpandIconCell';
import { HighlightRules } from '../../highlightRules';
import { ChangeSingleCriteriaAction, CriteriaDimension, SortCriteria } from '../../tableCriteria';
import { BaseRow, Columns, RowAction, RowActionButtonBaseProps, RowActionProps, TableColumn } from '../../tableHelpers';
import { TableHeader } from '../TableHeader';
import { TableRow } from '../TableRow';
import { ExpandComponentProps, TableRowExpanded } from '../TableRowExpanded';
import { TableRowTree } from '../TableRowTree';
import { ACTION_ICON_CELL } from './config';
import ColumnFilter from '../ColumnFilter';
import { recalculateColumsWidth } from './utils';

function renderRowsSkeleton<RowT extends BaseRow>(columns: Columns<RowT>, rowsAmount = 3): JSX.Element[] {
  const result: JSX.Element[] = [];

  for (let i = 0; i < rowsAmount; i += 1) {
    result.push(<TableRow columns={columns} key={i} />);
  }

  return result;
}

const useStyles = makeStyles({
  fixedColumnsWidth: {
    tableLayout: 'fixed',
  },
});

export interface DataSectionProps<RowT extends BaseRow> {
  data: RowT[];
  columns: Columns<RowT>;
  onRowClick?: RowAction<RowT>;
  loading: boolean;
  isTree?: boolean;
  isForcedExpanded?: boolean;
  treeChildrenKey?: string;
  expandComponent?: React.ComponentType<ExpandComponentProps<RowT>>;
  fetchTreeChildren?: (data: RowT) => Promise<RowT[]>;
  highlightRules?: HighlightRules;
  sortCriteria?: SortCriteria;
  onSortChange: ChangeSingleCriteriaAction<CriteriaDimension.SORT>;
  hideHeader?: boolean;
  primaryRowAction?: RowActionButtonBaseProps<RowT>;
  secondaryRowAction?: RowActionButtonBaseProps<RowT>;
  rowActions?: RowActionProps<RowT>[] | ((row: RowT) => RowActionProps<RowT>[]);
  stickyHeaderPosition?: number;
  children?: ReactNode;
  fixedLayout?: boolean;
  treeLevelItems?: number;
  enableTableHeaderCustomziation?: boolean;
}

export function DataSection<RowT extends BaseRow>(props: DataSectionProps<RowT>): JSX.Element {
  const {
    isTree,
    isForcedExpanded,
    treeChildrenKey,
    expandComponent,
    loading,
    data,
    onRowClick,
    hideHeader,
    primaryRowAction,
    secondaryRowAction,
    rowActions,
    stickyHeaderPosition,
    children,
    fixedLayout = false,
    treeLevelItems,
    fetchTreeChildren,
    enableTableHeaderCustomziation = true,
  } = props;
  const { onSortChange, highlightRules, sortCriteria } = props;
  const classes = useStyles();

  const [columns, updateColumns] = useState<Columns<RowT>>(recalculateColumsWidth(props.columns));

  const iconCellRenderer = useMemo(() => {
    if (rowActions || primaryRowAction || secondaryRowAction) {
      return (singleRowData: RowT) => (
        <ActionsCell
          rowData={singleRowData}
          actions={typeof rowActions === 'function' ? rowActions(singleRowData) : rowActions}
          primaryAction={primaryRowAction}
          secondaryAction={secondaryRowAction}
        />
      );
    }

    return undefined;
  }, [expandComponent, rowActions, primaryRowAction, secondaryRowAction]);

  const handleFilterColumn = useCallback(
    (column: TableColumn<RowT>) => {
      const updatedColumns = columns.map(singleColumn => {
        if (singleColumn.key === column.key) {
          singleColumn.hide = !singleColumn.hide;
        }
        return singleColumn;
      });

      const calcColsWidth = recalculateColumsWidth(updatedColumns);
      updateColumns(calcColsWidth);
    },
    [columns],
  );
  const filterColumnNode: ReactNode = useMemo(
    () => <ColumnFilter columns={columns} handleFilterColumn={handleFilterColumn} updateColumns={updateColumns} />,
    [columns, handleFilterColumn],
  );

  const filteredColumns = columns.filter(singleColumn => singleColumn.hide !== true);

  const headerColumns = useMemo(() => {
    const icon: ReactNode = filterColumnNode;
    const actionCell = enableTableHeaderCustomziation ? [{ key: ACTION_ICON_CELL, title: icon, width: '5%' }] : [];
    const expandIconCell = expandComponent ? [{ key: ACTION_ICON_CELL, title: '' }] : [];

    return [...expandIconCell, ...filteredColumns, ...actionCell];
  }, [filteredColumns, filterColumnNode, enableTableHeaderCustomziation]);

  const getRowComponent = useCallback(
    (row: RowT, index: number): JSX.Element => {
      const rowProps = {
        key: row.id || index,
        rowData: row,
        columns: filteredColumns,
        onRowClick,
        highlightRules,
        primaryRowAction,
        secondaryRowAction,
        rowActions,
        iconCellRenderer,
        treeLevelItems,
      };

      if (isTree) {
        return (
          <TableRowTree
            {...rowProps}
            isForcedExpanded={isForcedExpanded}
            treeChildrenKey={treeChildrenKey}
            fetchTreeChildren={fetchTreeChildren}
          />
        );
      }
      return expandComponent ? (
        <TableRowExpanded {...rowProps} expandComponent={expandComponent} />
      ) : (
        <TableRow {...rowProps} />
      );
    },
    [
      onRowClick,
      columns,
      highlightRules,
      expandComponent,
      primaryRowAction,
      secondaryRowAction,
      rowActions,
      iconCellRenderer,
      isTree,
      isForcedExpanded,
      treeChildrenKey,
      treeLevelItems,
      fetchTreeChildren,
    ],
  );

  const rows = useMemo(() => data.map(getRowComponent), [data, getRowComponent]);

  if (children) {
    return (
      <MaterialTable classes={{ root: fixedLayout ? classes.fixedColumnsWidth : undefined }}>
        {!hideHeader && (
          <TableHeader
            onChange={onSortChange}
            columns={headerColumns}
            criteria={sortCriteria}
            stickyHeaderPosition={stickyHeaderPosition}
          />
        )}
        <TableBody data-test={loading ? 'table-body-loading' : 'table-body'}>{children}</TableBody>
      </MaterialTable>
    );
  }

  const tableBody = loading ? renderRowsSkeleton(headerColumns) : rows;

  return (
    <MaterialTable classes={{ root: fixedLayout ? classes.fixedColumnsWidth : undefined }}>
      {!hideHeader && (
        <TableHeader
          onChange={onSortChange}
          columns={headerColumns}
          criteria={sortCriteria}
          stickyHeaderPosition={stickyHeaderPosition}
        />
      )}
      <TableBody data-test={loading ? 'table-body-loading' : 'table-body'}>{tableBody}</TableBody>
    </MaterialTable>
  );
}
