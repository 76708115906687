import React, { useCallback } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';

import { DebounceField } from '@openx/components/core';
import { ChipFiltersView } from './ChipFiltersView';
import { FiltersConfig, ChipFilterValue } from './types';

const useStyles = makeStyles(theme => ({
  topBar: {
    minHeight: '48px',
    background: '#F5F5F5',
    padding: '0px 32px',
    borderTop: `1px solid ${theme.palette.background.grayLight}`,
    borderBottom: `1px solid ${theme.palette.background.grayLight}`,
  },
}));

interface TopBarProps<FilterType extends { phrase: string }> {
  filters: FilterType;
  onChange: (filters: FilterType) => void;
  searchText?: string;
  chipFiltersConfig?: FiltersConfig;
}

export function TopBar<FilterType extends { phrase: string }>({
  filters,
  onChange,
  searchText,
  chipFiltersConfig,
}: TopBarProps<FilterType>) {
  const classes = useStyles();

  const onPhraseChange = useCallback(
    event => {
      if (event.target.value.length > 2 || !event.target.value.length) {
        onChange({ ...filters, phrase: event.target.value });
      }
    },
    [filters, onChange],
  );

  const onFilterChange = (newFilters: { [categoryName: string]: ChipFilterValue }) => {
    onChange({ ...filters, ...newFilters });
  };

  return (
    <Grid container className={classes.topBar}>
      {searchText && (
        <Grid item xs={4}>
          <Box mt={1.1}>
            <DebounceField
              placeholder={searchText}
              type="search"
              autoFocus
              onChange={onPhraseChange}
              value={filters.phrase}
              fullWidth
              debounceTimeout={500}
              data-test="search-field"
            />
          </Box>
        </Grid>
      )}
      {chipFiltersConfig && (
        <Grid item xs={8}>
          <ChipFiltersView filtersConfig={chipFiltersConfig} filters={filters} onFilterChange={onFilterChange} />
        </Grid>
      )}
    </Grid>
  );
}
