import React, { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { groupBy } from 'lodash';
import clsx from 'classnames';
import { createStyles, Grid, Typography, Theme } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ConfirmationDialog, Paper, Loader } from '@openx/components/core';
import { useQuery, useMutation } from '@apollo/client';
import { Alert } from '@material-ui/lab';

import {
  GetDealsByAudiencesIdsQuery,
  GetDealsByAudiencesIdsQueryVariables,
  ArchiveAudienceMutation,
  ArchiveAudienceMutationVariables,
} from 'types/schemaTypes';
import GetDealsByAudiencesIds from 'graphql/query/deals/GetDealsByAudiencesIds.gql';
import ArchiveAudience from 'graphql/mutation/audiences/ArchiveAudience.gql';

import { DealList, Deal } from 'modules/deals/components/DealList';

import { getCurrentUser } from 'firebaseIntegration/utils';
import createLog from 'graphql/mutation/error/createLog.gql';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningAlert: {
      padding: 0,
      color: '#8B5C00',
    },
    topWarningMessage: {
      paddingBottom: theme.spacing(2),
    },
    dealsListForAudience: {
      '&:not(:nth-child(2))': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
      paddingTop: theme.spacing(2),
    },
    audiencesWrapper: {
      width: '100%',
      '&:not(:last-child)': {
        paddingBottom: theme.spacing(2),
      },
    },
    audiencesWithoutDealsWrapper: {
      paddingTop: theme.spacing(4),
    },
    audiencesWithoutDealsGap: {
      marginTop: theme.spacing(0.6),
    },
    container: {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      boxShadow: 'none',
    },
    audienceName: {
      fontWeight: 'bold',
    },
  }),
);

interface Audience {
  addressable_reach?: number | null | undefined;
  created_date: Date;
  id: string;
  name: string;
  status: string;
  updated_date: Date;
}

interface Props {
  audiences?: Audience[];
  onClose: (success: boolean) => void;
  open: boolean;
}

type AudiencesWithDeals = Array<{
  id: string;
  name: string;
  deals: Deal[];
}>;

type AudiencesIdWithNames = Array<{
  id: string;
  name: string;
}>;

export function ArchiveConfirmationDialog({ audiences = [], onClose, open }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [pushLog] = useMutation(createLog);
  const audiencePlurality = audiences.length > 1;

  const {
    error: dealsError,
    loading: dealsLoading,
    data: { deal: deals = [] } = {},
  } = useQuery<GetDealsByAudiencesIdsQuery, GetDealsByAudiencesIdsQueryVariables>(GetDealsByAudiencesIds, {
    variables: {
      audiencesIds: audiences.map(({ id }) => id),
    },
  });

  const [archiveAudience, { error: archiveError, loading: archiveLoading }] = useMutation<
    ArchiveAudienceMutation,
    ArchiveAudienceMutationVariables
  >(ArchiveAudience);

  const audienceIdToDeals = useMemo(() => groupBy(deals, deal => deal._package?.audience_id), [deals]);

  const audiencesWithDeals: AudiencesWithDeals = useMemo(
    () =>
      audiences
        .filter(audience => Object.keys(audienceIdToDeals).includes(audience.id))
        .map(({ id, name }) => ({ name, deals: audienceIdToDeals[id], id })),
    [audiences, audienceIdToDeals],
  );

  const audiencesWithoutDeals: AudiencesIdWithNames = useMemo(
    () =>
      audiences
        .filter(audience => !Object.keys(audienceIdToDeals).includes(audience.id))
        .map(({ id, name }) => ({ id, name })),
    [audiences, audienceIdToDeals],
  );

  const onConfirmed = useCallback(async () => {
    try {
      const response = await archiveAudience({
        variables: {
          ids: audiences.map(({ id }) => id),
        },
      });

      const archivedAudiencesCount = response?.data?.audience?.returning.filter(
        ({ is_archived }) => is_archived,
      ).length;

      if (archiveError || archivedAudiencesCount === 0) {
        throw new Error();
      }

      audiencePlurality
        ? enqueueSnackbar(`Successfully archived ${archivedAudiencesCount} of ${audiences.length} audiences`, {
            variant: 'success',
          })
        : enqueueSnackbar('Successfully archived audience', {
            variant: 'success',
          });

      onClose(true);
    } catch (e) {
      const location = window.location.href;
      const user = await getCurrentUser();
      await pushLog({
        variables: {
          type: 'error',
          location: location,
          msg: `Failed to archive audience${audiences.length > 1 ? 's' : ''}`,
          name: user?.displayName,
          email: user?.email,
        },
      });
      enqueueSnackbar(`Failed to archive audience${audiences.length > 1 ? 's' : ''}`, { variant: 'error' });
    }
  }, [archiveAudience, audiences, archiveError, audiencePlurality, enqueueSnackbar, onClose, pushLog]);

  const onCancel = useCallback(() => {
    if (archiveLoading) return;
    onClose(false);
  }, [archiveLoading, onClose]);

  if (dealsLoading) {
    return (
      <Paper>
        <Loader />
      </Paper>
    );
  }

  if (dealsError) {
    console.error('Error fetching deals data');
  }

  return (
    <ConfirmationDialog
      isOpen={open}
      title="Archive Audiences"
      confirmLabel="Archive"
      onCancel={onCancel}
      onConfirmed={onConfirmed}
      customContent
      isLoading={dealsLoading}
      confirmationButtonColor="danger"
      data-test="confirmation-dialog"
    >
      <Paper className={classes.container}>
        {({ HighlightedSection }) => (
          <Grid container direction="column">
            <Typography color="textPrimary" className={classes.topWarningMessage} data-test="archive-info">
              {`You're about to archive ${audiencePlurality ? 'several' : ''} Audience${audiencePlurality ? 's' : ''}`}
            </Typography>

            {audiencesWithDeals.length > 0 && (
              <HighlightedSection>
                <Alert className={classes.warningAlert} severity="warning" data-test="alert-message">
                  The following deal IDs will be affected by the change.
                </Alert>

                {audiencesWithDeals.map(audience => (
                  <div key={audience.id} className={clsx(classes.dealsListForAudience, classes.audiencesWrapper)}>
                    <Typography
                      className={classes.audienceName}
                      noWrap
                      gutterBottom
                      color="textPrimary"
                      data-test="audiences-with-deals"
                    >
                      {audience.name}
                    </Typography>
                    <DealList deals={audience.deals} />
                  </div>
                ))}
              </HighlightedSection>
            )}

            {audiencesWithoutDeals.length > 0 && (
              <div
                className={clsx(
                  { [classes.audiencesWithoutDealsWrapper]: audiencesWithDeals.length > 0 },
                  classes.audiencesWrapper,
                )}
              >
                {audiencesWithoutDeals.map(({ id, name }) => (
                  <Typography
                    color="textPrimary"
                    className={clsx(classes.audiencesWithoutDealsGap, classes.audienceName)}
                    noWrap
                    key={id}
                    data-test="audiences-without-deals"
                  >
                    {name}
                  </Typography>
                ))}
              </div>
            )}
          </Grid>
        )}
      </Paper>
    </ConfirmationDialog>
  );
}
