import { Audience } from 'types/schemaTypes';

export enum AudienceType {
  CREATE = 'CREATE',
  BASE = 'BASE',
  USE = 'USE',
  USE_DIRECT_AUDIENCE = 'USE_DIRECT_AUDIENCE',
}

export interface OnChangeArgs {
  type?: AudienceType | null;
  audienceId?: Audience['id'];
  audienceName?: Audience['name'];
}

export interface AudienceOptionsProps {
  type?: AudienceType | null;
  audienceId?: Audience['id'] | null;
  audienceName?: Audience['id'] | null;
  isEdit?: boolean;
  loading?: boolean;
  onlyReadyAudiences?: boolean;
  skipFetchingAudience?: boolean;
  saveDisabled?: boolean;
  options?: AudienceType[];
  onChange?: (args: OnChangeArgs) => void;
}

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type SelectedAudience = RecursivePartial<Audience>;
