export const getErrorReason = (graphQLErrors): string => {
  const [graphQLError] = graphQLErrors || [];
  const { extensions: { exception: { errors = [] } = {} } = {} } = graphQLError || {};

  const [firstError] = errors || [];
  const { reason } = firstError || {};

  return reason;
};

export const tableNotFoundError = 'notFound';

export const suppressableOperations = ['GetAudienceCategoriesReach', 'GetSfAccountNames'];
