import React from 'react';
import { Grid } from '@material-ui/core';

import { ActionButton, ActionButtonProps } from './ActionButton';

export interface HeaderActionsProps {
  actions: ActionButtonProps[];
}

export function HeaderActions(props: HeaderActionsProps): JSX.Element {
  const { actions } = props;

  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
      {actions.map((action, index) => (
        <ActionButton {...action} key={index} />
      ))}
    </Grid>
  );
}
