import { ApolloError } from '@apollo/client';
import { getErrorReason, tableNotFoundError } from '@openx/utils';

import { DefaultDisabledMessage, noTableMessage } from './constants';

interface Props {
  disabled: boolean;
  disabledText?: string;
  error?: ApolloError;
  sfError?: ApolloError;
}

interface UseIsInsightsPanelDisabledResponse {
  isDisabled: boolean;
  disabledMessage: string;
}

export const useIsInsightsPanelDisabled = ({
  disabled,
  disabledText,
  error,
  sfError,
}: Props): UseIsInsightsPanelDisabledResponse => {
  const { graphQLErrors } = error || {};
  const { graphQLErrors: sfGraphQLErrors } = sfError || {};

  const reason = getErrorReason(graphQLErrors);
  const sfReason = getErrorReason(sfGraphQLErrors);

  const noTable = reason === tableNotFoundError;
  const noSfTable = sfReason === tableNotFoundError;

  const disabledMessage = disabledText || (noTable || noSfTable ? noTableMessage : DefaultDisabledMessage);

  const isDisabled = disabled || noTable || noSfTable;

  return { isDisabled, disabledMessage };
};
