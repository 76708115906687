import React, { useCallback, useState } from 'react';

import { PageHeader } from '@openx/components/header';
import { Grid } from '@material-ui/core';
import { ActionBar } from 'components/ActionBar';
import { ContentWrapper } from 'components/ContentWrapper';
import { ProviderForm } from '../components';

import { useHistory } from 'react-router-dom';
import { FormFields, initialFormState, ProviderFormState } from '../constants';

import { useMutation } from '@apollo/client';
import { CreateProviderMutation, CreateProviderMutationVariables } from 'types/schemaTypes';
import CreateProvider from 'graphql/mutation/providers/CreateProvider.gql';

import { useSnackbar } from 'notistack';
import { NestedErrorCode, useLocalErrorHandling } from 'modules/graphql';
import { useSideDrawer } from 'context';

export const ProviderCreate = () => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [createProvider, { loading }] = useMutation<CreateProviderMutation, CreateProviderMutationVariables>(
    CreateProvider,
    {
      refetchQueries: ['GetProvidersList'],
    },
  );

  const [formState, setFormState] = useState<ProviderFormState>(initialFormState);

  const nameFieldHandler = value => {
    setFormState(prevState => ({
      ...prevState,
      [FormFields.PROVIDER_NAME]: value,
      [FormFields.PROVIDER_ID]: value.replace(/\s/g, '').toLowerCase(),
    }));
  };

  const exportTypesFieldHandler = value => {
    if (formState[FormFields.ALLOWED_EXPORT_TYPES].includes(value)) {
      setFormState(prevState => ({
        ...formState,
        [FormFields.ALLOWED_EXPORT_TYPES]: prevState[FormFields.ALLOWED_EXPORT_TYPES].filter(i => i !== value),
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        [FormFields.ALLOWED_EXPORT_TYPES]: [...prevState[FormFields.ALLOWED_EXPORT_TYPES], value],
      }));
    }
  };

  useLocalErrorHandling(
    CreateProvider,
    useCallback(
      error => {
        const mapOfPathToMessage = {
          'object.name': 'Provider with such name already exists',
          'object.id': 'Another provider already has such ID',
        };

        if (
          error.code === NestedErrorCode.SINGLE_UNIQUENESS_VIOLATION &&
          Object.keys(mapOfPathToMessage).includes(error.path)
        ) {
          enqueueSnackbar(mapOfPathToMessage[error.path], { variant: 'error' });
          return true;
        }

        return false;
      },
      [enqueueSnackbar],
    ),
  );

  const onSave = async () => {
    try {
      await createProvider({ variables: { object: formState } });
      enqueueSnackbar('Provider created successfully', { variant: 'success' });
      history.push('/providers');
    } catch (e) {
      console.error(e);
    }
  };

  const saveDisabled =
    formState[FormFields.PROVIDER_ID].length === 0 || formState[FormFields.PROVIDER_NAME].length === 0;

  const actions = [
    {
      label: 'Save',
      type: 'button',
      props: {
        onClick: () => onSave(),
        disabled: saveDisabled,
        loading: loading,
        'data-test': 'provider-create-save',
      },
    },
  ];

  return (
    <ActionBar onGoBack={history.goBack} actions={actions}>
      <PageHeader
        title="Create New Provider"
        titlePrefix="OpenAudience"
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
      >
        <ContentWrapper>
          <Grid container justifyContent="center">
            <Grid item xs={5}>
              <ProviderForm
                values={formState}
                nameFieldHandler={nameFieldHandler}
                exportTypesFieldHandler={exportTypesFieldHandler}
              />
            </Grid>
          </Grid>
        </ContentWrapper>
      </PageHeader>
    </ActionBar>
  );
};
