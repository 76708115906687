import { useQuery } from '@apollo/client';

import GetDistinctAudienceStatuses from 'graphql/query/audiences/GetDistinctAudienceStatuses.gql';
import { GetDistinctAudienceStatusesQuery, GetDistinctAudienceStatusesQueryVariables } from 'types/schemaTypes';
import { firstLetterUppercase } from '@openx/utils';

export interface AudienceStatusMap {
  [key: string]: string;
}

export interface UseAudienceStatusResponse {
  gqlAudienceStatuses: string[];
  uiAudienceStatuses: string[];
  loadingAudienceStatuses: boolean;
}

export function useAudienceStatuses(): UseAudienceStatusResponse {
  const { data: { audience_statuses = [] } = {}, loading: loadingAudienceStatuses } = useQuery<
    GetDistinctAudienceStatusesQuery,
    GetDistinctAudienceStatusesQueryVariables
  >(GetDistinctAudienceStatuses);

  const gqlAudienceStatuses = ['expiring_soon', ...audience_statuses.map(item => item.status)];
  const uiAudienceStatuses = gqlAudienceStatuses.map(value =>
    value
      .split('_')
      .map(word => firstLetterUppercase(word))
      .join(' '),
  );

  return {
    gqlAudienceStatuses,
    uiAudienceStatuses,
    loadingAudienceStatuses,
  };
}
