import { AudienceStatus, ACTIVE_STATUSES, FORBIDDEN_STATUSES, FAILED_STATUSES } from '../constants';
import { firstLetterUppercase } from '@openx/utils';

export function mapStatus(gqlStatus?: string): {
  label: string;
  status: string;
} | null {
  if (gqlStatus) {
    const label = gqlStatus
      .split('_')
      .map(value => firstLetterUppercase(value))
      .join(' ');
    // I categorize all AudienceStatus into 4 categories with each mapped to one StatusIndicator status
    if (ACTIVE_STATUSES.includes(gqlStatus as AudienceStatus)) {
      return {
        label,
        status: 'active',
      };
    } else if (FAILED_STATUSES.includes(gqlStatus as AudienceStatus)) {
      return {
        label,
        status: 'error',
      };
    } else if (FORBIDDEN_STATUSES.includes(gqlStatus as AudienceStatus)) {
      return {
        label,
        status: 'warning',
      };
    } else {
      return {
        label,
        status: 'inactive',
      };
    }
  } else {
    return null;
  }
}
