import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

export const ICON_CELL_CLASS = 'iconCell';
const STOP_PROPAGATION = (e: React.MouseEvent | React.KeyboardEvent): void => e.stopPropagation();

const useStyles = makeStyles(theme => ({
  root: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
  },
}));

export interface IconCellProps {
  className?: string;
  stopClickPropagation?: boolean;
  children?: ReactNode;
}

export function IconCell(props: IconCellProps): JSX.Element {
  const { stopClickPropagation, children, className } = props;
  const classes = useStyles();
  const cellClassName = classNames(ICON_CELL_CLASS, classes.root, className);

  return (
    <TableCell className={cellClassName} onClick={stopClickPropagation ? STOP_PROPAGATION : undefined}>
      {children}
    </TableCell>
  );
}
