import React from 'react';
import { Typography } from '@material-ui/core';
import { Tooltip } from '@openx/components/core';

type Props = {
  source?: string | null;
  sourceDescription?: string | null;
};

export const SourceCell = ({ source, sourceDescription }: Props) => {
  if (!source) {
    return <> </>;
  }

  if (!sourceDescription) {
    return (
      <Typography variant="body1" color="primary">
        {source}
      </Typography>
    );
  }

  return (
    <Tooltip title={sourceDescription}>
      <Typography variant="body1" color="primary">
        {source}
      </Typography>
    </Tooltip>
  );
};
