import React, { useState, FC, useCallback, useEffect } from 'react';
import { makeStyles, Typography, ButtonBase, Menu, MenuItem, Divider, Box } from '@material-ui/core';

import { AutoComplete, Button, theme } from '@openx/components/core';

import { filtersConfig, supportedFields, FieldWithOptions } from './config';
import { Close, FilterList } from '@material-ui/icons';
import { Chip } from '@openx/components/table';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  counter: {
    color: '#000',
    marginLeft: '2px',
  },
  caption: {
    color: theme.palette.text.secondary,
  },
  root: {
    position: 'absolute',
    width: '20%',
    zIndex: 2,
    top: '29px',
    background: '#fff',
    borderTop: '1px solid #E6E6E6',
    boxShadow: '0px 2px 4px rgba(15, 15, 15, 0.08)',
    borderRadius: '0px 0px 2px 2px',
  },
  actionsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  applyButton: { marginLeft: '10px' },
  autocomplete: {
    '& input': {
      '&:focus': {
        boxShadow: 'none !important',
      },
    },
    marginBottom: '15px',
  },
  buttonBase: {
    padding: '2px',
    marginLeft: '7px',
    borderRadius: '50%',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgba(0, 0, 0, 0.54)',
    '&hover': {
      backgroundColor: 'transparent',
    },
  },
  arrowIcon: { width: '16px', height: '16px' },
  filterHeader: { display: 'flex', flexWrap: 'nowrap', padding: '12px 16px 0 16px' },
  clearFilters: { marginLeft: 'auto' },
  dropdownBody: { padding: '24px 16px 12px 16px' },
});

export interface DropdownProps {
  fieldsToFilterBy?: FieldWithOptions[];
  filters: {
    selectedStatuses: string[];
    selectedProviders: string[];
    selectedAccounts: string[];
    selectedCategories: string[];
    selectedSubCategories: string[];
    selectedTypes: string[];
    selectedSources: string[];
  };
  onApplyFilters: (
    statuses: string[],
    providers: string[],
    accounts: string[],
    categories: string[],
    sub_categories: string[],
    types: string[],
    sources: string[],
  ) => void;
}

export const Dropdown: FC<DropdownProps> = ({
  fieldsToFilterBy = [],
  onApplyFilters = () => {},
  filters: {
    selectedStatuses,
    selectedProviders,
    selectedAccounts,
    selectedTypes,
    selectedCategories,
    selectedSubCategories,
    selectedSources,
  },
}): JSX.Element => {
  const classes = useStyles();

  const [_selectedStatuses, setSelectedStatuses] = useState(selectedStatuses);
  const [_selectedProviders, setSelectedProviders] = useState(selectedProviders);
  const [_selectedAccounts, setSelectedAccounts] = useState(selectedAccounts);
  const [_selectedCategories, setSelectedCategories] = useState(selectedCategories);
  const [_selectedSubCategories, setSelectedSubCategories] = useState(selectedSubCategories);
  const [_selectedTypes, setSelectedTypes] = useState(selectedTypes);
  const [_selectedSources, setSelectedSources] = useState(selectedSources);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const [selectedFieldName, setSelectedFieldName] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handlers = {
    [supportedFields.AUDIENCE_STATUS]: (_, value) => setSelectedStatuses(value),
    [supportedFields.SEGMENT_STATUS]: (_, value) => setSelectedStatuses(value),
    [supportedFields.DEAL_STATUS]: (_, value) => setSelectedStatuses(value),
    [supportedFields.SEGMENT_PROVIDER]: (_, value) => setSelectedProviders(value),
    [supportedFields.AUDIENCE_PROVIDER]: (_, value) => setSelectedProviders(value),
    [supportedFields.DEAL_ACCOUNT]: (_, value) => setSelectedAccounts(value),
    [supportedFields.AUDIENCE_ACCOUNT]: (_, value) => setSelectedAccounts(value),
    [supportedFields.SEGMENT_ACCOUNT]: (_, value) => setSelectedAccounts(value),
    [supportedFields.TAXONOMY_CATEGORY]: (_, value) => setSelectedCategories(value),
    [supportedFields.TAXONOMY_SUB_CATEGORY]: (_, value) => setSelectedSubCategories(value),
    [supportedFields.TAXONOMY_TYPE]: (_, value) => setSelectedTypes(value),
    [supportedFields.TAXONOMY_SOURCE]: (_, value) => setSelectedSources(value),
  };

  const values = {
    [supportedFields.AUDIENCE_STATUS]: _selectedStatuses,
    [supportedFields.SEGMENT_STATUS]: _selectedStatuses,
    [supportedFields.DEAL_STATUS]: _selectedStatuses,
    [supportedFields.SEGMENT_PROVIDER]: _selectedProviders,
    [supportedFields.AUDIENCE_PROVIDER]: _selectedProviders,
    [supportedFields.DEAL_ACCOUNT]: _selectedAccounts,
    [supportedFields.AUDIENCE_ACCOUNT]: _selectedAccounts,
    [supportedFields.SEGMENT_ACCOUNT]: _selectedAccounts,
    [supportedFields.TAXONOMY_CATEGORY]: _selectedCategories,
    [supportedFields.TAXONOMY_SUB_CATEGORY]: _selectedSubCategories,
    [supportedFields.TAXONOMY_TYPE]: _selectedTypes,
    [supportedFields.TAXONOMY_SOURCE]: _selectedSources,
  };

  useEffect(() => {
    if (
      selectedStatuses ||
      selectedProviders ||
      selectedAccounts ||
      selectedCategories ||
      selectedSubCategories ||
      selectedTypes ||
      selectedSources
    ) {
      setSelectedStatuses(selectedStatuses);
      setSelectedProviders(selectedProviders);
      setSelectedAccounts(selectedAccounts);
      setSelectedCategories(selectedCategories);
      setSelectedSubCategories(selectedSubCategories);
      setSelectedTypes(selectedTypes);
      setSelectedSources(selectedSources);
    }
  }, [
    selectedStatuses,
    selectedProviders,
    selectedAccounts,
    selectedCategories,
    selectedSubCategories,
    selectedTypes,
    selectedSources,
  ]);

  const handleClearFilters = () => {
    setSelectedStatuses([]);
    setSelectedProviders([]);
    setSelectedAccounts([]);
    setSelectedCategories([]);
    setSelectedSubCategories([]);
    setSelectedTypes([]);
    setSelectedSources([]);

    onApplyFilters && onApplyFilters([], [], [], [], [], [], []);
  };

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectFilter = (fieldName, options) => {
    setAnchorEl(null);
    setIsFilterDropdownOpen(true);
    setSelectedFieldName(fieldName);
    setSelectedOptions(options);
  };

  const handleCloseSelectFilters = () => {
    setIsFilterDropdownOpen(false);
    setSelectedFieldName('');
    setSelectedOptions([]);
  };

  const handleApplyFilters = () => {
    onApplyFilters &&
      onApplyFilters(
        _selectedStatuses,
        _selectedProviders,
        _selectedAccounts,
        _selectedCategories,
        _selectedSubCategories,
        _selectedTypes,
        _selectedSources,
      );
    setIsFilterDropdownOpen(false);
  };

  const showClearFiltersButton =
    !isEmpty(_selectedStatuses) ||
    !isEmpty(_selectedProviders) ||
    !isEmpty(_selectedAccounts) ||
    !isEmpty(_selectedCategories) ||
    !isEmpty(_selectedSubCategories) ||
    !isEmpty(_selectedTypes) ||
    !isEmpty(_selectedSources);

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <ButtonBase
          centerRipple
          className={classes.buttonBase}
          onClick={handleClick}
          data-test="search-dropdown-button"
        >
          <FilterList />
        </ButtonBase>
        <Typography variant="button" className={classes.caption}>
          Add new filter
        </Typography>
      </div>

      <Menu
        open={Boolean(anchorEl)}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={handleClose}
      >
        {fieldsToFilterBy.map(({ fieldName, options }) => (
          <MenuItem key={fieldName} onClick={() => handleSelectFilter(fieldName, options)} data-test="menu-option">
            <Typography>{filtersConfig[fieldName].title}</Typography>
          </MenuItem>
        ))}
      </Menu>

      {Object.keys(values)
        .filter(key => !isEmpty(values[key]))
        .map(field => (
          <Chip label={`${filtersConfig[field].title}: ${values[field].join(',')}`} key={field} />
        ))}

      {showClearFiltersButton && (
        <Box className={classes.clearFilters}>
          <ButtonBase centerRipple onClick={handleClearFilters} className={classes.buttonBase}>
            <Close />
          </ButtonBase>
        </Box>
      )}

      {isFilterDropdownOpen && (
        <div className={classes.root}>
          <Box mb={1.5} justifyContent="space-between" alignItems="center" className={classes.filterHeader}>
            <Typography variant="h3" color="textPrimary">
              {filtersConfig?.[selectedFieldName]?.title}
            </Typography>
            <ButtonBase centerRipple onClick={handleCloseSelectFilters} className={classes.buttonBase}>
              <Close />
            </ButtonBase>
          </Box>
          <Divider />
          <div className={classes.dropdownBody}>
            <AutoComplete
              {...filtersConfig[selectedFieldName]}
              value={values[selectedFieldName]}
              onChange={handlers[selectedFieldName]}
              getOptionLabel={filtersConfig[selectedFieldName].getOptionLabel}
              className={classes.autocomplete}
              options={selectedOptions}
              fullWidth
              multiple
              limitTags={4}
              margin="dense"
            />
            <div className={classes.actionsContainer}>
              <Button
                data-test="apply-filters-button"
                color="primary"
                variant="text"
                className={classes.applyButton}
                onClick={handleApplyFilters}
              >
                APPLY
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
