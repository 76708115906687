import React from 'react';
import ReactDOM from 'react-dom';

import { initialize as initializeAnalyticsTools } from 'modules/analytics';
import { initialize as initializeEnvConfigs } from 'environmentConfigs';

import { App } from './App';

initializeEnvConfigs();
initializeAnalyticsTools();

ReactDOM.render(<App />, document.getElementById('root'));
