import React, { memo, FC } from 'react';

import { Title as ListTitle } from 'components/OptionsList';
import { EnhancedTreeData } from 'utils/segmentsToTreeData';
import {
  useAudience,
  ActionType,
  getDirectProvider,
  getOpenSubgroupDirectSegment,
  DIRECT_SEGMENT_FIELD,
} from 'modules/audiences/AudienceProvider';
import { useProviders } from 'modules/audiences/hooks';

import { SelectionList, ListType } from '../OptionsList';

interface DirectSegmentOptionsListProps {
  readonly: boolean;
  listType: ListType;
  values: EnhancedTreeData[];
}

export const DirectSegmentOptionsList: FC<DirectSegmentOptionsListProps> = memo(
  ({ readonly, listType, values }): JSX.Element | null => {
    const { state, dispatch } = useAudience();
    const { getProviderName } = useProviders();

    const directSegmentsSelected = values.length > 0;

    const directProvider = getDirectProvider(state);
    const directProviderName = getProviderName(directProvider);

    const handleRemoveClick = (optionToDelete: EnhancedTreeData) => {
      const selectedOptions = getOpenSubgroupDirectSegment(state);
      const newOptions = selectedOptions.filter(option => option.id !== optionToDelete.id);

      dispatch({ type: ActionType.SET_DIRECT_SEGMENT, payload: { segments: { [DIRECT_SEGMENT_FIELD]: newOptions } } });
    };

    return (
      <>
        {directSegmentsSelected && (
          <>
            <ListTitle data-test="segments-sub-title" uppercase>
              {directProviderName} segments
            </ListTitle>
            <SelectionList type={listType} items={values} onDelete={handleRemoveClick} readonly={readonly} />
          </>
        )}
      </>
    );
  },
);
