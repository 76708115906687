/// <reference types="google.analytics" />
import { GOOGLE_ANALYTICS_TRACKING_ID } from 'config';

export const ga = ((...args) => {
  if (window.ga) {
    // @ts-ignore
    window.ga(...args);
  }
}) as unknown as UniversalAnalytics.ga;

export const initialize = () => {
  require('./script');
  ga('create', GOOGLE_ANALYTICS_TRACKING_ID, 'auto');
};
