/* eslint-disable no-prototype-builtins */
/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
// DefinitionBlocks contain fields for different categories.  These are the supported ones
const allowed_categories = new Set(['location', 'demographic_age', 'demographic_gender', 'predefined', 'direct']);

// when combining segments, these are the allowed operators
const allowed_operators = new Set(['INTERSECT', 'UNION']);

// the error object returned from functions in this module
class ErrorObject {
  constructor(message) {
    this.error = { message: message };
  }
}

// Returns 'true' if ND has no segments anywhere, 'false' otherwise
function isEmpty(normalized_definition) {
  const { includes, excludes } = countSegmentsInNormalizedDefintion(normalized_definition);
  if (includes === 0 && excludes === 0) {
    return true;
  }
  return false;
}

function getSegmentIdsFromNormalizedDefinition(normalizedDefinition) {
  const segmentIds = [];
  traverse(normalizedDefinition, {
    segmentFn: fragment => {
      segmentIds.push(fragment.id);
      return fragment;
    },
  });
  return [...new Set(segmentIds)];
}

function getSegmentObjListFromNormalizedDefinition(normalizedDefinition) {
  const segmentList = {};
  traverse(normalizedDefinition, {
    segmentFn: fragment => {
      segmentList[fragment.id] = { id: fragment.id, external_id: null };
      return fragment;
    },
  });
  return { ...segmentList };
}

function stitchSegmentsIntoNormalizedDefinition(normalizedDefinition, segments) {
  const segmentsByID = Object.fromEntries(
    segments
      .map(entry => {
        if (entry.segment && entry.segment.id) {
          return [entry.segment.id, entry.segment];
        } else {
          return null;
        }
      })
      .filter(entry => entry !== null),
  );
  return stitchSegmentsByIdIntoNormalizedDefinition(normalizedDefinition, segmentsByID);
}

function stitchSegmentsByIdIntoNormalizedDefinition(normalizedDefinition, segmentsByID) {
  return traverse(normalizedDefinition, {
    segmentFn: fragment => {
      if (segmentsByID[fragment.id]) return segmentsByID[fragment.id];
      else return fragment;
    },
  });
}

async function normalDefsToSegMap(normalizedDefinition) {
  const segmentMap = {};
  let abort = false;
  await traverse(normalizedDefinition, {
    segmentFn: async fragment => {
      if (!abort && !!fragment.id && !!fragment.external_id) {
        segmentMap[fragment.id] = { id: fragment.id, external_id: fragment.external_id };
      } else {
        //Abort & Return Nil
        abort = true;
        return;
      }
      return fragment;
    },
  });
  if (abort) return null;
  return { ...segmentMap };
}

function createSegmentMapping(segments) {
  return segments.reduce((mapping, entry) => {
    return entry && entry.id ? { ...mapping, [entry.id]: entry } : { ...mapping };
  }, {});
}

function invalidSegmentIds(segmentIds, segmentMapping) {
  return segmentIds.filter(id => id in segmentMapping === false);
}

// checks for existence of the 'operator' field in an object, and that is has one of the allowable values
// returns an ErrorObject or {} if no errors
function validateOperatorField(obj) {
  if (!obj.hasOwnProperty('operator')) {
    return new ErrorObject("Missing or misspelled 'operator' field in object: " + JSON.stringify(obj, null, 1));
  }

  if (!allowed_operators.has(obj['operator'])) {
    return new ErrorObject(
      `Unsupported 'operator' value: '${obj['operator']}'.  Only ${Array.from(allowed_operators)} supported`,
    );
  }

  return {};
}

// definition block must contain two fields:  'operator' and 'segments'
// 'segments' must contain a non-empty array of {id: <segmentId>} or {external_id: <externalId>} objects
// returns an ErrorObject or {} if no errors
function validateDefinitionBlock(definitionBlock) {
  var { error } = validateOperatorField(definitionBlock);
  if (error) {
    return { error };
  }

  if (
    definitionBlock.segments === undefined ||
    !Array.isArray(definitionBlock.segments) ||
    definitionBlock.segments.length === 0
  ) {
    const e_string = JSON.stringify(definitionBlock, null, 1);
    return {
      error: {
        message: `Definition Block must contain a 'segments' field with a list containing at least one {id: <segmentId>}.  You gave ${e_string}`,
      },
    };
  }

  for (let segment of definitionBlock.segments) {
    if (segment.id === undefined && segment.external_id === undefined) {
      const e_string = JSON.stringify(definitionBlock, null, 1);
      return new ErrorObject(
        `Definition Block must contain a segment list with at least one {id: <segmentId>}.  You gave ${e_string}`,
      );
    }
  }

  return {};
}

// A DefinitionGroup must contain a valid 'operator' field and one or more 'category' fields from the allowed_categories list
// Each 'category' field must contain a valid DefinitionBlock
// returns an ErrorObject or {} if no errors
function validateDefinitionGroup(definitionGroup) {
  var { error } = validateOperatorField(definitionGroup);
  if (error) {
    return { error };
  }

  let categories = Object.keys(definitionGroup).filter(c => c !== 'operator');

  if (categories.length === 0) {
    const e_string = JSON.stringify(definitionGroup, null, 1);
    return new ErrorObject(`Definition Group must contain at least one valid category.  You gave ${e_string}`);
  }

  for (let category of categories) {
    if (!allowed_categories.has(category)) {
      return new ErrorObject(`Category: '${category}' not supported in Definition Group`);
    } else {
      let { error } = validateDefinitionBlock(definitionGroup[category]);
      if (error) {
        return new ErrorObject(`Category '${category}': ` + error.message);
      }
    }
  }

  return {};
}

// a DefinitionGroupList must have a valid 'operator' field and a valid 'groups' field
// Groups is an array containing either other DefinitionGroupLists or DefinitionGroups
// returns an ErrorObject or {} if no errors
function validateDefinitionGroupList(definitionGroupList) {
  var { error } = validateOperatorField(definitionGroupList);
  if (error) {
    return { error };
  }

  if (
    definitionGroupList.groups === undefined ||
    !Array.isArray(definitionGroupList.groups) ||
    definitionGroupList.groups.length == 0
  ) {
    const e_string = JSON.stringify(definitionGroupList, null, 1);
    return {
      error: {
        message: `DefinitionGroupList must contain a 'groups' field with an array.  You gave ${e_string}`,
      },
    };
  }

  for (let item of definitionGroupList.groups) {
    if (item.hasOwnProperty('groups')) {
      var { error } = validateDefinitionGroupList(item);
    } else {
      var { error } = validateDefinitionGroup(item);
    }
    if (error) {
      return { error };
    }
  }

  return {};
}

// This will perform some simple validations of a normalizedDefinition and
// return errors when the normalizedDefinition is invalid.  These are mostly
// structural checks as we might not have looked up any segments yet.
function validateNormalizedDefinition(normalizedDefinition) {
  if (!normalizedDefinition) {
    return {
      error: {
        message: 'Normalized Definition must be included',
      },
    };
  }
  if (!normalizedDefinition.includes) {
    return {
      error: {
        message: 'Normalized Definition Must have an "includes" section',
      },
    };
  } else {
    const { includes, excludes } = countSegmentsInNormalizedDefintion(normalizedDefinition);
    if (includes === 0) {
      if (excludes > 0) {
        return {
          error: {
            message:
              'Normalized Definition Must have at least one segment in the "includes" section in order to exclude segments',
          },
        };
      } else {
        return {
          error: {
            message: 'Normalized Definition Must have at least one segment in the "includes" section',
          },
        };
      }
    } else {
      let { error } = validateDefinitionGroupList(normalizedDefinition.includes);
      if (error) {
        return { error };
      }
      if (excludes > 0) {
        let { error } = validateDefinitionGroupList(normalizedDefinition.excludes);
        if (error) {
          return { error };
        }
      }
    }
  }

  return {};
}

function minimizeNormalizedDefinition(normalizedDefinition) {
  return traverse(normalizedDefinition, {
    segmentFn: fragment => {
      return { id: fragment.id };
    },
    valueChangeFn: (value, branch) => {
      // This is detecting if we have an empty list for the segments, and currently
      // checks both final demo form (aka, "include" or "exclude") as well as the gs
      // form (aka, "segments"), we are trying to match values of the form
      //   { operator: "UNION", include: [] }
      //   { operator: "UNION", exclude: [] }
      //   { operator: "UNION", segments: [] }

      // The final_demo form used "include" and "exclude" depending on the branch, but
      // the gs form uses just "segments", so check for both below and drop either
      // when they are empty.
      let segments = branch === 'includes' ? 'include' : 'exclude';
      if (
        typeof value === 'object' &&
        ((segments in value && Array.isArray(value[segments]) && value[segments].length === 0) ||
          ('segments' in value && Array.isArray(value['segments']) && value['segments'].length === 0))
      ) {
        return null;
      } else {
        return value;
      }
    },
    groupFn: group => {
      if (Object.keys(group).length === 1 && 'operator' in group) {
        return null;
      } else {
        return group;
      }
    },
  });
}

// Count the number of segments in each branch of the normalized definition, this will
// return an object of the form { includes: <count>, excludes: <count> }
function countSegmentsInNormalizedDefintion(normalizedDefinition) {
  let counts = { includes: 0, excludes: 0 };
  traverse(normalizedDefinition, {
    segmentFn: (fragment, branch) => {
      counts[branch]++;
      return fragment;
    },
  });
  return counts;
}

function addAudienceSegmentsToAudienceInsertInput(audienceInsertInput) {
  // see https://hasura.io/docs/latest/graphql/core/guides/data-modelling/many-to-many/#insert-using-many-to-many-relationships
  // note the extra `data` objects in the `objects` array
  if (audienceInsertInput.normalized_definition) {
    const segmentIds = getSegmentIdsFromNormalizedDefinition(audienceInsertInput.normalized_definition);
    const audienceSegmentObjects = segmentIds.map(segmentId => {
      return { segment_id: segmentId };
    });
    return {
      ...audienceInsertInput,
      audience_segments: { data: audienceSegmentObjects },
    };
  } else {
    return audienceObj;
  }
}

/**
 * Recursively traverse the normalized definition object allowing for modifications at various levels,
 * Callbacks available are
 * - segmentFn - called for each object with an 'id' field
 * - groupFn - called for each 'group' in a normalized definition after other functions are applied
 * - keyChangeFn - called for each 'key' in each object encountered
 * - valueChangeFn - called for each 'value' in each object encountered.
 * Each callback function is passed the entity they are operating on as well as the 'branch' value
 * which denotes which 'branch' of the normalized definition is being traversed.  There are 2 possible
 * values for 'branch', 'includes' denotes this is in the 'includes' branch, and 'excludes' denotes
 * being in the 'excludes' branch.
 */
function traverse(fragment, { segmentFn, groupFn, keyChangeFn, valueChangeFn }, branch) {
  if (fragment && typeof fragment === 'object') {
    if (fragment.id) {
      if (segmentFn) {
        return segmentFn(fragment, branch);
      } else {
        return fragment;
      }
    } else {
      // descend into the object (arrays are a type of object)
      if (Array.isArray(fragment)) {
        const newList = fragment.reduce((accum, value) => {
          let newValue = traverse(value, { segmentFn, groupFn, keyChangeFn, valueChangeFn }, branch);
          if (newValue) {
            accum.push(newValue);
          }
          return accum;
        }, []);
        return Array.from(newList);
      } else {
        const entries = Object.entries(fragment).reduce((accum, [key, value]) => {
          if (key === 'includes' || key === 'excludes') {
            branch = key;
          }
          if (keyChangeFn) {
            key = keyChangeFn(key, branch);
          }
          if (valueChangeFn) {
            value = valueChangeFn(value, branch);
          }
          // support removals via valueChangeFn, if it returns null or false this will
          // not add it, but instead skip it
          if (value) {
            accum.push([key, traverse(value, { segmentFn, groupFn, keyChangeFn, valueChangeFn }, branch)]);
          }
          return accum;
        }, []);
        let newGroup = Object.fromEntries(entries);
        // if a callback is defined for groups invoke it for the group
        if (groupFn) {
          newGroup = groupFn(newGroup, branch);
        }
        return newGroup;
      }
    }
  } else {
    return fragment;
  }
}

// checks that each segment in a 'DefinitionGroups' category has a matching
// segment.category field.  Normalized_definition should have had the
// category field stitched in for each segment in order to validate
// returns an ErrorObject or {} if no errors
function checkSegmentCategories(normalized_definition) {
  const errorMessages = [];
  traverse(normalized_definition, {
    groupFn: fragment => {
      let categories = Object.keys(fragment).filter(c => c !== 'operator');
      for (let category of categories) {
        // drill down nd tree until we get to DefinitionGroups which contain a segments field
        // we need to do it this way to capture the DefinitionGroup's 'category' for segment checking
        if (fragment[category].segments) {
          traverse(fragment[category], {
            segmentFn: segment => {
              if (segment.category !== category) {
                errorMessages.push(
                  `Segment ${segment.id} incorrectly listed in the '${category}' category, should be '${segment.category}'`,
                );
              }
              return segment;
            },
          });
        }
      }
      return fragment;
    },
  });
  if (errorMessages.length !== 0) {
    return new ErrorObject(errorMessages.join('; '));
  }
  return {};
}

// checks that the 'export_type' exists in each segment's provider.allowed_export_types
// array.  Normalized_definition should have had the provider.allowed_export_types field
// stitched in for each segment in order to validate
// returns an ErrorObject or {} if no errors
function checkSegmentExportTypes(normalized_definition, export_type) {
  const errorMessages = [];
  traverse(normalized_definition, {
    segmentFn: segment => {
      var allowed_export_types = new Set(segment.provider.allowed_export_types);
      if (!allowed_export_types.has(export_type)) {
        errorMessages.push(
          `Audience export_type: '${export_type}' not in Segment ${segment.id} allowed_export_types: ['${segment.provider.allowed_export_types}']`,
        );
      }
      return segment;
    },
  });
  if (errorMessages.length !== 0) {
    return new ErrorObject(errorMessages.join('; '));
  }
  return {};
}

function convertDemoFinalToDirect(normalizedDefinition) {
  return traverse(normalizedDefinition, {
    keyChangeFn: key => {
      if (key === 'items') {
        return 'groups';
      } else if (key === 'include' || key === 'exclude') {
        return 'segments';
      } else if (key === 'first_party') {
        return 'direct';
      } else {
        return key;
      }
    },
  });
}

// Convert a normalized definition from the direct format to the demo final format.
// This is needed because the UI uses the demo final format, but the API uses the direct format.
function convertDirectToDemoFinal(normalizedDefinition) {
  // OA-1700: the UI expects there to always be an excludes with a list of segments, so we initialize
  // it to an empty list and allow the incoming normalizedDefinition to override it.
  // NOTE: this needs to stay even if we stop needing this old form
  let normalizedDefinitionWithExcludes;
  if (
    normalizedDefinition.excludes === undefined ||
    normalizedDefinition.excludes === null ||
    JSON.stringify(normalizedDefinition.excludes) === '{}'
  ) {
    normalizedDefinitionWithExcludes = {
      ...normalizedDefinition,
      excludes: { operator: 'UNION', groups: [] },
    };
  } else {
    normalizedDefinitionWithExcludes = normalizedDefinition;
  }
  return traverse(normalizedDefinitionWithExcludes, {
    keyChangeFn: (key, branch) => {
      if (key === 'groups') {
        return 'items';
      } else if (branch === 'includes' && key === 'segments') {
        return 'include';
      } else if (branch === 'excludes' && key === 'segments') {
        return 'exclude';
      } else if (key === 'direct') {
        return 'first_party';
      } else {
        return key;
      }
    },
  });
}

module.exports = {
  createSegmentMapping,
  invalidSegmentIds,
  stitchSegmentsIntoNormalizedDefinition,
  stitchSegmentsByIdIntoNormalizedDefinition,
  validateNormalizedDefinition,
  getSegmentIdsFromNormalizedDefinition,
  getSegmentObjListFromNormalizedDefinition,
  addAudienceSegmentsToAudienceInsertInput,
  minimizeNormalizedDefinition,
  countSegmentsInNormalizedDefintion,
  normalDefsToSegMap,
  isEmpty,
  checkSegmentCategories,
  checkSegmentExportTypes,
  convertDemoFinalToDirect,
  convertDirectToDemoFinal,
};
