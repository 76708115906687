import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { theme, snackbarStyles, ConfirmationDialogProvider } from '@openx/components/core';

import { OA_VERSION, TOP_BAR_HEIGHT } from 'config';
import { AnalyticsIntegration, Routes, publicConfig } from 'routes';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { AuthProvider } from 'modules/auth/AuthProvider';
import { initializeFirebase } from 'firebaseIntegration';
import { GraphQLProvider } from './modules/graphql';

const useSnackbarStyles = makeStyles(snackbarStyles);

const useStyles = makeStyles({
  '@global': {
    '*': {
      fontFamily: 'Open Sans, sans-serif',
      margin: 0,
      padding: 0,
    },
    a: {
      textDecoration: 'none',
    },
    body: {
      minWidth: '1280px',
    },
  },
  errorBoundary: {
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  },
});

export const App = () => {
  const classes = useStyles();
  const snackbarClasses = useSnackbarStyles();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    console.info(`%cOpen-Audience version: ${OA_VERSION}`, 'font-weight:bold;');
    initializeFirebase().finally(() => setLoading(false));
  }, []);

  if (loading) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          preventDuplicate
          classes={snackbarClasses}
        >
          <BrowserRouter>
            <ErrorBoundary className={classes.errorBoundary}>
              <AnalyticsIntegration />
              <GraphQLProvider>
                <ConfirmationDialogProvider>
                  <CssBaseline />
                  <AuthProvider>
                    <Routes routes={publicConfig} />
                  </AuthProvider>
                </ConfirmationDialogProvider>
              </GraphQLProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};
