/* eslint-disable max-len */
import React from 'react';

export const HamburgerIcon = (): JSX.Element => (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <rect x="0" y="0" width="18" height="2" fill="rgb(15,15,15)" />
    <rect x="0" y="7" width="18" height="2" fill="rgb(15,15,15)" />
    <rect x="0" y="14" width="18" height="2" fill="rgb(15,15,15)" />
  </svg>
);

export const ExpandIcon = (): JSX.Element => (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
  >
    <g transform="matrix(1,0,0,1,1.2e-06,-3.75005e-08)">
      <path
        d="M8.863,0.138C8.68,-0.046 8.382,-0.046 8.199,0.138L4.5,3.86L0.802,0.138C0.618,-0.046 0.321,-0.046 0.138,0.138C-0.046,0.322 -0.046,0.622 0.138,0.806L4.168,4.862C4.26,4.955 4.38,5 4.5,5C4.621,5 4.741,4.955 4.833,4.862L8.863,0.806C9.046,0.622 9.046,0.322 8.863,0.138Z"
        fill="rgb(105,105,105)"
      />
    </g>
  </svg>
);
