import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  placeholder: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(7, 0),
  },
}));

interface PlaceholderProps {
  /** Optional title */
  title?: string;
  /** Optional subtitle */
  subtitle?: string;
}

export const Placeholder = ({ title, subtitle, ...restProps }: PlaceholderProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.placeholder} {...restProps} data-test="placeholder">
      {title && <Typography variant="h3">{title}</Typography>}
      {subtitle && (
        <Typography variant="body1" color="textSecondary">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};
