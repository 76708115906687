import Rollbar from 'rollbar';
import { OA_VERSION, ROLLBAR_ACCESS_TOKEN } from 'config';

let rollbar;

export const initialize = () => {
  rollbar = new Rollbar({
    accessToken: ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: 'production',
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: OA_VERSION,
          guess_uncaught_frames: true,
        },
      },
    },
  });
};

export const getRollbar = () => rollbar;
