import React from 'react';

import { Paper } from '@openx/components/core';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { ExportTypesList } from 'modules/providers/components';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: theme.spacing(3.8),
  },
  text: {
    marginTop: theme.spacing(0.8),
    lineHeight: '28px',
  },
}));

type Props = {
  id?: string;
  allowedExportTypes?: any;
};

export const Details = ({ id, allowedExportTypes }: Props) => {
  const { header, text } = useStyles();
  return (
    <Paper>
      <div className={header}>
        <Typography variant="h2" data-test="provider-details-title">
          Provider Details
        </Typography>
      </div>
      {id && (
        <Box marginBottom="12px">
          <Typography variant="body1" color="textSecondary" data-test="provider-id-heading">
            Provider ID
          </Typography>
          <Typography variant="body1" className={text} data-test="provider-id">
            {id}
          </Typography>
        </Box>
      )}
      {allowedExportTypes && (
        <Box>
          <Typography variant="body1" color="textSecondary" data-test="allowed-export-type-heading">
            Allowed Export Types
          </Typography>
          <ExportTypesList allowedExportTypes={allowedExportTypes} />
        </Box>
      )}
    </Paper>
  );
};
