import React, { useState } from 'react';
import { Dialog, FormControl, FormLabel, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button, DebounceField, Loader, Paper } from '@openx/components/core';

import { useValidateLocations } from 'modules/audiences/components/Locations/utils/useValidateLocations';
import { LocationType } from 'modules/audiences/AudienceProvider';

import { LocationTypeDropdown } from './LocationTypeDropdown';

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 20px',
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  bulkField: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 13,
  },
}));

interface Props {
  setLocationType: (type: LocationType) => void;
  locationType: LocationType;
  onClose: () => void;
  isOpen: boolean;
  setLocations: (options) => void;
}

export function BulkAdd(props: Props): JSX.Element {
  const { setLocationType, locationType, onClose, isOpen, setLocations } = props;
  const { actionsContainer, addButton, bulkField, error } = useStyles();

  const mapBulkPlaceHolder = {
    [LocationType.DMA]:
      'Locations may be entered as either semicolon, or new line separated values.\n\n Examples:\n\n New York\n Glendive',
    [LocationType.ZIP]:
      'Locations may be entered as either semicolon, or new line separated values.\n\n Examples:\n\n 94501\n 91101',
  };

  const [search, setSearch] = useState<string[]>([]);
  const [value, setValue] = useState('');
  const [valids, invalids, validating] = useValidateLocations({ locationType, search });

  const onAdd = () => {
    setLocations(valids);
    onDialogClose();
  };

  const onChange = e => {
    const value = e.target.value;
    setValue(value);
    const toSearch = new Set<string>(
      value
        .split(';')
        .flatMap(item => item.split('\n'))
        .map(option => option.trim())
        .filter(option => option),
    );
    setSearch(Array.from(toSearch));
  };

  const onDialogClose = () => {
    setValue('');
    setSearch([]);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onDialogClose}>
      <Paper padding="none">
        {({ HighlightedSection }) => (
          <>
            <Paper>
              <Typography variant="h2" gutterBottom data-test="bulk-add-title">
                Bulk Add
              </Typography>

              <FormControl>
                <FormLabel data-test="bulk-add-label">Location Type</FormLabel>
                <Grid container spacing={1}>
                  <Grid item xs={6} data-test="bulk-add-dropdown">
                    <LocationTypeDropdown
                      onChange={selected => {
                        setLocationType(selected);
                        setValue('');
                        setSearch([]);
                      }}
                      initValue={locationType}
                      forBulkUpload
                    />
                  </Grid>
                </Grid>
                <DebounceField
                  data-test="bulk-add-area"
                  fullWidth
                  placeholder={mapBulkPlaceHolder[locationType]}
                  className={bulkField}
                  onChange={onChange}
                  value={value}
                  autoFocus
                  multiline
                  minRows={10}
                  maxRows={10}
                  aria-hidden="false"
                />
              </FormControl>
              {validating && <Loader />}
              {value && invalids.length > 0 && (
                <div className={error} data-test="bulk-add-error">
                  The following invalid entries will be ignored on add: {invalids.join(', ')}
                </div>
              )}
            </Paper>
            <HighlightedSection>
              <div className={actionsContainer} data-test="bulk-add-action-buttons">
                <Button variant="text" onClick={onDialogClose} data-test="button">
                  CLOSE
                </Button>
                <Button className={addButton} onClick={onAdd} data-test="button" disabled={!value || validating}>
                  ADD
                </Button>
              </div>
            </HighlightedSection>
          </>
        )}
      </Paper>
    </Dialog>
  );
}
