import React, { ReactNode, useEffect, memo } from 'react';
import { Typography, Grid, Box, Drawer } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Breadcrumbs, BreadcrumbItemProps, useImpersonationBarScrollListener } from '@openx/components/core';
import { MainContainer } from './MainContainer';
import { SideMenu, MenuTypes } from './SideMenu';
import { ActionButtonProps } from './HeaderActions/ActionButton';
import { HeaderActions } from './HeaderActions/HeaderActions';

interface UseStylesProps {
  impersonationBarVisibleHeight: number;
}

const useStyles = makeStyles(theme => ({
  header: {
    background: `linear-gradient(93.1deg,${theme.palette.primary.main} 16.27%,${theme.palette.secondary.main} 74.56%)`,
    color: theme.palette.common.white,
    '& input, & .chipInput, & .MuiInputBase-input, & .MuiInput-input': {
      border: 'none',
      '&:hover': {
        border: 'none',
      },
      '&:focus': {
        boxShadow: '1px 1px 6px rgba(15, 15, 15, 0.26)',
      },
    },
  },
  headerContent: {
    padding: '24px 32px',
  },
  headerTitle: {
    fontSize: '20px',
    lineHeight: '32px',
  },
  drawer: {
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      top: ({ impersonationBarVisibleHeight }: UseStylesProps) => impersonationBarVisibleHeight + 54,
      width: 240,
      boxSizing: 'border-box',
      minWidth: 240,
      maxWidth: 240,
      padding: 32,
    },
  },
}));

export interface PageHeaderProps {
  children?: ReactNode;
  breadcrumbs?: BreadcrumbItemProps[];
  title?: string;
  render?: () => ReactNode;
  titlePrefix?: string;
  openDrawer?: boolean;
  handleDrawerOpen?: () => void;
  menuItems?: MenuTypes;
  token?: string;
  actions?: ActionButtonProps[];
}

export const PageHeader = memo((props: PageHeaderProps): JSX.Element => {
  const { children, breadcrumbs, title, titlePrefix, render, openDrawer, menuItems = [], token = '', actions } = props;

  const { barVisibleHeight: impersonationBarVisibleHeight } = useImpersonationBarScrollListener();

  const classes = useStyles({
    impersonationBarVisibleHeight,
  });

  useEffect(() => {
    document.title = `${titlePrefix || 'Unity'}  ${title && `- ${title}`}`;
  }, [title, titlePrefix]);

  const actionsButtons = actions && (
    <Grid item xs={6}>
      <HeaderActions actions={actions} />
    </Grid>
  );

  const getPageBody = () => {
    return (
      <>
        <div className={classes.header} data-test="page-header">
          {breadcrumbs && <Breadcrumbs items={breadcrumbs} data-test="breadcrumbs" />}
          <Grid container>
            <Grid item container className={classes.headerContent}>
              {title && (
                <Grid item xs={6}>
                  <Typography variant="h1" data-test="page-header-title" className={classes.headerTitle}>
                    {title}
                  </Typography>
                </Grid>
              )}
              {actionsButtons}
            </Grid>
            {render?.()}
          </Grid>
        </div>
        <div>{children}</div>
      </>
    );
  };

  return (
    <>
      <Box>
        <Drawer className={classes.drawer} variant="persistent" anchor="left" open={openDrawer}>
          {/* @ts-ignore */}
          <SideMenu menuItems={menuItems} token={token} />
        </Drawer>
        <MainContainer open={openDrawer} chldren={getPageBody()} />
      </Box>
    </>
  );
});
