import React, { useState, useCallback, useMemo, memo, FC } from 'react';
import { makeStyles, Typography, Collapse, IconButton, Box, Divider } from '@material-ui/core';

import { ModalDialog } from './ModalDialog';
import { useSnackbar } from 'notistack';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { areSegmentsEmpty } from 'modules/audiences/utils/areSegmentsEmpty';
import {
  useAudience,
  ActionType,
  getSubgroup,
  getSubgroupsSegments,
  RelationType,
  defaultSegments,
} from 'modules/audiences/AudienceProvider';

import { SegmentsList } from './SegmentsList';
import { Button } from '@openx/components/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.5, 0, 2.5, 2.5),
    background: theme.palette.background.black03,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 2,
  },
  h2: {
    '&:before': { display: 'none' },
  },
  segmentsContainer: {
    marginTop: theme.spacing(2.5),
    marginLeft: -theme.spacing(2.5),
  },
  subheader: {
    marginLeft: theme.spacing(2.5),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    alignSelf: 'flex-end',
    marginLeft: 'auto',
    marginRight: theme.spacing(1.75),
  },
  arrow: {
    marginLeft: theme.spacing(0.5),
  },
  modalText: {
    lineHeight: '28px',
  },
  addButton: {
    marginTop: theme.spacing(2.5),
  },
}));

interface SubgroupPanelProps {
  readonly?: boolean;
  groupIndex: number;
  subgroupIndex: number;
  relationField: RelationType;
}

export const SubgroupPanel: FC<SubgroupPanelProps> = memo(
  ({ readonly = false, groupIndex, subgroupIndex, relationField }) => {
    const { state, dispatch } = useAudience();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const subgroup = getSubgroup(state, relationField, groupIndex, subgroupIndex);

    const [expanded, setExpanded] = useState(true);
    const [removeSubgroupDialog, setRemoveSubgroupDialog] = useState(false);

    const segments = useMemo(
      () => !!subgroup && getSubgroupsSegments(state, relationField, groupIndex, subgroupIndex),
      [state, subgroup],
    );

    const toggleExpand = () => {
      setExpanded(prevExpanded => !prevExpanded);
    };

    const handleRemoveSubgroup = useCallback(
      (groupIndex, subgroupIndex) => {
        dispatch({ type: ActionType.REMOVE_SUBGROUP, payload: { relationField, groupIndex, subgroupIndex } });
        closeDeleteSubgroupDialog();
        enqueueSnackbar(`Subgroup ${groupIndex}.${subgroupIndex} deleted successfully`, { variant: 'success' });
      },
      [dispatch, state],
    );

    const handleAddSegment = useCallback(
      (groupIndex, subgroupIndex) => {
        dispatch({
          type: ActionType.SET_OPEN_SUBGROUP,
          payload: { relationField, groupIndex, subgroupIndex, segments: defaultSegments },
        });

        dispatch({
          type: ActionType.SET_IS_DRAWER_OPEN,
          payload: { isDrawerOpen: true },
        });
      },
      [dispatch, state],
    );

    const handleEditSegment = useCallback(
      (groupIndex, subgroupIndex) => {
        dispatch({
          type: ActionType.SET_OPEN_SUBGROUP,
          payload: { relationField, groupIndex, subgroupIndex, segments },
        });

        dispatch({
          type: ActionType.SET_IS_DRAWER_OPEN,
          payload: { isDrawerOpen: true },
        });
      },
      [dispatch, state],
    );

    const openDeleteSubgroupDialog = () => {
      if (areSegmentsEmpty(segments)) {
        handleRemoveSubgroup(groupIndex, subgroupIndex);
      } else {
        setRemoveSubgroupDialog(true);
      }
    };

    const closeDeleteSubgroupDialog = () => {
      setRemoveSubgroupDialog(false);
    };

    return (
      <div className={classes.root} data-test="subgroup-panel">
        <div className={classes.flex}>
          <Typography variant="h2" className={classes.h2}>
            Subgroup {`${groupIndex}.${subgroupIndex}`}
          </Typography>
          {!readonly && areSegmentsEmpty(segments) && (
            <Typography color="textSecondary" className={classes.subheader}>
              Add segments
            </Typography>
          )}
          <Divider />
          <div className={classes.buttons}>
            {!readonly && (
              <Button variant="text" color="secondary" onClick={openDeleteSubgroupDialog} data-test="remove-subgroup">
                Remove Subgroup
              </Button>
            )}

            {!readonly && !areSegmentsEmpty(segments) && (
              <Button
                variant="text"
                data-test="audience-open-edit-segment-drawer"
                color="primary"
                onClick={() => handleEditSegment(groupIndex, subgroupIndex)}
              >
                Edit Segment
              </Button>
            )}

            {!areSegmentsEmpty(segments) && (
              <IconButton size="small" onClick={toggleExpand} className={classes.arrow}>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </div>
        </div>

        <Collapse in={expanded}>
          {!areSegmentsEmpty(segments) && (
            <div className={classes.segmentsContainer}>
              <SegmentsList segments={segments} readonly relationField={relationField} isSubgroup />
            </div>
          )}
          {!readonly && areSegmentsEmpty(segments) && (
            <Button
              className={classes.addButton}
              data-test="audience-open-add-segment-drawer"
              color="primary"
              onClick={() => handleAddSegment(groupIndex, subgroupIndex)}
            >
              Add Segment
            </Button>
          )}
        </Collapse>

        <ModalDialog
          title="Remove Subgroup"
          isOpen={removeSubgroupDialog}
          onClose={closeDeleteSubgroupDialog}
          onConfirmed={() => handleRemoveSubgroup(groupIndex, subgroupIndex)}
        >
          <Box marginTop="16px">
            <Typography variant="body1" className={classes.modalText}>
              You’re about to delete the following subgroup:
            </Typography>
            <Typography variant="body1" className={classes.modalText}>
              <b>
                Subgroup {groupIndex}.{subgroupIndex}
              </b>
            </Typography>
          </Box>
          <Box marginTop="13px">
            <Typography variant="body1" className={classes.modalText}>
              Do you want to proceed?
            </Typography>
          </Box>
        </ModalDialog>
      </div>
    );
  },
);
