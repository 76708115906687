import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  loaderContainer: {
    textAlign: 'center',
  },
  paddedLoaderContainer: {
    padding: theme.spacing(4),
  },
}));

interface LoaderProps extends CircularProgressProps {
  noPadding?: boolean;
}

export const Loader = (props: LoaderProps): JSX.Element => {
  const classes = useStyles();
  const { noPadding, ...restProps } = props;

  const className = classNames({
    [classes.loaderContainer]: true,
    [classes.paddedLoaderContainer]: !noPadding,
  });

  return (
    <div className={className} data-test={props['data-test']}>
      <CircularProgress color="primary" {...restProps} />
    </div>
  );
};
