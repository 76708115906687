import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useQuery, ApolloError } from '@apollo/client';
import GetSegments from 'graphql/query/audiences/GetSegments.gql';

import { GetSegmentsQueryVariables, GetSegmentsQuery, Order_By } from 'types/schemaTypes';
import {
  RTG_FIELD,
  THIRD_PARTY_FIELD,
  SITE_CATEGORY_FIELD,
  Predefined,
  defaultPredefined,
  PredefinedSegmentType,
} from 'modules/audiences/AudienceProvider';
import { filterTree, EnhancedTreeData } from 'utils';
import { isPredefinedEmpty, localSegments } from 'modules/audiences/utils';

import { Propensity } from '../components/PredefinedAudienceSegments/PropensityDropdown';

export const mapTypeToQueryParam = {
  [RTG_FIELD]: 'rtg',
  [THIRD_PARTY_FIELD]: '3p',
  [SITE_CATEGORY_FIELD]: 'iab_site_categories',
};

export const mapCategoryToQueryParam = {
  [RTG_FIELD]: 'partner_audiences',
  [THIRD_PARTY_FIELD]: 'third_party_data',
  [SITE_CATEGORY_FIELD]: 'past_browsing_interests',
};

export function getSegmentParams(segmentType: PredefinedSegmentType, fetchExpiredSegments = false) {
  return {
    variables: {
      order_by: [{ full_name: Order_By.Asc }],
      where: {
        category: { _eq: 'predefined' },
        sub_category: { _eq: mapCategoryToQueryParam[segmentType] },
        is_archived: { _eq: false },
        _or: [
          {
            status: !fetchExpiredSegments ? { _eq: 'ready' } : { _in: ['ready', 'expired'] },
          },
          { is_nav_only: { _eq: true } },
        ],
      },
    },
  };
}

export interface UsePredefinedOptionsResponse {
  loading: boolean;
  error?: ApolloError;
  options: Predefined;
  setClickedOptions: Dispatch<SetStateAction<EnhancedTreeData[]>>;
  clickedOptions: EnhancedTreeData[];
}

export interface UsePredefinedOptionsProps {
  propensity?: Propensity;
  fetchExpiredSegments?: boolean;
}

const [localCache, memoizeSegments] = localSegments();

export const usePredefinedOptions = ({
  propensity = Propensity.ALL,
  fetchExpiredSegments = false,
}: UsePredefinedOptionsProps): UsePredefinedOptionsResponse => {
  const [options, setOptions] = useState<Predefined>(defaultPredefined);
  const [clickedOptions, setClickedOptions] = useState<EnhancedTreeData[]>([]);

  const {
    loading: rtgLoading,
    error: rtgError,
    data: { segment: rtgSegments = [] } = {},
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(
    GetSegments,
    getSegmentParams(PredefinedSegmentType.RTG_FIELD, fetchExpiredSegments),
  );

  const {
    loading: thirdPartyLoading,
    error: thirdPartyError,
    data: { segment: thirdPartySegments = [] } = {},
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(
    GetSegments,
    getSegmentParams(PredefinedSegmentType.THIRD_PARTY_FIELD, fetchExpiredSegments),
  );

  const {
    loading: siteCategoryLoading,
    error: siteCategoryError,
    data: { segment: siteCategorySegments = [] } = {},
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(
    GetSegments,
    getSegmentParams(PredefinedSegmentType.SITE_CATEGORY_FIELD, fetchExpiredSegments),
  );

  const loading = rtgLoading || thirdPartyLoading || siteCategoryLoading;

  useEffect(() => {
    if (!loading) {
      memoizeSegments([
        { [RTG_FIELD]: rtgSegments },
        { [THIRD_PARTY_FIELD]: thirdPartySegments },
        { [SITE_CATEGORY_FIELD]: siteCategorySegments },
      ]);
    }
  }, [rtgSegments, thirdPartySegments, siteCategorySegments]);

  useEffect(() => {
    if (!loading) {
      const newOptions = {
        [RTG_FIELD]: localCache[RTG_FIELD]?.calculatedSegments || [],
        [THIRD_PARTY_FIELD]:
          propensity === Propensity.ALL
            ? localCache[THIRD_PARTY_FIELD]?.calculatedSegments || []
            : filterTree(
                localCache[THIRD_PARTY_FIELD]?.calculatedSegments || [],
                segment => segment.propensity === propensity,
              ),
        [SITE_CATEGORY_FIELD]: localCache[SITE_CATEGORY_FIELD]?.calculatedSegments || [],
      };

      setOptions(newOptions);
    }
  }, [localCache, loading, propensity]);

  return {
    loading: loading || isPredefinedEmpty(options),
    error: rtgError || thirdPartyError || siteCategoryError,
    options,
    clickedOptions,
    setClickedOptions,
  };
};
