import { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import CreateAudienceShares from 'graphql/mutation/audiences/CreateAudienceShares.gql';
import UpdateAudienceShares from 'graphql/mutation/audiences/UpdateAudienceShares.gql';
import DeleteAudienceShares from 'graphql/mutation/audiences/DeleteAudienceShares.gql';
import { getCurrentUser } from 'firebaseIntegration/utils';
import createLog from 'graphql/mutation/error/createLog.gql';

import { defaultShareRow } from 'modules/audiences/AudienceProvider';
import { isEqual, omit, uniqBy, uniqWith } from 'lodash';

import { RevenueMethod, useValidationInterface } from 'modules/audiences/components/AudienceShare/types';

const groupSharesByShareUID = shares =>
  shares.reduce(
    (acc, { third_party_revenue, uid, recipient_account_id, revenue_method, cpm_cap, uids }) => ({
      ...acc,
      [uid || '']: {
        fee: revenue_method === RevenueMethod.PoM ? third_party_revenue : cpm_cap,
        recipientId: recipient_account_id,
        revenueMethod: revenue_method,
        uids,
      },
    }),
    {},
  );

interface ValidationState {
  isTouched: boolean;
  isError: boolean;
}

interface RowState {
  [AudienceId: string]: ValidationState;
}

interface InputState {
  [rowIndex: string]: RowState;
}

export interface UseAudienceSharesResponse {
  loading: boolean;
  error: boolean;
  createConfirmationHandler: (initialShareRows, nextShareRows, onClose?: (success: boolean) => void) => () => void;
  useValidation: useValidationInterface;
}

export interface Props {
  isBulk?: boolean;
  audienceId?: string;
}

export const useAudienceShares = ({ isBulk = false, audienceId }: Props): UseAudienceSharesResponse => {
  const { enqueueSnackbar } = useSnackbar();
  const [pushLog] = useMutation(createLog);

  const [validationState, setValidationState] = useState<InputState>({});

  const getInputError = useCallback(
    (rowIndex, audienceId) =>
      validationState[rowIndex]?.[audienceId].isTouched && validationState[rowIndex]?.[audienceId].isError,
    [validationState],
  );

  const getValidationError = useCallback(
    (rowIndex, audienceId) => validationState[rowIndex]?.[audienceId].isError,
    [validationState],
  );

  const getIsFieldTouched = useCallback(
    (rowIndex: number, audienceId: string) => validationState[rowIndex]?.[audienceId].isTouched,
    [validationState],
  );

  const setValidationError = (rowIndex: number, audienceId: string, isError: boolean) => {
    setValidationState(prev => ({
      ...prev,
      [rowIndex]: {
        ...(prev[rowIndex] || {}),
        [audienceId]: {
          ...(prev[rowIndex]?.[audienceId] || {}),
          isError,
        },
      },
    }));
  };

  const setIsFieldTouched = (rowIndex, audienceId, isTouched) => {
    setValidationState(prev => ({
      ...prev,
      [rowIndex]: {
        ...(prev[rowIndex] || {}),
        [audienceId]: {
          ...(prev[rowIndex]?.[audienceId] || {}),
          isTouched,
        },
      },
    }));
  };

  const touchAllFields = () => {
    setValidationState(prev =>
      Object.keys(prev).reduce(
        (rows, rowIndex) => ({
          ...rows,
          [rowIndex]: {
            ...(rows[rowIndex] || {}),
            ...Object.keys(prev[rowIndex]).reduce(
              (audienceIds, audienceId) => ({
                ...audienceIds,
                [audienceId]: {
                  ...(prev[rowIndex]?.[audienceId] || {}),
                  isTouched: true,
                },
              }),
              {},
            ),
          },
        }),
        {},
      ),
    );
  };

  const validationError = useMemo(
    () =>
      Object.values(validationState)
        .reduce<ValidationState[]>((acc, cur) => [...acc, ...Object.values(cur)], [])
        .some(({ isError }) => isError === true),
    [validationState],
  );

  const atLeastOneFieldIsTouched = useMemo(
    () =>
      Object.values(validationState)
        .reduce<ValidationState[]>((acc, cur) => [...acc, ...Object.values(cur)], [])
        .some(({ isTouched, isError }) => isTouched && isError),
    [validationState],
  );

  const isSubmissionDisabled = useMemo(
    () => validationError && atLeastOneFieldIsTouched,
    [validationError, atLeastOneFieldIsTouched],
  );

  const purgeRemovedRow = () => {
    setValidationState(prev => {
      const lastRowIndex = Math.max(...Object.keys(prev).map(index => +index));

      return omit(prev, [`${lastRowIndex}`]);
    });
  };

  const [createAudienceShares, { error: createAudienceSharesError, loading: createAudienceSharesLoading }] =
    useMutation(CreateAudienceShares, {
      refetchQueries: ['GetAudienceShares', 'GetAudience', 'GetBulkShareAudiencesList'],
    });

  const [updateAudienceShares, { error: updateAudienceSharesError, loading: updateAudienceSharesLoading }] =
    useMutation(UpdateAudienceShares, {
      refetchQueries: ['GetAudienceShares', 'GetAudience', 'GetBulkShareAudiencesList'],
    });

  const [deleteAudienceShares, { error: deleteAudienceSharesError, loading: deleteAudienceSharesLoading }] =
    useMutation(DeleteAudienceShares, {
      refetchQueries: ['GetAudienceShares', 'GetAudience', 'GetBulkShareAudiencesList'],
    });

  const loading = createAudienceSharesLoading || updateAudienceSharesLoading || deleteAudienceSharesLoading;

  const error = !!createAudienceSharesError || !!updateAudienceSharesError || !!deleteAudienceSharesError;

  const shareRowsToShares = useCallback(
    shareRows =>
      shareRows
        .filter(shareRow => !isEqual(shareRow, defaultShareRow))
        .reduce((acc, { fee, recipients, revenueMethod }) => {
          const currentFeeShares = recipients.map(({ id, uid }) => ({
            revenue_method: revenueMethod,
            third_party_revenue: revenueMethod === RevenueMethod.PoM ? `${fee / 100}` : undefined,
            cpm_cap: revenueMethod === RevenueMethod.CPM ? fee : undefined,
            recipient_account_id: id,
            audience_external_openaudience_id: audienceId,
            uid,
            uids: [uid],
          }));

          return [...acc, ...currentFeeShares];
        }, []),
    [audienceId],
  );

  const bulkShareRowsToShares = bulkShareRows =>
    bulkShareRows.reduce((acc, { recipients, audiences }) => {
      const shares = audiences.reduce(
        (allShares, { fee, revenueMethod, shareId, uids, audience: { id: audienceId } }) => {
          const curShares = recipients.map(({ id, uid }) => ({
            revenue_method: revenueMethod,
            third_party_revenue: revenueMethod === RevenueMethod.PoM ? `${fee / 100}` : undefined,
            cpm_cap: revenueMethod === RevenueMethod.CPM ? fee : undefined,
            recipient_account_id: id,
            audience_external_openaudience_id: audienceId,
            uids: uids,
            uid: shareId ? uid : undefined,
          }));

          return [...allShares, ...curShares];
        },
        [],
      );

      return [...acc, ...shares];
    }, []);

  const apiSharesToUiShares = apiShares =>
    apiShares.reduce((acc, { id, audience_share }) => {
      const curAudienceShares = audience_share.map(
        ({ third_party_revenue, recipient_account_id, uid, cpm_cap, revenue_method }) => ({
          third_party_revenue,
          recipient_account_id,
          audience_external_openaudience_id: id,
          cpm_cap,
          revenue_method,
          uid,
        }),
      );

      return [...acc, ...curAudienceShares];
    }, []);

  const prepareShares = (initialShares, nextShares) => {
    const sharesPresentOnlyInInitialState = initialShares.filter(
      ({ uid: initialUid }) => nextShares.map(({ uid }) => uid).indexOf(initialUid) === -1,
    );

    const sharesPresentInBothStates = nextShares.filter(
      ({ uid }) => initialShares.map(({ uid }) => uid).indexOf(uid) !== -1,
    );

    const sharesPresentOnlyInNextState = nextShares.filter(
      ({ uid }) => initialShares.map(({ uid }) => uid).indexOf(uid) === -1,
    );

    const initialSharesByUID = groupSharesByShareUID(initialShares);
    const nextSharesByUID = groupSharesByShareUID(nextShares);

    const uidsPresentInBothStates = Object.keys(groupSharesByShareUID(sharesPresentInBothStates));

    const uidsToUpdateFee = uidsPresentInBothStates.filter(
      uid =>
        initialSharesByUID[uid].fee !== nextSharesByUID[uid].fee &&
        initialSharesByUID[uid].recipientId === nextSharesByUID[uid].recipientId,
    );

    const prepareSharesToDelete = sharesPresentOnlyInInitialState.map(({ uid }) => uid);

    const sharesToDelete = prepareSharesToDelete;

    const prepareSharesToUpdate = sharesPresentInBothStates
      .filter(({ uid }) => uidsToUpdateFee.indexOf(uid) !== -1)
      .reduce(
        (
          shares,
          {
            audience_external_openaudience_id,
            cpm_cap,
            recipient_account_id,
            revenue_method,
            third_party_revenue,
            uid,
            uids,
          },
        ) => {
          if (uids.includes(uid)) {
            return [
              ...shares,
              {
                audience_external_openaudience_id,
                cpm_cap,
                recipient_account_id,
                revenue_method,
                third_party_revenue,
                uid,
                uids,
              },
            ];
          } else return [...shares];
        },
        [],
      )
      .map(share =>
        omit(share, ['audience_external_openaudience_id', 'recipient_account_id', 'cpm_cap', 'revenue_method', 'uids']),
      );

    const sharesToUpdate = uniqBy(prepareSharesToUpdate, 'uid');
    const prepareSharesToCreate = sharesPresentOnlyInNextState.map(share => omit(share, ['uid', 'uids']));

    const sharesToCreate = uniqWith(prepareSharesToCreate, isEqual);

    return {
      sharesToDelete,
      sharesToUpdate,
      sharesToCreate,
    };
  };

  const createConfirmationHandler = useCallback(
    (initialShareRows = [], nextShareRows = [], onClose = () => {}) =>
      async () => {
        try {
          const initialShares = isBulk ? apiSharesToUiShares(initialShareRows) : shareRowsToShares(initialShareRows);
          const nextShares = isBulk ? bulkShareRowsToShares(nextShareRows) : shareRowsToShares(nextShareRows);

          const { sharesToDelete, sharesToUpdate, sharesToCreate } = prepareShares(initialShares, nextShares);

          const {
            data: { audienceShareDelete },
          } = sharesToDelete.length
            ? await deleteAudienceShares({
                variables: {
                  input: sharesToDelete,
                },
              })
            : { data: { audienceShareDelete: [] } };

          const {
            data: { audienceShareUpdate },
          } = sharesToUpdate.length
            ? await updateAudienceShares({
                variables: {
                  input: sharesToUpdate,
                },
              })
            : { data: { audienceShareUpdate: [] } };

          const {
            data: { audienceShareCreate },
          } = sharesToCreate.length
            ? await createAudienceShares({
                variables: {
                  input: sharesToCreate,
                },
              })
            : { data: { audienceShareCreate: [] } };

          const deleteErrors = audienceShareDelete.filter(({ __typename }) => __typename === 'Error');
          const updateErrors = audienceShareUpdate.filter(({ __typename }) => __typename === 'Error');
          const createErrors = audienceShareCreate.filter(({ __typename }) => __typename === 'Error');

          const results = [...audienceShareDelete, ...audienceShareUpdate, ...audienceShareCreate];
          const errors = [...deleteErrors, ...updateErrors, ...createErrors];

          const getWarningMessage = ({
            audienceShareDelete,
            deleteErrors,
            audienceShareUpdate,
            updateErrors,
            audienceShareCreate,
            createErrors,
          }) => {
            const deleteShareCount = audienceShareDelete.length;
            const updateShareCount = audienceShareUpdate.length;
            const createShareCount = audienceShareCreate.length;

            const deleteErrorCount = deleteErrors.length;
            const updateErrorCount = updateErrors.length;
            const createErrorCount = createErrors.length;

            const successfulDeletes = deleteShareCount - deleteErrorCount;
            const successfulUpdates = updateShareCount - updateErrorCount;
            const successfulCreates = createShareCount - createErrorCount;

            const deleteCaption = deleteShareCount > 0 ? `Deleted: ${successfulDeletes}/${deleteShareCount}.` : '';
            const updateCaption = updateShareCount > 0 ? `Updated: ${successfulUpdates}/${updateShareCount}.` : '';
            const createCaption = createShareCount > 0 ? `Created: ${successfulCreates}/${createShareCount}.` : '';

            return `Audience was shared partially. ${deleteCaption} ${updateCaption} ${createCaption}`.replace(
              /  +/g,
              ' ',
            );
          };

          const warningMessage = getWarningMessage({
            audienceShareDelete,
            deleteErrors,
            audienceShareUpdate,
            updateErrors,
            audienceShareCreate,
            createErrors,
          });

          if (errors.length === 0) {
            enqueueSnackbar('Audience shared successfully', { variant: 'success' });
          } else {
            if (errors.length < results.length) {
              enqueueSnackbar(warningMessage, { variant: 'warning' });
            } else {
              throw new Error('Cannot share audience');
            }
          }
        } catch (e) {
          const user = await getCurrentUser();

          await pushLog({
            variables: {
              type: 'error',
              location: window.location.href,
              msg: 'Failed to share audiences',
              name: user?.displayName,
              email: user?.email,
            },
          });

          enqueueSnackbar('Failed to share the audience', { variant: 'error' });
        } finally {
          onClose();
        }
      },
    [
      isBulk,
      shareRowsToShares,
      deleteAudienceShares,
      updateAudienceShares,
      createAudienceShares,
      enqueueSnackbar,
      pushLog,
    ],
  );

  return {
    createConfirmationHandler,
    loading,
    error,
    useValidation: {
      validationError,
      isSubmissionDisabled,
      getInputError,
      touchAllFields,
      setValidationError,
      getValidationError,
      purgeRemovedRow,
      setIsFieldTouched,
      getIsFieldTouched,
    },
  };
};
