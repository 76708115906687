import { object, string, mixed } from 'yup';

import { NAME_FIELD, DATA_TYPE_FIELD, FILE_FIELD } from './constants';

export const supportedFileTypes = ['text/csv', 'text/plain'];

export const validationSchema = object().shape({
  [NAME_FIELD]: string().required('Segment name is required'),
  [DATA_TYPE_FIELD]: string().required('Data type is required'),
  [FILE_FIELD]: mixed((input): input is File => input instanceof File)
    .required('File is required')
    .test('fileSize', 'File cannot be empty', (file: File) => file && file.size !== undefined && file.size > 0)
    .test(
      'fileFormat',
      'Wrong file type, only .csv or .txt files are supported',
      (file: File) => file && file.type !== undefined && supportedFileTypes.includes(file.type),
    ),
});
