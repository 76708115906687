import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ProvidersHeader } from './ProvidersHeader';
import { ModalDialog } from '../components';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Paper } from '@openx/components/core';
import { EmptyTablePlaceholder } from 'components/TablePlaceholder/EmptyTablePlaceholder';
import { SearchResultPlaceholder } from 'components/TablePlaceholder/SearchResultsPlaceholder';

import { ProviderToDele } from '../constants';

import {
  AllTableCriteria,
  applyCriteriaChange,
  clientSideCriteriaProcessing,
  SearchFiltersValue,
  SortDirection,
  Table,
} from '@openx/components/table';
import { TABLE_PAGE_SIZE, TOP_BAR_HEIGHT } from 'config';

import { useMutation, useQuery } from '@apollo/client';
import GetProvidersList from 'graphql/query/providers/GetProvidersList.gql';
import DeleteProvider from 'graphql/mutation/providers/DeleteProvider.gql';
import { tableColumns } from './tableColumns';
import { Account, GetProvidersListQuery, GetProvidersListQueryVariables, Provider } from 'types/schemaTypes';

import { useSnackbar } from 'notistack';
import { useHistoryWithRef } from 'utils/useHistoryWithRef';

export type ProviderRow = Pick<Provider, 'id' | 'name'> & { allowed_accounts: Pick<Account, 'id'>[] };

const initialCriteria = {
  pagination: {
    pageSize: TABLE_PAGE_SIZE,
    pageNumber: 1,
    totalCount: 100,
  },
  sort: {
    column: 'name',
    direction: SortDirection.DESC,
    sortableColumns: ['name'],
  },
  filters: {
    byPhrase: {
      fields: ['name', 'id'],
      phrase: '',
    },
  },
};

const useStyles = makeStyles({
  nameToDelete: {
    fontWeight: 'bold',
  },
});

interface HistoryState {
  page?: number;
  ref?: string;
}

export const ProvidersList = () => {
  const history = useHistoryWithRef<HistoryState>();

  const { nameToDelete } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const {
    loading,
    error,
    data: { provider = [] } = {},
  } = useQuery<GetProvidersListQuery, GetProvidersListQueryVariables>(GetProvidersList, {
    fetchPolicy: 'network-only',
  });

  const [deleteProvider, { loading: deleteLoading }] = useMutation(DeleteProvider, {
    refetchQueries: ['GetProvidersList'],
  });

  const [criteria, setCriteria] = useState<AllTableCriteria>({
    ...initialCriteria,
    pagination: {
      ...initialCriteria.pagination,
      pageNumber: history.location.state?.page ?? 1,
    },
  });
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [toDelete, setToDelete] = useState<ProviderToDele>(null);

  const handleCriteriaChange = useCallback(
    (newCriteria, isInitialCall) => {
      setCriteria(applyCriteriaChange(criteria, newCriteria, { skipPaginationReset: isInitialCall }));
    },
    [criteria],
  );

  const getData = useMemo(() => {
    return clientSideCriteriaProcessing(provider, criteria, { onCriteriaAdjust: setCriteria });
  }, [provider, criteria]);

  const openDeleteDialogHandler = () => {
    setDeleteVisible(true);
  };

  const closeDeleteDialogHandler = () => {
    setDeleteVisible(false);
    setToDelete(null);
  };

  const deleteProviderHandler = async id => {
    try {
      await deleteProvider({ variables: { id } });
      enqueueSnackbar(`Provider ${toDelete?.name} deleted successfully`, { variant: 'success' });
      closeDeleteDialogHandler();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Error deleting provider ${toDelete?.name}`, { variant: 'error' });
      closeDeleteDialogHandler();
    }
  };

  useEffect(() => {
    history.replace({
      ...history.location,
      state: {
        ...history.location.state,
        page: criteria.pagination?.pageNumber,
      },
    });
  }, [criteria.pagination?.pageNumber]);

  const rowActions = [
    {
      label: 'Details',
      onClick: values => {
        const { id } = values;
        history.push(`/providers/${id}`);
      },
      'data-test': 'provider-details',
    },
    {
      label: 'Edit',
      topDivider: true,
      onClick: values => {
        const { id } = values;
        history.push(`/providers/${id}/edit`);
      },
      'data-test': 'provider-edit',
    },
    {
      label: 'Delete',
      danger: true,
      onClick: values => {
        const { id, name } = values;
        setToDelete({ id, name });
        openDeleteDialogHandler();
      },
      'data-test': 'provider-delete',
    },
  ];

  const isTableEmpty = !loading && !criteria.filters && getData.length === 0;
  const noSearchResults = !loading && criteria.filters && getData.length === 0;

  if (error) throw new Error('Providers Error');

  if (isTableEmpty) {
    return (
      <ProvidersHeader handleCriteriaChange={handleCriteriaChange}>
        <Paper padding="none">
          <EmptyTablePlaceholder />
        </Paper>
      </ProvidersHeader>
    );
  }

  return (
    <ProvidersHeader handleCriteriaChange={handleCriteriaChange}>
      <Paper padding="none">
        <Table
          data={getData}
          columns={tableColumns}
          loading={loading}
          onCriteriaChange={handleCriteriaChange}
          onRowClick={({ id }) => history.push(`/providers/${id}`)}
          highlightRules={(criteria.filters as SearchFiltersValue)?.byPhrase}
          criteria={criteria}
          stickyHeaderPosition={TOP_BAR_HEIGHT}
          rowActions={rowActions}
          fixedLayout
        >
          {noSearchResults && <SearchResultPlaceholder columnsAmount={3} />}
        </Table>
      </Paper>
      <ModalDialog
        title="Delete Provider"
        isOpen={deleteVisible && !!toDelete}
        onConfirmed={() => deleteProviderHandler(toDelete?.id)}
        onClose={closeDeleteDialogHandler}
        loading={deleteLoading}
      >
        <Box>
          <Typography variant="body2" color="textSecondary" data-test="delete-provider-message">
            You're about to delete provider
          </Typography>

          <Box mt={2}>
            <Typography color="textPrimary" className={nameToDelete} noWrap data-test="provider-to-delete-name">
              {toDelete?.name}
            </Typography>
          </Box>
        </Box>
      </ModalDialog>
    </ProvidersHeader>
  );
};
