import React, { useCallback, useState } from 'react';
import { AutoCompleteVirtualize, Loader, Paper } from '@openx/components/core';
import { Box, Typography } from '@material-ui/core';

import { useQuery } from '@apollo/client';
import GetAccountOptions from 'graphql/query/providers/GetAccountOptions.gql';
import { Account, GetAccountOptionsQuery, GetAccountOptionsQueryVariables } from 'types/schemaTypes';

import { mapRowsToLabels, FormFields } from 'modules/providers/constants';

type Props = {
  linkedAccounts?: Pick<Account, 'id' | 'name'>[];
  allowedAccountFieldHandler: (value: Pick<Account, 'id' | 'name'>[]) => void;
  loading?: boolean;
};

export const LinkedAccounts = ({ linkedAccounts = [], allowedAccountFieldHandler, loading = false }: Props) => {
  const {
    loading: accountOptionsLoading,
    error,
    data: { account = [] } = {},
  } = useQuery<GetAccountOptionsQuery, GetAccountOptionsQueryVariables>(GetAccountOptions);

  const [inputPhrase, setInputPhrase] = useState('');

  const onSearchValueChange = (_, value: string, reason: string) => {
    if (reason === 'input') {
      setInputPhrase(value);
    }
  };

  const onAutocompleteClose = () => {
    setInputPhrase('');
  };

  const onAutocompleteChange = (_, item) => {
    if (!item) {
      return;
    }

    allowedAccountFieldHandler(item);
  };

  const filterOptions = useCallback(() => {
    if (inputPhrase) {
      return account
        .filter(option => !linkedAccounts.map(({ id }) => id).includes(option.id))
        .filter(({ name }) => name.match(inputPhrase));
    }

    return account.filter(option => !linkedAccounts.map(({ id }) => id).includes(option.id));
  }, [account, linkedAccounts, inputPhrase]);

  if (loading) {
    return (
      <Paper>
        <Loader />
      </Paper>
    );
  }

  return (
    <Paper>
      <Box mb={3.8}>
        <Typography variant="h2" data-test="allowed-organizations-title">
          Allowed Organizations
        </Typography>
      </Box>
      <AutoCompleteVirtualize
        multiple
        data-test="allowed-accounts-input"
        textFieldProps={{
          placeholder: '[ Select Organization ]',
          label: mapRowsToLabels[FormFields.ALLOWES_ORGANIZATIONS],
        }}
        options={account}
        renderOption={option => option.name}
        getOptionLabel={option => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        inputValue={inputPhrase}
        value={linkedAccounts}
        onInputChange={onSearchValueChange}
        onChange={onAutocompleteChange}
        onClose={onAutocompleteClose}
        filterOptions={filterOptions}
        loading={accountOptionsLoading}
        limitTags={6}
        disableCloseOnSelect
        filterSelectedOptions
        disableClearable
      />
    </Paper>
  );
};
