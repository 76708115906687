import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';

import { ConfirmationDialog, Paper, Loader } from '@openx/components/core';
import GetBulkShareAudiencesList from 'graphql/query/audiences/GetBulkShareAudiencesList.gql';
import ShareRecipientsOptions from 'graphql/query/options/ShareRecipientsOptions.rest.gql';
import { useAudienceShares } from 'modules/audiences/hooks/useAudienceShares';

import { AudienceShareRow } from './AudienceShareRow';
import { prepareBulkShares } from './utils';
import { Audience, BulkFeeHandler, BulkRevenueMethodHandler, Recipient, RevenueMethod, ShareRow } from './types';

const useStyles = makeStyles(theme => ({
  container: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
    maxHeight: '100%',
    overflow: 'auto',
  },
  caption: {
    lineHeight: '13px',
    fontSize: '13px',
    marginBottom: '9px',
  },
  audienceName: {
    fontWeight: 600,
    lineHeight: '28px',
  },
}));

interface Props {
  audiences: Audience[];
  onClose: (success: boolean) => void;
  open: boolean;
}

export const BulkShareConfirmationDialog = ({ audiences = [], onClose, open }: Props): JSX.Element | null => {
  const { audienceName, container, caption } = useStyles();

  const [shareRows, setShareRows] = useState<ShareRow[]>([]);
  const [recipientOptions, setRecipientOptions] = useState<Recipient[]>([]);
  const [initialShares, setInitialShares] = useState(null);

  const {
    createConfirmationHandler,
    loading: confirmLoading,
    error,
    useValidation,
  } = useAudienceShares({ isBulk: true });

  const { validationError, purgeRemovedRow, touchAllFields, isSubmissionDisabled } = useValidation;

  const shareAudiences = createConfirmationHandler(initialShares, shareRows, onClose);

  const onConfirmed = useCallback(() => {
    touchAllFields();

    if (!validationError) {
      shareAudiences();
    }
  }, [touchAllFields, shareAudiences, validationError]);

  const { loading: recipientsLoading, data: { audienceShareRecipients: { objects = [] } = {} } = {} } = useQuery(
    ShareRecipientsOptions,
    { variables: { limit: 100000 } },
  );

  const recipientList = useMemo(() => objects.map(({ id, name }) => ({ id, name })), [objects]);

  const selectedRecipients = useMemo(
    () => shareRows.reduce<Recipient[]>((acc, { recipients }) => [...acc, ...recipients], []),
    [shareRows],
  );

  const {
    error: sharesError,
    data,
    loading: sharesLoading,
  } = useQuery(GetBulkShareAudiencesList, {
    variables: { ids: audiences.map(({ id }) => id) },
    fetchPolicy: 'network-only',
  });

  const { audience: shares } = data || {};

  const loading = useMemo(
    () => sharesLoading || recipientsLoading || confirmLoading,
    [sharesLoading, recipientsLoading, confirmLoading],
  );

  useEffect(() => {
    const newOptions = recipientList.filter(({ id }) => selectedRecipients.map(({ id }) => id).indexOf(id) === -1);
    if (!recipientsLoading && !isEqual(newOptions, recipientOptions)) {
      setRecipientOptions(recipientList.filter(({ id }) => selectedRecipients.map(({ id }) => id).indexOf(id) === -1));
    }
  }, [selectedRecipients, recipientOptions, recipientList, recipientsLoading]);

  useEffect(() => {
    setShareRows(prepareBulkShares(shares));
  }, [shares]);

  const defaultShareRow = useMemo(
    () => ({
      recipients: [],
      audiences: audiences.map(audience => ({
        audience,
        fee: null,
        revenueMethod: RevenueMethod.PoM,
      })),
    }),
    [audiences],
  );

  useEffect(() => {
    if (initialShares === null) {
      if (shares !== undefined && !sharesLoading) {
        setInitialShares(shares);
      }
    }
  }, [sharesLoading]);

  const handleRevenueMethodChange = useCallback(
    (rowIndex: number) => (audienceId: string) => (audienceShareRevenueMethod: string) => {
      setShareRows(prevShareRows => {
        const { audiences } = prevShareRows[rowIndex];

        const newAudiences = audiences.map(({ audience, fee, revenueMethod, shareId, uids }) =>
          audienceId === audience.id
            ? { audience, fee, revenueMethod: audienceShareRevenueMethod, shareId, uids }
            : { audience, fee, revenueMethod, shareId, uids },
        );

        return prevShareRows.map((shareRow, index) =>
          index === rowIndex ? { ...shareRow, audiences: newAudiences } : shareRow,
        );
      });
    },
    [audiences],
  );

  const handleFeeChange = useCallback(
    (rowIndex: number) => (audienceId: string) => (audienceShareFee: string) => {
      setShareRows(prevShareRows => {
        const { audiences } = prevShareRows[rowIndex];

        const newAudiences = audiences.map(({ audience, fee, revenueMethod, shareId, uids }) =>
          audienceId === audience.id
            ? { audience, fee: audienceShareFee, revenueMethod, shareId, uids }
            : { audience, fee, revenueMethod, shareId, uids },
        );

        return prevShareRows.map((shareRow, index) =>
          index === rowIndex ? { ...shareRow, audiences: newAudiences } : shareRow,
        );
      });
    },
    [audiences],
  );

  const handleRecipientChange = useCallback(
    (rowIndex: number) => (_, recipients: Recipient[], reason, detail) => {
      if (recipients.length) {
        if (reason === 'remove-option') {
          setShareRows(prev =>
            prev.map((row, index) =>
              index === rowIndex ? { ...row, recipients: recipients.filter(({ id }) => id !== detail.option.id) } : row,
            ),
          );
        } else {
          setShareRows(prev => prev.map((row, index) => (index === rowIndex ? { ...row, recipients } : row)));
        }
      } else {
        setShareRows(prev => prev.filter((_, index) => index !== rowIndex));
      }
    },
    [audiences],
  );

  const handleRemoveRow = useCallback(
    (rowIndex: number) => () => {
      setShareRows(prev => prev.filter((_, index) => index !== rowIndex));

      purgeRemovedRow();
    },
    [audiences],
  );

  useEffect(() => {
    if (shareRows.every(({ recipients }) => recipients.length >= 1) && recipientOptions.length > 0) {
      setShareRows(prev => [...prev, defaultShareRow]);
    }
  }, [shareRows, recipientOptions]);

  useEffect(() => {
    const newRows = shareRows.filter(row => !isEqual(row, defaultShareRow));

    if (!recipientOptions.length && !isEqual(shareRows, newRows)) {
      setShareRows(prev => prev.filter(row => !isEqual(row, defaultShareRow)));
    }
  }, [recipientOptions, shareRows]);

  const onCancel = useCallback(() => {
    if (!loading) {
      onClose(false);
    }
  }, [loading, onClose]);

  if (error || sharesError) {
    return null;
  }

  return (
    <ConfirmationDialog
      isOpen={open}
      confirmationDisabled={isSubmissionDisabled}
      title="Share Audience"
      confirmLabel="Share"
      onCancel={onCancel}
      onConfirmed={onConfirmed}
      customContent
      isLoading={loading}
      confirmationButtonColor="primary"
      data-test="confirmation-dialog"
    >
      <Paper className={container}>
        <Typography variant="body1" className={caption}>
          You intend to share selected audience(s) with the following publisher(s):
        </Typography>

        {audiences.map(({ name }) => (
          <Typography variant="body1" className={audienceName} key={name} data-test="share-audience-names">
            {name}
          </Typography>
        ))}

        {loading && <Loader />}

        {!loading &&
          shareRows.map(({ recipients, audiences }, index) => (
            <AudienceShareRow
              useValidation={useValidation}
              rowIndex={index}
              audiences={audiences}
              recipientOptions={recipientOptions}
              recipients={recipients}
              key={JSON.stringify(recipients)}
              handleFeeChange={handleFeeChange(index) as BulkFeeHandler}
              handleRecipientChange={handleRecipientChange(index)}
              handleRemoveRow={handleRemoveRow(index)}
              handleRevenueMethodChange={handleRevenueMethodChange(index) as BulkRevenueMethodHandler}
            />
          ))}
      </Paper>
    </ConfirmationDialog>
  );
};
