import { EnhancedTreeData } from 'utils';

const check = (item: EnhancedTreeData): boolean => item.id.length > 0;

export const anySelected = (items: EnhancedTreeData[]): boolean => {
  if (Array.isArray(items)) {
    return items.some(check);
  }

  return check(items);
};
