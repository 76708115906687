import { isEqual } from 'lodash';
import { FormFields, ProviderFormState } from '../constants';
import { prepareAccountsToLink, prepareAccountsToUnlink } from './prepareDataToUpdate';

export function getAffectedRows(
  initialValues: ProviderFormState,
  editedValues: ProviderFormState,
  providerId,
): string[] {
  const difference: string[] = [];

  const initialAccountIds = initialValues[FormFields.ALLOWES_ORGANIZATIONS]?.map(({ id }) => id) || [];
  const updatedAccountIds = editedValues[FormFields.ALLOWES_ORGANIZATIONS]?.map(({ id }) => id) || [];

  if (!isEqual(initialValues[FormFields.PROVIDER_NAME], editedValues[FormFields.PROVIDER_NAME])) {
    difference.push(FormFields.PROVIDER_NAME);
  }
  if (!isEqual(initialValues[FormFields.ALLOWED_EXPORT_TYPES], editedValues[FormFields.ALLOWED_EXPORT_TYPES])) {
    difference.push(FormFields.ALLOWED_EXPORT_TYPES);
  }
  if (
    prepareAccountsToLink(initialAccountIds, updatedAccountIds, providerId)[0] ||
    prepareAccountsToUnlink(initialAccountIds, updatedAccountIds, providerId)[0]
  ) {
    difference.push(FormFields.ALLOWES_ORGANIZATIONS);
  }

  return difference;
}
