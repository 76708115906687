import { isEqual } from 'lodash';
import { FormikProps } from 'formik';

interface IncludeExcludeType {
  include: unknown[];
  exclude: unknown[];
}

interface FormikValue {
  [key: string]: IncludeExcludeType;
}

type Props = Pick<FormikProps<FormikValue>, 'values'> & any;

interface IncludeExcludeDeepComparatorProps {
  fields: string[];
  prevProps: Props;
  nextProps: Props;
}

export function includeExcludeDeepComparator({
  prevProps,
  nextProps,
  fields,
}: IncludeExcludeDeepComparatorProps): boolean {
  return fields.every((field: string) => {
    const {
      values: { [field]: prev },
    } = prevProps;
    const {
      values: { [field]: next },
    } = nextProps;

    return isEqual(prev.include, next.include) && isEqual(prev.exclude, next.exclude);
  });
}

export const makeIncludeExcludeDeepComparator = (fields: string[]) => (prevProps: Props, nextProps: Props) =>
  includeExcludeDeepComparator({
    prevProps,
    nextProps,
    fields,
  });
