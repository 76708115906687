import { State, getName, getDescription, getDirectProvider } from 'modules/audiences/AudienceProvider';
import { noSegmentsSelected, isDirectedSegmentSelected } from 'modules/audiences/utils';

export const validateAudienceForm = (state: State) => {
  const name = getName(state);
  const description = getDescription(state);
  const directProvider = getDirectProvider(state);

  if (!name) {
    throw new Error('Audience Name is required');
  }

  if (name.length > 255) {
    throw new Error('Audience Name cannot be longer than 255 characters');
  }

  if (description && description.length > 1000) {
    throw new Error('Audience description cannot be longer than 1,000 characters');
  }

  if (directProvider && !isDirectedSegmentSelected(state)) {
    throw new Error('Direct segment has to be selected');
  }

  if (!directProvider && noSegmentsSelected(state)) {
    throw new Error('At least one segment should be selected');
  }
};
