import { useQuery } from '@apollo/client';

import GetDistinctSegmentStatuses from 'graphql/query/segments/GetDistinctSegmentStatuses.gql';
import { GetDistinctSegmentStatusesQuery, GetDistinctSegmentStatusesQueryVariables } from 'types/schemaTypes';
import { firstLetterUppercase } from '@openx/utils';

export interface UseSegmentStatusResponse {
  gqlSegmentStatuses: string[];
  uiSegmentStatuses: string[];
  loadingSegmentStatuses: boolean;
}

export function useSegmentStatuses(): UseSegmentStatusResponse {
  const { data: { segment_statuses = [] } = {}, loading: loadingSegmentStatuses } = useQuery<
    GetDistinctSegmentStatusesQuery,
    GetDistinctSegmentStatusesQueryVariables
  >(GetDistinctSegmentStatuses);
  // status field is optional.
  const gqlSegmentStatuses = ['expiring_soon', ...segment_statuses.map(item => item.status || '')];
  const uiSegmentStatuses = gqlSegmentStatuses.map(value =>
    value
      .split('_')
      .map(word => firstLetterUppercase(word))
      .join(' '),
  );

  return {
    gqlSegmentStatuses,
    uiSegmentStatuses,
    loadingSegmentStatuses,
  };
}
