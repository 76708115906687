import React from 'react';
import { Route } from 'react-router-dom';

import { usePermissionsCheck } from 'permissions';
import { AccessForbiddenPage } from 'modules/auth/AccessForbiddenPage';

import { RouteConfig } from './types';

export function PrivateRoute(props: RouteConfig): JSX.Element {
  const { path, exact, component, permissionValidators } = props;
  const sessionPermissions = usePermissionsCheck(permissionValidators || []);
  const isAllowed = !permissionValidators || sessionPermissions;

  if (!isAllowed) {
    return <Route path={path} exact={exact} component={AccessForbiddenPage} />;
  }

  return <Route path={path} exact={exact} component={component} />;
}
