import React, { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { PageHeader, ListHeaderContent } from '@openx/components/headerv2';
import { SearchFilters, supportedFields, CriteriaDimension, CriteriaChange } from '@openx/components/tablev2';
import { useSideDrawer } from 'context';
import { ButtonColor } from '@openx/components/core';
import { useTaxonomyDownload } from '../utils';
import { CSVDownload } from './components';

interface TaxonomyHeaderProps {
  children: React.ReactNode;
  handleCriteriaChange: (props: CriteriaChange<CriteriaDimension.FILTERS>) => void;
  categories: string[];
  subCategories: string[];
  types: string[];
  sources: string[];
}

export const taxonomyHeaders = [
  { label: 'Segment', key: 'name' },
  { label: 'Segment Full Name', key: 'full_name' },
  { label: 'Segment Description', key: 'description' },
  { label: 'Segment Sub Category', key: 'segment_sub_category' },
  { label: 'Segment Category', key: 'segment_category' },
  { label: 'Segment Type', key: 'segment_type' },
  { label: 'Segment Source', key: 'segment_source' },
  { label: 'Segment Source Description', key: 'segment_source_description' },
];

const TaxonomyHeaderRaw: FC<TaxonomyHeaderProps> = ({
  children,
  handleCriteriaChange,
  categories,
  subCategories,
  types,
  sources,
}): JSX.Element => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();

  const downloadRef = useRef(null);

  const { taxonomyLoading, downloadActions, handleDownloading } = useTaxonomyDownload();

  const handleClick = useCallback(
    event => {
      if (downloadActions.data.length > 0) {
        //@ts-ignore
        downloadRef?.current?.click();
      } else {
        handleDownloading();
      }
    },
    [downloadActions, handleDownloading],
  );

  const actions = [
    {
      label: 'Download Taxonomy',
      color: 'default' as ButtonColor,
      loading: taxonomyLoading,
      onClick: e => handleClick(e),
      'data-test': 'upload-file-button',
    },
  ];

  const HeaderFilters = () => (
    <ListHeaderContent
      filtersComponent={SearchFilters}
      filtersProps={{
        advanced: true,
        byPhraseFields: ['name', 'description'],
        byValueFields: [
          { fieldName: supportedFields.TAXONOMY_CATEGORY, options: categories },
          { fieldName: supportedFields.TAXONOMY_SUB_CATEGORY, options: subCategories },
          { fieldName: supportedFields.TAXONOMY_TYPE, options: types },
          { fieldName: supportedFields.TAXONOMY_SOURCE, options: sources },
        ],
        onFiltersChange: filters => {
          handleCriteriaChange({ dimension: CriteriaDimension.FILTERS, value: filters });
        },
        inputPlaceholder: 'Search Name or Description',
      }}
    />
  );

  return (
    <>
      <PageHeader
        title="OpenAudience Taxonomy"
        titlePrefix="OpenAudience"
        render={HeaderFilters}
        openDrawer={openDrawer}
        handleDrawerOpen={handleDrawerOpen}
        menuItems={menuItems}
        token={token}
        actions={actions}
      >
        {children}
      </PageHeader>

      {downloadActions.data.length > 0 && <CSVDownload {...downloadActions} downloadRef={downloadRef} />}
    </>
  );
};

export const TaxonomyHeader = memo(TaxonomyHeaderRaw, (prevProps, nextProps) => isEqual(prevProps, nextProps));
