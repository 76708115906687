import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { isEqual } from 'lodash';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { Button, useImpersonationBarScrollListener } from '@openx/components/core';
import { TOP_BAR_HEIGHT } from 'config';

import { Select } from './Select';
import { Provider } from './context';
import { Actions, ActionBarProps, OnGoBack } from './types';
import { useSideDrawer } from 'context';

interface UseStylesProps {
  impersonationBarVisibleHeight: number;
}

const useStyles = makeStyles(theme => ({
  body: {
    alignItems: 'center',
    background: theme.palette.background.boxDark,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    height: 40,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 4),
    position: 'fixed',
    top: ({ impersonationBarVisibleHeight }: UseStylesProps) => impersonationBarVisibleHeight + TOP_BAR_HEIGHT,
    zIndex: 2,
  },
  shrinkBody: {
    left: 240,
    transition: theme.transitions.create(['left', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: 'calc(100% - 240px)',
  },
  stretchBody: {
    width: '100%',
    left: 0,
    transition: theme.transitions.create(['left', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  placeholder: {
    height: 40,
  },
  block: {
    display: 'flex',
    '&:not(:last-child)': {
      marginRight: theme.spacing(1.25),
    },
  },
  backIcon: {
    height: 18,
    marginRight: theme.spacing(1.25),
    width: 18,
  },
}));

const actionTypeToComponentMap = {
  button: Button,
  select: Select,
};

export const ActionBar = memo(
  ({ actions, children, isMenuButton, onGoBack }: ActionBarProps): JSX.Element => {
    const { openDrawer } = useSideDrawer();
    const { barVisibleHeight: impersonationBarVisibleHeight } = useImpersonationBarScrollListener();
    const classes = useStyles({ impersonationBarVisibleHeight });
    const [currentActions, setCurrentActions] = useState<Actions>(actions || []);
    const [currentOnGoBack, setCurrentOnGoBack] = useState<OnGoBack | null>(() => onGoBack || null);

    useEffect(() => {
      setCurrentActions(actions || []);
    }, [actions]);

    useEffect(() => {
      setCurrentOnGoBack(() => onGoBack || null);
    }, [onGoBack]);

    const setActions = useCallback(
      (nextActions: Actions) => {
        if (actions) {
          console.warn('Actions are already defined by the ActionBar. You cannot override them');
          return;
        }
        queueMicrotask(() => {
          setCurrentActions(nextActions);
        });
      },
      [actions],
    );

    const setOnGoBack = useCallback(
      (nextOnGoBack: OnGoBack | null) => {
        if (onGoBack) {
          console.warn('OnGoBack callback is already defined by the ActionBar. You cannot override it');
          return;
        }
        queueMicrotask(() => {
          setCurrentOnGoBack(() => nextOnGoBack);
        });
      },
      [onGoBack],
    );

    const contextValue = useMemo(() => ({ setActions, setOnGoBack }), [setActions, setOnGoBack]);

    return (
      <Provider value={contextValue}>
        <div className={`${classes.body} ${openDrawer ? classes.shrinkBody : classes.stretchBody}`}>
          <div className={classes.block}>
            {currentOnGoBack && (
              <Button variant="text" onKeyUp={currentOnGoBack} onClick={currentOnGoBack} data-test="back-button">
                <ChevronLeftIcon className={classes.backIcon} />
                Back
              </Button>
            )}
          </div>
          <div className={classes.block}>
            {currentActions.map(({ type, label, props }, index) => {
              const ActionComponent = actionTypeToComponentMap[type];
              return (
                <div key={`${type}.${label}.${index}`} className={classes.block}>
                  <ActionComponent {...props} data-test={`${label.toLowerCase()}-button`}>
                    {label}
                  </ActionComponent>
                </div>
              );
            })}
            {isMenuButton && children}
          </div>
        </div>
        <div className={classes.placeholder} />
        {!isMenuButton && children}
      </Provider>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
