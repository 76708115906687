import { OptionState } from 'modules/audiences/components/OptionsList';
import { flatChildren } from './flatChildren';
import { findParent, findParents } from './findParentsChildren';
import { EnhancedTreeData } from './segmentsToTreeData';

function mapToValues(options: EnhancedTreeData[]): string[] {
  return options.map(({ value }) => value);
}

function calculate(
  tree: EnhancedTreeData[],
  options: EnhancedTreeData[],
  option: EnhancedTreeData,
): EnhancedTreeData[] | undefined {
  const parent = findParent(tree, option);

  if (!parent) {
    return;
  }
  const parentsChildren = parent.children!;
  if (
    parentsChildren.length > 1 &&
    parentsChildren.every(childItem => mapToValues([...options, option]).includes(childItem.value))
  ) {
    const result = calculate(tree, options, parent);
    return result ? result : [...options, parent, ...flatChildren(parent)];
  }
}

export const calculateClickedOptions = (
  action: OptionState,
  tree: EnhancedTreeData[],
  options: EnhancedTreeData[],
  option: EnhancedTreeData,
): EnhancedTreeData[] => {
  if (action === OptionState.REMOVE) {
    const parentsValues = findParents(tree, option).map(({ value }) => value);
    return options
      .filter(current => current.value !== option.value)
      .filter(current => !parentsValues.includes(current.value));
  }
  return calculate(tree, options, option) || [...options, option];
};
