import { useState, useEffect, useCallback } from 'react';
import { useQuery, ApolloError } from '@apollo/client';
import GetSegments from 'graphql/query/audiences/GetSegments.gql';
import { EnhancedTreeData, TreeData } from 'utils';

import { GetSegmentsQueryVariables, GetSegmentsQuery, Order_By } from 'types/schemaTypes';
import { fields, defaultDemographics, Demographics, AgeType } from 'modules/audiences/AudienceProvider';
import { localSegments } from 'modules/audiences/utils';

export const GENDER_SUB_TYPE = 'gender';
export const AGE_SUB_TYPE = 'age';

const segmentsParams = {
  variables: {
    order_by: [{ full_name: Order_By.Desc }],
    where: {
      category: { _in: ['demographic_age', 'demographic_gender'] },
      is_archived: { _eq: false },
      _or: [
        {
          status: { _eq: 'ready' },
        },
        { is_nav_only: { _eq: true } },
      ],
    },
  },
};

export const defaultAgeRange = {
  min: undefined,
  max: undefined,
};

export const MIN_AGE = 19;
export const MAX_AGE = 99;

export const ANY_AGE_VALUE = -1;
export const ANY_GENDER_VALUE = 'Any';

export interface UseDemographicsOptionsResponse {
  loading: boolean;
  error?: ApolloError;
  options: Required<Demographics>;
  segmentsToRange: (ageSegments: TreeData[]) => AgeType;
  segmentsToRangeGroups: (ageSegments: TreeData[]) => AgeType[];
  rangeToSegments: (ageRange: AgeType) => EnhancedTreeData[];
  ageList: number[];
}

const [localCache, memoizeSegments] = localSegments();

export const useDemographicsOptions = (): UseDemographicsOptionsResponse => {
  const [options, setOptions] = useState<Required<Demographics>>(defaultDemographics);

  const {
    loading,
    error,
    data: { segment = [] } = {},
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegments, segmentsParams);

  useEffect(() => {
    if (!loading) {
      memoizeSegments([{ [fields.DEMOGRAPHICS_FIELD]: segment }]);
    }
  }, [loading, segment]);

  useEffect(() => {
    if (!loading) {
      const calculatedSegments = localCache[fields.DEMOGRAPHICS_FIELD]?.calculatedSegments || [];

      const { children: ageOptions = [] } =
        calculatedSegments.find(({ category }) => category === fields.AGE_FIELD) || {};

      const { children: genderOptions = [] } =
        calculatedSegments.find(({ category }) => category === fields.GENDER_FIELD) || {};

      const newOptions = {
        [fields.AGE_FIELD]: ageOptions,
        [fields.GENDER_FIELD]: genderOptions,
      };

      setOptions(newOptions);
    }
  }, [loading]);

  const segmentsToRange = (ageSegments: TreeData[]): AgeType => {
    let min;
    let max;
    for (const ageSegment of ageSegments) {
      const age = +ageSegment.name; // convert to number
      if (!max || age > max) max = age;
      if (!min || age < min) min = age;
    }
    return {
      min: min,
      max: max,
    };
  };

  const segmentsToRangeGroups = (ageSegments: TreeData[]): AgeType[] => {
    const ages: number[] = [] as number[];
    for (const ageSegment of ageSegments) {
      const age = +ageSegment.name; // convert to number
      ages.push(age);
    }
    ages.sort((a, b) => a - b);

    const result: number[][] = [];
    let temp: number[] = [ages[0]];

    for (let i = 1; i < ages.length; i++) {
      if (ages[i] - ages[i - 1] > 1) {
        result.push(temp);
        temp = [];
      }
      temp.push(ages[i]);
    }

    result.push(temp);

    const rangeGroups = result.map(group => ({ min: Math.min(...group), max: Math.max(...group) }));
    return rangeGroups;
  };

  const rangeToSegments = useCallback(
    ({ min, max }: AgeType): EnhancedTreeData[] =>
      !min || !max ? [] : options[fields.AGE_FIELD].slice(min - MIN_AGE, max - MIN_AGE + 1),
    [options],
  );

  const ageList = [ANY_AGE_VALUE, ...options[fields.AGE_FIELD].map(({ name }) => +name)];

  return {
    segmentsToRange,
    rangeToSegments,
    segmentsToRangeGroups,
    ageList,
    loading,
    error,
    options,
  };
};
