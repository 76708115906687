import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { FieldProps } from 'formik';
import { DatePicker, DatePickerProps } from '@material-ui/pickers';
import { InputAdornment, IconButton, FormControl, FormLabel, makeStyles } from '@material-ui/core';
import { Clear as ClearIcon, InsertInvitation as CalendarIcon } from '@material-ui/icons';

export type DatePickerFieldProps = DatePickerProps &
  Partial<FieldProps> & {
    'data-test'?: string;
    label?: string;
    helperText?: string;
  };

const defaultProps: Partial<DatePickerProps> = {
  inputVariant: 'outlined',
  variant: 'inline',
  autoOk: true,
  format: 'YYYY-MM-DD',
  placeholder: 'YYYY-MM-DD',
};

const makeInputProps = ({ clearable, clearableCallback, inputValue }) => ({
  endAdornment:
    clearable && inputValue ? (
      <InputAdornment position="end">
        <IconButton data-testid="clear-button" onClick={clearableCallback}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ) : (
      <InputAdornment position="end">
        <IconButton>
          <CalendarIcon />
        </IconButton>
      </InputAdornment>
    ),
});

const useStyles = makeStyles({
  input: {
    marginTop: 0,
    '&.MuiFormControl-root .MuiButtonBase-root': {
      padding: '5px',
      margin: '1px',
      '&.MuiIconButton-root .MuiSvgIcon-root': {
        width: '.8em',
        height: '.8em',
      },
    },
  },
});

export const DatePickerField = (props: DatePickerFieldProps): JSX.Element => {
  const { name, value, label, 'data-test': dataTest, error, onChange, helperText, clearable, ...otherProps } = props;

  const classes = useStyles();

  let pickerProps = {
    name,
    value,
    error,
    helperText,
    onChange,
  };

  if (props.form && props.field) {
    const {
      form: { errors, setFieldTouched, touched, setFieldValue },
      field: { name: fieldName, value: fieldValue },
    } = props as FieldProps;

    const fieldError = touched[fieldName] && errors[fieldName];

    pickerProps = {
      name: fieldName,
      value: fieldValue ? moment(fieldValue).format('YYYY-MM-DD') : null,
      error: Boolean(fieldError),
      helperText: fieldError ? (fieldError as string) : undefined,
      onChange: date => setFieldValue(fieldName, moment(date).format('YYYY-MM-DD')),
      //@ts-ignore
      onBlur: () => setFieldTouched(fieldName),
    };
  }

  const clearableCallback = useCallback(
    e => {
      e.stopPropagation();
      if (props.form && props.field) {
        props.form.setFieldValue(props.field.name, null);
        return;
      }
      if (pickerProps.onChange) {
        pickerProps.onChange(null);
      }
    },
    [pickerProps, props.field, props.form],
  );

  const inputValue = props.field ? props.field.value : props.value;

  const InputProps = useMemo(
    () => makeInputProps({ clearable, clearableCallback, inputValue }),
    [clearable, clearableCallback, inputValue],
  );

  return (
    <FormControl data-test={dataTest} fullWidth>
      {label && <FormLabel>{label}</FormLabel>}
      <DatePicker
        className={classes.input}
        data-testid="date-picker"
        {...defaultProps}
        {...pickerProps}
        InputProps={InputProps}
        {...otherProps}
      />
    </FormControl>
  );
};
