export enum FeeType {
  SAME = 'SAME',
  DIFFERENT = 'DIFFERENT',
}

export enum RevenueMethod {
  PoM = 'PoM',
  CPM = 'CPM',
}

export type Fee = string | null;
export type TRevenueMethod = string | null;

export interface Audience {
  id: string;
  name?: string;
}

export interface AudienceWithFee {
  audience: Audience;
  revenueMethod: string | null;
  fee: Fee;
  shareId?: string | null;
  uids?: string[] | null;
}

export interface Recipient {
  id: string;
  uid?: string;
  name: string;
}

export interface AudienceSharesByFee {
  fee: Fee;
  revenueMethod: RevenueMethod;
  recipients: Recipient[];
  shareId?: string;
  uids?: string[] | null;
}

export interface ShareRow {
  recipients: Recipient[];
  audiences: AudienceWithFee[];
}

export interface IndividualShare {
  audience: Audience;
  fee: Fee;
  revenueMethod: TRevenueMethod;
  recipient: Recipient;
  shareId?: string;
  uids?: string[] | null;
}

export interface ShareByRecipients {
  audience: Audience;
  fee: Fee;
  revenueMethod: TRevenueMethod;
  recipients: Recipient[];
  shareId?: string;
  uids?: string[] | null;
}

export type FeeHandler = (audienceShareFee: Fee) => void;
export type BulkFeeHandler = (audienceId: string) => (audienceShareFee: Fee) => void;
export type RevenueMethodHandler = (audienceShareRevenueMethod: TRevenueMethod) => void;
export type BulkRevenueMethodHandler = (audienceId: string) => (audienceShareRevenueMethod: TRevenueMethod) => void;

export interface useValidationInterface {
  validationError: boolean;
  isSubmissionDisabled: boolean;
  setValidationError: (rowIndex: number, audienceId: string, error: boolean) => void;
  getValidationError: (rowIndex: number, audienceId: string) => boolean;
  setIsFieldTouched: (rowIndex: number, audienceId: string, isTouched: boolean) => void;
  getIsFieldTouched: (rowIndex: number, audienceId: string) => boolean;
  getInputError: (rowIndex: number, audienceId: string) => boolean;
  touchAllFields: () => void;
  purgeRemovedRow: () => void;
}
