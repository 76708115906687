import { sortRecursive } from './sort';
import { findParentsInFlatTree } from 'utils/findParentsChildren';

export interface TreeData {
  id: string;
  external_id: string;
  value: string;
  name: string;
  type: string;
  sub_type: string;
  category: string | null;
  sub_category: string | null;
  is_leaf: boolean;
  is_nav_only: boolean;
  description?: string;
  full_name: string;
  parent_segment: string | null;
  bq_location: string | null;
  source_bq_tables?: Record<string, string> | null;
  propensity?: string;
  segment_group_id?: string | null;
  status?: string;
  expiration_date?: string | null;
  account_id?: string;
  is_archived?: boolean;
  reach?: number | null;
}

export interface EnhancedTreeData extends TreeData {
  key: string;
  title: string;
  value: string;
  calculated_full_name: string;
  disableCheckbox: boolean;
  children?: EnhancedTreeData[];
}

const localCache = {};

export function segmentsToTreeData(treeSegments, parentId?): EnhancedTreeData[] {
  const roots = treeSegments.filter(segment => {
    return (segment.is_nav_only || segment.is_leaf) && (segment.parent_segment === parentId || parentId === undefined);
  });

  const addTreeDataFields = segment => ({
    ...segmentToTreeData(treeSegments, segment),
    children: segment.is_leaf ? [] : segmentsToTreeData(treeSegments, segment.id),
  });

  return sortRecursive(roots.map(addTreeDataFields));
}

export function segmentToTreeData(
  tree,
  {
    id,
    external_id,
    name,
    is_leaf,
    is_nav_only,
    type,
    sub_type,
    category,
    sub_category,
    parent_segment,
    full_name,
    bq_location,
    source_bq_tables,
    ...otherFields
  },
): EnhancedTreeData {
  return {
    ...otherFields,
    id,
    external_id,
    name,
    key: id,
    value: id,
    title: name.trim(),
    is_leaf,
    is_nav_only,
    full_name,
    calculated_full_name: parent_segment
      ? [...parentsNames(tree, parent_segment, id, name), name.trim()].join(` ${String.fromCharCode(8213)} `)
      : name,
    disableCheckbox: is_leaf && is_nav_only,
    type,
    sub_type,
    category,
    sub_category,
    parent_segment,
    bq_location,
    source_bq_tables,
  };
}

function parentsNames(tree, parent_segment, id, name) {
  if (parent_segment && localCache[parent_segment]) {
    return localCache[parent_segment];
  } else {
    const parentsNames = findParentsInFlatTree(tree, {
      id,
      parent_segment,
      name,
    }).map(({ name }) => name.trim());

    if (parent_segment) {
      localCache[parent_segment] = parentsNames;
    }
    return parentsNames;
  }
}
