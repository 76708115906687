import React from 'react';
import { useQuery } from '@apollo/client';

import GetSegmentByPk from 'graphql/query/segments/GetSegmentByPk.gql';
import { ReachTooltip as ReachTooltipComponent, ReachTooltipContent, SectionType } from 'components/ReachTooltip';
import { abbreviatedNumber } from '@openx/utils';

interface ReachTooltipProps {
  id: string;
  reach: number;
}

function TooltipContent({ id, reach }: ReachTooltipProps): JSX.Element {
  const { loading, data } = useQuery(GetSegmentByPk, {
    variables: { id: id },
  });

  const segmentReach = data?.segment_by_pk;

  const sections =
    segmentReach?.source_matched_count === null
      ? { [SectionType.SOURCE_PROVIDED_COUNT]: segmentReach?.source_provided_count }
      : {
          [SectionType.SOURCE_PROVIDED_COUNT]: segmentReach?.source_provided_count,
          [SectionType.SOURCE_MATCHED_COUNT]: segmentReach?.source_matched_count,
        };

  const text = `The total segment reach of ${abbreviatedNumber(
    reach,
  )} users is associated with the following source id counts:`;

  return <ReachTooltipContent text={text} sections={sections} loading={loading} />;
}

export function ReachTooltip({ id, reach }: ReachTooltipProps): JSX.Element {
  return <ReachTooltipComponent customWidth title={<TooltipContent id={id} reach={reach} />} />;
}
