import React from 'react';
import { createStyles } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { AutoComplete } from '@openx/components/core';

import { ActionsDropdownProps } from './types';

const useStyles = makeStyles(
  createStyles({
    body: {
      width: 164,
    },
  }),
);

export function Select({ children, options = [] }: ActionsDropdownProps): JSX.Element {
  const { body } = useStyles();

  return (
    <AutoComplete
      className={body}
      options={options}
      value={undefined}
      filterOptions={options => options}
      getOptionLabel={({ label }) => label}
      onChange={(_, { onClick = () => undefined }) => {
        onClick();
      }}
      inputValue={children}
      selectOnFocus={false}
      textFieldReadOnly
      blurOnSelect
      disableClearable
    />
  );
}
