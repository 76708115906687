import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, FieldPolicy } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';
import { shouldSuppressSnackbar } from '@openx/utils';

import { fetchAuthToken } from 'firebaseIntegration';
import { getRollbar } from 'modules/analytics';

import { runLocalHandlers } from './runLocalHandlers';
import { MakeApolloClientArgs } from './types';
import { Reference } from '@apollo/client/utilities';
import { KeySpecifier } from '@apollo/client/cache/inmemory/policies';

const restLink = new RestLink({
  uri: `${window.location.origin}/api/mgmt`,
});

const authLink = setContext(async () => {
  const token = await fetchAuthToken();

  return {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

type KeyArgs = FieldPolicy<unknown>['keyArgs'];

export function openAudiencePagination<T = Reference>(keyArgsAlias: string = 'NaN'): FieldPolicy<T[]> {
  const keyArgs: KeyArgs = (args: any, context: any): KeySpecifier => {
    const keys: KeySpecifier = [];
    if (context?.field?.alias?.value === keyArgsAlias) {
      return context.field.arguments.map(arg => arg.name.value).filter(arg => arg !== 'limit' && arg !== 'offset');
    }

    const res = [...context.field.arguments.map(arg => arg.name.value)];
    return res.length > 0 ? res : keys || (false as const);
  };

  //Merge function to handle pagination
  const merge = (existing, incoming, { args }) => {
    // const merge = (existing, incoming, { args, field, ...other }) => {

    const merged = existing ? existing.slice(0) : [];

    if (incoming) {
      if (args) {
        // Assume an offset of 0 if args.offset omitted.
        const { offset = 0 } = args;
        for (let i = 0; i < incoming.length; ++i) {
          const matchIdx = merged.map(e => e?.__ref).indexOf(incoming[i]?.__ref);
          if (matchIdx !== -1) {
            merged[matchIdx] = undefined;
          }
          merged[offset + i] = incoming[i];
        }
      } else {
        merged.push(...incoming);
      }
    }

    return merged;
  };

  //TODO Read function to handle where filters
  // const read = (existing, { args, ..._other }) => {
  const read = (existing, { args, field }) => {
    if (keyArgsAlias !== field?.alias?.value) return existing;

    const { offset = 0, limit = 0 } = args || {};
    if (!existing || existing.length < offset) {
      return;
    }
    const slice = offset + limit > existing.length ? existing.length : offset + limit;

    if (existing?.slice(offset, slice).includes(undefined) || existing?.slice(offset, slice).length === 0) return;

    return existing.slice(offset, slice);
  };

  return {
    keyArgs,
    merge,
    read,
  };
}

const httpLink = new HttpLink({
  uri: `${window.location.origin}/api/graphql`,
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        deal: openAudiencePagination('dealFeed'),
        audience: openAudiencePagination('audienceFeed'),
      },
    },
  },
});

export const makeApolloClient = ({ handlersConfigMap, printError }: MakeApolloClientArgs) => {
  const errorLink = onError(({ graphQLErrors, operation, response }) => {
    if (!graphQLErrors || shouldSuppressSnackbar(operation, response)) return;

    const rollbar = getRollbar();
    const handlersResults = runLocalHandlers({ graphQLErrors, handlersConfigMap, operation });
    const handledLocally = handlersResults.some(Boolean);

    if (!handledLocally && graphQLErrors[0]?.message === 'Validation error') {
      printError(graphQLErrors[0]?.extensions?.errors[0]?.message || 'Something went wrong');
    }
    if (!handledLocally && graphQLErrors[0]?.message !== 'Validation error') {
      printError(graphQLErrors[0]?.message || 'Something went wrong');
    }

    if (rollbar) {
      rollbar.error('Caught by Apollo OnError interceptor', {
        caughtErrors: graphQLErrors,
        handledLocally,
      });
    }
  });

  return new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, restLink as unknown as ApolloLink, httpLink]),
    cache,
  });
};
