import React, { useState, useEffect, useCallback, memo, FC } from 'react';
import { makeStyles, Typography, Collapse, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { Button, Tooltip } from '@openx/components/core';
import {
  useAudience,
  ActionType,
  getExcludesGroups,
  RelationType,
  getIncludesGroups,
} from 'modules/audiences/AudienceProvider';

import { MatchTypeContainer } from './MatchTypeContainer';
import { GroupPanel } from './GroupPanel';
import { Alert } from '@material-ui/lab';
import { isGroupEmpty } from 'modules/audiences/utils';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2.5),
    margin: '0 -20px',
    background: '#FAFAFA',
    borderTop: '1px solid #E7E7E7',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  h2: {
    '&:before': { display: 'none' },
  },
  buttonWithTooltip: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& button': {
      marginRight: theme.spacing(2),
    },
  },
  info: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    margin: '30px 0 10px 0',
    padding: 0,
  },
  message: {
    padding: 0,
  },
  icon: {
    '& svg': {
      width: 15,
      height: 15,
    },
    marginRight: 6.5,
    padding: 0,
  },
}));

const tooltipText =
  'Add a segment group to your audience, to be combined (Match Any) or intersected (Match All) with other segment groups';

interface Props {
  readonly?: boolean;
}

export const ExcludesPanel: FC<Props> = memo(({ readonly = true }) => {
  const { state, dispatch } = useAudience();
  const { root, header, h2, buttonWithTooltip, info, message, icon } = useStyles();

  const [expanded, setExpanded] = useState(true);
  const [matchItems, setMatchItems] = useState<JSX.Element[]>([
    <GroupPanel relationField={RelationType.EXCLUDE} groupIndex={1} readonly={readonly} />,
  ]);
  const includes = getIncludesGroups(state);
  const groups = getExcludesGroups(state);

  const areIncludesEmpty = includes.every(isGroupEmpty);

  const toggleExpand = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  const handleAddGroup = useCallback(() => {
    dispatch({
      type: ActionType.ADD_GROUP,
      payload: { relationField: RelationType.EXCLUDE, groupIndex: groups[groups.length - 1].index + 1 },
    });
  }, [dispatch, groups]);

  useEffect(() => {
    const newMatchItems = groups?.map(({ index }) => (
      <GroupPanel relationField={RelationType.EXCLUDE} groupIndex={index} readonly={readonly} />
    ));

    if (!readonly && newMatchItems.length === 0) {
      dispatch({
        type: ActionType.ADD_GROUP,
        payload: { relationField: RelationType.EXCLUDE, groupIndex: 1 },
      });
    } else {
      setMatchItems(newMatchItems);
    }
  }, [groups]);

  if (readonly && !groups?.length) return null;

  return (
    <div className={root} data-test="excludes-panel">
      <div className={header}>
        <Typography variant="h2" className={h2}>
          Excludes
        </Typography>
        {!areIncludesEmpty && (
          <IconButton size="small" onClick={toggleExpand}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </div>
      {areIncludesEmpty ? (
        <Alert className={info} severity="info" classes={{ message, icon }}>
          <Typography variant="body1">
            An Includes segment group must be added before adding an Excludes segment group
          </Typography>
        </Alert>
      ) : (
        <Collapse in={expanded}>
          <MatchTypeContainer matchItems={matchItems} readonly compactDividers />

          {!readonly && (
            <div className={buttonWithTooltip}>
              <Button onClick={handleAddGroup} data-test="add-excludes-group">
                Add Another Group
              </Button>
              <Tooltip placement="right" title={tooltipText} withRipple />
            </div>
          )}
        </Collapse>
      )}
    </div>
  );
});
