import { Account } from 'types/schemaTypes';

export const ExportTypes = {
  OA_MATCH: 'oa_match',
  BIDSTREAM: 'bidstream',
  BIDSTREAM_PLUS: 'bidstream_plus',
} as const;

export const FormFields = {
  PROVIDER_ID: 'id',
  PROVIDER_NAME: 'name',
  ALLOWED_EXPORT_TYPES: 'allowed_export_types',
  ALLOWES_ORGANIZATIONS: 'allowed_organizations',
} as const;

export const mapRowsToLabels = {
  [FormFields.PROVIDER_ID]: 'Provider ID',
  [FormFields.PROVIDER_NAME]: 'Provider Name',
  [FormFields.ALLOWED_EXPORT_TYPES]: 'Allowed Export Types',
  [FormFields.ALLOWES_ORGANIZATIONS]: 'Linked Organizations',
} as const;

export type ProviderFormState = {
  [FormFields.PROVIDER_NAME]: string;
  [FormFields.PROVIDER_ID]: string;
  [FormFields.ALLOWED_EXPORT_TYPES]: string[];
  [FormFields.ALLOWES_ORGANIZATIONS]?: Pick<Account, 'id' | 'name'>[];
};

export const initialFormState: ProviderFormState = {
  [FormFields.PROVIDER_NAME]: '',
  [FormFields.PROVIDER_ID]: '',
  [FormFields.ALLOWED_EXPORT_TYPES]: [],
};

export type ProviderToDele = {
  id: string;
  name: string;
} | null;
