import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GetSegmentsQuery, GetSegmentsQueryVariables, Order_By } from 'types/schemaTypes';
import GetSegments from 'graphql/query/audiences/GetSegments.gql';
import { TreeData, segmentsToTreeData } from 'utils';

interface ValidateDirectSegmentsParam {
  search: string[];
  directProvider: string;
}

export const SearchDirectSegmentsParams = (search: string[], directProvider: string) => ({
  variables: {
    order_by: [{ full_name: Order_By.Asc }],
    where: {
      name: { _in: search },
      category: { _eq: 'direct' },
      provider_id: { _eq: directProvider },
      status: { _eq: 'ready' },
      is_archived: { _eq: false },
    },
  },
});

export function useValidateDirectSegments({
  search = [],
  directProvider,
}: ValidateDirectSegmentsParam): [TreeData[], string[], boolean] {
  const [valids, setValids] = React.useState<TreeData[]>([]);
  const [invalids, setInvalids] = React.useState<string[]>([]);

  const {
    loading: validating,
    data,
    refetch,
  } = useQuery<GetSegmentsQuery, GetSegmentsQueryVariables>(GetSegments, {
    ...SearchDirectSegmentsParams(search, directProvider),
    skip: !search[0],
  });

  useEffect(() => {
    if (search[0]) {
      setValids([]);
      setInvalids([]);
      refetch();
    }
  }, [directProvider, search, refetch]);

  useEffect(() => {
    if (data && !validating) {
      setValids(segmentsToTreeData(data.segment));
      setInvalids(search.filter(value => !data.segment.find(segment => segment.name === value)));
    }
  }, [data, search, validating]);

  return [valids, invalids, validating];
}
