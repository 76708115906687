/* eslint-disable max-lines */
import { createTheme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import React from 'react';
import { ExpandMore } from '@material-ui/icons';

import { typography, WEIGHT_SEMIBOLD, WEIGHT_NORMAL } from './typography';
import { palette, COLORS } from './palette';

// TODO - these values should be imported from Table component
const ICON_CELL_CLASS = 'iconCell';
const EXPAND_CONTENT_ROW_CLASS = 'expandContentRow';
const EXPAND_CONTENT_CELL_CLASS = 'expandContentCell';

export const CommonInputStyles = {
  borderRadius: 2,
  minHeight: 30,
  backgroundColor: COLORS.WHITE,
  border: `1px solid ${COLORS.GRAY_LIGHT}`,
  '&:hover': {
    borderColor: COLORS.GRAY_DARK,
    backgroundColor: COLORS.BOX_LIGHT,
  },
  '&:focus': {
    backgroundColor: COLORS.WHITE,
    borderColor: COLORS.GRAY_DARK,
  },
};

const InputErrorStyles = {
  borderColor: COLORS.RASPBERRY,
  backgroundColor: '#FFFBFC',
  '&:hover': {
    borderColor: COLORS.RASPBERRY,
  },
};

const InputDisableStyles = {
  backgroundColor: COLORS.BACKGROUND,
  lineHeight: '18px',
  '&:hover': {
    borderColor: COLORS.GRAY_LIGHT,
    backgroundColor: COLORS.BACKGROUND,
  },
};

export const theme = createTheme({
  palette,
  typography,
  props: {
    MuiTabs: {
      indicatorColor: 'primary',
    },
    MuiTab: {
      disableRipple: true,
    },
    MuiExpansionPanelSummary: {
      expandIcon: React.createElement(ExpandMore),
    },
  },
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none !important',
        borderRadius: 2,
        padding: '8px 12px',
        fontSize: 11,
        fontWeight: WEIGHT_SEMIBOLD,
        lineHeight: '14px',
        '&$contained': {
          '&$disabled': {
            color: COLORS.BACKGROUND,
            backgroundColor: COLORS.GRAY_DARK,
          },
        },
        '&$contained:hover': {
          backgroundColor: '#5f5f5f',
        },
        '&$containedPrimary:hover': {
          backgroundColor: '#006cac',
        },
        '&$containedSecondary:hover': {
          backgroundColor: '#1ca9a0',
        },
        '&$sizeSmall': {
          padding: '4px 9px',
          fontSize: 10,
          fontWeight: WEIGHT_SEMIBOLD,
          lineHeight: '12px',
        },
        '&$text&$sizeSmall': {
          padding: '4px 0',
        },
        '&$textPrimary:hover': {
          color: '#016CAC',
        },
        '&$textSecondary:hover': {
          color: '#1ca9a0',
        },
      },
      contained: {
        backgroundColor: COLORS.TEXT_LIGHT,
        color: COLORS.WHITE,
      },
      text: {
        padding: '6px',
        minWidth: 'auto',
        color: COLORS.TEXT_LIGHT,
        '&$textSecondary': {
          color: COLORS.RASPBERRY,
        },
        '$text&:hover': {
          backgroundColor: `${COLORS.TEXT_LIGHT}0A`,
        },
        '&$textPrimary:hover': {
          backgroundColor: `${COLORS.REGAL}0A`,
        },
        '&$textSecondary:hover': {
          color: COLORS.RASPBERRY,
          backgroundColor: `${COLORS.RASPBERRY}0A`,
        },
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: `1px solid ${COLORS.GRAY_LIGHT}`,
      },
    },
    MuiTableRow: {
      hover: {
        '$root&:hover': {
          backgroundColor: COLORS.SKY,
          borderColor: COLORS.GRAY_LIGHT,
        },
      },
      root: {
        [`&$selected:not(.${EXPAND_CONTENT_ROW_CLASS})`]: {
          '&:nth-child(4n), &:nth-child(4n + 1)': {
            [`&, & + .${EXPAND_CONTENT_ROW_CLASS}`]: {
              backgroundColor: COLORS.BOX_LIGHT,
            },
          },
          '&:nth-child(4n + 2), &:nth-child(4n + 3)': {
            [`&, & + .${EXPAND_CONTENT_ROW_CLASS}`]: {
              backgroundColor: COLORS.BOX_DARK,
            },
          },
        },
      },
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
      },
    },
    MuiTableCell: {
      root: {
        ...typography.body1,
        borderBottom: `1px solid ${COLORS.GRAY_LIGHT}`,
        height: 18,
        wordBreak: 'break-all',
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10,
        '&:first-child': {
          paddingLeft: 20,
          wordBreak: 'break-word',
          wordWrap: 'break-word',
        },
        '&:nth-child(2)': {
          wordBreak: 'break-word',
          wordWrap: 'break-word',
        },
        [`&:last-child:not(.${ICON_CELL_CLASS}):not(.${EXPAND_CONTENT_CELL_CLASS})`]: {
          paddingRight: 20,
        },
        [`&:not(.${ICON_CELL_CLASS}):not(.${EXPAND_CONTENT_CELL_CLASS})`]: {
          width: '46px',
          minWidth: '46px',
        },
      },
      head: {
        height: 50,
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: COLORS.WHITE,
      },
      li: {
        opacity: 0.8,
        '& > a': {
          color: COLORS.WHITE,
        },
        '&:last-child': {
          opacity: 1,
        },
      },
      separator: {
        opacity: 0.8,
        '&:nth-last-child(2)': {
          opacity: 1,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$error': {
          ...InputErrorStyles,
          '& input, & textarea': {
            ...InputErrorStyles,
          },
        },
        '&$disabled': {
          ...InputDisableStyles,
          '& input, & textarea': {
            ...InputDisableStyles,
          },
        },
      },
      multiline: {
        padding: 0,
      },
    },
    MuiInput: {
      input: {
        fontSize: 13,
        lineHeight: '18px',
        padding: '6px 12px',
        boxSizing: 'border-box',
        ...CommonInputStyles,
      },
      inputMultiline: {
        minHeight: 30,
        height: 'auto',
      },
    },
    MuiAutocomplete: {
      input: {
        border: 'none!important',
        minHeight: 28,
      },
      inputRoot: {
        ...CommonInputStyles,
        '&[class*="MuiInput-root"]': {
          paddingBottom: 0,
          '&[class*="Mui-disabled"]': {
            ...InputDisableStyles,
            '& input, & textarea': {
              ...InputDisableStyles,
            },
          },
          '&:hover input': {
            borderColor: COLORS.GRAY_DARK,
            backgroundColor: COLORS.BOX_LIGHT,
          },
        },
        '& input': {
          marginLeft: 12,
        },
      },
      endAdornment: {
        paddingTop: 1,
        paddingRight: 12,
      },
      option: {
        height: 36,
        '&:hover': {
          backgroundColor: COLORS.REGAL,
          color: COLORS.WHITE,
        },
      },
      popupIndicator: {
        '& svg': {
          width: 16,
          height: 16,
          color: COLORS.TEXT_LIGHT,
        },
        '&:hover': {
          background: 'none',
        },
      },
    },
    MuiInputLabel: {
      root: {
        marginLeft: 12,
        fontSize: 11,
        lineHeight: '16px',
        color: COLORS.TEXT_LIGHT,
        '&$focused': {
          color: COLORS.TEXT_LIGHT,
        },
        '&$error': {
          color: COLORS.RASPBERRY,
        },
      },
      marginDense: {
        transform: 'translate(0)',
      },
      shrink: {
        transform: 'translate(0)',
      },
    },
    MuiSelect: {
      select: {
        borderRadius: 2,
        backgroundColor: COLORS.WHITE,
        fontSize: 13,
        lineHeight: '18px',
        '&:focus': {
          backgroundColor: COLORS.WHITE,
          borderRadius: 2,
        },
        '&$disabled': {
          backgroundColor: COLORS.GRAY_LIGHT,
          '&:hover': {
            backgroundColor: COLORS.GRAY_LIGHT,
            borderColor: COLORS.GRAY_LIGHT,
          },
        },
      },
      icon: {
        marginRight: 12,
        marginTop: 5,
        width: 16,
        height: 16,
        color: COLORS.TEXT_LIGHT,
      },
      selectMenu: {
        height: undefined,
        minHeight: undefined,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: 11,
        lineHeight: '16px',
        fontWeight: WEIGHT_NORMAL,
        marginLeft: 12,
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: '-9px',
      },
      label: {
        fontSize: 12,
        lineHeight: '18px',
      },
    },
    MuiFormLabel: {
      root: {
        marginLeft: 12,
        fontSize: 11,
        lineHeight: '16px',
        color: COLORS.TEXT_LIGHT,
        '&$focused': {
          color: COLORS.TEXT_LIGHT,
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: COLORS.TEXT_LIGHT,
        color: COLORS.WHITE,
        borderRadius: 2,
        fontWeight: WEIGHT_SEMIBOLD,
        fontSize: 10,
        lineHeight: '16px',
        padding: '3px 7px',
        height: 22,
        '&:hover': {
          backgroundColor: '#5f5f5f',
          '& $deleteIcon': {
            color: COLORS.WHITE,
          },
        },
        '&:focus': {
          backgroundColor: 'red',
        },
      },
      label: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      labelSmall: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      deleteIcon: {
        width: 12,
        height: 12,
        color: COLORS.GRAY_DARK,
        marginRight: -2,
        marginLeft: 3,
      },
      deleteIconSmall: {
        width: 12,
        height: 12,
        marginRight: -2,
        marginLeft: 3,
      },
      deletable: {
        '&:focus': {
          backgroundColor: COLORS.TEXT_LIGHT,
        },
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: '0px 0px 4px rgba(15, 15, 15, 0.16)',
      },
      elevation0: {
        boxShadow: '0px 2px 4px rgba(15, 15, 15, 0.08)',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
      },
      rounded: {
        borderRadius: 2,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 10,
        lineHeight: '16px',
        borderRadius: 2,
        padding: '5px 10px',
        backgroundColor: COLORS.TEXT_LIGHT,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 2,
        width: '100%',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '17px 20px',
        fontWeight: WEIGHT_SEMIBOLD,
        fontSize: 15,
        lineHeight: '20px',
      },
    },
    MuiDialogContentText: {
      root: {
        color: palette.text.primary,
        marginBottom: 0,
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: palette.background.boxLight,
        padding: '12px 24px',
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: '30px',
        },
      },
      dividers: {
        padding: '30px 20px',
      },
    },
    MuiDrawer: {
      paper: {
        padding: '16px 20px',
        minWidth: '33.3%',
        maxWidth: '66.6%',
        '& ul': {
          marginLeft: 50,
        },
      },
    },
    MuiMenuItem: {
      root: {
        height: 36,
        '&:hover': {
          backgroundColor: `${COLORS.REGAL}`,
          color: `${COLORS.WHITE}`,
        },
        '&.danger': {
          color: COLORS.RASPBERRY,
          '&:hover': {
            color: COLORS.WHITE,
            backgroundColor: COLORS.RASPBERRY,
          },
        },
        '&$selected': {
          color: palette.text.primary,
          '&:hover': {
            backgroundColor: `${COLORS.REGAL}`,
            color: COLORS.WHITE,
          },
        },
      },
    },
    MuiTabs: {
      flexContainer: {
        fontWeight: WEIGHT_SEMIBOLD,
        alignItems: 'center',
        borderBottom: `1px solid ${palette.divider}`,
        padding: '0 5px',
        '& button': {
          padding: 0,
          minWidth: 0,
          margin: '0 15px',
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: 12,
      },
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiSnackbarContent: {
      root: {
        color: COLORS.TEXT_DARK,
      },
      message: {
        ...typography.body1,
      },
    },
    MuiAlert: {
      root: {
        flexDirection: 'row',
        backgroundColor: 'transparent !important',
        boxShadow: 'none',
        padding: 4,
        ...typography.body1,
      },
    },
    MuiAlertTitle: {
      root: {
        ...typography.h3,
        marginTop: 0,
      },
    },
    MuiSwitch: {
      root: {
        width: 28,
        height: 18,
        padding: 0,
        margin: 8,
        overflow: 'visible',
      },
      switchBase: {
        padding: 0,
        '&$disabled': {
          color: `${COLORS.BACKGROUND}!important`,
          '& + $track': {
            backgroundColor: `${COLORS.GRAY_DARK}!important`,
            opacity: 1,
          },
        },
        '&$checked': {
          transform: 'translateX(10px)',
          color: `${COLORS.WHITE}!important`,
          '& + $track': {
            opacity: 1,
          },
        },
      },
      thumb: {
        width: 18,
        height: 18,
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.4)',
      },
      track: {
        borderRadius: 18 / 2,
        backgroundColor: palette.action.disabled,
        opacity: 1,
      },
    },
    MuiToggleButtonGroup: {
      root: {
        borderRadius: 2,
        height: 30,
      },
    },
    MuiToggleButton: {
      root: {
        borderRadius: 2,
        fontSize: 11,
        lineHeight: '16px',
        fontWeight: 600,
        border: 'none',
        backgroundColor: COLORS.BACKGROUND,
        '&:hover': {
          backgroundColor: '#d9d9d9',
        },
        '&$selected': {
          backgroundColor: COLORS.REGAL,
          color: COLORS.WHITE,
          '&:hover': {
            backgroundColor: COLORS.REGAL,
          },
        },
      },
    },
    MuiSlider: {
      rail: {
        height: 4,
        opacity: 0.15,
      },
      mark: {
        height: 4,
        width: 4,
        borderRadius: 2,
      },
      thumb: {
        marginTop: -4,
      },
    },
    MuiStepper: {
      root: {
        background: 'rgba(0, 0, 0, 0)',
        width: '100%',
        padding: 0,
        border: 'none',
      },
    },
    MuiStepConnector: {
      active: {
        '& $line': {
          opacity: 1,
        },
      },
      completed: {
        '& $line': {
          opacity: 1,
        },
      },
      line: {
        borderColor: COLORS.WHITE,
        opacity: 0.4,
        borderTopWidth: 1,
        borderRadius: 1,
      },
    },
    MuiStepLabel: {
      label: {
        ...typography.body1,
        color: `${COLORS.WHITE} !important`,
      },
      labelContainer: {
        color: COLORS.WHITE,
      },
    },
    MuiStepIcon: {
      root: {
        border: '1px solid ' + COLORS.WHITE,
        zIndex: 1,
        color: 'transparent',
        width: 30,
        height: 30,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none',
        '&$active': {
          color: COLORS.WHITE,
          border: 'none',
          '& text': {
            fill: COLORS.REGAL,
          },
        },
        '&$completed': {
          color: COLORS.WHITE,
          border: 'none',
          '& text': {
            fill: COLORS.REGAL,
          },
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none',
        },
        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        '&$expanded': {
          minHeight: '48px',
        },
      },
      content: {
        '&$expanded': {
          margin: '12px 0',
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '20px',
        display: 'block',
        fontSize: typography.body1.fontSize,
      },
    },
    MuiOutlinedInput: {
      root: {
        lineHeight: '18px',
        height: '30px',
        ...CommonInputStyles,
      },
      input: {
        boxSizing: 'border-box',
        padding: '6px 12px',
      },
      notchedOutline: {
        border: 'none',
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
  } as Overrides,
});
