import React, { FC } from 'react';
import {
  Tooltip as MuiTooltip,
  Theme,
  makeStyles,
  TooltipProps as muiTooltipProps,
  IconButton,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

interface StyleProps {
  withRipple?: boolean;
  customWidth?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    padding: theme.spacing(0.75, 1),
    maxWidth: ({ customWidth }) => (customWidth ? 'auto' : 178),
  },
  infoIcon: {
    cursor: 'pointer',
    position: 'relative',
    color: ({ withRipple }) => (withRipple ? theme.palette.text.secondary : theme.palette.background.black30),
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  iconButton: {
    position: 'relative',
    bottom: '2px',
    width: '30px',
    height: '30px',
  },
  iconContainer: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: ({ withRipple }) => (withRipple ? 0 : theme.spacing(1.5)),
  },
}));

export interface TooltipProps extends Omit<muiTooltipProps, 'children'> {
  withRipple?: boolean;
  iconDataTest?: string;
  children?: JSX.Element;
  customWidth?: boolean;
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  withRipple,
  iconDataTest = 'info-icon',
  customWidth = false,
  placement = 'right',
  ...props
}): JSX.Element => {
  const { infoIcon, iconButton, tooltip, iconContainer } = useStyles({ withRipple, customWidth });

  const Icon = () => <InfoIcon fontSize="small" className={infoIcon} data-test={iconDataTest} />;

  const renderIcon = () =>
    withRipple ? (
      <IconButton disableRipple classes={{ root: iconButton }}>
        <Icon />
      </IconButton>
    ) : (
      <div className={iconContainer}>
        <Icon />
      </div>
    );

  return (
    <MuiTooltip classes={{ tooltip }} placement={placement} {...props}>
      {children || renderIcon()}
    </MuiTooltip>
  );
};
