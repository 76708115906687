import React, { useState, useCallback, useMemo } from 'react';

import {
  Table,
  AllTableCriteria,
  SortDirection,
  applyCriteriaChange,
  clientSideCriteriaProcessing,
  SearchFiltersValue,
} from '@openx/components/table';
import { useQuery, useMutation } from '@apollo/client';
import { Paper, ConfirmationDialog } from '@openx/components/core';
import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import GetUsersList from 'graphql/query/users/GetUsersList.gql';
import DeleteUser from 'graphql/query/users/DeleteUser.gql';
import { GetUsersListQuery, GetUsersListQueryVariables, User, Account } from 'types/schemaTypes';
import { TABLE_PAGE_SIZE, TOP_BAR_HEIGHT } from 'config';

import { UsersHeader } from './UsersHeader';
import { tableColumns } from './tableColumns';
import { EmptyTablePlaceholder } from 'components/TablePlaceholder/EmptyTablePlaceholder';
import { SearchResultPlaceholder } from 'components/TablePlaceholder/SearchResultsPlaceholder';

export type UserRow = Pick<
  User,
  'id' | 'email_address' | 'role' | 'firebase_id' | 'is_active' | 'last_signin_time' | 'created_date'
> & { name: string } & { account: Pick<Account, 'name'> };

const initialCriteria: AllTableCriteria = {
  pagination: {
    pageSize: TABLE_PAGE_SIZE,
    pageNumber: 1,
    totalCount: 100,
  },
  sort: {
    column: 'last_signin_time',
    direction: SortDirection.DESC,
    sortableColumns: ['name', 'email_address', 'role', 'is_active', 'last_signin_time', 'created_date'],
  },
  filters: {
    byPhrase: { fields: ['name'], phrase: '' },
  },
};

export function UsersList(): JSX.Element {
  const {
    loading,
    error,
    data: { user: users = [] } = {},
  } = useQuery<GetUsersListQuery, GetUsersListQueryVariables>(GetUsersList);

  const [userToDelete, setUserToDelete] = useState<UserRow>();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [deleteUser, { loading: isUserBeingDeleted }] = useMutation(DeleteUser, {
    refetchQueries: ['GetUsersList'],
    awaitRefetchQueries: true,
  });

  const [criteria, setCriteria] = useState<AllTableCriteria>(initialCriteria);

  const handleCriteriaChange = useCallback(
    newCriteria => {
      setCriteria(applyCriteriaChange(criteria, newCriteria));
    },
    [criteria],
  );

  const handleConfirm = async () => {
    setIsConfirmationDialogOpen(false);
    await deleteUser({ variables: { id: userToDelete?.id } });
    await setUserToDelete(undefined);
    enqueueSnackbar('User successfully deleted', { variant: 'success' });
  };

  const rowActions = [
    {
      label: 'Delete',
      onClick: async values => {
        await setUserToDelete(values);
        setIsConfirmationDialogOpen(true);
      },
      danger: true,
      loading: ({ id }) => userToDelete?.id === id && isUserBeingDeleted,
      'data-test': 'user-delete',
    },
  ];

  const getData = useMemo(() => {
    const usersWithName = users.map(user => ({
      ...user,
      name: `${user.first_name} ${user.last_name}`,
    }));

    return clientSideCriteriaProcessing(usersWithName, criteria, { onCriteriaAdjust: setCriteria });
  }, [users, criteria]);

  if (error) throw new Error('Users error');

  const isTableEmpty = !loading && !criteria.filters && getData.length === 0;
  const noSearchResults = !loading && criteria.filters && getData.length === 0;

  if (isTableEmpty) {
    return (
      <UsersHeader handleCriteriaChange={handleCriteriaChange}>
        <Paper padding="none">
          <EmptyTablePlaceholder />
        </Paper>
      </UsersHeader>
    );
  }

  const handleCancel = () => {
    setUserToDelete(undefined);
    setIsConfirmationDialogOpen(false);
  };

  return (
    <>
      <UsersHeader handleCriteriaChange={handleCriteriaChange}>
        <Paper padding="none">
          <Table
            data={getData}
            columns={tableColumns}
            loading={loading}
            onCriteriaChange={handleCriteriaChange}
            highlightRules={(criteria.filters as SearchFiltersValue)?.byPhrase}
            criteria={criteria}
            stickyHeaderPosition={TOP_BAR_HEIGHT}
            fixedLayout
            rowActions={rowActions}
          >
            {noSearchResults && <SearchResultPlaceholder columnsAmount={tableColumns.length} />}
          </Table>
        </Paper>
      </UsersHeader>
      <ConfirmationDialog
        title="Confirmation"
        confirmLabel="Confirm"
        isOpen={isConfirmationDialogOpen}
        onCancel={handleCancel}
        onConfirmed={handleConfirm}
      >
        <Typography variant="body1">
          You are about to delete <b>{userToDelete?.name}</b> user from <b>{userToDelete?.account?.name}</b>, please
          confirm to continue.
        </Typography>
      </ConfirmationDialog>
    </>
  );
}
