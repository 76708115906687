import { ApolloProvider } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';

import { configContext } from './configContext';
import { extractDocumentInstance } from './extractDocumentInstance';
import { makeApolloClient } from './makeApolloClient';
import { ConfigContextValue, GraphQLProviderProps, HandlersConfigMap } from './types';

const messagesConfigMap: HandlersConfigMap = new Map();

export function GraphQLProvider({ children }: GraphQLProviderProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const configContextValue = useMemo<ConfigContextValue>(
    () => ({
      registerLocalErrorHandler: (document, handler) => {
        const documentInstance = extractDocumentInstance(document);
        let handlers = messagesConfigMap.get(documentInstance) || [];
        handlers = handlers.concat(handler);
        messagesConfigMap.set(documentInstance, handlers);
      },
      unregisterLocalErrorHandler: (document, handler) => {
        const documentInstance = extractDocumentInstance(document);
        let handlers = messagesConfigMap.get(documentInstance);

        if (!handlers) {
          console.error(`No such local error handler registered. Handler: ${handler}`);
          return;
        }

        handlers = handlers.filter(h => h !== handler);

        if (handlers.length) {
          messagesConfigMap.set(documentInstance, handlers);
        } else {
          messagesConfigMap.delete(documentInstance);
        }
      },
    }),
    [],
  );

  const printError = useCallback(
    message => {
      enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  const apolloClient = useMemo(
    () => makeApolloClient({ handlersConfigMap: messagesConfigMap, printError }),
    [printError],
  );

  return (
    <ApolloProvider client={apolloClient}>
      <configContext.Provider value={configContextValue}>{children}</configContext.Provider>
    </ApolloProvider>
  );
}
