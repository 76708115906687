import React, { memo } from 'react';
import { SetupActionBar } from 'components/ActionBar';

interface Props {
  loading: boolean;
  onSubmit: () => void;
}

export const SubmitButton = memo(({ loading, onSubmit }: Props): JSX.Element => {
  return (
    <SetupActionBar
      actions={[
        {
          type: 'button',
          label: 'Send Invite',
          props: {
            color: 'primary',
            type: 'submit',
            onClick: onSubmit,
            loading,
          },
        },
      ]}
    />
  );
});
