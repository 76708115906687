import { LazyQueryResult, QueryLazyOptions } from '@apollo/client';
import { FirebaseListenerResult, LoginProps, fetchAuthToken } from 'firebaseIntegration';
import { History } from 'history';
import { CheckUserPermissionsQuery, CheckUserPermissionsQueryVariables, User } from 'types/schemaTypes';

export enum AuthState {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  AUTHENTICATED = 'AUTHENTICATED',
  CHECKING_PERMISSIONS = 'CHECKING_PERMISSIONS',
  PERMISSIONS_CHECKED = 'PERMISSIONS_CHECKED',
  OBTAINING_SESSION = 'OBTAINING_SESSION',
  SESSION_OBTAINED = 'SESSION_OBTAINED',
  SESSION_STALE = 'SESSION_STALE',
}

export interface AuthContext {
  login: (credentials: LoginProps) => Promise<void>;
  reloadSession: () => void;
  logout: () => void;
}

export interface AuthProviderProps {
  children: React.ReactNode;
}

export interface SessionContext
  extends Omit<User, 'create_date' | 'deals' | 'deals_aggregate' | 'is_active' | 'is_deleted'> {
  exchange_session: any;
  email: string;
  uid: string;
  sigview_url?: string;
}

export interface StateActionArguments {
  checkPermissionQuery: LazyQueryResult<CheckUserPermissionsQuery, CheckUserPermissionsQueryVariables>;
  currentPage: string;
  fetchAuthToken: typeof fetchAuthToken;
  firebaseListener: FirebaseListenerResult;
  history: History;
  pageIsPublic: boolean;
  sessionContextValue: SessionContext;
  sessionQuery: LazyQueryResult<any, any>;
  setAuthState: (authState: AuthState) => void;
  skipAuth: boolean;
  triggerCheckPermissionsQuery: (options: QueryLazyOptions<CheckUserPermissionsQueryVariables>) => void;
  triggerSessionQuery: (options?: QueryLazyOptions<any>) => void;
}
