import React, { ReactNode, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Paper as MUIPaper, Theme } from '@material-ui/core';

import { makeActionsSection } from './ActionsSection';
import { defaultPadding, paddingMap, verticalPaddingFactor } from './config';
import { makeHighlightedSection } from './HighlightedSection';
import { makeIndentation } from './Indentation';
import { PaperProps, RenderProp } from './types';

const useStyles = makeStyles<Theme, PaperProps>(theme => ({
  root: {
    position: 'relative',
    marginTop: ({ gutterTop = false }) => (gutterTop ? theme.spacing(3) : 0),
    marginBottom: ({ gutterBottom = false }) => (gutterBottom ? theme.spacing(3) : 0),
    padding: ({ padding = defaultPadding }) =>
      `${paddingMap[padding] * verticalPaddingFactor}px ${paddingMap[padding]}px`,
  },
}));

const makeRenderChildren =
  (children: ReactNode): RenderProp =>
  () =>
    children;

export const Paper = (props: PaperProps): JSX.Element => {
  const { children, className, padding = defaultPadding } = props;
  const paddingValue = paddingMap[padding];
  const classes = useStyles(props);
  const ActionsSection = useMemo(() => makeActionsSection(padding), [padding]);
  const HighlightedSection = useMemo(() => makeHighlightedSection(padding), [padding]);
  const Indentation = useMemo(() => makeIndentation(padding), [padding]);
  const renderChildren: RenderProp = useMemo(
    () => (typeof children === 'function' ? (children as RenderProp) : makeRenderChildren(children)),
    [children],
  );

  return (
    <MUIPaper elevation={0} classes={{ root: classes.root }} data-test={props['data-test']} className={className}>
      {renderChildren({ ActionsSection, HighlightedSection, Indentation, paddingValue })}
    </MUIPaper>
  );
};
