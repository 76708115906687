import React from 'react';
import { Paper } from '@openx/components/core';
import { Box, Typography } from '@material-ui/core';

type Props = {
  allowedAccounts?: any;
};

export const LinkedAccountsReadonly = ({ allowedAccounts }: Props) => {
  const noLinkedAccounts = allowedAccounts.length === 0;

  return (
    <Paper>
      <Box mb={3.8}>
        <Typography variant="h2" data-test="allowed-organizations-title">
          Allowed Organizations
        </Typography>
      </Box>
      <Box marginBottom="6px">
        <Typography variant="caption" color="textSecondary" data-test="no-linked-accounts-message">
          {noLinkedAccounts ? 'No Organization(s) linked to this provider.' : 'Linked Organization(s)'}
        </Typography>
      </Box>
      {!noLinkedAccounts &&
        allowedAccounts.map(organization => (
          <Box marginTop="10px" key={organization.account.id}>
            <Typography variant="body1" data-test="linked-account-name">
              {organization.account.name}
            </Typography>
          </Box>
        ))}
    </Paper>
  );
};
