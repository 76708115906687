import React, { useCallback } from 'react';
import { Typography, Theme, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@openx/components/core';

import { EnhancedTreeData } from 'utils';
import {
  useAudience,
  FIRST_PARTY_FIELD,
  ActionType,
  getOpenSubgroupFirstParty,
  getAccountId,
} from 'modules/audiences/AudienceProvider';

import { IncludeExcludeDropdown } from '../IncludeExcludeDropdown';
import { useFirstPartyOptions } from 'modules/audiences/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    padding: '30px 20px',
    paddingBottom: '0',
  },
  form: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: '20px',
  },
  listTitle: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(),
  },
  divider: {
    marginTop: '30px',
  },
}));

interface Props {
  readonly?: boolean;
}

export const FirstParty = ({ readonly = false }: Props): JSX.Element | null => {
  const classes = useStyles();
  const { state, dispatch } = useAudience();
  const accountId = getAccountId(state);
  const { loading, error, options } = useFirstPartyOptions({ accountId });

  const selectedFirstParty = getOpenSubgroupFirstParty(state);

  const handleSelectChange = useCallback(
    (option: EnhancedTreeData) => {
      const newFirstPraty = [...selectedFirstParty, option];
      dispatch({
        type: ActionType.SET_FIRST_PARTY,
        payload: { segments: { [FIRST_PARTY_FIELD]: newFirstPraty } },
      });
    },
    [dispatch, selectedFirstParty],
  );

  if (loading) {
    return (
      <div className={classes.root}>
        <Loader />
      </div>
    );
  }

  if (error) {
    throw new Error('Query Error');
  }

  return (
    <div className={classes.root} data-test="1p-dropdown">
      <Typography variant="h3" gutterBottom data-test="1p-title" className={classes.title}>
        Direct Audience Segments
      </Typography>

      {!readonly && (
        <div className={classes.form}>
          <IncludeExcludeDropdown
            label="Uploaded Segments"
            placeholder="[ Select Uploaded Segment ]"
            onClick={handleSelectChange}
            options={options}
            selected={selectedFirstParty}
          />
        </div>
      )}
      <Divider className={classes.divider} />
    </div>
  );
};
