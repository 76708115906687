import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Checkbox } from '@openx/components/core';
import { ExportTypes } from 'modules/providers/constants';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-4px',
  },
}));

type Props = {
  allowedExportTypes?: any;
  isEdit?: boolean;
};

const allExportTypes = {
  [ExportTypes.OA_MATCH]: 'OA Match',
  [ExportTypes.BIDSTREAM]: 'Bidstream',
  [ExportTypes.BIDSTREAM_PLUS]: 'Bidstream Plus',
};

export const ExportTypesList = ({ allowedExportTypes = [], isEdit = false }: Props) => {
  const { flex } = useStyles();

  return (
    <>
      {Object.entries(allExportTypes).map(([exportType, label]) => (
        <Box className={flex} key={exportType} data-test="allowed-export-type">
          <Checkbox
            checked={allowedExportTypes.includes(exportType)}
            disabled={!isEdit}
            data-test="export-type-readonly-checkbox"
          />
          <Typography variant="body1" data-test="export-type-readonly-label">
            {label}
          </Typography>
        </Box>
      ))}
    </>
  );
};
