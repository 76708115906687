import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BaseRow } from '../tableHelpers';
import { TableRow, TableRowProps } from './TableRow';
import { ShowMoreButton } from './ShowMoreButton';
import { Loader } from '@openx/components/core';

export interface TableRowTreeProps<RowT extends BaseRow> extends TableRowProps<RowT> {
  rowData: RowT;
  treeChildrenKey?: string;
  isForcedExpanded?: boolean;
  depth?: number;
  treeLevelItems?: number;
  fetchTreeChildren?: (data: RowT) => Promise<RowT[]>;
  updateChildren?: (data: RowT) => void;
}
const useStyles = makeStyles((depth: number) => ({
  rowColor: {
    backgroundColor: `rgba(0, 0, 0, ${depth * 0.02})`,
  },
  rowMouse: {
    cursor: 'default',
  },
}));

export function TableRowTree<RowT extends BaseRow>(props: TableRowTreeProps<RowT>): JSX.Element {
  const {
    rowData,
    isForcedExpanded = false,
    onRowClick,
    updateChildren,
    treeChildrenKey = 'children',
    depth = 0,
    treeLevelItems = 50,
    fetchTreeChildren,
  } = props;
  const classes = useStyles(depth);

  const [showItems, setShowItems] = useState(treeLevelItems);
  const [children, setChildren] = useState(rowData[treeChildrenKey] || []);
  const [loading, setLoading] = useState(false);

  const [expanded, setExpanded] = useState<boolean>(isForcedExpanded);

  const updateRowChildren = useCallback(
    (updatedChild: RowT) => {
      const sortedArray = [...(children?.filter(child => child.id !== updatedChild.id) || []), updatedChild].sort(
        (a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
          return -1;
        },
      );

      setChildren(sortedArray);
    },
    [children],
  );

  // useEffect(() => {
  //   if (isForcedExpanded && children.length === 0) {
  //     setLoading(true);
  //     fetchTreeChildren?.(rowData)
  //       .then(data => {
  //         setChildren(data);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [fetchTreeChildren, isForcedExpanded, rowData, children]);

  const onClick = useCallback(
    async (data: RowT, e: React.SyntheticEvent) => {
      // Check attribute expand row only when expand IconButton is clicked
      const attr = (e.target as HTMLElement).dataset.expandicon;
      const { parentNode } = e.target as HTMLElement;
      const parentAttr = parentNode && (parentNode as HTMLElement).dataset.expandicon;

      if ([attr, parentAttr].indexOf('expandRow') !== -1) {
        if (fetchTreeChildren && children.length === 0) {
          setLoading(true);
          fetchTreeChildren(rowData).then(data => {
            setLoading(false);
            setChildren(data);
          });
        }
        setExpanded(expanded => !expanded);
      } else {
        onRowClick && onRowClick(data, e);
      }
      return;
    },
    [children, onRowClick, rowData],
  );

  const childrenToRender = useMemo(() => {
    const childrenToRender =
      children &&
      children
        .slice(0, showItems)
        .map((child, id) => (
          <TableRowTree {...props} key={id} rowData={child} depth={depth + 1} updateChildren={updateRowChildren} />
        ));
    if (children && children.length > showItems) {
      childrenToRender.push(
        <ShowMoreButton key="show-more" depth={depth + 1} onClick={() => setShowItems(showItems + treeLevelItems)} />,
      );
    }

    return childrenToRender;
  }, [children, depth, props, showItems, treeLevelItems, updateRowChildren, rowData]);

  const additionalRenderParams = {
    depth: depth,
    expanded,
    isFetching: loading,
  };

  return (
    <>
      <TableRow
        {...props}
        onRowClick={onClick}
        data-test="table-row-expand"
        customClassNames={classNames(classes.rowMouse, { [classes.rowColor]: depth })}
        rowData={{ ...rowData, ...additionalRenderParams, updateChildren }}
      />
      {expanded && childrenToRender}
    </>
  );
}
