import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';

import { PageHeader, ListHeaderContent } from '@openx/components/header';
import { CriteriaChange, CriteriaDimension, SearchFilters } from '@openx/components/table';
import { useSideDrawer } from 'context';

interface UsersHeaderProps {
  children: React.ReactNode;
  handleCriteriaChange: (props: CriteriaChange<CriteriaDimension.FILTERS>) => void;
}

const UsersHeaderRaw = ({ children, handleCriteriaChange }: UsersHeaderProps): JSX.Element => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();

  const headerFilters = () => (
    <ListHeaderContent
      filtersComponent={SearchFilters}
      filtersProps={{
        byPhraseFields: ['name', 'email_address'],
        onFiltersChange: filters => {
          handleCriteriaChange({ dimension: CriteriaDimension.FILTERS, value: filters });
        },
        inputPlaceholder: 'Search User',
      }}
      actions={[
        {
          label: 'New User',
          color: 'primary',
          onClick: () => {
            history.push('/users/create');
          },
        },
      ]}
    />
  );

  return (
    <PageHeader
      title="Users List"
      titlePrefix="OpenAudience"
      render={headerFilters}
      openDrawer={openDrawer}
      handleDrawerOpen={handleDrawerOpen}
      menuItems={menuItems}
      token={token}
    >
      {children}
    </PageHeader>
  );
};

export const UsersHeader = memo(UsersHeaderRaw, (prevProps, nextProps) => isEqual(prevProps, nextProps));
