import { getEnvironmentConfigs } from 'environmentConfigs';
import { initialize as initializeGoogleAnalytics } from './google';
import { initialize as initializeHotjar } from './hotjar';
import { initialize as initializeRollbar } from './rollbar';
import { initializePendo } from './pendo';

export async function initialize() {
  try {
    const { env } = await getEnvironmentConfigs();
    if (env === 'prod') {
      initializeGoogleAnalytics();
      initializeHotjar();
      initializeRollbar();
      initializePendo();
    }
  } catch (e) {
    console.error('Failed on initializing analytics tools:', e);
    return;
  }
}
