import { COLORS } from './palette';

export const WEIGHT_NORMAL = 400;
export const WEIGHT_SEMIBOLD = 600;

export const typography = {
  fontSize: 13,
  fontWeightMedium: WEIGHT_SEMIBOLD,
  fontWeightBold: WEIGHT_SEMIBOLD,
  useNextVariants: true,
  fontFamily: '"Open Sans", sans-serif',
  h1: {
    fontSize: 15,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '22px',
  },
  h2: {
    fontSize: 17,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '22px',
    '&:before': {
      content: '""',
      display: 'block',
      width: 12,
      borderTop: `1px solid ${COLORS.REGAL}`,
      marginBottom: 9,
    },
    '&$gutterBottom': {
      marginBottom: 30,
    },
  },
  h3: {
    fontSize: 15,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '20px',
  },
  body1: {
    fontSize: 13,
    fontWeight: WEIGHT_NORMAL,
    lineHeight: '18px',
  },
  body2: {
    fontSize: 11,
    fontWeight: WEIGHT_NORMAL,
    lineHeight: '16px',
  },
  caption: {
    fontSize: 10,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '16px',
  },
  button: {
    fontSize: 10,
    fontWeight: WEIGHT_SEMIBOLD,
    lineHeight: '16px',
  },
};
