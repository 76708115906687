import { AllTableCriteria } from './tableCriteria';

export type QueryVar = {
  limit?: number | null;
  offset?: number | null;
  order_by?:
    | {
        [column: string]: Record<string, unknown> | string | null | undefined;
      }
    | {
        [column: string]: Record<string, unknown> | string | null | undefined;
      }[]
    | null;
  where?: {
    _and?:
      | [
          { _or?: { [column: string]: { _ilike: string } }[] } | null,
          { _and?: { [column: string]: Record<string, unknown> }[] } | null,
        ]
      | [{ _and?: { [column: string]: Record<string, unknown> }[] } | null]
      | [{ _or?: { [column: string]: { _ilike: string } }[] } | null]
      | null;
  } | null;
};

export type FiltersT = {
  byPhrase?: {
    fields: string[];
    phrase: string;
  };
  byValue?: [
    {
      field: string;
      values: string[];
    },
  ];
};

const convertSort = (sort: string) => {
  if (sort === 'asc') return 'asc_nulls_first';
  if (sort === 'desc') return 'desc_nulls_last';
  return sort;
};

export const sortStage = sort => {
  if (sort?.column && sort.direction) {
    const queryDirection = convertSort(sort.direction);
    if (
      (sort.column === 'account' && sort.valueDerivers?.account !== undefined) ||
      (sort.column === 'provider' && sort.valueDerivers?.provider !== undefined)
    )
      return {
        [sort.column]: {
          name: queryDirection,
        },
      };
    return { [sort.column]: queryDirection };
  }
  return {};
};

export const filterSubstageValue = filters => {
  const byValue = { _and: [] as { [column: string]: Record<string, unknown> }[] };
  if (filters?.byValue) {
    byValue._and = filters.byValue
      .filter(valFil => !!valFil.field && (valFil.values || []).length > 0)
      .map(({ field, values }) => {
        if (field === 'account' || field === 'provider') {
          return {
            [field]: {
              name: {
                _in: values,
              },
            },
          };
        }
        if (field === 'direct_audience_provider' || field === 'sub_type') {
          return {
            [field]: {
              _in: values.map(val => val?.toLowerCase() || 'openaudience'),
            },
          };
        }
        if (field === 'estimated_expiry_date' || field === 'expiration_date') {
          return {
            [field]: {
              _lte: values[0],
              _gte: values[1],
            },
          };
        }
        if (field === 'created_by') {
          return {
            user_id: {
              // select only correct uuid
              _in: values.filter(value => {
                if (!value || typeof value !== 'string') {
                  return;
                }

                const uidRegexp = new RegExp(
                  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                );

                return uidRegexp.test(value);
              }),
            },
          };
        }
        return {
          [field]: {
            _in: values,
          },
        };
      });
  }
  return byValue;
};

//from serverSideCriteriaProcessing.ts
export const filterSubstagePhrase = filters => {
  const byPhrase = { _or: [] as { [column: string]: { _ilike: string } }[] };
  if (filters?.byPhrase) {
    const { fields, phrase } = filters.byPhrase;

    if (phrase && fields) {
      byPhrase._or = fields.map(fieldKey => ({
        [fieldKey]: {
          _ilike: `%${phrase}%`,
        },
      }));
    }
  }
  return byPhrase;
};

//from serverSideCriteriaProcessing.ts
export const filterStage = filters => {
  const byPhrase = filterSubstagePhrase(filters);
  const byValue = filterSubstageValue(filters);

  const _and = [] as Record<string, unknown>[];
  if (byPhrase._or.length > 0) {
    _and.push(byPhrase);
  }
  if (byValue._and.length > 0) {
    _and.push(byValue);
  }

  const where: any = _and.length > 0 ? { _and } : undefined;
  return where;
};

export function serverSideCriteriaProcessing<T extends QueryVar = QueryVar, F extends FiltersT = FiltersT>(
  criteria: AllTableCriteria<F>,
  initSort?: AllTableCriteria<F>['sort'],
): T {
  const { filters, sort, pagination } = criteria;
  const queryVar: QueryVar = {
    limit: pagination?.pageSize ?? null,
    offset: 0,
    order_by: null,
    where: null,
  };
  const pageIndex = (pagination?.pageNumber ?? 1) - 1;

  queryVar.where = filterStage(filters);

  const defaultSort = initSort && sortStage(initSort);
  const currentSort = sortStage(sort);

  queryVar.order_by = defaultSort ? [currentSort, defaultSort] : [currentSort];

  // paginationStage()
  if (pagination) {
    if (pageIndex * pagination.pageSize < pagination.totalCount) {
      queryVar.offset = pagination.pageSize * pageIndex;
    }
  }

  return queryVar as T;
}
