import React, { useCallback, useMemo, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { ButtonColor, Paper } from '@openx/components/core';

import {
  Table,
  SortDirection,
  AllTableCriteria,
  applyCriteriaChange,
  clientSideCriteriaProcessing,
} from '@openx/components/table';
import { apiKeys as tableColumns } from './tableColumns';

import { GetOrgApiKeysQuery, GetOrgApiKeysQueryVariables, User } from 'types/schemaTypes';
import { TOP_BAR_HEIGHT } from 'config';

import { useMutation, useQuery } from '@apollo/client';
import GetOrgApiKeys from 'graphql/query/organizations/GetOrgApiKeys.gql';
import DeleteUser from 'graphql/query/users/DeleteUser.gql';

import { useSnackbar } from 'notistack';
import { DeleteApiKeyModal } from './DeleteApiKeyModal';

const useStyles = makeStyles(theme => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3.8),
  },
  table: {
    margin: '-20px',
  },
  keyName: {
    fontWeight: 'bold',
  },
}));

export type ApiKeysRow = Pick<User, 'id' | 'first_name' | 'expires_at' | 'created_date'>;

const initialCriteria: AllTableCriteria = {
  sort: {
    column: 'created_date',
    direction: SortDirection.DESC,
    sortableColumns: ['first_name', 'expires_at', 'created_date'],
  },
};

type Props = {
  organizationId: string;
};

type ToDelete = {
  id: string;
  name: string;
} | null;

export const APIKeysSection = ({ organizationId }: Props) => {
  const { header, table, keyName } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [criteria, setCriteria] = useState<AllTableCriteria>(initialCriteria);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [toDelete, setToDelete] = useState<ToDelete>(null);

  const {
    data: { user: apiKeys = [] } = {},
    error: getKeysError,
    loading: keysLoading,
  } = useQuery<GetOrgApiKeysQuery, GetOrgApiKeysQueryVariables>(GetOrgApiKeys, {
    variables: { organizationId },
    fetchPolicy: 'no-cache',
  });

  const [deleteKey, { loading: deleteLoading }] = useMutation(DeleteUser, {
    refetchQueries: ['GetApiKeys'],
    awaitRefetchQueries: true,
  });

  const handleCriteriaChange = useCallback(
    newCriteria => {
      setCriteria(applyCriteriaChange(criteria, newCriteria));
    },
    [criteria],
  );

  const handleOpenDeleteDialog = (id, name) => {
    setDeleteVisible(true);
    setToDelete({ id, name });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteVisible(false);
    setToDelete(null);
  };

  const handleDeleteKey = async id => {
    try {
      await deleteKey({ variables: { id } });
      enqueueSnackbar('API Key deleted successfully', { variant: 'success' });
      setToDelete(null);
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Failed to delete API Key', { variant: 'error' });
    }
  };

  const secondaryRowAction = {
    label: 'delete',
    color: 'danger' as ButtonColor,
    'data-test': 'delete-api-key',
    onClick: ({ id, first_name }) => handleOpenDeleteDialog(id, first_name),
  };

  const getData = useMemo(() => {
    return clientSideCriteriaProcessing(apiKeys, criteria, { onCriteriaAdjust: setCriteria });
  }, [apiKeys, criteria]);

  const showTable = (apiKeys.length < 1 && keysLoading) || (apiKeys.length > 0 && !keysLoading);

  if (getKeysError) {
    console.error(getKeysError);
    enqueueSnackbar('Failed to fetch API Keys list', { variant: 'error' });
  }

  return (
    <Paper data-test="api-keys-table">
      <div className={header}>
        <Typography variant="h2" data-test="api-keys-title">
          API Keys
        </Typography>
      </div>
      <Box className={table}>
        {showTable ? (
          <Table
            data={getData}
            columns={tableColumns}
            loading={keysLoading}
            stickyHeaderPosition={TOP_BAR_HEIGHT}
            secondaryRowAction={secondaryRowAction}
            onCriteriaChange={handleCriteriaChange}
            criteria={criteria}
            fixedLayout
          />
        ) : (
          <Box margin="20px">
            <Typography variant="body2" color="textSecondary" data-test="no-api-keys-message">
              No API Keys were generated.
            </Typography>
          </Box>
        )}
      </Box>
      <DeleteApiKeyModal
        title="Delete API key"
        isOpen={deleteVisible && !!toDelete}
        onConfirmed={() => handleDeleteKey(toDelete?.id)}
        onClose={handleCloseDeleteDialog}
        loading={deleteLoading}
      >
        <Box>
          <Typography variant="body2" color="textSecondary" data-test="delete-key-message">
            You're about to delete API key
          </Typography>

          <Typography color="textPrimary" className={keyName} noWrap data-test="key-to-delete-name">
            {toDelete?.name}
          </Typography>
        </Box>
      </DeleteApiKeyModal>
    </Paper>
  );
};
