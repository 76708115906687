import { SegmentStatus } from '../constants';
import { firstLetterUppercase } from '@openx/utils';

const mapSubTypeToName = {
  lal: 'Lookalike',
  uploaded: 'Uploaded',
};

export function createFullName(name: string): string {
  return `OpenAudience > 1P > ${name}`;
}

export function mapSubType(subtype?: string | null): string | null {
  return (subtype && mapSubTypeToName[subtype]) || null;
}

export function mapStatus(gqlStatus?: string | null): {
  label: string;
  status: string;
} | null {
  if (gqlStatus) {
    const label = gqlStatus
      .split('_')
      .map(value => firstLetterUppercase(value))
      .join(' ');
    // I categorize all AudienceStatus into 4 categories with each mapped to one StatusIndicator status
    if (SegmentStatus.READY === (gqlStatus as SegmentStatus)) {
      return {
        label,
        status: 'active',
      };
    } else if (SegmentStatus.ERROR === (gqlStatus as SegmentStatus)) {
      return {
        label,
        status: 'error',
      };
    } else if (SegmentStatus.EXPIRED === (gqlStatus as SegmentStatus)) {
      return {
        label,
        status: 'warning',
      };
    } else {
      return {
        label,
        status: 'inactive',
      };
    }
  } else {
    return null;
  }
}
