import React, { useMemo, memo, FC } from 'react';
import { Loader } from '@openx/components/core';

import { filterBySubtype } from 'utils';
import { Title as ListTitle } from 'components/OptionsList';
import { TreeData, segmentsToTreeData } from 'utils/segmentsToTreeData';
import {
  useAudience,
  ActionType,
  getOpenSubgroupsLocation,
  LOCATION_FIELD,
  LocationType,
} from 'modules/audiences/AudienceProvider';
import { useLocationOptions } from 'modules/audiences/hooks';

import { ListType, NestedList, SelectionList } from '../../OptionsList';

interface LocationOptionsListProps {
  readonly: boolean;
  listType: ListType;
  values: TreeData[];
}

export const LocationOptionsList: FC<LocationOptionsListProps> = memo(
  ({ readonly, listType, values }): JSX.Element | null => {
    const { state, dispatch } = useAudience();
    const {
      loading,
      error,
      options: { [LocationType.CONGRESSIONAL]: congressionalDistrictsOptions },
    } = useLocationOptions();

    const locationStates = useMemo(() => filterBySubtype(values, LocationType.STATE), [values]);
    const zipCodes = useMemo(() => filterBySubtype(values, LocationType.ZIP), [values]);
    const dmaStates = useMemo(() => filterBySubtype(values, LocationType.DMA), [values]);
    const congressionalStates = useMemo(() => filterBySubtype(values, LocationType.CONGRESSIONAL), [values]);

    const handleRemoveClick = (optionToDelete: TreeData) => {
      const selectedOptions = getOpenSubgroupsLocation(state);
      const newOptions = selectedOptions.filter(option => option.id !== optionToDelete.id);

      dispatch({ type: ActionType.SET_LOCATION, payload: { segments: { [LOCATION_FIELD]: newOptions } } });
    };

    if (loading) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    if (error) {
      throw new Error('Query Error');
    }

    return (
      <>
        {locationStates.length > 0 && (
          <>
            <ListTitle gutterTop data-test="location-type-title">
              STATE
            </ListTitle>
            <SelectionList items={locationStates} type={listType} readonly={readonly} onDelete={handleRemoveClick} />
          </>
        )}
        {dmaStates.length > 0 && (
          <>
            <ListTitle gutterTop data-test="location-type-title">
              DMA
            </ListTitle>
            <SelectionList items={dmaStates} type={listType} readonly={readonly} onDelete={handleRemoveClick} />
          </>
        )}
        {zipCodes.length > 0 && (
          <>
            <ListTitle gutterTop data-test="location-type-title">
              ZIP-CODE
            </ListTitle>
            <SelectionList items={zipCodes} type={listType} readonly={readonly} onDelete={handleRemoveClick} />
          </>
        )}
        {congressionalStates.length > 0 && (
          <>
            <ListTitle gutterTop data-test="location-type-title">
              CONGRESSIONAL DISTRICT
            </ListTitle>
            <NestedList
              items={segmentsToTreeData(congressionalStates)}
              type={listType}
              readonly={readonly}
              onDelete={handleRemoveClick}
              tree={congressionalDistrictsOptions}
            />
          </>
        )}
      </>
    );
  },
);
