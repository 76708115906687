import classNames from 'classnames';
import React from 'react';
import { MenuItem, Divider, makeStyles } from '@material-ui/core';
import { Tooltip } from '@openx/components/core';

interface MenuButtonItemProps {
  disabled: boolean;
  isAllowedMessage: string;
  topDivider?: boolean;
  danger?: boolean;
  label: string;
  onClick: (e: React.SyntheticEvent) => void;
  onHover?: (e: React.SyntheticEvent) => void;
}

const useStyles = makeStyles(() => ({ menuItem: { minWidth: 150 } }));

export default function MenuButtonItem(props: MenuButtonItemProps): JSX.Element {
  const classes = useStyles();
  const { disabled, topDivider, onClick, onHover, label, isAllowedMessage, danger } = props;
  const className = classNames({ [classes.menuItem]: true, danger });

  return (
    <Tooltip title={isAllowedMessage}>
      <div>
        {topDivider && <Divider />}
        <MenuItem
          onClick={e => onClick(e)}
          onMouseEnter={onHover}
          disabled={disabled}
          className={className}
          data-test={props['data-test'] || 'actions-item'}
        >
          {label}
        </MenuItem>
      </div>
    </Tooltip>
  );
}
