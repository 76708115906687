import React, { ReactNode } from 'react';
import classNames from 'classnames';
import ClearIcon from '@material-ui/icons/Clear';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@openx/components/core';

interface StyleProps {
  readonly: boolean;
  indentationLevel: number;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  item: {
    display: 'flex',
    minHeight: 28,
    alignItems: 'center',
    margin: '0 -20px',
    paddingRight: 20,
    paddingLeft: ({ indentationLevel }) => 20 + indentationLevel * 30,
  },
  content: {
    flexGrow: 1,
    marginTop: ({ readonly }) => (readonly ? -1 : 0),
  },
  hoverable: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      '& .hoverable': {
        visibility: 'visible',
      },
    },
  },
  actions: {
    visibility: 'hidden',
  },
  removeButton: {
    marginLeft: theme.spacing(1.25),
  },
  icon: {
    marginRight: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',
  },
  actionItem: {
    '& :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}));

interface Props {
  children: ReactNode;
  icon?: ReactNode;
  indentationLevel?: number;
  onClick?: () => void;
  clickLabel?: string | ReactNode;
  onDelete?: () => void;
  isNavOnly?: boolean;
  readonly?: boolean;
  dataTest?: string;
}

export function HoverableItem(props: Props): JSX.Element {
  const {
    icon,
    onClick,
    clickLabel,
    onDelete,
    children,
    indentationLevel = 0,
    isNavOnly = false,
    readonly = false,
    dataTest = 'include-exclude-row',
  } = props;
  const classes = useStyles({ readonly, indentationLevel });

  return (
    <div className={classNames(classes.item, { [classes.hoverable]: !readonly })} data-test={dataTest}>
      {icon && <div className={classes.icon}>{icon}</div>}
      <div className={classes.content}>{children}</div>
      {(onClick || onDelete) && (
        <div className={classNames('hoverable', classes.actionItem, classes.actions)}>
          {onClick && !readonly && !isNavOnly && (
            <Button variant="text" onClick={onClick} data-test="include-exclude-button">
              {clickLabel}
            </Button>
          )}
          {onDelete && !readonly && !isNavOnly && (
            <Button variant="text" className={classes.removeButton} data-test="item-delete-button" onClick={onDelete}>
              <ClearIcon fontSize="small" />
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
