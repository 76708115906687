import { EnhancedTreeData } from 'utils';
import { DetermineNextFoldedStateArgs, GetVisibleItemsArgs } from '../types';
import { foldedListItemsCount, nextPageItemsCount } from './constants';

export const determineNextFoldedState = ({
  items,
  listFolded,
  lastItemsCount,
}: DetermineNextFoldedStateArgs): boolean =>
  listFolded || (lastItemsCount > foldedListItemsCount && items.length <= foldedListItemsCount);

export const getVisibleItems = ({ items, listFolded, page = 1 }: GetVisibleItemsArgs): EnhancedTreeData[] =>
  items.slice(0, listFolded ? foldedListItemsCount : page * nextPageItemsCount);
