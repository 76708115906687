import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import createLog from 'graphql/mutation/error/createLog.gql';

import ActivateAudience from 'graphql/mutation/audiences/ActivateAudience.gql';
import CreateAudience from 'graphql/mutation/audiences/CreateAudience.gql';
import {
  ActivateAudienceMutation,
  ActivateAudienceMutationVariables,
  CreateAudienceMutation,
  CreateAudienceMutationVariables,
  Audience,
} from 'types/schemaTypes';

import { NestedErrorCode, useLocalErrorHandling } from '../../graphql';
import { State } from '../AudienceProvider';
import { mapAudienceToCreateMutation, validateAudienceForm } from '../utils';
import { getCurrentUser } from 'firebaseIntegration/utils';

type CreateAndActivateAudience = (state: State) => Promise<Audience['id']>;
type Options = { loading: boolean };

export const useCreateAndActivate = (accountId: string, userId: string): [CreateAndActivateAudience, Options] => {
  const { enqueueSnackbar } = useSnackbar();

  const [createAudience, { loading: creatingLoading }] = useMutation<
    CreateAudienceMutation,
    CreateAudienceMutationVariables
  >(CreateAudience, {
    refetchQueries: ['GetAudienceFeed', 'GetAudienceTemplates'],
  });

  const [activateAudience, { loading: activatingLoading }] = useMutation<
    ActivateAudienceMutation,
    ActivateAudienceMutationVariables
  >(ActivateAudience, {
    refetchQueries: ['GetAudienceFeed', 'GetAudienceTemplates'],
  });

  const [pushLog] = useMutation(createLog);

  useLocalErrorHandling(
    CreateAudience,
    useCallback(
      error => {
        if (error.code !== NestedErrorCode.JOINT_UNIQUENESS_VIOLATION || error.path !== 'object.name') return false;
        enqueueSnackbar('Audience with such name already exists', { variant: 'error' });
        return true;
      },
      [enqueueSnackbar],
    ),
  );

  const saveAndActivateAudience = useCallback(
    async (state: State) => {
      try {
        validateAudienceForm(state);
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error?.message, { variant: 'error' });

          const location = window.location.href;
          const user = await getCurrentUser();
          await pushLog({
            variables: {
              type: error,
              location: location,
              msg: error?.message,
              name: user?.displayName,
              email: user?.email, // eslint-disable-line
            },
          });
          throw error;
        }
      }

      const audience = mapAudienceToCreateMutation(accountId, userId, state);

      const response = await createAudience({
        variables: {
          object: audience,
        },
      });

      const audienceId = response?.data?.insert_audience_one?.id;

      if (!audienceId) {
        const location = window.location.href;
        const user = await getCurrentUser();
        await pushLog({
          variables: {
            type: 'error',
            location: location,
            msg: 'Error when creating Audience',
            name: user?.displayName,
            email: user?.email,
          },
        });
        throw enqueueSnackbar('Error when creating Audience', { variant: 'error' });
      }

      await activateAudience({ variables: { id: audienceId } });

      return audienceId;
    },
    [accountId, userId, createAudience, activateAudience, enqueueSnackbar],
  );

  return [saveAndActivateAudience, { loading: creatingLoading || activatingLoading }];
};
