import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

import { Paper } from '@openx/components/core';
import { AudienceShareForm, Audience, mapAudienceSharesToList } from 'modules/audiences/components/AudienceShare';
import { groupAudienceSharesByFee } from 'modules/audiences/utils';
import { useValidationInterface } from 'modules/audiences/components/AudienceShare/types';
import { AudienceShare } from 'types/schemaTypes';

import { AllowedOrganizationsList } from './AllowedOrganizationsList';

interface Props {
  readonly?: boolean;
  audience: Audience;
  audienceShares: AudienceShare[];
  useValidation?: useValidationInterface;
}

export const AllowedOrganizations: FC<Props> = ({
  readonly = false,
  audience,
  audienceShares,
  useValidation,
}): JSX.Element | null => {
  const publisherList = groupAudienceSharesByFee(audienceShares);

  if (!publisherList.length && readonly) {
    return null;
  }

  return (
    <Paper data-test="allowed-organizations">
      <Typography variant="h2" gutterBottom data-test="allowed-organizations-title">
        Allowed Organizations
      </Typography>

      <Typography variant="body1">
        <b>The audience is shared with the following organization(s):</b>
      </Typography>

      {readonly && <AllowedOrganizationsList publisherList={publisherList} />}

      {!readonly && !!useValidation && <AudienceShareForm useValidation={useValidation} audience={audience} />}
    </Paper>
  );
};
