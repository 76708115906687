import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { Typography, FormControlLabel, Theme } from '@material-ui/core';

import { firstLetterUppercase } from '@openx/utils';
import { Paper, Checkbox, Loader } from '@openx/components/core';
import GetFeatureFlags from 'graphql/query/organizations/GetFeatureFlags.gql';

interface Props {
  readonly: boolean;
  onChange?: (value: boolean) => (checked: boolean) => {};
  value?: boolean;
  accountFeatureFlags: any;
  loading: boolean;
  featureFlagsState?: any;
}

const useStyles = makeStyles<Theme>(theme => ({
  featureFlagsBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
}));

const prettifyFeatueFlagName = value =>
  value
    .split('_')
    .map(value => firstLetterUppercase(value))
    .join(' ');

export const FeatureFlags = ({ accountFeatureFlags, loading, readonly, featureFlagsState, onChange }: Props) => {
  const { featureFlagsBlock } = useStyles();

  const { loading: featureFlagsLoading, data: { feature_flags: featureFlags = [] } = {} } = useQuery(GetFeatureFlags);

  const isFeatureFlagPresent = useMemo(
    () =>
      readonly
        ? featureFlags.reduce(
            (acc, { value: featureFlag }) => ({
              ...acc,
              [featureFlag]: !!accountFeatureFlags.find(({ value }) => value === featureFlag),
            }),
            {},
          )
        : featureFlagsState,
    [accountFeatureFlags, featureFlags, featureFlagsState],
  );

  const handleChange =
    value =>
    ({ target: { checked } }) => {
      !!onChange && onChange(value)(checked);
    };

  return (
    <Paper>
      <Typography variant="h2" data-test="feature-flags-title">
        {`Feature Flag${featureFlags.length > 1 ? 's' : ''}`}
      </Typography>
      <div className={featureFlagsBlock}>
        {loading || (featureFlagsLoading && <Loader />)}
        {!loading &&
          !featureFlagsLoading &&
          featureFlags.map(({ value }) => (
            <FormControlLabel
              key={value}
              disabled={readonly}
              control={
                <Checkbox
                  data-test="feature-flag-checkbox"
                  onChange={handleChange(value)}
                  checked={isFeatureFlagPresent[value] || false}
                />
              }
              label={prettifyFeatueFlagName(value)}
            />
          ))}
      </div>
    </Paper>
  );
};
