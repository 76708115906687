import React, { FC, memo } from 'react';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router-dom';

import { PageHeader, ListHeaderContent } from '@openx/components/header';
import {
  SearchFilters,
  supportedFields,
  CriteriaDimension,
  CriteriaChange,
  FieldOption,
} from '@openx/components/table';
import { useSideDrawer } from 'context';

interface AudiencesHeaderProps {
  children: React.ReactNode;
  handleCriteriaChange: (props: CriteriaChange<CriteriaDimension.FILTERS>, isInitialCall?: boolean) => void;
  statuses: string[];
  providers: string[];
  audienceAccounts: string[];
  users: FieldOption[];
  isInternalOxUser: boolean;
}

export const AudiencesHeaderRaw: FC<AudiencesHeaderProps> = ({
  children,
  handleCriteriaChange,
  statuses,
  providers,
  audienceAccounts,
  users,
  isInternalOxUser = false,
}): JSX.Element => {
  const { handleDrawerOpen, openDrawer, menuItems, token } = useSideDrawer();
  const history = useHistory();

  const HeaderFilters = () => (
    <ListHeaderContent
      filtersComponent={SearchFilters}
      filtersProps={{
        advanced: true,
        byPhraseFields: ['name'],
        byValueFields: [
          { fieldName: supportedFields.AUDIENCE_STATUS, options: statuses.map(status => ({ value: status })) },
          {
            fieldName: supportedFields.AUDIENCE_PROVIDER,
            options: providers
              .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
              .map(provider => ({ value: provider })),
          },
          ...(isInternalOxUser
            ? [
                {
                  fieldName: supportedFields.AUDIENCE_ACCOUNT,
                  options: audienceAccounts
                    .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
                    .map(audienceAccount => ({ value: audienceAccount })),
                },
              ]
            : []),
          {
            fieldName: supportedFields.USER,
            options: users,
          },
        ],
        onFiltersChange: (filters, isInitialCall) => {
          handleCriteriaChange({ dimension: CriteriaDimension.FILTERS, value: filters }, isInitialCall);
        },
        inputPlaceholder: 'Search Audiences',
      }}
      actions={[
        {
          label: 'New Audience',
          color: 'primary',
          onClick: () => {
            history.push('/audiences/create');
          },
        },
      ]}
    />
  );

  return (
    <PageHeader
      title="Audiences List"
      titlePrefix="OpenAudience"
      render={HeaderFilters}
      openDrawer={openDrawer}
      handleDrawerOpen={handleDrawerOpen}
      menuItems={menuItems}
      token={token}
    >
      {children}
    </PageHeader>
  );
};

export const AudiencesHeader = memo(AudiencesHeaderRaw, (prevProps, nextProps) => isEqual(prevProps, nextProps));
