import React, { useState, FC } from 'react';
import { makeStyles, Typography, ButtonBase } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { AutoComplete, Button, theme, COLORS } from '@openx/components/core';

import { filtersConfig, supportedFields, FieldWithOptions } from './config';

const useStyles = makeStyles({
  header: {
    width: '84px',
    height: '30px',
    position: 'absolute',
    right: '0',
    top: '0px',
    zIndex: 2,
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
    display: 'flex',
    alignItems: 'center',
  },
  counter: {
    color: '#000',
    marginLeft: '2px',
  },
  caption: {
    color: theme.palette.text.secondary,
  },
  root: {
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    top: '29px',
    padding: '17px 20px 20px 20px',
    background: '#fff',
    borderTop: '1px solid #E6E6E6',
    boxShadow: '0px 2px 4px rgba(15, 15, 15, 0.08)',
    borderRadius: '0px 0px 2px 2px',
  },
  actionsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  applyButton: { marginLeft: '10px' },
  autocomplete: {
    '& input': {
      '&:focus': {
        boxShadow: 'none !important',
      },
    },
    marginBottom: '15px',
  },
  buttonBase: {
    padding: '2px',
    marginLeft: '7px',
    borderRadius: '50%',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgba(0, 0, 0, 0.54)',
    '&hover': {
      backgroundColor: 'transparent',
    },
  },
  arrowIcon: { width: '16px', height: '16px' },
});

export interface DropdownProps {
  filterCount?: number;
  fieldsToFilterBy?: FieldWithOptions[];
  filters: {
    selectedStatuses?: string[];
    selectedProviders?: string[];
    selectedAccounts?: string[];
    selectedUsers?: string[];
  };
  onApplyFilters: (
    filterCount: number,
    statuses: string[],
    providers: string[],
    accounts: string[],
    users: string[],
  ) => void;
}

export const Dropdown: FC<DropdownProps> = ({
  filterCount = 0,
  fieldsToFilterBy = [],
  onApplyFilters = () => {},
  filters: { selectedStatuses = [], selectedProviders = [], selectedAccounts = [], selectedUsers = [] },
}): JSX.Element => {
  const classes = useStyles();

  const [_selectedStatuses, setSelectedStatuses] = useState(selectedStatuses);
  const [_selectedProviders, setSelectedProviders] = useState(selectedProviders);
  const [_selectedAccounts, setSelectedAccounts] = useState(selectedAccounts);
  const [_selectedUsers, setSelectedUsers] = useState(selectedUsers);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handlers = {
    [supportedFields.AUDIENCE_STATUS]: (_, value) => setSelectedStatuses(value),
    [supportedFields.SEGMENT_STATUS]: (_, value) => setSelectedStatuses(value),
    [supportedFields.DEAL_STATUS]: (_, value) => setSelectedStatuses(value),
    [supportedFields.SEGMENT_PROVIDER]: (_, value) => setSelectedProviders(value),
    [supportedFields.AUDIENCE_PROVIDER]: (_, value) => setSelectedProviders(value),
    [supportedFields.DEAL_ACCOUNT]: (_, value) => setSelectedAccounts(value),
    [supportedFields.AUDIENCE_ACCOUNT]: (_, value) => setSelectedAccounts(value),
    [supportedFields.SEGMENT_ACCOUNT]: (_, value) => setSelectedAccounts(value),
    [supportedFields.USER]: (_, value) => setSelectedUsers(value),
  };

  const values = {
    [supportedFields.AUDIENCE_STATUS]: _selectedStatuses,
    [supportedFields.SEGMENT_STATUS]: _selectedStatuses,
    [supportedFields.DEAL_STATUS]: _selectedStatuses,
    [supportedFields.SEGMENT_PROVIDER]: _selectedProviders,
    [supportedFields.AUDIENCE_PROVIDER]: _selectedProviders,
    [supportedFields.DEAL_ACCOUNT]: _selectedAccounts,
    [supportedFields.AUDIENCE_ACCOUNT]: _selectedAccounts,
    [supportedFields.SEGMENT_ACCOUNT]: _selectedAccounts,
    [supportedFields.USER]: _selectedUsers,
  };

  const handleClearFilters = () => {
    setSelectedStatuses([]);
    setSelectedProviders([]);
    setSelectedAccounts([]);
    setSelectedUsers([]);
  };

  const toggleDropdown = () => {
    setSelectedStatuses(selectedStatuses);
    setSelectedProviders(selectedProviders);
    setSelectedAccounts(selectedAccounts);
    setSelectedUsers(selectedUsers);

    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen(true);
    }
  };

  const handleApplyFilters = () => {
    const filterCount = _selectedStatuses.length + _selectedProviders.length + _selectedAccounts.length;

    onApplyFilters &&
      onApplyFilters(filterCount, _selectedStatuses, _selectedProviders, _selectedAccounts, _selectedUsers);
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="button" className={classes.caption}>
          FILTERS:
        </Typography>
        <Typography data-test="filter-count" variant="caption" className={classes.counter}>
          {filterCount}
        </Typography>

        <ButtonBase
          centerRipple
          className={classes.buttonBase}
          onClick={toggleDropdown}
          data-test="search-dropdown-button"
        >
          {!isDropdownOpen && <KeyboardArrowDownIcon htmlColor={COLORS.TEXT_LIGHT} className={classes.arrowIcon} />}
          {isDropdownOpen && <KeyboardArrowUpIcon htmlColor={COLORS.TEXT_LIGHT} className={classes.arrowIcon} />}
        </ButtonBase>
      </div>

      {isDropdownOpen && (
        <div className={classes.root} data-test="searchbar-dropdown">
          {fieldsToFilterBy.map(({ fieldName, options }) => {
            const visibleOptions = options.filter(option => {
              if (typeof option?.isHidden === 'boolean' || option?.isHidden === undefined) return !option?.isHidden;

              if (fieldName === 'user') {
                return !option.isHidden(_selectedAccounts);
              }

              return !option.isHidden();
            });

            return (
              <AutoComplete
                key={fieldName}
                {...filtersConfig[fieldName]}
                value={values[fieldName]}
                onChange={handlers[fieldName]}
                className={classes.autocomplete}
                options={visibleOptions.map(option => option?.displayName ?? option.value)}
                fullWidth
                disableClearable
                multiple
                limitTags={4}
                margin="dense"
              />
            );
          })}
          <div className={classes.actionsContainer}>
            <Button data-test="clear-filters-button" variant="text" onClick={handleClearFilters}>
              CLEAR FILTERS
            </Button>
            <Button
              data-test="apply-filters-button"
              color="primary"
              className={classes.applyButton}
              onClick={handleApplyFilters}
            >
              APPLY
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
