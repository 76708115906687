import React, { useMemo, useReducer, Dispatch } from 'react';

import { audienceReducer } from './audienceReducer';
import fields from './fields';
import {
  NormalizedDefinitionItem,
  MatchingOperator,
  OpenSubgroup,
  RelationType,
  Action,
  Predefined,
  ExportType,
  AudienceShare,
  BulkOption,
} from './types';

export interface State {
  [fields.ACCOUNT_ID_FIELD]: string | null;
  [fields.NAME_FIELD]: string;
  [fields.DESCRIPTION_FIELD]: string | null;
  [fields.STATUS_FIELD]?: string;
  [fields.EXPORT_TYPE_FIELD]?: ExportType;
  [fields.CTV_FIELD]: boolean;
  [fields.AUDIENCE_SHARES_FIELD]?: AudienceShare[];
  [fields.UPDATED_DATE_FIELD]?: string;
  [fields.DIRECT_PROVIDER_FIELD]?: string;
  [fields.INCLUDE_FIELD]: NormalizedDefinitionItem;
  [fields.EXCLUDE_FIELD]: NormalizedDefinitionItem;
  [fields.OPEN_SUBGROUP_FIELD]: OpenSubgroup;
  [fields.IS_DRAWER_OPEN_FIELD]: boolean;
  [fields.SELECTED_AGE]: number | undefined;
  [fields.IS_STATE_SYNCED_FIELD]: boolean;
  [fields.BULK_ACTION_FIELD]?: BulkOption;
  [fields.AUDIENCE_INSIGHTS]?: any;
  [fields.TEMPLATE_NAME_FIELD]?: string;
  [fields.EXPORT_TARGETS_FIELD]?: string[];
}

export const defaultShareRow = {
  recipients: [],
  fee: null,
  revenueMethod: 'PoM',
  currency: null,
};

export const defaultBulkOption = {
  label: '',
  value: '',
  function: () => {},
};

export const defaultDemographics = {
  [fields.AGE_FIELD]: [],
  [fields.GENDER_FIELD]: [],
};

export const defaultPredefined: Predefined = {
  [fields.RTG_FIELD]: [],
  [fields.THIRD_PARTY_FIELD]: [],
  [fields.SITE_CATEGORY_FIELD]: [],
};

export const defaultSegments = {
  ...defaultDemographics,
  [fields.LOCATION_FIELD]: [],
  [fields.FIRST_PARTY_FIELD]: [],
  [fields.DIRECT_SEGMENT_FIELD]: [],
  [fields.PREDEFINED_FIELD]: defaultPredefined,
};

export const defaultSubgroup = { [fields.INDEX_FIELD]: 1, [fields.SEGMENTS_FIELD]: defaultSegments };

export const defaultGroup = {
  [fields.OPERATOR_FIELD]: MatchingOperator.INTERSECTION,
  [fields.INDEX_FIELD]: 1,
  [fields.SUBGROUPS_FIELD]: [],
  [fields.SEGMENTS_FIELD]: defaultSegments,
};

export const defaultInitialState: State = {
  [fields.ACCOUNT_ID_FIELD]: null,
  [fields.NAME_FIELD]: '',
  [fields.DESCRIPTION_FIELD]: '',
  [fields.EXPORT_TYPE_FIELD]: undefined,
  [fields.DIRECT_PROVIDER_FIELD]: undefined,
  [fields.CTV_FIELD]: false,
  [fields.AUDIENCE_SHARES_FIELD]: [defaultShareRow],
  [fields.INCLUDE_FIELD]: {
    [fields.OPERATOR_FIELD]: MatchingOperator.INTERSECTION,
    [fields.GROUPS_FIELD]: [defaultGroup],
  },
  [fields.EXCLUDE_FIELD]: {
    [fields.GROUPS_FIELD]: [defaultGroup],
  },
  [fields.OPEN_SUBGROUP_FIELD]: {
    [fields.GROUP_INDEX_FIELD]: 0,
    [fields.SUBGROUP_INDEX_FIELD]: 0,
    [fields.SEGMENTS_FIELD]: defaultSegments,
    [fields.RELATION_FIELD]: RelationType.INCLUDE,
  },
  [fields.IS_DRAWER_OPEN_FIELD]: false,
  [fields.SELECTED_AGE]: undefined,
  [fields.IS_STATE_SYNCED_FIELD]: false,
  [fields.BULK_ACTION_FIELD]: defaultBulkOption,
  [fields.TEMPLATE_NAME_FIELD]: undefined,
};

interface AudienceProviderProps {
  children: React.ReactNode;
  initialState?: State;
}

export interface AudienceContextProps {
  state: State;
  dispatch: Dispatch<Action>;
}

const contextDefaultValues: AudienceContextProps = {
  state: defaultInitialState,
  dispatch: () => {},
};

export const AudienceContext = React.createContext(contextDefaultValues);

export function AudienceProvider({ children, initialState }: AudienceProviderProps) {
  const [state, dispatch] = useReducer(audienceReducer, initialState || defaultInitialState);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return <AudienceContext.Provider value={contextValue}>{children}</AudienceContext.Provider>;
}
