import React, { useCallback, useMemo, useState } from 'react';
import { Theme, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { intersection, isEmpty } from 'lodash';
import classNames from 'classnames';

import { AutoCompleteVirtualize, AutoComplete, HighlightedPhrase, Tooltip } from '@openx/components/core';
import { filterTreeFromSelected, filterTreeBySearch, EnhancedTreeData } from 'utils';

import { Propensity } from '../PredefinedAudienceSegments/PropensityDropdown';

type Props = {
  options: EnhancedTreeData[];
  onClick: (option: EnhancedTreeData) => void;
  selected: EnhancedTreeData[];
  label?: string;
  placeholder?: string;
  nested?: boolean;
  open?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    width: 'fit-content!important',
    minWidth: 'calc((56vw * 0.375) - 40px)',
  },
  option: {
    padding: 0,
    margin: 0,
    display: 'block',
    height: 'auto',
    '&:hover': {
      backgroundColor: '#fff',
      color: theme.palette.text.primary,
    },
  },
  optionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 36,
    paddingRight: 13,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.boxLight,
      '& .hoverable': {
        visibility: 'visible',
      },
    },
  },
  optionContainerDisabled: {
    opacity: 0.6,
  },
  optionTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    height: 18,
    width: 18,
    color: theme.palette.text.secondary,
  },
}));

export const IncludeExcludeDropdown = React.memo((props: Props): JSX.Element => {
  const { options, onClick, selected, label, placeholder, nested, open } = props;
  const { option, popper, optionContainer, optionContainerDisabled, optionTitle, icon } = useStyles();
  const [expandedSubOptions, setExpandedSubOptions] = useState({});
  const [searchValue, setSearchValue] = useState<string>('');
  const isSearchActive = searchValue.length > 2;
  const optionKey = useCallback(option => option.value, []);
  const isExpanded = useCallback(option => !!expandedSubOptions[optionKey(option)], [expandedSubOptions, optionKey]);
  const hasChildren = useCallback(option => !isEmpty(option.children), []);
  const filteredOptions = useMemo(() => filterTreeFromSelected(options, selected), [options, selected]);

  const optionsHaveChildren = options.some(hasChildren);

  const Component = optionsHaveChildren ? AutoComplete : AutoCompleteVirtualize;

  const renderOptions = useCallback(
    (option: EnhancedTreeData, state: object, spacingFactor = 0) => {
      const isOptionExpanded = isExpanded(option);
      const hasOptionChildren = hasChildren(option);
      const Icon = isOptionExpanded ? ExpandMore : ChevronRight;

      const propensityList = [Propensity.LOW, Propensity.MEDIUM, Propensity.HIGH];

      const is3pEXPOption = !hasOptionChildren && option.type === '3p' && option.sub_type === 'EXP';
      const matchSegmetGroupId = selected.filter(({ segment_group_id: i }) => i === option.segment_group_id);
      const selectedPropensities = matchSegmetGroupId.map(i => i.propensity);
      const isMatchedPropensity = intersection(selectedPropensities, propensityList);
      const isOptionDisabled = is3pEXPOption && isMatchedPropensity.length >= 2;

      const itemOnClickHandler = e => {
        if (isOptionDisabled) {
          e.stopPropagation();
          return false;
        }
        hasOptionChildren
          ? setExpandedSubOptions({ ...expandedSubOptions, [optionKey(option)]: !isOptionExpanded })
          : onClick(option);

        e.stopPropagation();
      };

      return (
        <>
          <div
            data-test="include-exclude-item"
            onClick={itemOnClickHandler}
            className={classNames(optionContainer, { [optionContainerDisabled]: isOptionDisabled })}
          >
            <Tooltip
              title={
                isOptionDisabled ? (
                  'You cannot select all three propensities at the same time for this segment, up to two propensities are allowed'
                ) : isSearchActive ? (
                  <HighlightedPhrase searchPhrase={searchValue.split(' ')}>
                    {option.description || ''}
                  </HighlightedPhrase>
                ) : (
                  option.description || ''
                )
              }
            >
              <div
                data-test="item-name"
                className={optionTitle}
                style={{ paddingLeft: 15 * spacingFactor + (!hasOptionChildren ? 20 : 8) }}
              >
                {hasOptionChildren && <Icon className={icon} />}
                {isSearchActive ? (
                  <HighlightedPhrase searchPhrase={searchValue.split(' ')}>
                    {option.calculated_full_name}
                  </HighlightedPhrase>
                ) : (
                  option.title
                )}
              </div>
            </Tooltip>
          </div>

          {hasOptionChildren && isOptionExpanded && (
            <>
              {option.children!.map(subOption => (
                <span key={subOption.key}>{renderOptions(subOption, state, spacingFactor + 1)}</span>
              ))}
            </>
          )}
        </>
      );
    },
    [
      icon,
      optionContainer,
      optionTitle,
      expandedSubOptions,
      hasChildren,
      isExpanded,
      isSearchActive,
      optionKey,
      searchValue,
      onClick,
    ],
  );

  const onOptionsClose = () => {
    setExpandedSubOptions({});
  };

  const onSearchValueChange = (_, newSearchValue) => {
    setSearchValue(newSearchValue);
  };

  const filterOptionsBySearchValue = useCallback(
    options => {
      if (!isSearchActive) {
        return options;
      }
      return filterTreeBySearch(options, searchValue);
    },
    [isSearchActive, searchValue],
  );

  return (
    <Component
      classes={{ option, popper }}
      options={filteredOptions}
      filterOptions={filterOptionsBySearchValue}
      inputValue={searchValue}
      onInputChange={onSearchValueChange}
      getOptionLabel={({ title }) => title}
      disableCloseOnSelect
      open={open}
      renderOption={renderOptions}
      onClose={onOptionsClose}
      textFieldProps={{ label, placeholder }}
      PopperComponent={props => <Popper {...props} placement="bottom-start" />}
      ListboxProps={isSearchActive && nested ? { width: 700 } : {}}
    />
  );
});
