import React, { useCallback, useMemo, useState } from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { Paper, AutoComplete, Loader } from '@openx/components/core';
import { useQuery } from '@apollo/client';
import { Account, GetOrganizationsListQuery, GetOrganizationsListQueryVariables } from 'types/schemaTypes';
import GetOrganizationsList from 'graphql/query/organizations/GetOrganizationsList.gql';
import { useAudience, ActionType, getAccountId } from 'modules/audiences/AudienceProvider';
import { Alert } from '@material-ui/lab';

type Option = { displayName: string; value: string };

type Props = {
  disabled?: boolean;
  onOrganizationChange?: (option: Option) => void;
};

const useStyles = makeStyles(theme => ({
  infoAlert: {
    backgroundColor: '#EBF4FA',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 2),
    '& .MuiAlert-root': {
      alignItems: 'center',
      display: 'flex',
    },
  },
}));

export const WorkOnBehalf = ({ disabled = false, onOrganizationChange = () => {} }: Props): JSX.Element => {
  const classes = useStyles();
  const { state, dispatch } = useAudience();
  const {
    loading: organizationsLoading,
    error: organizationsError,
    data: { account: organizations = [] } = {},
  } = useQuery<GetOrganizationsListQuery, GetOrganizationsListQueryVariables>(GetOrganizationsList);

  const options: Option[] = useMemo(
    () => organizations.map(org => ({ displayName: org.name, value: org.id })),
    [organizations],
  );

  const accountId = getAccountId(state);
  const value: Option | undefined = useMemo(
    () => options.find(option => option.value === accountId),
    [accountId, options],
  );

  const handleOrganizationChange = useCallback(
    (option: Option) => {
      onOrganizationChange(option);
      dispatch({ type: ActionType.RESET_AUDIENCE });
      dispatch({ type: ActionType.SET_ACCOUNT_ID, payload: { account_id: option.value } });
    },
    [dispatch],
  );

  if (organizationsLoading) {
    return (
      <Paper>
        <Loader />
      </Paper>
    );
  }

  if (organizationsError) {
    throw new Error('Could not get data from the server');
  }

  return (
    <Paper>
      <Typography variant="h2" gutterBottom data-test="work-on-behalf-title">
        Work on Behalf
      </Typography>
      {!disabled && (
        <div className={classes.infoAlert}>
          <Alert severity="info" data-test="work-on-behalf-alert-message">
            Changing the Organization will remove all audience options, details and criteria inputs
          </Alert>
        </div>
      )}
      <Grid item>
        <AutoComplete
          value={value}
          options={options}
          getOptionLabel={option => option?.displayName || ''}
          textFieldProps={{
            label: 'Organization Name',
            placeholder: '[ Select Organization ]',
            margin: 'dense',
          }}
          onChange={(e, value) => handleOrganizationChange(value)}
          data-test="organization-name"
          disabled={disabled}
          disableClearable
        />
      </Grid>
    </Paper>
  );
};
