import React, { FC, useState, useMemo, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { isEqual } from 'lodash';

import { Loader } from '@openx/components/core';
import { useAudience, ActionType, getAudienceShares, defaultShareRow } from 'modules/audiences/AudienceProvider';
import ShareRecipientsOptions from 'graphql/query/options/ShareRecipientsOptions.rest.gql';

import { AudienceShareRow } from './AudienceShareRow';
import { Recipient, Audience, useValidationInterface, Fee, RevenueMethod, TRevenueMethod } from './types';

interface AudienceShareFormProps {
  audience: Audience;
  loading?: boolean;
  useValidation: useValidationInterface;
  fromEditPage?: boolean;
}

export const AudienceShareForm: FC<AudienceShareFormProps> = ({
  audience,
  loading = false,
  useValidation,
  fromEditPage = false,
}) => {
  const { state, dispatch } = useAudience();
  const [recipientOptions, setRecipientOptions] = useState([]);
  const audienceShares = useMemo(() => getAudienceShares(state), [state]);

  const { purgeRemovedRow } = useValidation;

  const { loading: recipientsLoading, data: { audienceShareRecipients: { objects = [] } = {} } = {} } = useQuery(
    ShareRecipientsOptions,
    { variables: { limit: 100000 } },
  );

  const recipientList = useMemo(() => (objects || []).map(({ id, name }) => ({ id, name })), [objects]);

  const selectedRecipients = useMemo(
    () => audienceShares.reduce<Recipient[]>((acc, { recipients }) => [...acc, ...recipients], []),
    [audienceShares],
  );

  const handleRevenueMethodChange = (audienceShareIndex: number) => (audienceShareRevenueMethod: TRevenueMethod) => {
    dispatch({
      type: ActionType.SET_AUDIENCE_SHARE_REVENUE_METHOD,
      payload: { audienceShareIndex, audienceShareRevenueMethod },
    });
  };

  const handleFeeChange = (audienceShareIndex: number) => (audienceShareFee: Fee) => {
    dispatch({ type: ActionType.SET_AUDIENCE_SHARE_FEE, payload: { audienceShareIndex, audienceShareFee } }); // TODO:
  };

  const handleRecipientChange = (audienceShareIndex: number) => (_, audienceShareRecipients: Recipient[]) => {
    if (audienceShareRecipients.length) {
      dispatch({
        type: ActionType.SET_AUDIENCE_SHARE_RECIPIENTS,
        payload: { audienceShareIndex, audienceShareRecipients },
      });
    } else {
      dispatch({ type: ActionType.REMOVE_AUDIENCE_SHARE_ROW, payload: { audienceShareIndex } });
    }
  };

  const handleRemoveRow = useCallback(
    (audienceShareIndex: number) => () => {
      purgeRemovedRow();

      dispatch({ type: ActionType.REMOVE_AUDIENCE_SHARE_ROW, payload: { audienceShareIndex } });
    },
    [audience],
  );

  useEffect(() => {
    if (audienceShares.every(({ recipients }) => recipients.length >= 1) && !!recipientOptions.length) {
      dispatch({ type: ActionType.ADD_DEFAULT_AUDIENCE_SHARE });
    }
  }, [audienceShares, recipientOptions]);

  useEffect(() => {
    const newRecipientOptions = recipientList.filter(
      ({ id }) => selectedRecipients.map(({ id }) => id).indexOf(id) === -1,
    );

    if (!loading && !isEqual(recipientOptions, newRecipientOptions)) {
      setRecipientOptions(newRecipientOptions);
    }
  }, [selectedRecipients, recipientList, loading, recipientOptions]);

  useEffect(() => {
    const newShares = audienceShares.filter(row => !isEqual(row, defaultShareRow));

    const shouldUpdate = !isEqual(audienceShares, newShares);

    if (!recipientOptions.length && shouldUpdate) {
      dispatch({ type: ActionType.REMOVE_DEFAULT_AUDIENCE_SHARE });
    }
  }, [recipientOptions]);

  if (loading || recipientsLoading) {
    return <Loader />;
  }

  return (
    <>
      {audienceShares.map(({ recipients, fee = null, revenueMethod = null, shareId = null }, index) => (
        <AudienceShareRow
          fromEditPage={fromEditPage}
          useValidation={useValidation}
          rowIndex={index}
          audiences={[{ audience, fee, revenueMethod, shareId }]}
          key={JSON.stringify(recipients)}
          recipientOptions={recipientOptions}
          recipients={recipients}
          handleFeeChange={handleFeeChange(index)}
          handleRecipientChange={handleRecipientChange(index)}
          handleRemoveRow={handleRemoveRow(index)}
          handleRevenueMethodChange={handleRevenueMethodChange(index)}
        />
      ))}
    </>
  );
};
