import { useCallback } from 'react';
import { useQuery } from '@apollo/client';

import GetProviders from 'graphql/query/audiences/GetProviders.gql';
import GetOrganization from 'graphql/query/organizations/GetOrganization.gql';
import {
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  GetProvidersQuery,
  GetProvidersQueryVariables,
  Provider,
} from 'types/schemaTypes';
import { useSession } from 'modules/auth/AuthProvider';

export interface ProvidersMap {
  [key: string]: Provider;
}

export type GetProviderParam = string | undefined | null;

export const DEFAULT_PROVIDER_NAME = 'OpenAudience';
export const DEFAULT_PROVIDER_ID = 'openaudience';

export interface UseProvidersResponse {
  providers: Pick<Provider, 'id' | 'name' | 'allowed_export_types'>[];
  allowedProviders: Provider[];
  getProviderName: (provider_name: GetProviderParam) => string;
  getAllowedExportTypes: (provider_name: GetProviderParam) => string[];
  loadingProviders: boolean;
}

export function useProviders(accountId?: string | null): UseProvidersResponse {
  const { data: { provider = [] } = {}, loading: loadingProviders } = useQuery<
    GetProvidersQuery,
    GetProvidersQueryVariables
  >(GetProviders);

  const { account: { id: sessionAccountId, allowed_providers = [] } = {} } = useSession();
  const { data: { account_by_pk } = {} } = useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganization,
    { skip: !accountId || accountId === sessionAccountId, variables: { id: accountId } },
  );

  const providers = provider; // All providers
  const allowedProviders =
    accountId && accountId !== sessionAccountId
      ? account_by_pk?.allowed_providers?.map(allowedProvider => allowedProvider.provider)
      : allowed_providers;
  const providersMap = providers.reduce((current, next) => ({ ...current, [next.id]: next }), {});

  const getProviderName = useCallback(
    (provider_name: GetProviderParam): string => providersMap[provider_name || '']?.name || '',
    [providersMap],
  );
  const getAllowedExportTypes = useCallback(
    (provider_name: GetProviderParam): string[] => providersMap[provider_name || '']?.allowed_export_types || [],
    [providersMap],
  );

  return {
    loadingProviders,
    providers,
    allowedProviders: (allowedProviders ?? []) as unknown as Provider[],
    getProviderName,
    getAllowedExportTypes,
  };
}
