import React from 'react';
import { Alert } from '@material-ui/lab';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { isExpired, isSoonToBeExpired, segmentDaysRemaining } from 'utils/expiryDates';

import { audienceSoonWillBeExpiredText } from '../../constants';

type Props = {
  estimatedExpiryDate: string | null;
  isArchived?: boolean;
  status?: string;
  segments: {
    segmentStatus: string | null | undefined;
    expiryDate: string | null | undefined;
    isArchived: boolean | null | undefined;
  }[];
};

const useStyles = makeStyles(theme => ({
  errorAlert: {
    backgroundColor: '#FCEEF2',
    padding: theme.spacing(0, 4),
  },
  warningAlert: {
    backgroundColor: '#FDF9F0',
    padding: theme.spacing(0, 4),
  },
}));

export const AudienceAlerts = (props: Props) => {
  const classes = useStyles();

  const { estimatedExpiryDate, isArchived, status, segments } = props;

  const showAudienceExpiredAlert = isExpired(status);
  const showAudienceExpireSoonAlert = isSoonToBeExpired(estimatedExpiryDate, status);

  const showArchivedSegmentAlert = segments.some(({ isArchived }) => isArchived);
  const showExpiredSegmentAlert = segments.some(({ segmentStatus }) => isExpired(segmentStatus));
  const showSegmentExpireSoonAlert = segments.some(({ expiryDate, segmentStatus }) =>
    isSoonToBeExpired(expiryDate, segmentStatus),
  );
  const segmentDaysRemain = segmentDaysRemaining(segments);

  const noAlertsToDisplay =
    !showArchivedSegmentAlert &&
    !showAudienceExpiredAlert &&
    !showAudienceExpireSoonAlert &&
    !showExpiredSegmentAlert &&
    !showSegmentExpireSoonAlert &&
    !isArchived;

  if (noAlertsToDisplay) {
    return null;
  }

  const showExpireSoon = showAudienceExpireSoonAlert || showSegmentExpireSoonAlert;

  return (
    <>
      {isArchived && (
        <div className={classes.errorAlert}>
          <Alert severity="error">This audience has been archived and can no longer be used.</Alert>
        </div>
      )}
      {showAudienceExpiredAlert && (
        <div className={classes.errorAlert}>
          <Alert severity="error">
            Audience hasn’t been refreshed in more than 90 days and is expired, please edit and save to reactivate.
          </Alert>
        </div>
      )}
      {showExpiredSegmentAlert && (
        <div className={classes.errorAlert}>
          <Alert severity="error">Segment used in the audience is expired.</Alert>
        </div>
      )}
      {showArchivedSegmentAlert && (
        <div className={classes.errorAlert}>
          <Alert severity="error">Segment used in the audience is archived.</Alert>
        </div>
      )}
      {showExpireSoon && (
        <div className={classes.warningAlert}>
          <Alert severity="warning">
            {showAudienceExpireSoonAlert && `${audienceSoonWillBeExpiredText}`}
            {showSegmentExpireSoonAlert &&
              `\n Segment used in an audience is going to expire within the next ${segmentDaysRemain} days`}
          </Alert>
        </div>
      )}
    </>
  );
};
