import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@openx/components/core';
import AddIcon from '@material-ui/icons/Add';

import { IconCell } from './IconCell';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

type ExpandIconCellProps = {
  expanded: boolean;
  onClick?: (expanded: boolean) => void;
  ExpandIcon?: JSX.Element;
  CollapseIcon?: JSX.Element;
};

export function ExpandIconCell({
  expanded,
  onClick,
  CollapseIcon = <KeyboardArrowUp />,
  ExpandIcon = <KeyboardArrowDown />,
}: ExpandIconCellProps): JSX.Element {
  return (
    <IconCell style={{ padding: 0 }}>
      <Button data-test="expand-button" variant="text" onClick={() => onClick?.(expanded)}>
        {expanded ? CollapseIcon : ExpandIcon}
      </Button>
    </IconCell>
  );
}

const useStyles = makeStyles({
  root: {
    visibility: 'hidden',
    borderLeft: '1px solid',
  },
});

export function ExpandCellPlaceholder(): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Button variant="text">
        <AddIcon />
      </Button>
    </div>
  );
}
