import React, { useCallback } from 'react';
import { Box, Typography, makeStyles, FormControlLabel, FormControl, Switch } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import { Checkbox } from '../Checkbox';
import { Loader } from '../Loader';
import { CopyToClipboard } from '../CopyToClipboard';

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 6,
    color: theme.palette.text.disabled,
  },
  emptyValue: {
    color: theme.palette.background.black30,
  },
  box: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  label: {
    fontSize: '13px',
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
  copy: {
    verticalAlign: 'middle',
  },
  span: {
    display: 'block',
  },
}));

interface FieldDetailsProps {
  title: string;
  value?: string[] | string | boolean | null | number;
  checkbox?: boolean;
  loading?: boolean;
  copy?: boolean;
  toggle?: boolean;
  dateFormat?: string;
}

export function FieldDetails({ title, value, checkbox, loading, copy, toggle, dateFormat }: FieldDetailsProps) {
  const classes = useStyles();

  const isEmpty = !(Array.isArray(value) ? value.length : value);

  const renderValue = useCallback(
    (loading, copy, value, title, dateFormat) => {
      const renderList = Array.isArray(value) ? value : [value];
      if (loading) return <Loader size={20} noPadding />;
      if (!isEmpty && copy) {
        return (
          <Typography
            data-test={`${title.toLowerCase().replace(/\s/g, '-')}-value`}
            variant="body1"
            className={classNames({ [classes.emptyValue]: isEmpty })}
          >
            {renderList.map((el, idx) => (
              <span className={classes.span} key={idx}>
                {el} <CopyToClipboard text={value as string} iconSize="medium" containerClass={classes.copy} />
              </span>
            ))}
          </Typography>
        );
      }

      return (
        <Typography
          data-test={`${title.toLowerCase().replace(/\s/g, '-')}-value`}
          variant="body1"
          className={classNames({ [classes.emptyValue]: isEmpty })}
        >
          {!isEmpty
            ? renderList.map((el, idx) => (
                <span className={classes.span} key={idx}>
                  {dateFormat ? moment.utc(el).format(dateFormat) : el}
                </span>
              ))
            : 'N/A'}
        </Typography>
      );
    },
    [classes, isEmpty],
  );

  if (checkbox) {
    return (
      <FormControl data-test={title.toLowerCase().replace(/\s/g, '-')} fullWidth>
        <FormControlLabel
          control={<Checkbox checked={!isEmpty} disabled />}
          label={title}
          classes={{ label: classes.label }}
        />
      </FormControl>
    );
  }

  if (toggle) {
    return (
      <FormControl className={classes.box} data-test={title.toLowerCase().replace(/\s/g, '-')} fullWidth>
        <FormControlLabel
          control={<Switch checked={value === '1' || value === true} disabled />}
          label={title}
          classes={{ label: classes.label }}
        />
      </FormControl>
    );
  }

  return (
    <Box className={classes.box} data-test={title.toLowerCase().replace(/\s/g, '-')}>
      <Typography
        data-test={`${title.toLowerCase().replace(/\s/g, '-')}-title`}
        variant="body2"
        color="textSecondary"
        className={classes.title}
      >
        {title}
      </Typography>
      {renderValue(loading, copy, value, title, dateFormat)}
    </Box>
  );
}
