import React, { useState } from 'react';
import { ApolloSandbox } from '@apollo/sandbox/react';
import { fetchAuthToken } from 'firebaseIntegration';
import { styled } from '@material-ui/core/styles';
import { Loader } from '@openx/components/core';

import { TOP_BAR_HEIGHT } from 'config';

const ApolloSandboxFull = styled(ApolloSandbox)(({ theme }) => ({
  position: 'relative',
  height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  width: '100vw',
  overflow: 'hidden',
  '& iframe': {
    position: 'absolute',
  },
}));

export default function EmbeddedSandbox() {
  const [token, setToken] = useState<string | null>(null);

  fetchAuthToken().then(token => {
    setToken(token + '');
  });

  const handleRequest = (endpointUrl, options) => {
    return fetch(endpointUrl, {
      ...options,
      headers: {
        ...options.headers,
        authorization: `Bearer ${token}`,
      },
    });
  };

  const initialState = {
    document: `
    query ExampleQuery {
      deal {
        id
        name
      }
    }
  `,
    variables: {
      offset: 0,
      limit: 100,
    },
  };

  if (!token) return <Loader />;

  return (
    <>
      <ApolloSandboxFull
        initialState={initialState}
        className="apolloSandbox"
        initialEndpoint={`${window.location.origin}/api/graphql`}
        handleRequest={handleRequest}
        hideCookieToggle={false}
      />
    </>
  );
}
