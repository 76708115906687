import React from 'react';
import { FormControlLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox';
import { CheckboxProps as FCheckboxProps } from 'formik-material-ui';
import { getIn } from 'formik';

import { Checkbox } from '../Checkbox';

export type CheckboxProps = Partial<MuiCheckboxProps> &
  Partial<FCheckboxProps> & {
    'data-test'?: string;
    label?: string;
    message?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    error?: boolean;
  };

export function CheckboxField(props: CheckboxProps): JSX.Element {
  const { message, label, error, 'data-test': dataTest, fullWidth, ...withoutCustomProps } = props;
  const isFormikConnected = props.form && props.field;

  if (isFormikConnected) {
    const {
      form: { touched, errors, isSubmitting },
      field: { name, value },
    } = props as FCheckboxProps;
    const fieldError = getIn(errors, name);
    const showError = error || (getIn(touched, name) && !!fieldError);
    const helperText = message || showError;

    return (
      <FormControl error={showError} data-test={dataTest} fullWidth={fullWidth}>
        <FormControlLabel
          control={<Checkbox {...props.field} checked={value} disabled={props.disabled || isSubmitting} />}
          label={label}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }

  return (
    <FormControl error={error} data-test={dataTest} fullWidth={fullWidth}>
      <FormControlLabel control={<Checkbox {...withoutCustomProps} />} label={label} />
      {message && <FormHelperText>{message}</FormHelperText>}
    </FormControl>
  );
}
