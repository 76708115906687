import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
}));

interface InfoProps {
  children: string;
}

export function Info({ children }: InfoProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root} data-test="info">
      <InfoIcon fontSize="small" className={classes.icon} />
      <Typography variant="body1">{children}</Typography>
    </div>
  );
}
