import React, { useCallback, useEffect, useState } from 'react';
import { useSession } from 'modules/auth/AuthProvider';
import { isEmpty } from 'lodash';
import { usePermissionsCheck, isAdmin } from 'permissions';
import { fetchAuthToken } from 'firebaseIntegration';
import { Provider } from './context';
import { MenuItemsTypes, MenuTypes } from './types';

const getDefaultTopMenuItems = (isAdmin: boolean): Array<MenuItemsTypes> => [
  {
    label: 'Deals',
    path: '/deals',
  },
  {
    label: 'Packages',
    path: isAdmin ? '/inventory/packages/curated' : '/inventory/packages',
  },
  {
    label: 'Audiences',
    path: '/audiences',
  },
  {
    label: 'Segments',
    path: '/segments',
  },
  {
    label: 'OpenAudience Taxonomy',
    path: '/taxonomy',
  },
];

const adminTopMenuItems: Array<MenuItemsTypes> = [
  {
    label: 'Settings',
    path: '/settings',
  },
];

const reportingTopMenuItems: Array<MenuItemsTypes> = [
  {
    label: 'Docs',
    path: '/docs/openaudience/oa-main/index.html',
    isInternal: false,
    isAuthenticated: true,
  },
];

const SideDrawerProvider = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const [token, setToken] = useState('');
  const canSeeAdminTabs = usePermissionsCheck([isAdmin]);
  const session = useSession();

  useEffect(() => {
    async function getToken() {
      const token = await fetchAuthToken();
      setToken(token ?? '');
    }
    getToken();
  }, []);

  const getTopMenuItems = useCallback(() => {
    const {
      sigview_url,
      account: { exchange_account_partner_id },
    } = session;

    const hasPartnerId = !isEmpty(exchange_account_partner_id);

    const reportingMenuItem = {
      label: 'Reporting',
      path: sigview_url || '',
      isInternal: false,
    };

    const adminMenuItem = [
      {
        label: 'Organizations',
        path: '/organizations',
      },
      {
        label: 'Providers',
        path: '/providers',
      },
      {
        label: 'Users',
        path: '/users',
      },
    ];

    const menuItems: MenuTypes = {
      general: getDefaultTopMenuItems(isAdmin(session)),
      administration: adminTopMenuItems,
      'reporting & doc': reportingTopMenuItems,
    };

    if (hasPartnerId && sigview_url) {
      menuItems['reporting & doc'] = [reportingMenuItem, ...reportingTopMenuItems];
    }

    if (canSeeAdminTabs) {
      menuItems.administration = [...adminMenuItem, ...adminTopMenuItems];
    }

    return menuItems;
  }, [canSeeAdminTabs, session]);

  const handleDrawerOpen = useCallback(() => {
    setOpenDrawer(prev => !prev);
  }, []);

  return <Provider value={{ openDrawer, handleDrawerOpen, menuItems: getTopMenuItems(), token }}>{children}</Provider>;
};

export default SideDrawerProvider;
