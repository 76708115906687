import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

interface FormContainerProps {
  children?: ReactNode | ReactNode[];
}

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '560px',
  },
}));

export const FormContainer = ({ children }: FormContainerProps): JSX.Element => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};
