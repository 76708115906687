import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { TextField } from '@openx/components/core';

import { LocationType } from 'modules/audiences/AudienceProvider';
import { TreeData } from 'utils/segmentsToTreeData';

import { useValidateLocations } from '../utils/useValidateLocations';

interface Props {
  setLocations: (newLocationOption: TreeData) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

const cleanInput = val => {
  return (val || '').replace(/\D*/gi, '');
};

export function ZipInput(props: Props): JSX.Element {
  const { setLocations } = props;
  const classes = useStyles();

  const [search, setSearch] = useState<string[]>([]);
  const [value, setFieldValue] = useState('');
  const [valids, invalids, validating] = useValidateLocations({ locationType: LocationType.ZIP, search });

  useEffect(() => {
    if (/^[0-9]{4,5}$/.test(value)) {
      setSearch([value]);
    }
  }, [value]);

  const ENTER_KEY = 13;
  const handleOnKeyPress = e => {
    if (e.charCode === ENTER_KEY) {
      e.stopPropagation();
      e.preventDefault();
      if (valids.length) {
        setLocations(valids[0]);
        setFieldValue('');
      }
    }
  };

  const onChange = e => {
    setFieldValue(cleanInput(e.target.value));
  };

  return (
    <>
      <TextField
        name="zipIput"
        placeholder="Enter zip code and press Enter."
        fullWidth
        margin="dense"
        data-test="zip-code-input"
        autoComplete="off"
        onKeyPress={handleOnKeyPress}
        onChange={onChange}
        value={value}
        error={!validating && invalids.length > 0}
      />
      {!validating && invalids.length > 0 && (
        <div className={classes.error} data-test="zip-code-error">
          Invalid zip code
        </div>
      )}
    </>
  );
}
