import React from 'react';
import { Columns } from '@openx/components/table';
import { UserRow } from './UsersList';
import { DateCell } from 'components/DateCell';
import { StatusIndicator, ValuePlaceholder } from '@openx/components/core';

export const tableColumns: Columns<UserRow> = [
  {
    title: 'Name',
    key: 'name',
    width: '22%',
    ellipsisMaxWidth: '400px',
    ellipsis: true,
  },
  {
    title: 'Email',
    key: 'email_address',
    width: '23%',
  },
  {
    title: 'Role',
    key: 'role',
    width: '10%',
  },
  {
    title: 'Organization',
    key: 'account_name',
    width: '15%',
    render: ({ account }) => <span>{account.name}</span>,
  },
  {
    title: 'Status',
    key: 'is_active',
    width: '10%',
    render: ({ is_active }) => {
      const statusIndicatorProps = is_active
        ? { label: 'Active', status: 'active' }
        : { label: 'Inactive', status: 'inactive' };

      return <StatusIndicator {...statusIndicatorProps} />;
    },
  },
  {
    title: 'Last Login',
    key: 'last_signin_time',
    width: '10%',
    render: ({ last_signin_time }) => (last_signin_time ? <DateCell date={last_signin_time} /> : <ValuePlaceholder />),
  },
  {
    title: 'Date Joined',
    key: 'created_date',
    width: '10%',
    render: ({ created_date }) => <DateCell date={created_date} />,
  },
];
