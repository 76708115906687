import React, { useCallback, useState, memo } from 'react';
import { Dialog, DialogProps, DialogActions, DialogContent } from '@material-ui/core';
import { isEqual } from 'lodash';
import { Formik, Form, FormikHelpers as FormikActions } from 'formik';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { DialogTitle, Button } from '@openx/components/core';
import CreateSegment from 'graphql/mutation/segments/CreateSegment.gql';
import AddUsersToSegment from 'graphql/mutation/segments/AddUsersToSegment.gql';
import UpdateSegment from 'graphql/mutation/segments/UpdateSegment.gql';
import {
  Segment,
  CreateSegmentMutation,
  CreateSegmentMutationVariables,
  AddUsersToSegmentMutation,
  AddUsersToSegmentMutationVariables,
  UpdateSegmentMutation,
  UpdateSegmentMutationVariables,
} from 'types/schemaTypes';
import { useSession } from 'modules/auth/AuthProvider';

import { NestedErrorCode, useLocalErrorHandling } from '../../../graphql';
import { validationSchema } from './validationSchema';
import { UploadFileFormFields } from './UploadFileFormFields';
import { UploadFileProgress, UploadFileProgressProps } from './UploadFileProgress';
import { UploadFileSummary, UploadFileSummaryProps, UploadFileSummaryStatus } from './UploadFileSummary';
import { NAME_FIELD, DATA_TYPE_FIELD, FILE_FIELD, DATA_CHUNK_SIZE } from './constants';
import { DataType } from './dataType';
import { prepareDataToUpload } from './prepareDataToUpload';
import { createFullName } from '../utils';

export interface FormikState {
  [NAME_FIELD]: string;
  [DATA_TYPE_FIELD]?: DataType;
  [FILE_FIELD]?: File;
}

const initialValues: FormikState = {
  [NAME_FIELD]: '',
};

const DEFAULT_SEGMENT_CATEGORY = 'direct';
const DEFAULT_SEGMENT_SUB_CATEGORY = 'uploaded';
const DEFAULT_SEGMENT_PROVIDER = 'uploaded';

export interface UploadFileDialogProps extends Pick<DialogProps, 'open'> {
  onClose: (success: boolean) => void;
}

export function UploadFileDialogRaw({ open, onClose }): JSX.Element {
  const { account, id: userId } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadFileProgress, setUploadFileProgress] = useState<UploadFileProgressProps>();
  const [uploadFileSummary, setUploadFileSummary] = useState<UploadFileSummaryProps>();
  const [createSegment] = useMutation<CreateSegmentMutation, CreateSegmentMutationVariables>(CreateSegment);
  const [updateSegment] = useMutation<UpdateSegmentMutation, UpdateSegmentMutationVariables>(UpdateSegment);
  const [addUsersToSegment] = useMutation<AddUsersToSegmentMutation, AddUsersToSegmentMutationVariables>(
    AddUsersToSegment,
    {
      fetchPolicy: 'no-cache',
    },
  );

  useLocalErrorHandling(
    CreateSegment,
    useCallback(
      error => {
        if (error.code !== NestedErrorCode.JOINT_UNIQUENESS_VIOLATION || error.path !== 'objects.name') return false;
        enqueueSnackbar('Segment with such name already exists', { variant: 'error' });
        return true;
      },
      [enqueueSnackbar],
    ),
  );

  const onCloseHandler = useCallback(() => {
    onClose(!!uploadFileSummary);
  }, [onClose, uploadFileSummary]);

  const onExitedHandler = useCallback((resetForm: () => void) => {
    setUploadFileProgress(undefined);
    setUploadFileSummary(undefined);
    resetForm();
  }, []);

  const handleSubmit = useCallback(
    async (formikState: FormikState, { setSubmitting }: FormikActions<FormikState>): Promise<unknown> => {
      let createdSegment: Segment | undefined;

      return new Promise<void>((resolve, reject) => {
        const startProcessingUploadedDataTime = new Date().getTime();
        const file = formikState[FILE_FIELD] as File;
        const dataType = formikState[DATA_TYPE_FIELD] as DataType;
        const showProgress = file.size > DATA_CHUNK_SIZE;
        let totalRecordsProcessed = 0;
        let totalRecordsFailed = 0;
        let progress = 0;

        if (showProgress) {
          setUploadFileProgress({
            recordsProcessed: totalRecordsProcessed,
            progress,
          });
        }

        prepareDataToUpload({
          file,
          dataType,
          chunkSize: DATA_CHUNK_SIZE,
          onProcessedDataChunk: async (recordsProcessed: number, dataToUpload: string[][]): Promise<void> => {
            totalRecordsProcessed += recordsProcessed;
            totalRecordsFailed += recordsProcessed - dataToUpload.length;
            progress += Math.round(((progress + DATA_CHUNK_SIZE) / file.size) * 100);

            if (!dataToUpload.length) return;

            if (!createdSegment) {
              const createSegmentResponse = await createSegment({
                variables: {
                  data: {
                    account_id: account.id,
                    name: formikState[NAME_FIELD],
                    full_name: createFullName(formikState[NAME_FIELD]),
                    description: '',
                    is_leaf: true,
                    status: 'error',
                    sub_type: 'uploaded',
                    type: '1p',
                    source: '',
                    category: DEFAULT_SEGMENT_CATEGORY,
                    sub_category: DEFAULT_SEGMENT_SUB_CATEGORY,
                    provider_id: DEFAULT_SEGMENT_PROVIDER,
                    path: [DEFAULT_SEGMENT_CATEGORY, DEFAULT_SEGMENT_SUB_CATEGORY, formikState[NAME_FIELD]],
                  },
                },
              });

              createdSegment = createSegmentResponse.data?.segment?.returning[0] as Segment;
            }

            await addUsersToSegment({
              variables: {
                segmentId: createdSegment.id,
                data: {
                  headers: [formikState[DATA_TYPE_FIELD] as string],
                  data: dataToUpload,
                },
              },
            });

            if (showProgress) {
              setUploadFileProgress({
                recordsProcessed: totalRecordsProcessed,
                progress,
              });
            }
          },
          onComplete: async () => {
            if (!createdSegment) {
              throw new Error('No valid records found');
            }

            await updateSegment({
              variables: {
                where: {
                  id: { _eq: createdSegment.id },
                },
                set: {
                  status: 'publishing',
                },
              },
            });

            const endProcessingUploadedDataTime = new Date().getTime();
            const processingUploadedDataTimeInSeconds = Math.round(
              (endProcessingUploadedDataTime - startProcessingUploadedDataTime) / 1000,
            );

            setUploadFileSummary({
              status: UploadFileSummaryStatus.SUCCESS,
              dataType: dataType,
              processingTime: processingUploadedDataTimeInSeconds,
              recordsProcessed: totalRecordsProcessed,
              recordsFailed: totalRecordsFailed,
            });

            resolve();
          },
          onError: error => {
            if (createdSegment) {
              setUploadFileSummary({
                status: UploadFileSummaryStatus.FAILED,
              });
            }

            reject(error);
          },
        });
      })
        .catch(error => {
          if (!error.graphQLErrors?.length) {
            enqueueSnackbar('Failed to upload segment', { variant: 'error' });
          }
          setUploadFileProgress(undefined);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [addUsersToSegment, createSegment, account.id, updateSegment, enqueueSnackbar],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {formikProps => {
        const { isSubmitting, isValid, dirty, resetForm } = formikProps;
        const handleExited = () => {
          onExitedHandler(resetForm);
        };

        return (
          <Dialog
            open={open}
            onClose={(_, reason) => {
              if (isSubmitting && reason === 'backdropClick') {
                return false;
              }
              if (isSubmitting && reason === 'escapeKeyDown') {
                return false;
              }

              return onCloseHandler();
            }}
            TransitionProps={{ onExited: handleExited }}
          >
            <DialogTitle onClose={onCloseHandler} onCloseDisabled={isSubmitting}>
              Upload Segment
            </DialogTitle>
            <Form data-test="upload-file-form">
              {!uploadFileProgress && !uploadFileSummary && (
                <>
                  <DialogContent dividers>
                    <UploadFileFormFields {...formikProps} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onCloseHandler} variant="text" data-test="cancel">
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      loading={isSubmitting}
                      disabled={!isValid || !dirty || isSubmitting}
                      data-test="upload"
                    >
                      Upload
                    </Button>
                  </DialogActions>
                </>
              )}

              {uploadFileProgress && !uploadFileSummary && (
                <DialogContent dividers>
                  <UploadFileProgress {...uploadFileProgress} />
                </DialogContent>
              )}

              {uploadFileSummary && (
                <>
                  <DialogContent dividers>
                    <UploadFileSummary {...uploadFileSummary} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={onCloseHandler} variant="text" data-test="close">
                      Close
                    </Button>
                  </DialogActions>
                </>
              )}
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
}

export const UploadFileDialog = memo(UploadFileDialogRaw, (prevProps, nextProps) => isEqual(prevProps, nextProps));
