import { AuthState, StateActionArguments } from './types';

export const mapOfAuthStateToAction: Record<AuthState, (args: StateActionArguments) => Promise<void> | void> = {
  // Skip signing in and permission check when running for tests
  // Go to checking permissions immediately if we have user data
  // Redirect to login if there is no user logged in and the page is private
  [AuthState.UNAUTHENTICATED]: ({ firebaseListener, history, pageIsPublic, setAuthState, skipAuth }) => {
    if (skipAuth) {
      setAuthState(AuthState.PERMISSIONS_CHECKED);
      return;
    }

    if (firebaseListener.user) {
      setAuthState(AuthState.AUTHENTICATED);
      return;
    }

    if (!firebaseListener.loading && !pageIsPublic) {
      history.replace(`/login?redir=${document.location.pathname}`);
    }
  },

  // Trigger permission check query
  [AuthState.AUTHENTICATED]: ({
    checkPermissionQuery,
    firebaseListener,
    setAuthState,
    triggerCheckPermissionsQuery,
  }) => {
    if (!checkPermissionQuery.called) {
      triggerCheckPermissionsQuery({ variables: { uid: firebaseListener.user!.uid } });
    } else {
      checkPermissionQuery.refetch?.({ uid: firebaseListener.user!.uid });
    }
    setAuthState(AuthState.CHECKING_PERMISSIONS);
  },

  // If permission check data arrived refetch token if needed
  [AuthState.CHECKING_PERMISSIONS]: async ({ checkPermissionQuery, fetchAuthToken, setAuthState }) => {
    if (!checkPermissionQuery.data) return;

    const { checkUserPermissions } = checkPermissionQuery.data;

    if (checkUserPermissions?.forceRefreshToken) {
      // only happened to users which dont have customClaims
      // Which means that account was created before proper permissions model
      await fetchAuthToken(true);
    }

    setAuthState(AuthState.PERMISSIONS_CHECKED);
  },

  // Trigger query for session for the first time or again in case of reloading session
  [AuthState.PERMISSIONS_CHECKED]: ({ sessionQuery, setAuthState, triggerSessionQuery }) => {
    if (!sessionQuery.called) {
      triggerSessionQuery();
    } else {
      sessionQuery.refetch?.();
    }
    setAuthState(AuthState.OBTAINING_SESSION);
  },

  // If session data arrived go next state
  [AuthState.OBTAINING_SESSION]: ({ sessionQuery, setAuthState }) => {
    if (sessionQuery.loading || !sessionQuery.data?.getSession) return;
    setAuthState(AuthState.SESSION_OBTAINED);
  },

  // If terms are not accepted and user is on private page then redirect to terms acceptance
  [AuthState.SESSION_OBTAINED]: ({ currentPage, history, pageIsPublic, sessionContextValue }) => {
    if (pageIsPublic || sessionContextValue?.terms_and_conditions_accepted || currentPage === '/terms-acceptance')
      return;
    history.push('/terms-acceptance');
  },

  // If session reload was requested (by changing to SESSION_STALE) than wait for session query to start refetching
  [AuthState.SESSION_STALE]: ({ sessionQuery, setAuthState }) => {
    if (!sessionQuery.loading) return;
    setAuthState(AuthState.OBTAINING_SESSION);
  },
};
