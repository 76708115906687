import React from 'react';
import { makeStyles } from '@material-ui/core';
import { abbreviatedNumber } from '@openx/utils';

import { ReachTooltip } from 'components/ReachTooltip';
import { TooltipContent } from './TooltipContent';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
});

interface Props {
  id: string;
  reach?: number | null;
}

export const AddressableReach = ({ id, reach }: Props) => {
  const label = reach ? abbreviatedNumber(reach) : 'Pending';
  const { root } = useStyles();

  return (
    <div className={root}>
      <span>{label}</span>
      <ReachTooltip customWidth title={<TooltipContent id={id} reach={reach} />} />
    </div>
  );
};
