import React from 'react';
import {
  FormControlLabel,
  FormHelperText,
  FormControl,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, SwitchProps as FSwitchProps } from 'formik-material-ui';
import { FieldProps, getIn } from 'formik';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
  },
}));

export type SwitchFieldProps = Partial<FSwitchProps> &
  Partial<MuiSwitchProps> & {
    'data-test'?: string;
    label?: string | JSX.Element;
    message?: string;
    error?: boolean;
  };

export function SwitchField(props: SwitchFieldProps): JSX.Element {
  const { label, error, message, 'data-test': dataTest, ...withoutCustomProps } = props;
  const { root } = useStyles();
  const isFormikConnected = props.field && props.form;

  const defaultProps = {
    disableRipple: true,
    color: 'primary',
  } as Partial<SwitchFieldProps>;

  if (isFormikConnected) {
    const {
      form: { touched, errors },
      field: { name },
    } = props as FieldProps;

    const fieldError = getIn(errors, name);
    const showError = error || (getIn(touched, name) && !!fieldError);
    const helperText = message || showError;

    return (
      <FormControl error={showError} data-test={dataTest}>
        <FormControlLabel
          control={<Switch {...defaultProps} {...(withoutCustomProps as FSwitchProps)} />}
          label={label}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }

  return (
    <div className={root}>
      <FormControl error={error} data-test={dataTest}>
        <FormControlLabel control={<MuiSwitch {...defaultProps} {...withoutCustomProps} />} label={label} />
        {message && <FormHelperText>{message}</FormHelperText>}
      </FormControl>
    </div>
  );
}
