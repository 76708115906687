import { prepareHighlightRegExp } from '@openx/components/core';

import { SearchFiltersValue, isSearchFiltersValue } from './SearchFilters';
import { prepareSortAlphabeticallyHandler } from './sort';
import {
  AllTableCriteria,
  CriteriaDimension,
  currentPageRange,
  PaginationCriteria,
  SortCriteria,
} from './tableCriteria';
import { adjustPaginationCriteria } from './tableHelpers';

function basicSearch(wholePhrase: unknown, searchPhrase: string): boolean {
  const regexp = prepareHighlightRegExp(searchPhrase);

  return typeof wholePhrase === 'string' && Boolean(wholePhrase.match(regexp));
}

function fieldSearch(fieldValue: unknown, searchPhrase: string): boolean {
  if (Array.isArray(fieldValue)) {
    return fieldValue.some(singleValue => basicSearch(singleValue, searchPhrase));
  }

  return basicSearch(fieldValue, searchPhrase);
}

export type ApplyFilter<ItemT extends {}, FiltersT extends {}> = (data: ItemT[], filters: FiltersT) => ItemT[];

export function applySearch<ItemT extends {}>(items: ItemT[], search: SearchFiltersValue): ItemT[] {
  const { byPhrase, byValue } = search;

  let result = items;

  if (byPhrase) {
    const { fields, phrase } = byPhrase;

    if (phrase && fields) {
      result = result.filter((item: ItemT) => fields.some(fieldKey => fieldSearch(item[fieldKey], phrase)));
    }
  }

  if (byValue) {
    result = result.filter((item: ItemT) =>
      byValue.every(({ field, values, valueDeriver = x => x }) =>
        values.length
          ? values
              .map(value => (value ? value.toLowerCase() : value))
              .includes(
                typeof valueDeriver(item[field]) === 'string'
                  ? valueDeriver(item[field]).toLowerCase()
                  : valueDeriver(item[field]),
              )
          : true,
      ),
    );
  }

  return result;
}

function applySort<ItemT extends {}>(items: ItemT[], sort: SortCriteria): ItemT[] {
  const { valueDerivers, column, direction } = sort;

  const sorted = [...items].sort(
    prepareSortAlphabeticallyHandler(
      (item: ItemT) => (valueDerivers?.[column] ? valueDerivers[column](item[column]) : item[column] || ''),
      direction,
    ),
  );

  return [...sorted];
}

export function applyPagination<ItemT extends {}>(items: ItemT[], pagination: PaginationCriteria): ItemT[] {
  const range = currentPageRange(pagination.pageNumber, pagination.pageSize, pagination.totalCount);

  return items.slice(range.min - 1, range.max);
}

interface CriteriaProcessingOptions<ItemT extends {}, FiltersT extends {}> {
  onCriteriaAdjust?: (criteria: AllTableCriteria<FiltersT>) => void;
  ignoreDimensions?: CriteriaDimension[];
  applyCustomFilter?: ApplyFilter<ItemT, FiltersT>;
}

export function clientSideCriteriaProcessing<ItemT extends {}, FiltersT extends {}>(
  allItems: ItemT[],
  criteria: AllTableCriteria<FiltersT>,
  options: CriteriaProcessingOptions<ItemT, FiltersT> = {},
): ItemT[] {
  const { filters, sort, pagination } = criteria;
  const { onCriteriaAdjust, ignoreDimensions, applyCustomFilter } = options;

  function dimensionIgnored(dimension: CriteriaDimension): boolean {
    return !!ignoreDimensions && ignoreDimensions.includes(dimension);
  }

  function filtersStage(input: ItemT[]): ItemT[] {
    if (dimensionIgnored(CriteriaDimension.FILTERS)) {
      return input;
    }

    if (applyCustomFilter && filters) {
      return applyCustomFilter(input, filters);
    }

    if (isSearchFiltersValue(filters)) {
      return applySearch(input, filters);
    }

    return input;
  }

  function sortStage(input: ItemT[]): ItemT[] {
    if (sort && !dimensionIgnored(CriteriaDimension.SORT)) {
      return applySort(input, sort);
    }

    return input;
  }

  function paginationStage(input: ItemT[]): ItemT[] {
    if (pagination) {
      const itemsNumberChanged = pagination && pagination.totalCount !== input.length;
      const adjustedCriteria = adjustPaginationCriteria(criteria, input.length, pagination.hasMore);
      onCriteriaAdjust && itemsNumberChanged && onCriteriaAdjust(adjustedCriteria);

      if (adjustedCriteria.pagination) {
        return applyPagination(input, adjustedCriteria.pagination);
      }
    }

    return input;
  }

  return [filtersStage, sortStage, paginationStage].reduce((input, stage) => stage(input), allItems);
}
