import { DealStatus } from 'modules/deals/constants';

export function mapStatus(status?: string | DealStatus): {
  label: string;
  status: string;
} | null {
  switch (status) {
    case DealStatus.PAUSED:
      return {
        label: 'Paused',
        status: 'inactive',
      };
    case DealStatus.PUBLISHING:
    case DealStatus.READY_FOR_ACTIVATION:
      return {
        label: 'Publishing',
        status: 'inactive',
      };
    case DealStatus.ACTIVE:
      return {
        label: 'Active',
        status: 'active',
      };
    case DealStatus.EXPIRED:
      return {
        label: 'Expired',
        status: 'warning',
      };
    case DealStatus.ERROR:
      return {
        label: 'Error',
        status: 'error',
      };
    default:
      return null;
  }
}
