import React, { ReactNode, useMemo } from 'react';

import { HighlightRules } from './highlightRules';
import { DataSection, DataSectionProps } from './parts/sections/DataSection';
import { ManagementSection, ManagementSectionProps } from './parts/sections/ManagementSection';
import { ExpandComponentProps } from './parts/TableRowExpanded';
import { AllTableCriteria, ChangeCriteriaHandler, CriteriaDimension } from './tableCriteria';
import {
  BaseRow,
  Columns,
  prepareChangeCriteriaHandler,
  RowAction,
  RowActionButtonBaseProps,
  RowActionProps,
} from './tableHelpers';

export interface TableProps<RowT extends BaseRow, FiltersT extends {} = {}> {
  data: RowT[];
  columns: Columns<RowT>;
  onRowClick?: RowAction<RowT>;
  loading: boolean;
  children?: ReactNode;
  isTree?: boolean;
  isForcedExpanded?: boolean;
  treeChildrenKey?: string;
  treeLevelItems?: number;
  fetchTreeChildren?: (data: RowT) => Promise<RowT[]>;
  hideHeader?: boolean;
  expandComponent?: React.ComponentType<ExpandComponentProps<RowT>>;
  criteria?: AllTableCriteria<FiltersT>;
  onCriteriaChange?: ChangeCriteriaHandler<CriteriaDimension, FiltersT>;
  highlightRules?: HighlightRules;
  customManagementSection?: ReactNode;
  primaryRowAction?: RowActionButtonBaseProps<RowT>;
  secondaryRowAction?: RowActionButtonBaseProps<RowT>;
  rowActions?: RowActionProps<RowT>[] | ((row: RowT) => RowActionProps<RowT>[]);
  stickyHeaderPosition?: number;
  fixedLayout?: boolean;
  enableTableHeaderCustomziation?: boolean;
}

export function Table<RowT extends BaseRow, FiltersT extends {} = {}>(props: TableProps<RowT, FiltersT>): JSX.Element {
  const { onCriteriaChange, criteria, highlightRules, children, stickyHeaderPosition, enableTableHeaderCustomziation } =
    props;

  const { onPaginationChange, onSortChange } = useMemo(
    () => ({
      onPaginationChange: prepareChangeCriteriaHandler(CriteriaDimension.PAGINATION, onCriteriaChange),
      onSortChange: prepareChangeCriteriaHandler(CriteriaDimension.SORT, onCriteriaChange),
    }),
    [onCriteriaChange],
  );

  const managementSectionProps: ManagementSectionProps = {
    ...props,
    onPaginationChange,
    paginationCriteria: criteria && criteria.pagination,
  };

  const dataSectionProps: DataSectionProps<RowT> = {
    ...props,
    onSortChange,
    sortCriteria: criteria && criteria.sort,
    highlightRules,
    stickyHeaderPosition,
    children,
    enableTableHeaderCustomziation,
  };

  return (
    <div data-test={props['data-test']}>
      {(managementSectionProps.customManagementSection || managementSectionProps.customManagementSection === null) && (
        <ManagementSection {...managementSectionProps} />
      )}
      <DataSection {...dataSectionProps} />
      <ManagementSection {...managementSectionProps} />
    </div>
  );
}
