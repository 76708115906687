import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { User } from '@firebase/auth-types';
import { getEnvironmentConfigs } from 'environmentConfigs';

export async function initializeFirebase() {
  try {
    const { firebase: config } = await getEnvironmentConfigs();
    firebase.initializeApp(config);
  } catch (e) {
    console.error('Could not initialize firebase:', e);
  }
}

export interface LoginProps {
  email: string;
  password: string;
  redir?: string; // optional, redirects with `token=...` query arg if specified
}

export async function login({ email, password }: LoginProps): Promise<unknown> {
  return await firebase.auth().signInWithEmailAndPassword(email, password);
}

export async function logout(): Promise<void> {
  return await firebase.auth().signOut();
}

export async function getCurrentUser(): Promise<User | null> {
  return await firebase.auth().currentUser;
}

export async function fetchAuthToken(forceRefresh = false): Promise<string | null> {
  const currentUser = await firebase.auth().currentUser;

  if (!currentUser) {
    return null;
  }

  return await currentUser.getIdToken(forceRefresh);
}

export async function sendPasswordResetEmail(email: string): Promise<void> {
  return await firebase.auth().sendPasswordResetEmail(email);
}

export async function verifyPasswordResetCode(actionCode: string): Promise<string> {
  return await firebase.auth().verifyPasswordResetCode(actionCode);
}

export async function confirmPasswordReset(actionCode: string, newPassword: string): Promise<void> {
  return await firebase.auth().confirmPasswordReset(actionCode, newPassword);
}
